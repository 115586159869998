import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getNestedAssetNodeId } from "../definitions/assetsSlice";

export interface DefaultAssetState {
  defaultAssetId: string | null | undefined;
  defaultAssetSourceStack: Array<string> | null;
}

export const initialState: DefaultAssetState = {
  defaultAssetId: null,
  defaultAssetSourceStack: [],
};

export const defaultAssetSlice = createSlice({
  name: "defaultEnt",
  initialState,
  reducers: {
    selected: (state, action: PayloadAction<DefaultAssetState>) => {
      state.defaultAssetId = action.payload.defaultAssetId;
      state.defaultAssetSourceStack = action.payload.defaultAssetSourceStack;
    },
    skipped: (state, action: PayloadAction<void>) => {
      state.defaultAssetId = undefined;
      state.defaultAssetSourceStack = null;
    },
    cleared: (state, action: PayloadAction<void>) => {
      state.defaultAssetId = null;
      state.defaultAssetSourceStack = null;
    },
  },
});

export const {
  selected: defaultAssetSelected,
  skipped: defaultAssetSkipped,
  cleared: defaultAssetCleared,
} = defaultAssetSlice.actions;

export const selectDefaultAssetId = createSelector(
  (state: RootState) => state.user.defaultAsset.defaultAssetId,
  (defaultAssetId) => defaultAssetId
);

export const selectDefaultAssetSourceStack = createSelector(
  (state: RootState) => state.user.defaultAsset.defaultAssetSourceStack,
  (defaultSourceStack) => defaultSourceStack
);

export const selectDefaultAssetNodeId = createSelector(
  selectDefaultAssetSourceStack,
  selectDefaultAssetId,
  (defaultAssetSourceStack, defaultAssetId) =>
    defaultAssetId
      ? getNestedAssetNodeId(defaultAssetSourceStack, defaultAssetId)
      : undefined
);

export default defaultAssetSlice.reducer;
