import * as React from "react";

function SvgComponent(props: any) {
  return (
    <svg
      id="prefix__Icon_Jobs"
      data-name="Icon / Jobs"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <g
        id="prefix__Group_49"
        data-name="Group 49"
        transform="translate(-1611.852 -1643.001)"
      >
        <g id="prefix__Group_48" data-name="Group 48">
          <path
            id="prefix__Path_1598"
            data-name="Path 1598"
            className="prefix__cls-1"
            d="M12901.5 1286.63a2.2 2.2 0 011.5 2.12v10a4.236 4.236 0 01-4.2 4.25h-10a2.332 2.332 0 01-2.2-1.5h12.2a2.719 2.719 0 002.7-2.75v-12.12zm-3.3-3.63a2.258 2.258 0 012.3 2.25v13a2.258 2.258 0 01-2.3 2.25h-13a2.237 2.237 0 01-2.2-2.25v-13a2.237 2.237 0 012.2-2.25zm0 1.5h-13a.72.72 0 00-.7.75v13a.72.72 0 00.7.75h13a.744.744 0 00.8-.75v-13a.744.744 0 00-.8-.75z"
            transform="translate(-11271.148 360.001)"
          />
        </g>
        <path
          id="prefix__Path_1804"
          data-name="Path 1804"
          className="prefix__cls-1"
          d="M1904 4047.44l6.47-6.47a.75.75 0 011.13.98l-.07.08-7 7a.748.748 0 01-.98.07l-.08-.07-3-3a.75.75 0 01.98-1.13l.08.07 2.47 2.47 6.47-6.47z"
          transform="translate(-285 -2393)"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
