import OpenWithIcon from "@mui/icons-material/OpenWith";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  ImageList,
  Paper,
  PaperProps,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { selectEditDialogCustomComponents } from "../../../../store/common/customGridComponentsSlice";
import {
  editGridsDialogClosed,
  selectEditGridsDialogGridName,
  selectEditGridsDialogOpen,
  selectEditGridsDialogTiles,
} from "../../../../store/common/dialogs/editGridsSlice";
import {
  cancelNewLayout,
  gridEdititingFinished,
  saveGloballyNewLayout,
  saveNewLayout,
  selectAreGridsEditable,
  selectBreakpoint,
  selectGridItems,
} from "../../../../store/common/gridsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import SingleEditorTile from "../../grid/SingleEditorTile";
import ConfirmSavingGlobalLayoutDialogComponent from "./ConfirmSavingGlobalLayoutDialogComponent";

const DraggablePaper = styled(Paper)(({ theme }) => ({
  "&.MuiDialog-paper": {
    width: "25rem",
    position: "absolute",
    zIndex: 1,
    right: 0,
    bottom: 160,
    margin: 0,
  },
}));

const StyledOpenWithIcon = styled(OpenWithIcon)(({ theme }) => ({
  right: "1rem",
  width: "2rem",
  height: "2rem",
}));

const DialogTitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

interface DraggablePaperComponentProps extends PaperProps {}

const DraggablePaperComponent: FunctionComponent<
  DraggablePaperComponentProps
> = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds="#container"
      positionOffset={{ x: 0, y: 100 }}
    >
      <DraggablePaper {...props} elevation={2} />
    </Draggable>
  );
};

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  justifyContent: "center",
  cursor: "-webkit-grab" /* fallback if grab cursor is unsupported */,
  "&:active": {
    cursor: "-webkit-grabbing",
  },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  gap: "0.5rem",
}));

const ComponentsListContainer = styled(SimpleBar)(({ theme }) => ({
  overflow: "auto",
  maxHeight: "22rem",
  width: "100%",
}));

const StyledGridComponentsList = styled(ImageList)(({ theme }) => ({
  "&.MuiImageList-root": {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  "& .MuiImageListItem-img": {
    objectFit: "scale-down",
  },
}));

interface GridEditDialogComponentProps {}

const GridEditDialogComponent: FunctionComponent<
  GridEditDialogComponentProps
> = () => {
  const { t } = useTranslation();
  const [saveGlobal, setSaveGlobal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const gridName = useAppSelector(selectEditGridsDialogGridName);
  const childrenList = useAppSelector(selectEditGridsDialogTiles);
  const gridItems = useAppSelector(selectGridItems(gridName));
  // const mayEditGlobal = useAppSelector(selectMayEditGlobalConfiguration);
  const editGridsDialogOpen = useAppSelector(selectEditGridsDialogOpen);
  const areGridsEditable = useAppSelector(selectAreGridsEditable);
  const customComponents = useAppSelector(selectEditDialogCustomComponents);
  const breakpoint = useAppSelector(selectBreakpoint);

  const handleCancelNewLayout = () => {
    gridName && dispatch(cancelNewLayout(gridName));
    dispatch(gridEdititingFinished());
    dispatch(editGridsDialogClosed());
  };

  const handleSaveNewLayout = () => {
    if (saveGlobal) {
      setOpen(true);
    } else {
      gridName && dispatch(saveNewLayout(gridName));
      dispatch(gridEdititingFinished());
      dispatch(editGridsDialogClosed());
    }
  };

  return (
    <>
      <Dialog
        open={editGridsDialogOpen && areGridsEditable}
        PaperComponent={DraggablePaperComponent}
        hideBackdrop
        disableEnforceFocus
        style={{ display: "contents" }}
      >
        <StyledDialogTitle>
          <DialogTitleBox id="draggable-dialog-title">
            <div>{gridName ? breakpoint : ""}</div>
            <div>{gridName ? t(gridName) : t("No layout")}</div>
            <StyledOpenWithIcon />
          </DialogTitleBox>
        </StyledDialogTitle>
        <DialogContent sx={{ minHeight: "24rem" }}>
          {gridName ? (
            <>
              <ComponentsListContainer>
                <StyledGridComponentsList
                  rowHeight={240}
                  variant="masonry"
                  cols={1}
                >
                  {childrenList.filter(
                    (c) =>
                      !gridItems.some(
                        (i) => c.key.toLowerCase() === i.toLowerCase()
                      )
                  ).length === 0 && (
                    <Typography variant="subtitle2" align="center">
                      {t("No items")}
                    </Typography>
                  )}
                  {childrenList.map((i) => {
                    const customComponent = customComponents.find(
                      (c) =>
                        c.key.toLowerCase() === i.key.toLowerCase() &&
                        c.gridName === gridName
                    );
                    return (i.key.toLowerCase() !== "editable_component" &&
                      !gridItems.some(
                        (oi) => oi.toLowerCase() === i.key.toLowerCase()
                      )) ||
                      (i.key.toLowerCase() === "editable_component" &&
                        !customComponents.some((c) => c.isNew)) ? (
                      <SingleEditorTile
                        gridName={gridName}
                        key={i.key}
                        tileKey={i.key}
                        title={i.title}
                        width={i.width}
                        height={i.height}
                        background={i.background}
                        deletable={
                          customComponent !== undefined &&
                          !customComponent.isNew
                        }
                      />
                    ) : null;
                  })}
                </StyledGridComponentsList>
              </ComponentsListContainer>
              {/* {mayEditGlobal && ( */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveGlobal}
                    onChange={(e) => setSaveGlobal(e.target.checked)}
                  />
                }
                label={t("Set as default for all entities")}
              />
              {/* )} */}
            </>
          ) : (
            <Typography variant="subtitle1" color="text.secondary">
              {t("NoLayoutToEditMessage")}
            </Typography>
          )}
        </DialogContent>
        <StyledDialogActions>
          <Tooltip
            title={
              customComponents.some((c) => c.isNew)
                ? t("CantSaveLayoutMsg").toString()
                : ""
            }
          >
            <div>
              <Button
                variant="contained"
                color="success"
                onClick={() => handleSaveNewLayout()}
                disabled={customComponents.some((c) => c.isNew) || !gridName}
              >
                {t("Save")}
              </Button>
            </div>
          </Tooltip>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleCancelNewLayout()}
          >
            {t("Cancel")}
          </Button>
        </StyledDialogActions>
      </Dialog>
      <ConfirmSavingGlobalLayoutDialogComponent
        open={open}
        handleConfirm={() => {
          gridName && dispatch(saveGloballyNewLayout(gridName));
          setOpen(false);
          dispatch(gridEdititingFinished());
          dispatch(editGridsDialogClosed());
        }}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default GridEditDialogComponent;
