import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../../..";
import {
  taskCompletedSocketEvent,
  taskPausedSocketEvent,
} from "../../../sockets/manufacturing-execution/tasksSlice";
import { runningTaskStateChangedDialogOpened } from "./runningTaskStateChangedSlice";
import { selectRunnedTaskId } from "../../../main/job-execution/jobExecutionSlice";

const runningTaskStateChangedListener = createListenerMiddleware();

runningTaskStateChangedListener.startListening({
  matcher: isAnyOf(taskPausedSocketEvent, taskCompletedSocketEvent),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId } = action.payload;
    const runnedTaskId = selectRunnedTaskId(
      listenerApi.getOriginalState() as RootState
    );
    if (taskId === runnedTaskId) {
      listenerApi.dispatch(runningTaskStateChangedDialogOpened());
    }
  },
});

export default runningTaskStateChangedListener;
