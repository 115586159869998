import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Column } from "react-table";
import { AppThunk, RootState } from "..";
import TableName from "../../helpers/enums/tableName";
import { LocalStorageKey } from "../../helpers/localStorageKeys";
import SnackbarUtils from "../../helpers/snackbarHelper";
import i18n from "../../i18n";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../services/localStorageService";
import { changeSystemAttributeDetails } from "../../services/main/systemAttributesService";
import { editTableDialogClosed } from "./dialogs/editTableSlice";
import ConfigurationAttributes from "../../helpers/enums/configurationAttributes";
import { getTableConfigurationAttribute } from "../../helpers/tables/getTableConfigurationAttribute";
import { loadNestedAttributeGroupsAsync } from "../definitions/attributeGroupsSlice";

export const localStorageKey: Map<TableName, LocalStorageKey> = new Map<
  TableName,
  LocalStorageKey
>([
  [
    TableName.PRODUCTION_HISTORY,
    LocalStorageKey.PRODUCTION_HISTORY_TABLE_CONFIGURATION,
  ],
  [TableName.UTIL_HISTORY, LocalStorageKey.UTIL_HISTORY_TABLE_CONFIGURATION],
  [TableName.WORK_QUEUE, LocalStorageKey.WORK_QUEUE_TABLE_CONFIGURATION],
  [
    TableName.WORK_QUEUE_TREE,
    LocalStorageKey.WORK_QUEUE_TREE_TABLE_CONFIGURATION,
  ],
]);

export const configurationAttributeName = new Map<
  TableName,
  ConfigurationAttributes
>([
  [
    TableName.WORK_QUEUE,
    ConfigurationAttributes.WORK_QUEUE_TABLE_CONFIGURATION,
  ],
  [
    TableName.WORK_QUEUE_TREE,
    ConfigurationAttributes.WORK_QUEUE_TREE_TABLE_CONFIGURATION,
  ],
  [
    TableName.PRODUCTION_HISTORY,
    ConfigurationAttributes.PRODUCTION_HISTORY_TABLE_CONFIGURATION,
  ],
  [
    TableName.UTIL_HISTORY,
    ConfigurationAttributes.UTIL_HISTORY_TABLE_CONFIGURATION,
  ],
]);

interface TableConfiguration {
  id: string | null;
  columns: Array<ColumnDefinition>;
}

interface ColumnDefinition {
  columnId: string;
  // width?: number | string;
}

export interface TablesState {
  areTablesEditable: boolean;
  configurations: Record<string, TableConfiguration>;
}

export const initialState: TablesState = {
  areTablesEditable: false,
  configurations: {} as Record<string, TableConfiguration>,
};

interface UpdateTableColumnsParameters {
  name: TableName;
  columns: Array<string> | undefined;
}

export const saveNewTableLayout =
  (name: TableName): AppThunk =>
  (dispatch, getState) => {
    const storageKey = localStorageKey.get(name);
    if (storageKey) {
      saveToLocalStorage(
        storageKey,
        getState().tables.configurations[name].columns
      );
    }
  };

export const resetTablesConfiguration =
  (): AppThunk => async (dispatch, getState) => {
    for (var tableName in TableName) {
      const tableAttr = getTableConfigurationAttribute(
        tableName as TableName,
        getState()
      );
      if (tableAttr && tableAttr.value) {
        const storageKey = localStorageKey.get(TableName[tableName]);
        storageKey && deleteFromLocalStorage(storageKey);
        dispatch(
          tableColumnsUpdated({
            name: tableName as TableName,
            columns: JSON.parse(tableAttr.value).map((tc) => tc.columnId),
          })
        );
      }
    }
  };

export const saveNewTableLayoutGlobal =
  (tableName: TableName): AppThunk =>
  async (dispatch, getState) => {
    const storageKey = localStorageKey.get(tableName);
    if (storageKey) {
      saveToLocalStorage(
        storageKey,
        getState().tables.configurations[tableName].columns
      );
    }
    const tableAttr = getTableConfigurationAttribute(
      tableName as TableName,
      getState()
    );

    if (tableAttr?.id) {
      await changeSystemAttributeDetails(
        tableAttr?.id,
        tableAttr?.name,
        tableAttr?.description,
        JSON.stringify(getState().tables.configurations[tableName].columns),
        4
      );
      await dispatch(loadNestedAttributeGroupsAsync());
      SnackbarUtils.success(i18n.t("SaveTableToMesSuccess"));
    }
  };

export const loadDefaultTablesConfiguration =
  (): AppThunk => async (dispatch, getState) => {
    for (var tableName in TableName) {
      const storageKey = localStorageKey.get(TableName[tableName]);
      const tableAttr = getTableConfigurationAttribute(
        tableName as TableName,
        getState()
      );
      if (storageKey) {
        const localConfiguration = getFromLocalStorage(storageKey);
        if (localConfiguration !== undefined) {
          dispatch(
            tableColumnsDefinitionLoaded({
              name: tableName as TableName,
              columnsDefinition: localConfiguration,
              id: tableAttr?.id,
            })
          );
        } else {
          if (tableAttr !== undefined && tableAttr.value) {
            dispatch(
              tableColumnsDefinitionLoaded({
                name: tableName as TableName,
                columnsDefinition: JSON.parse(tableAttr.value),
                id: tableAttr.id,
              })
            );
          }
        }
      }
    }
  };

export const endEditingTables = (): AppThunk => (dispatch, getState) => {
  dispatch(editTableDialogClosed());
  dispatch(editingTablesDisabled());
};

export const cancelNewTableLayouts =
  (): AppThunk => async (dispatch, getState) => {
    for (var tableName in TableName) {
      const tableAttr = getTableConfigurationAttribute(
        tableName as TableName,
        getState()
      );
      const storageKey = localStorageKey.get(TableName[tableName]);
      dispatch(
        tableColumnsUpdated({
          name: tableName as TableName,
          columns:
            (storageKey &&
              getFromLocalStorage(storageKey)?.map((tc) => tc.columnId)) ||
            (tableAttr &&
              tableAttr.value &&
              JSON.parse(tableAttr.value).map((tc) => tc.columnId)) ||
            undefined,
        })
      );
    }
    dispatch(editTableDialogClosed());
    dispatch(editingTablesDisabled());
  };

export const tablesSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    editingTablesEnabled: (state, action: PayloadAction<void>) => {
      state.areTablesEditable = true;
    },
    editingTablesDisabled: (state, action: PayloadAction<void>) => {
      state.areTablesEditable = false;
    },
    tableColumnsDefinitionLoaded: (
      state,
      action: PayloadAction<{
        name: TableName;
        columnsDefinition: Array<any>;
        id?: string | null;
      }>
    ) => {
      const { name, columnsDefinition, id } = action.payload;
      if (
        columnsDefinition.every((d) => typeof d === "object" && "columnId" in d)
      ) {
        state.configurations[name] = {
          columns: columnsDefinition,
          id: id,
        } as TableConfiguration;
      }
    },
    tableColumnsUpdated: (
      state,
      action: PayloadAction<UpdateTableColumnsParameters>
    ) => {
      const { name, columns } = action.payload;
      if (columns !== undefined) {
        const newColumnsDefinitions = columns.map((c) => {
          const oldDef = state.configurations[name].columns.find(
            (oldC) => oldC.columnId === c
          );

          return oldDef ?? ({ columnId: c } as ColumnDefinition);
        });

        state.configurations[name].columns = newColumnsDefinitions;
      }
      // else {
      //   (state.configurations[name] !== undefined) && delete state.configurations[name].columns;
      // }
    },
  },
});

export const {
  editingTablesEnabled,
  editingTablesDisabled,
  tableColumnsUpdated,
  tableColumnsDefinitionLoaded,
} = tablesSlice.actions;

export const selectAreTablesEditable = createSelector(
  (state: RootState) => state.tables.areTablesEditable,
  (areTablesEditable) => areTablesEditable
);

export const selectColumnsDefinitionByTableName = createSelector(
  [
    (state: RootState) => state.tables.configurations,
    (state: RootState, tableName: TableName) => tableName,
  ],
  (configurations, tableName) => configurations[tableName]
);

export const selectColumnsOrderByTableName = createSelector(
  selectColumnsDefinitionByTableName,
  (columnsByTableName) => columnsByTableName?.columns.map((c) => c.columnId)
);

export const selectFilterColumnsByTableName = createSelector(
  [
    selectColumnsDefinitionByTableName,
    (state, _, columns: Array<Column<any>>) => columns,
  ],
  (columnsByTableName, columns) => {
    const filteredColumns = [...columns]
      .map((c) => {
        const localColumnDef = columnsByTableName?.columns.find(
          (cd) => cd.columnId === c.id
        );
        if (localColumnDef) return c;
        else return undefined;
      })
      .filter((c) => c !== undefined) as Column<any>[];
    const sortedColumns = _.sortBy(filteredColumns, (fc) =>
      columnsByTableName?.columns.findIndex((c) => c.columnId === fc.id)
    );
    return sortedColumns;
  }
);

export default tablesSlice.reducer;
