import { Box, CircularProgress, styled } from "@mui/material";
import React, { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { selectSplitUtilEventDialogOpen } from "../../store/common/dialogs/splitUtilEventSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectSelectedAssetId } from "../../store/user/assetSelectionSlice";
import { refreshTokenAsync } from "../../store/user/authSlice";
import AppBarComponent from "../common/AppBarComponent";
import DialogIdleDetectorComponent from "../common/DialogIdleDetectorComponent";
import DialogMesSocketComponent from "../common/DialogMesSocketComponent";
import FooterComponent from "../common/FooterComponent";
import EditableComponentEditDialogComponent from "../common/dialogs/grid/EditableComponentEditDialogComponent";
import GridEditDialogComponent from "../common/dialogs/grid/GridEditDialogComponent";
import TableColumnsEditDialogComponent from "../common/dialogs/table/TableColumnsEditDialogComponent";
import ErrorFallback, {
  ErrorLevel,
} from "../common/error-fallbacks/ErrorFallback";
import AssetNotSelected from "../main/AssetNotSelected";
import MenuPanelComponent from "../main/navigation/MenuPanelComponent";
import NavigationEntitiesPanelComponent from "../main/navigation/NavigationEntitiesPanelComponent";
import DialogJobStateChangedComponent from "../main/production/DialogJobStateChangedComponent";
import DialogAddNewUtilComponent from "../main/utilization/DialogAddNewUtilComponent";
import DialogSplitUtilComponent from "../main/utilization/DialogSplitUtilComponent";
import DialogUpdateUtilComponent from "../main/utilization/DialogUpdateUtilComponent";
import NewShiftDialogComponent from "../common/dialogs/NewShiftDialogComponent";

const Main = styled("main")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "calc(100vh - 6.625rem)",
  width: "100vw",
  maxWidth: "100vw",
  maxHeight: "calc(100vh - 6.625rem)",
}));

interface MainLayoutProps {}

const MainLayout: FunctionComponent<PropsWithChildren<MainLayoutProps>> = ({
  children,
}) => {
  const selectedAssetId = useAppSelector(selectSelectedAssetId);
  const openSplitUtilDialog = useAppSelector(selectSplitUtilEventDialogOpen);
  const userRehydrated = useAppSelector(
    (state) => state.user?._persist?.rehydrated
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(refreshTokenAsync());
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <>
      <AppBarComponent />
      {userRehydrated || userRehydrated === undefined ? (
        <>
          {selectedAssetId && <MenuPanelComponent />}
          <NavigationEntitiesPanelComponent />
          <Main id="container">
            <ErrorBoundary
              FallbackComponent={(props) => (
                <ErrorFallback {...props} level={ErrorLevel.View} />
              )}
            >
              {selectedAssetId ? children : <AssetNotSelected />}
            </ErrorBoundary>
          </Main>
        </>
      ) : (
        <Box
          display="flex"
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      <>
        <DialogJobStateChangedComponent />
        <DialogMesSocketComponent />
        <DialogAddNewUtilComponent />
        <DialogUpdateUtilComponent />
        {openSplitUtilDialog && <DialogSplitUtilComponent />}
        <DialogIdleDetectorComponent />
        <NewShiftDialogComponent />
        <TableColumnsEditDialogComponent />
        <GridEditDialogComponent />
        <EditableComponentEditDialogComponent />
      </>
      <FooterComponent />
    </>
  );
};

export default MainLayout;
