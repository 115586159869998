import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import TableName from "../../../helpers/enums/tableName";
import { RootState } from "../..";

interface EditTableDialogState {
  open: boolean;
  name: TableName | undefined;
  allColumns: Array<string> | undefined;
}

export const initialState: EditTableDialogState = {
  open: false,
  name: undefined,
  allColumns: undefined,
};

interface OpenTableDialogParameters {
  name: TableName | undefined;
  allColumns: Array<string> | undefined;
}

interface UpdateTableDialogParameters {
  name: TableName | undefined;
  allColumns: Array<string> | undefined;
}

export const editTableSlice = createSlice({
  name: "editTableDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<OpenTableDialogParameters>) => {
      state.open = true;
      state.name = action.payload.name;
      state.allColumns = action.payload.allColumns;
    },
    updated: (state, action: PayloadAction<UpdateTableDialogParameters>) => {
      state.name = action.payload.name;
      state.allColumns = action.payload.allColumns;
    },
    closed: (state, action: PayloadAction<void>) => {
      state.open = false;
      state.name = undefined;
      state.allColumns = undefined;
    },
  },
});

export const {
  opened: editTableDialogOpened,
  updated: editTableDialogUpdated,
  closed: editTableDialogClosed,
} = editTableSlice.actions;

export const selectOpenEditTablesDialog = createSelector(
  (state: RootState) => state.dialogs.editTableDialog.open,
  (open) => open
);

export default editTableSlice.reducer;
