import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { AnyAction, Dispatch } from "redux";
import { env } from "../../../../env";
import {
  productionEventAggregatedSocketEvent,
  productionEventCorrectedSocketEvent,
  productionEventCreatedSocketEvent,
  productionEventFinishedSocketEvent,
  productionEventsConnectionStatusChanged,
} from "../../../../store/sockets/manufacturing-execution/productionEventsSlice";
import { selectIsAuthorized } from "../../../../store/user/authSlice";
import convertStringDates from "../../dateParser";
import RetryPolicy from "../../retryPolicy";

export interface ProductionEventFinishedSocketDto {
  logId: string;
  finishedAt: Date;
}

export interface ProductionEventCorrectedSocketDto {
  logId: string;
  newLogId: string;
}

export interface ProductionEventCreatedSocketDto {
  logId: string;
}

export interface ProductionEventAggregatedSocketDto {
  logId: string;
}

interface IProductionEventsAssetGroupService {
  init(): void;
  connect(dispatch: Dispatch<AnyAction>, getState: any): void;
  disconnect(): void;
  subscribe(dispatch: Dispatch<AnyAction>, getState: any): void;
  unsubscribe(): void;
  joinAssetGroup(assetId: string): void;
  leaveAssetGroup(assetId: string): void;
}

const assetsHubUrl = `${env.REACT_APP_MES_API_URL}/manufacturing-execution/production-events`;

const retryPolicy = new RetryPolicy();

const protocol = new JsonHubProtocol();

const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options = {
  transport,
};

export class ProductionEventsAssetGroupService
  implements IProductionEventsAssetGroupService
{
  public connectionProductionEventsHub: HubConnection | undefined;
  private curAssetId: string | undefined;

  constructor() {
    this.init();
  }

  init() {
    this.connectionProductionEventsHub = new HubConnectionBuilder()
      .withUrl(assetsHubUrl, options)
      .configureLogging(LogLevel.Debug)
      .withHubProtocol(protocol)
      .withAutomaticReconnect(retryPolicy)
      .build();
  }

  async start(dispatch: Dispatch<AnyAction>) {
    try {
      await this.connectionProductionEventsHub?.start().then(async (a) => {
        dispatch(
          productionEventsConnectionStatusChanged(HubConnectionState.Connected)
        );
      });
    } catch (error) {
      console.error(error);
    }
  }

  async connect(dispatch: Dispatch<AnyAction>, getState: any) {
    if (
      this.connectionProductionEventsHub?.state !==
      HubConnectionState.Disconnected
    ) {
      this.unsubscribe();
      this.init();
      this.subscribe(dispatch, getState());
    }

    await this.start(dispatch);

    this.connectionProductionEventsHub?.onreconnecting((error) => {
      console.error(error);
      dispatch(
        productionEventsConnectionStatusChanged(HubConnectionState.Reconnecting)
      );
    });

    this.connectionProductionEventsHub?.onreconnected((connectionId) => {
      this.curAssetId !== undefined && this.joinAssetGroup(this.curAssetId);
      dispatch(
        productionEventsConnectionStatusChanged(HubConnectionState.Connected)
      );
    });

    this.connectionProductionEventsHub?.onclose(async (error) => {
      error && console.error(error);
      const isAuthorized = selectIsAuthorized(getState());
      if (isAuthorized) {
        //STILL LOGGED IN
        await this.start(dispatch);
      } else {
        dispatch(
          productionEventsConnectionStatusChanged(
            HubConnectionState.Disconnected
          )
        );
      }
    });
  }

  disconnect() {
    this.unsubscribe();
    this.connectionProductionEventsHub?.stop();
  }

  subscribe(dispatch: Dispatch<AnyAction>, getState: any) {
    this.connectionProductionEventsHub?.on(
      "production-event-corrected",
      (message) => {
        if (message != null) {
          dispatch(productionEventCorrectedSocketEvent(message));
        }
      }
    );
    this.connectionProductionEventsHub?.on(
      "production-event-finished",
      (message) => {
        if (message != null) {
          convertStringDates(message, ["finishedAt"]);
          dispatch(productionEventFinishedSocketEvent(message));
        }
      }
    );
    this.connectionProductionEventsHub?.on(
      "production-event-created",
      (message) => {
        if (message != null) {
          dispatch(productionEventCreatedSocketEvent(message));
        }
      }
    );
    this.connectionProductionEventsHub?.on(
      "production-event-aggregated",
      (message) => {
        if (message != null) {
          dispatch(productionEventAggregatedSocketEvent(message));
        }
      }
    );
  }
  async joinAssetGroup(assetId: string): Promise<void> {
    this.curAssetId = assetId;
    if (
      this.connectionProductionEventsHub?.state === HubConnectionState.Connected
    ) {
      this.connectionProductionEventsHub?.invoke("JoinAssetGroup", assetId);
    }
  }

  leaveAssetGroup = (assetId: string) => {
    if (
      this.connectionProductionEventsHub?.state === HubConnectionState.Connected
    ) {
      this.connectionProductionEventsHub?.invoke("LeaveAssetGroup", assetId);
    }
  };

  unsubscribe = () => {
    this.connectionProductionEventsHub?.off("production-event-finished");
    this.connectionProductionEventsHub?.off("production-event-corrected");
    this.connectionProductionEventsHub?.off("production-event-created");
    this.connectionProductionEventsHub?.off("production-event-aggregated");
  };
}
