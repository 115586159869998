import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: boolean = false;

export const entNavigationSlice = createSlice({
  name: "entNavigationDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      return true;
    },
    closed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const {
  opened: entNavigationDialogOpened,
  closed: entNavigationDialogClosed,
} = entNavigationSlice.actions;

export default entNavigationSlice.reducer;
