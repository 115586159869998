import { CheckmarkIcon, CrossIcon } from "@improdis/core";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  styled,
} from "@mui/material";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import {
  selectUpdateProductionEventDialogOpen,
  updateProductionEventDialogClosed,
} from "../../../../store/common/dialogs/production-events/updateProductionEventSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  correctProductionEventAsync,
  selectProductionEventsPendingCorrect,
  selectSelectedProductionEvent,
} from "../../../../store/main/production-events/prodEventsSlice";
import {
  selectProductionReasons,
  selectProductionReasonsPending,
} from "../../../../store/main/production-reasons/productionReasonsSlice";

const CommentTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "15rem",
  },
  width: "25rem",
  maxWidth: "25rem",
  padding: 0,
}));

const ProductionReasonsContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "15rem",
  },
  overflowX: "auto",
  overflowY: "auto",
  marginBottom: "0rem",
  width: "25rem",
  border: "1px solid #c4c4c4",
  borderRadius: theme.shape.borderRadius,
}));

const SuccessCheckmarkIcon = styled(CheckmarkIcon)(({ theme }) => ({
  fill: theme.palette.success.main,
}));
const ErrorCrossIcon = styled(CrossIcon)(({ theme }) => ({
  fill: theme.palette.error.main,
}));

interface DialogUpdateProductionEventProps {}

const DialogUpdateProductionEventComponent: FunctionComponent<
  DialogUpdateProductionEventProps
> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const open = useAppSelector(selectUpdateProductionEventDialogOpen);
  const selectedProductionEvent = useAppSelector(selectSelectedProductionEvent);
  const pendingCorrect = useAppSelector(selectProductionEventsPendingCorrect);
  const productionReasons = useAppSelector(selectProductionReasons);
  const productionReasonsPending = useAppSelector(
    selectProductionReasonsPending
  );

  const [selectedProductionReasonId, setSelectedProductionReasonId] = useState(
    selectedProductionEvent?.productionReasonId
  );
  const [quantity, setQuantity] = useState<string>(
    selectedProductionEvent?.quantityProduced.toString() ?? "0"
  );
  const [comment, setComment] = useState(
    selectedProductionEvent?.comment ?? ""
  );

  const numericalQuantity = useMemo(() => Number(quantity), [quantity]);

  const isQuantityValid = useMemo(
    () =>
      quantity.trim() !== "" &&
      numericalQuantity >= 0 &&
      !isNaN(numericalQuantity),
    [quantity, numericalQuantity]
  );

  useEffect(() => {
    if (open) {
      setSelectedProductionReasonId(
        selectedProductionEvent?.productionReasonId
      );
      setQuantity(selectedProductionEvent?.quantityProduced.toString() ?? "0");
      setComment(selectedProductionEvent?.comment ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleProductionReasonSelect = (id: string) => {
    setSelectedProductionReasonId(id);
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(event.target.value);
  };

  const handleDialogClose = () => {
    dispatch(updateProductionEventDialogClosed());
  };

  const handleSelectedProductionEventCorrect = async () => {
    if (selectedProductionReasonId) {
      dispatch(
        correctProductionEventAsync({
          newProductionReasonId: selectedProductionReasonId,
          newQuantity: numericalQuantity,
          comment: comment || null,
        })
      );
      handleDialogClose();
    }
  };

  const getIcon = (name: string) => {
    switch (name) {
      case "GoodProduction":
        return <SuccessCheckmarkIcon />;
      case "BadProduction":
        return <ErrorCrossIcon />;
      default:
        return <QuestionMarkIcon />;
    }
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth={false}>
      <DialogTitle>
        <div>{t("Update of the production")}</div>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          gap="1rem"
          alignItems="center"
          minHeight="2rem"
          mb={1}
        >
          <div>{t("Edited production")}: </div>
          {/* <Divider flexItem orientation="vertical" /> */}
          <Box display="flex" gap="1rem" alignItems="center">
            {getIcon(selectedProductionEvent?.productionReasonName ?? "")}
            {t(selectedProductionEvent?.productionReasonName ?? "")}
          </Box>
          <Divider flexItem orientation="vertical" />
          <div>
            {selectedProductionEvent?.quantityProduced}{" "}
            {selectedProductionEvent?.uomName}
          </div>
        </Box>
        <Box display="flex" height="24rem">
          <ProductionReasonsContent component={SimpleBar}>
            <List disablePadding>
              {productionReasons.map((pr) => (
                <ListItem disableGutters>
                  <ListItemButton
                    selected={selectedProductionReasonId === pr.id}
                    onClick={() => handleProductionReasonSelect(pr.id)}
                  >
                    <ListItemText
                      primary={t(pr.name ?? "")}
                      secondary={pr.description}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </ProductionReasonsContent>
          <Box
            display="flex"
            flexDirection="column"
            ml="0.5rem"
            justifyContent="space-between"
          >
            <TextField value={quantity} onChange={handleQuantityChange} />
            <CommentTextField
              placeholder={t("Comment")}
              multiline
              minRows={14}
              maxRows={14}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={handleSelectedProductionEventCorrect}
          disabled={
            pendingCorrect ||
            productionReasonsPending ||
            !selectedProductionReasonId ||
            !isQuantityValid
          }
        >
          {t("Submit")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDialogClose}
          disabled={pendingCorrect}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpdateProductionEventComponent;
