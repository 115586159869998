import React from "react";
import i18n from "../i18n";
import moment from "moment";

export const formatDuration = (value: number) => {
  const hours = Math.floor(Number(value) / 3600);
  const minutes = Math.floor((Number(value) - hours * 3600) / 60);
  const seconds = Math.floor(Number(value - hours * 3600 - minutes * 60));
  return `${hours}${i18n.t("h")} ${minutes}${i18n.t("m")} ${seconds}${i18n.t(
    "s"
  )}`;
};

export const formatDateTime = (value: any, format: string) => {
  return value !== undefined ? (
    value !== null ? (
      <span>{moment(value).format(i18n.t(format).toString())}</span>
    ) : (
      <span>-----</span>
    )
  ) : (
    <span></span>
  );
};
