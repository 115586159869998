import { combineReducers } from "@reduxjs/toolkit";
import productionReasonsReducer from "./production-reasons/productionReasonsSlice";
import availableUtilRawsReducer from "./availableUtilRawsSlice";
import utilExecsReducer from "./util-execs/utilExecsSlice";
import utilizationEventsReducer from "./utilization-events/utilEventsSlice";
import productionEventsReducer from "./production-events/prodEventsSlice";
import workQueueReducer from "./work-queue/workQueueSlice";
import jobExecutionReducer from "./job-execution/jobExecutionSlice";

const mainReducer = combineReducers({
  productionReasons: productionReasonsReducer,
  availableUtilRaws: availableUtilRawsReducer,
  utilExecs: utilExecsReducer,
  utilizationEvents: utilizationEventsReducer,
  productionEvents: productionEventsReducer,
  workQueue: workQueueReducer,
  jobExecution: jobExecutionReducer,
});

export default mainReducer;

export type MainState = ReturnType<typeof mainReducer>;
