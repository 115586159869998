import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { updateProductionEventDialogOpened } from "../../common/dialogs/production-events/updateProductionEventSlice";
import { loadProductionReasonsAsync } from "./productionReasonsSlice";

const productionReasonsListener = createListenerMiddleware();

productionReasonsListener.startListening({
  matcher: isAnyOf(updateProductionEventDialogOpened),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(loadProductionReasonsAsync());
  },
});

export default productionReasonsListener;
