import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { env } from "../../env";

export const logOffTaskFetch = async (
  token: string,
  taskId: string
  // throwError: boolean = true
): Promise<void> => {
  try {
    fetch(
      `${env.REACT_APP_MES_API_URL}/api/access-management/task-logons/log-off`,
      {
        keepalive: true,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          taskId: taskId,
        }),
      }
    );
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    // if (throwError) throw ApiException;
  }
};
