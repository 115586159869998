import * as React from "react";
import { useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import "simplebar-react/dist/simplebar.min.css";
import GridName from "../../../../helpers/enums/gridName";
import {
  ProductionGridItemKey,
  defaultNewUniversalComponent,
} from "../../../../helpers/grids/production-grid/defaultGridSettings";
import defaultTileBackground from "../../../../icons/tiles/defaultBackground.png";
import productionTiles from "../../../../icons/tiles/production";
import { selectProductionCustomGridComponents } from "../../../../store/common/customGridComponentsSlice";
import {
  editGridsDialogCleared,
  editGridsDialogUpdated,
  selectEditGridsDialogOpen,
} from "../../../../store/common/dialogs/editGridsSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ErrorFallback, {
  ErrorLevel,
} from "../../../common/error-fallbacks/ErrorFallback";
import EditableGridComponent from "../../../common/grid/EditableGridComponent";
import GridWrapper, {
  GridElement,
} from "../../../common/grid/GridWrapperComponent";
import AssetParametersComponent from "./production-parts/AssetParametersComponent";
import BadProductionReadOnlyComponent from "./production-parts/BadProductionReadOnlyComponent";
import BadProductionWithReductionComponent from "./production-parts/BadProductionWithReductionComponent";
import GoodProductionReadOnlyComponent from "./production-parts/GoodProductionReadOnlyComponent";
import GoodProductionWithReductionComponent from "./production-parts/GoodProductionWithReductionComponent";
import ProgresOfWorkComponent from "./production-parts/ProgresOfWorkComponent";
import TargetProductionComponent from "./production-parts/TargetProductionComponent";

export interface ProductionGridWrapperProps {}

interface GridTile {
  key: ProductionGridItemKey;
  title: string;
  width: number;
  height: number;
  background: string;
  id: string;
}

const ProductionGridWrapper: React.FC<ProductionGridWrapperProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const openDialog = useAppSelector(selectEditGridsDialogOpen);
  const customComponents = useAppSelector(selectProductionCustomGridComponents);

  const gridComponents = useMemo(
    () =>
      [
        {
          key: ProductionGridItemKey.GOOD_PRODUCTION,
          element: <GoodProductionWithReductionComponent />,
        },
        {
          key: ProductionGridItemKey.GOOD_PRODUCTION_READONLY,
          element: <GoodProductionReadOnlyComponent />,
        },
        {
          key: ProductionGridItemKey.BAD_PRODUCTION,
          element: <BadProductionWithReductionComponent />,
        },
        {
          key: ProductionGridItemKey.BAD_PRODUCTION_READONLY,
          element: <BadProductionReadOnlyComponent />,
        },
        {
          key: ProductionGridItemKey.JOB_INFORMATION,
          element: <TargetProductionComponent />,
        },
        {
          key: ProductionGridItemKey.JOB_PROGRESS,
          element: <ProgresOfWorkComponent />,
        },
        {
          key: ProductionGridItemKey.ENTITY_PARAMETERS,
          element: <AssetParametersComponent />,
        },
      ] as Array<GridElement>,
    []
  );

  const gridTiles = useMemo(
    () => [
      {
        key: ProductionGridItemKey.EDITABLE_COMPONENT,
        title: t("EditableComponentTitle").toString(),
        width: 4,
        height: 8,
        background: defaultTileBackground,
      },
      {
        key: ProductionGridItemKey.GOOD_PRODUCTION,
        title: t("GoodProductionTitle").toString(),
        width: 4,
        height: 8,
        background: productionTiles.goodProductionBackground,
      },
      {
        key: ProductionGridItemKey.GOOD_PRODUCTION_READONLY,
        title: t("GoodProductionReadOnlyTitle").toString(),
        width: 3,
        height: 5,
        background: productionTiles.goodProductionReadonlyBackground,
      },
      {
        key: ProductionGridItemKey.BAD_PRODUCTION,
        title: t("BadProductionTitle").toString(),
        width: 4,
        height: 8,
        background: productionTiles.badProductionBackground,
      },
      {
        key: ProductionGridItemKey.BAD_PRODUCTION_READONLY,
        title: t("BadProductionReadOnlyTitle").toString(),
        width: 3,
        height: 5,
        background: productionTiles.badProductionReadonlyBackground,
      },
      {
        key: ProductionGridItemKey.JOB_INFORMATION,
        title: t("JobInformationTitle").toString(),
        width: 8,
        height: 8,
        background: productionTiles.jobInformationBackground,
      },
      {
        key: ProductionGridItemKey.JOB_PROGRESS,
        title: t("JobProgressTitle").toString(),
        width: 16,
        height: 8,
        background: productionTiles.jobProgressBackground,
      },
      {
        key: ProductionGridItemKey.ENTITY_PARAMETERS,
        title: t("AssetParametersTitle").toString(),
        width: 4,
        height: 8,
        background: productionTiles.entityParametersBackground,
      },
    ],
    [t]
  );

  const allTiles = useMemo(
    () =>
      gridTiles.concat(
        customComponents
          .filter((c) => !c.isNew)
          .map(
            (c) =>
              ({
                key: c.key,
                title: t(c.title),
                width: 4,
                height: 8,
                background: defaultTileBackground,
                id: c.componentAttrId,
              } as GridTile)
          )
      ),
    [t, gridTiles, customComponents]
  );

  const allGridComponents = useMemo(
    () =>
      gridComponents.concat(
        customComponents.map(
          (c) =>
            ({
              key: c.key,
              element: (
                <ErrorBoundary
                  FallbackComponent={(props) => (
                    <ErrorFallback {...props} level={ErrorLevel.Component} />
                  )}
                >
                  <EditableGridComponent code={c.code} isNew={c.isNew} />
                </ErrorBoundary>
              ),
              attrName: c.componentAttrName,
              title: c.title,
              code: c.code,
              isEditable: true,
              isNew: c.isNew,
              id: c.componentAttrId,
            } as GridElement)
        )
      ),
    [gridComponents, customComponents]
  );

  useEffect(() => {
    if (openDialog) {
      dispatch(
        editGridsDialogUpdated({
          gridName: GridName.PRODUCTION,
          allTiles: allTiles,
        })
      );
    }
  }, [dispatch, openDialog, allTiles]);

  useEffect(() => {
    return () => {
      openDialog && dispatch(editGridsDialogCleared());
    };
  }, [dispatch, openDialog]);

  return (
    <GridWrapper
      gridName={GridName.PRODUCTION}
      childrenList={allGridComponents}
      defaultNewUniversalComponent={defaultNewUniversalComponent}
    />
  );
};

export default ProductionGridWrapper;
