import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path fill="none" d="M0 0h16v16H0z" />
      <path
        d="M2 8a6 6 0 116 6 6.018 6.018 0 01-6-6zm1.2 0A4.8 4.8 0 108 3.2 4.814 4.814 0 003.2 8zm4.2.6V5h.9v3.12L11 9.74l-.48.721z"
        fill="#000100"
      />
    </svg>
  );
}

export default SvgComponent;
