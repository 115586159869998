import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "../..";
import { GetUtilLogsByAssetIdQueryDto } from "../../../services/httpService";
import { changeAssetUtilizationState } from "../../../services/main/assetsService";
import {
  correctUtilEvent,
  getCurrentShiftUtilEventsByAssetId,
  splitUtilEvent,
} from "../../../services/main/utilizationEventsService";
import { selectSelectedAssetId } from "../../user/assetSelectionSlice";
import OeeDefinition from "../../../helpers/enums/oeeDefinitionEnum";
import _ from "lodash";

export interface UtilEvent extends GetUtilLogsByAssetIdQueryDto {}
export type UtilEventStateSummary = Pick<
  GetUtilLogsByAssetIdQueryDto,
  "duration" | "utilStateName" | "utilStateColor"
>;
export type UtilEventOverTime = Pick<
  GetUtilLogsByAssetIdQueryDto,
  | "startedAt"
  | "finishedAt"
  | "duration"
  | "utilRawName"
  | "utilStateName"
  | "utilStateColor"
>;
export interface UtilEventDowntime
  extends Pick<
    GetUtilLogsByAssetIdQueryDto,
    "duration" | "utilRawName" | "utilStateName" | "utilStateColor"
  > {
  count: number;
}

export interface UtilEventsState {
  utilEvents: AsyncValue<Array<UtilEvent>>;
  selectedUtilEventLogId: string | null; //logId
  pendingAdd: boolean;
  pendingSplit: boolean;
  pendingCorrect: boolean;
}

export const initialState: UtilEventsState = {
  utilEvents: {
    value: [],
    pending: false,
  },
  selectedUtilEventLogId: null,
  pendingAdd: false,
  pendingSplit: false,
  pendingCorrect: false,
};

export const loadUtilEventsForCurrentShiftAsync = createAsyncThunk(
  "utilEvents/loadUtilEventsForCurrentShiftAsync",
  async (_, { getState, dispatch }) => {
    const assetId = selectSelectedAssetId(getState() as RootState);
    if (assetId) {
      const result = await getCurrentShiftUtilEventsByAssetId(assetId);
      return result;
    } else {
      return [];
    }
  }
);

interface AddUtilEventParams {
  newUtilRawId: string;
  comment: string | null;
}

export const addUtilEventAsync = createAsyncThunk(
  "utilEvents/addUtilEventAsync",
  async (params: AddUtilEventParams, { getState, dispatch }) => {
    const selectedAssetId = selectSelectedAssetId(getState() as RootState);
    if (selectedAssetId) {
      await changeAssetUtilizationState(
        selectedAssetId,
        params.newUtilRawId,
        params.comment
      );
    }
  }
);

interface CorretUtilEventParams {
  newUtilRawId: string;
  comment: string | null;
}

export const correctUtilEventAsync = createAsyncThunk(
  "utilEvents/correctUtilEventAsync",
  async (params: CorretUtilEventParams, { getState, dispatch }) => {
    const selectedUtilEventLogId = selectSelectedUtilEventLogId(
      getState() as RootState
    );
    if (selectedUtilEventLogId) {
      const result = await correctUtilEvent(
        selectedUtilEventLogId,
        params.newUtilRawId,
        params.comment
      );
      return result;
    }
  }
);

interface SplitUtilEventParams {
  olderRange: {
    utilRawId: string;
    comment: string | null;
  } | null;
  splitTime: Date;
  newerRange: {
    utilRawId: string;
    comment: string | null;
  } | null;
}

export const splitUtilEventAsync = createAsyncThunk(
  "utilEvents/splitUtilEventAsync",
  async (params: SplitUtilEventParams, { getState, dispatch }) => {
    const selectedUtilEventLogId = selectSelectedUtilEventLogId(
      getState() as RootState
    );
    if (selectedUtilEventLogId) {
      const result = await splitUtilEvent(
        selectedUtilEventLogId,
        params.olderRange,
        params.splitTime,
        params.newerRange
      );
      return result;
    }
  }
);

export const utilEventsSlice = createSlice({
  name: "utilEvents",
  initialState,
  reducers: {
    utilEventSelected: (state, action: PayloadAction<string>) => {
      state.selectedUtilEventLogId = action.payload;
    },
    utilEventsSelectionCleared: (state, action: PayloadAction<void>) => {
      state.selectedUtilEventLogId = null;
    },
    utilEventCreated: (state, action: PayloadAction<UtilEvent>) => {
      state.utilEvents.value = [action.payload, ...state.utilEvents.value];
    },
    utilEventUpdated: (
      state,
      action: PayloadAction<{
        oldEventLogId: string;
        newEvent: UtilEvent;
      }>
    ) => {
      const { oldEventLogId, newEvent } = action.payload;
      state.utilEvents.value = state.utilEvents.value.map((pe) =>
        pe.logId === oldEventLogId ? { ...pe, ...newEvent } : pe
      );
    },
    utilEventSplitted: (
      state,
      action: PayloadAction<{
        logId: string;
        olderEvent: UtilEvent;
        newerEvent: UtilEvent;
      }>
    ) => {
      const { logId, olderEvent, newerEvent } = action.payload;
      const index = state.utilEvents.value.findIndex(
        (ue) => ue.logId === logId
      );
      if (index !== -1) {
        state.utilEvents.value.splice(index, 1, ...[newerEvent, olderEvent]);
      }
    },
    currentUtilEventDurationUpdated: (state, action: PayloadAction<number>) => {
      const seconds = action.payload;
      state.utilEvents.value = state.utilEvents.value.map((ue) =>
        !ue.finishedAt ? { ...ue, duration: ue.duration + seconds } : ue
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUtilEventsForCurrentShiftAsync.pending, (state, action) => {
        state.utilEvents.pending = true;
      })
      .addCase(
        loadUtilEventsForCurrentShiftAsync.fulfilled,
        (state, action) => {
          state.utilEvents.value = action.payload!;
          state.utilEvents.pending = false;
        }
      )
      .addCase(loadUtilEventsForCurrentShiftAsync.rejected, (state, action) => {
        state.utilEvents.value = [];
        state.utilEvents.pending = false;
      })
      .addCase(addUtilEventAsync.pending, (state, action) => {
        state.pendingAdd = true;
      })
      .addCase(addUtilEventAsync.fulfilled, (state, action) => {
        // state.utilEvents.value = action.payload!;
        state.pendingAdd = false;
      })
      .addCase(addUtilEventAsync.rejected, (state, action) => {
        // state.utilEvents.value = [];
        state.pendingAdd = false;
      })
      .addCase(correctUtilEventAsync.pending, (state, action) => {
        state.pendingCorrect = true;
      })
      .addCase(correctUtilEventAsync.fulfilled, (state, action) => {
        // state.utilEvents.value = action.payload!;
        state.pendingCorrect = false;
      })
      .addCase(correctUtilEventAsync.rejected, (state, action) => {
        // state.utilEvents.value = [];
        state.pendingCorrect = false;
      })
      .addCase(splitUtilEventAsync.pending, (state, action) => {
        state.pendingSplit = true;
      })
      .addCase(splitUtilEventAsync.fulfilled, (state, action) => {
        // state.utilEvents.value = action.payload!;
        state.pendingSplit = false;
      })
      .addCase(splitUtilEventAsync.rejected, (state, action) => {
        // state.utilEvents.value = [];
        state.pendingSplit = false;
      });
  },
});

export const {
  utilEventSelected,
  utilEventsSelectionCleared,
  utilEventCreated,
  utilEventUpdated,
  utilEventSplitted,
  currentUtilEventDurationUpdated,
} = utilEventsSlice.actions;

export const selectUtilEvents = createSelector(
  (state: RootState) => state.main.utilizationEvents.utilEvents.value,
  (utilEvents) => utilEvents
);

export const selectUtilEventByLogId = createSelector(
  selectUtilEvents,
  (state: RootState, logId: string) => logId,
  (utilEvents, logId) => utilEvents.find((ue) => ue.logId === logId)
);

export const selectUtilEventsPending = createSelector(
  (state: RootState) => state.main.utilizationEvents.utilEvents.pending,
  (utilEventsPending) => utilEventsPending
);

export const selectSelectedUtilEventLogId = createSelector(
  (state: RootState) => state.main.utilizationEvents.selectedUtilEventLogId,
  (selectedUtilEventLogId) => selectedUtilEventLogId
);

export const selectSelectedUtilEvent = createSelector(
  (state: RootState) => state.main.utilizationEvents.utilEvents,
  selectSelectedUtilEventLogId,
  (utilHistories, selectedUtilEventLogId) =>
    utilHistories.value.find((uh) => uh.logId === selectedUtilEventLogId)
);

export const selectUtilEventsPendingAdd = createSelector(
  (state: RootState) => state.main.utilizationEvents.pendingAdd,
  (pendingAdd) => pendingAdd
);

export const selectUtilEventsPendingCorrect = createSelector(
  (state: RootState) => state.main.utilizationEvents.pendingCorrect,
  (pendingCorrect) => pendingCorrect
);

export const selectUtilEventsPendingSplit = createSelector(
  (state: RootState) => state.main.utilizationEvents.pendingSplit,
  (pendingSplit) => pendingSplit
);

//CHARTS

export const selectUtilEventsStateSummary = createSelector(
  selectUtilEvents,
  (utilEvents) => {
    const utilEventsDowntimes = _.uniqBy(utilEvents, "utilStateName").map(
      (v) =>
        ({
          utilStateName: v.utilStateName,
          utilStateColor: v.utilStateColor,
          duration: _.sumBy(
            utilEvents.filter((d) => d.utilStateName === v.utilStateName),
            "duration"
          ),
        } as UtilEventStateSummary)
    );
    return utilEventsDowntimes;
  }
);

export const selectUtilEventsOverTime = createSelector(
  selectUtilEvents,
  (utilEvents) => utilEvents.map((ue) => ({ ...ue } as UtilEventOverTime))
);

export const selectUtilEventsDowntimes = createSelector(
  selectUtilEvents,
  (utilEvents) => {
    const downtimes = utilEvents.filter(
      (ue) => Number(ue.oeeDefinition) === OeeDefinition.Downtime
    );
    const utilEventsDowntimes = _.uniqBy(downtimes, "utilRawName").map(
      (v) =>
        ({
          utilRawName: v.utilRawName,
          utilStateName: v.utilStateName,
          utilStateColor: v.utilStateColor,
          duration: _.sumBy(
            downtimes.filter((d) => d.utilRawName === v.utilRawName),
            "duration"
          ),
          count: downtimes.filter((d) => d.utilRawName === v.utilRawName)
            .length,
        } as UtilEventDowntime)
    );
    return utilEventsDowntimes;
  }
);

export default utilEventsSlice.reducer;
