import { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  NestedProductionEventHistory,
  loadProductionEventHistoryAsync,
  productionEventHistoryDialogClosed,
  selectProductionEventHistory,
  selectProductionEventHistoryDialogOpen,
  selectProductionEventHistoryPending,
} from "../../../../store/common/dialogs/production-events/productionEventHistorySlice";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Column } from "react-table";
import React from "react";
import BooleanColumnFilter from "../../../common/table-filters/BooleanColumnFilter";
import ProductionReason from "../../../../helpers/enums/productionReasonEnum";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import { CheckmarkIcon, CrossIcon } from "@improdis/core";
import { FormatDate } from "../../../../helpers/tables/date/FormatDate";
import { filterDates } from "../../../../helpers/tables/date/filterDates";
import { sortDateTime } from "../../../../helpers/tables/date/sortDates";
import TableComponent from "../../../common/table/TableComponent";
import TableName from "../../../../helpers/enums/tableName";

const SuccessCheckmarkIcon = styled(CheckmarkIcon)(({ theme }) => ({
  fill: theme.palette.success.main,
}));
const ErrorCrossIcon = styled(CrossIcon)(({ theme }) => ({
  fill: theme.palette.error.main,
}));

interface DialogProductionEventHistoryProps {}

const DialogProductionEventHistoryComponent: FunctionComponent<
  DialogProductionEventHistoryProps
> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectProductionEventHistoryDialogOpen);
  const productionHistory = useAppSelector(selectProductionEventHistory);
  const pending = useAppSelector(selectProductionEventHistoryPending);

  useEffect(() => {
    if (open) {
      dispatch(loadProductionEventHistoryAsync());
    }
  }, [dispatch, open]);

  const columnsTree: Array<Column<NestedProductionEventHistory>> = useMemo(
    () => [
      {
        id: "expander", // Make sure it has an ID
        disableSortBy: true,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              style={{
                marginLeft: `${row.depth * 2}rem`,
                cursor: "auto",
              }}
            >
              {row.isExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
            </span>
          ) : null,
      },
      {
        Header: t("logId").toString(),
        id: "logId",
        accessor: "logId",
      },
      {
        Header: t("workOrderName").toString(),
        id: "workOrderName",
        accessor: "workOrderName",
      },
      {
        Header: t("taskId").toString(),
        id: "taskId",
        accessor: "taskId",
      },
      {
        Header: t("taskName").toString(),
        id: "taskName",
        accessor: "taskName",
      },
      {
        Header: t("assetName").toString(),
        id: "assetName",
        accessor: "assetName",
      },
      {
        Header: t("productionReason").toString(),
        id: "productionReasonName",
        accessor: "productionReasonName",
      },
      {
        Header: t("isGood").toString(),
        id: "isGood",
        Filter: BooleanColumnFilter,
        filter: "boolean",
        accessor: (row) => row.productionReasonName === ProductionReason.Good,
        Cell: (cell) =>
          cell.value ? <SuccessCheckmarkIcon /> : <ErrorCrossIcon />,
      },
      {
        Header: t("quantityProduced").toString(),
        id: "quantityProduced",
        accessor: "quantityProduced",
        // Filter: NumberColumnFilter,
        // filter: "number",
      },
      {
        Header: t("startedAt").toString(),
        id: "startedAt",
        accessor: "startedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
        sortType: sortDateTime,
      },
      {
        Header: t("finishedAt").toString(),
        id: "finishedAt",
        accessor: "finishedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
        sortType: sortDateTime,
      },
      {
        Header: t("comment").toString(),
        id: "comment",
        accessor: "comment",
      },
      {
        Header: t("itemName").toString(),
        id: "itemName",
        accessor: "itemName",
      },
      {
        Header: t("uomName").toString(),
        id: "uomName",
        accessor: "uomName",
      },
      {
        Header: t("createdById"),
        id: "createdById",
        accessor: "createdById",
      },
      {
        Header: t("createdByName"),
        id: "createdByName",
        accessor: (o) => o.createdByFullName || o.createdByUserName,
      },
      {
        Header: t("createdAt").toString(),
        id: "createdAt",
        accessor: "createdAt",
        Cell: (cell) => FormatDate(cell.value, t),
        disableSortBy: true,
      },
    ],
    [t]
  );

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(productionEventHistoryDialogClosed())}
      fullScreen
    >
      <DialogTitle>
        {/* <AssetIcon /> */}
        <div>{t("Production")}</div>
      </DialogTitle>
      <DialogContent>
        <TableComponent
          name={TableName.PRODUCTION_EVENT_HISTORY}
          data={productionHistory}
          columns={columnsTree}
          isLoading={pending}
          subRowsProperty="childrenProductionEvents"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => dispatch(productionEventHistoryDialogClosed())}
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogProductionEventHistoryComponent;
