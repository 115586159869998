import { Box, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useLocalStorageState from "../../../../helpers/hooks/useLocalStorageState";
import { LocalStorageKey } from "../../../../helpers/localStorageKeys";
import { TaskState } from "../../../../helpers/productionEnums";
import JobIcon from "../../../../icons/JobIcon";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  StepOfProduction,
  resumeTaskAsync,
  runTaskAsync,
  selectRunnedTask,
  stepOfProductionSelected,
} from "../../../../store/main/job-execution/jobExecutionSlice";
import SelectWorkOrderComponent from "./SelectWorkOrderComponent";
import {
  selectSelectedWorkQueue,
  taskSelectionCleared,
} from "../../../../store/main/work-queue/workQueueSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    flexGrow: 1,
  },

  paper: {
    width: "12rem",
    // height: "13rem",
    marginBottom: "1rem",
  },

  paperToggle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  header: {
    display: "flex",
    textAlign: "left",
    fontSize: "1.5rem",
    flexDirection: "row",
    alignItems: "center",
    padding: "1rem",
  },

  contentButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },

  runButton: {
    width: "85%",
    margin: "0.2rem",
  },

  jobIcon: {
    width: 25,
    height: 25,
    marginRight: "0.5rem",
  },
}));

export interface FirstStepContainerProps {}

const FirstStepContainerComponent: React.FC<FirstStepContainerProps> = () => {
  const dispatch = useAppDispatch();
  const selectedWorkQueue = useAppSelector(selectSelectedWorkQueue);
  const areTablesEditable = useAppSelector(
    (state) => state.tables.areTablesEditable
  );
  const runnedJob = useAppSelector(selectRunnedTask);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [submittingWorkOrder, setSubmittingWorkOrder] =
    useState<boolean>(false);
  const [viewType, setViewType] = useLocalStorageState(
    "tree",
    LocalStorageKey.PRODUCTION_VIEW_TYPE
  );

  const startSelectedJob = async () => {
    setSubmittingWorkOrder(true);
    await dispatch(runTaskAsync());
    setSubmittingWorkOrder(false);
  };

  const resumeSelectedJob = async () => {
    setSubmittingWorkOrder(true);
    await dispatch(resumeTaskAsync());
    setSubmittingWorkOrder(false);
  };

  const handleViewTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newViewType: "tree" | "flat"
  ) => {
    if (newViewType !== null) {
      setViewType(newViewType);
    }
  };

  return (
    <div className={classes.root}>
      <SelectWorkOrderComponent viewType={viewType} />
      <Box display="flex" flexDirection="column">
        <Paper className={classes.paper} elevation={2} square>
          <div className={classes.header}>
            <JobIcon className={classes.jobIcon}></JobIcon>
            <div>{t("Job")}</div>
          </div>
          <div className={classes.paperToggle}>
            <ToggleButtonGroup
              value={viewType}
              exclusive
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                newViewType: "tree" | "flat"
              ) => {
                handleViewTypeChange(event, newViewType);
                dispatch(taskSelectionCleared());
              }}
            >
              <ToggleButton value="tree">
                <ViewQuiltIcon />
              </ToggleButton>
              <ToggleButton value="flat">
                <ViewHeadlineIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className={classes.contentButton}>
            <Button
              variant="contained"
              color="success"
              fullWidth
              className={classes.runButton}
              disabled={
                !selectedWorkQueue ||
                selectedWorkQueue.taskStateName === TaskState.Running ||
                submittingWorkOrder ||
                areTablesEditable
              }
              onClick={() => {
                startSelectedJob();
              }}
            >
              {t("Run")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={classes.runButton}
              disabled={
                !selectedWorkQueue ||
                selectedWorkQueue.taskStateName !== TaskState.Running ||
                submittingWorkOrder
              }
              onClick={() => {
                resumeSelectedJob();
              }}
            >
              {t("Resume")}
            </Button>
            {runnedJob !== undefined && (
              <Button
                variant="contained"
                color="warning"
                fullWidth
                className={classes.runButton}
                onClick={() => {
                  dispatch(
                    stepOfProductionSelected(StepOfProduction.SecondStep)
                  );
                }}
              >
                {t("Return")}
              </Button>
            )}
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default FirstStepContainerComponent;
