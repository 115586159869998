import { StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import AppSuspense from "./components/common/AppSuspense";
import ErrorFallback, {
  ErrorLevel,
} from "./components/common/error-fallbacks/ErrorFallback";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
import appTheme from "./themes/appTheme";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <BrowserRouter>
          <PersistGate loading={null} persistor={persistor}>
            <ErrorBoundary
              FallbackComponent={(props) => (
                <ErrorFallback {...props} level={ErrorLevel.App} />
              )}
            >
              <Suspense fallback={<AppSuspense />}>
                <App />
              </Suspense>
            </ErrorBoundary>
          </PersistGate>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
