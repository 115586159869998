import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import { ProductionReasonDto, ProductionReasonsClient } from "../httpService";

const http = new ProductionReasonsClient(env.REACT_APP_MES_API_URL, instance);

export const getProductionReasons = async (
  throwError: boolean = true
): Promise<Array<ProductionReasonDto> | undefined> => {
  try {
    const result = await http.getProductionReasons();
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
