import { Validator } from "jsonschema";
import { Middleware } from "redux";
import { REHYDRATE } from "redux-persist";
import { PersistConfigKey } from "..";
import topicsSchema from "../../helpers/schemas/topicsSchema.json";
import { instance } from "../../services/axiosInstance";
import { EntityTopic, topicsDefinitionsLoaded } from "../common/mqttSlice";
import { logInAsync, logOutAsync, refreshTokenAsync } from "../user/authSlice";
import { connectedToMes } from "../user/mesConnectionSlice";
import { env } from "../../env";

const fetchMqttTopics = (dispatch) => {
  fetch(`/topics.json`)
    .then((r) => r.json())
    .then((data: Array<EntityTopic>) => {
      var v = new Validator();
      var result = v.validate(data, topicsSchema as any, {
        nestedErrors: true,
      });
      let errors;
      let entityTopics = data;

      result.errors.length > 0 &&
        result.errors.forEach((err: any) => {
          if (err.path.length >= 3) {
            data[err.path[0]].topics[err.path[2]].errors = [
              ...(data[err.path[0]].topics[err.path[2]].errors || []),
              err.stack,
            ];
          } else if (err.path.length >= 1) {
            data[err.path[0]].errors = [
              ...(data[err.path[0]].errors || []),
              err.stack,
            ];
          } else {
            errors = [...(errors || []), err.stack] as Array<string>;
          }
        });

      dispatch(
        topicsDefinitionsLoaded({
          definitions: entityTopics,
          errors: errors,
        })
      );
    })
    .catch((err) => {
      const errors = [err.message];
      const entityTopics = [];
      dispatch(
        topicsDefinitionsLoaded({
          definitions: entityTopics,
          errors: errors,
        })
      );
    });
};

const authMiddleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === logInAsync.fulfilled.type) {
      dispatch(connectedToMes());
      const token = action.payload.token;
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
      env.REACT_APP_MQTT_URL && fetchMqttTopics(dispatch);
    } else if (
      action.type === REHYDRATE &&
      action.key === PersistConfigKey.User
    ) {
      const token = action?.payload?.auth?.details?.token;
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
      env.REACT_APP_MQTT_URL && fetchMqttTopics(dispatch);
    } else if (action.type === refreshTokenAsync.fulfilled.type) {
      const token = action.payload.token;
      instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else if (action.type === logOutAsync.fulfilled.type) {
      instance.defaults.headers.common.Authorization = null;
    }
    return next(action);
  };

export default authMiddleware;
