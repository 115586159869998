import { HubConnectionState } from "@microsoft/signalr";
import { combineReducers, createSelector } from "@reduxjs/toolkit";
import manufacturingExecutionReducer from "./manufacturing-execution";
import productionPlanningReducer from "./production-planning";
import { selectManufacturingExecutionTasksConnectionStatus } from "./manufacturing-execution/tasksSlice";
import { selectProductionPlanningTasksConnectionStatus } from "./production-planning/tasksSlice";
import utilizationReducer from "./utilization";
import { selectAssetsConnectionStatus } from "./utilization/assetsSlice";
import { selectUtilizationEventsConnectionStatus } from "./utilization/utilizationEventsSlice";

export default combineReducers({
  utilization: utilizationReducer,
  manufacturingExecution: manufacturingExecutionReducer,
  productionPlanning: productionPlanningReducer,
});

export const selectIsAnySocketDisconnected = createSelector(
  selectUtilizationEventsConnectionStatus,
  selectAssetsConnectionStatus,
  selectManufacturingExecutionTasksConnectionStatus,
  selectProductionPlanningTasksConnectionStatus,
  (
    utilizationEventsConnectionStatus,
    assetsConnectionStatus,
    manufacturingExecutionTasksConnectionStatus,
    productionPlanningTasksConnectionStatus
  ) =>
    utilizationEventsConnectionStatus === HubConnectionState.Disconnected ||
    assetsConnectionStatus === HubConnectionState.Disconnected ||
    manufacturingExecutionTasksConnectionStatus ===
      HubConnectionState.Disconnected ||
    productionPlanningTasksConnectionStatus === HubConnectionState.Disconnected
);

export const selectIsAnySocketReconnecting = createSelector(
  selectUtilizationEventsConnectionStatus,
  selectAssetsConnectionStatus,
  selectManufacturingExecutionTasksConnectionStatus,
  selectProductionPlanningTasksConnectionStatus,
  (
    utilizationEventsConnectionStatus,
    assetsConnectionStatus,
    manufacturingExecutionTasksConnectionStatus,
    productionPlanningTasksConnectionStatus
  ) =>
    utilizationEventsConnectionStatus === HubConnectionState.Reconnecting ||
    assetsConnectionStatus === HubConnectionState.Reconnecting ||
    manufacturingExecutionTasksConnectionStatus ===
      HubConnectionState.Reconnecting ||
    productionPlanningTasksConnectionStatus === HubConnectionState.Reconnecting
);
