import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "../..";
import { GetAllUtilExecsQueryDto } from "../../../services/httpService";
import { getAssetsUtilizationExecs } from "../../../services/main/assetsService";
import { selectSelectedAsset } from "../../user/assetSelectionSlice";

export type UtilExec = Omit<GetAllUtilExecsQueryDto, "assetName" | "duration">;

export type UtilExecsState = AsyncValue<Array<UtilExec>>;

export const initialState: UtilExecsState = {
  value: [],
  pending: false,
};

export const loadUtilizationExecsAsync = createAsyncThunk(
  "utilExecs/loadUtilizationExecsAsync",
  async (_, { getState, dispatch }) => {
    const result = await getAssetsUtilizationExecs();
    return result;
  }
);

export const utilExecsSlice = createSlice({
  name: "utilExecs",
  initialState,
  reducers: {
    utilExecChanged: (state, action: PayloadAction<UtilExec>) => {
      state.value = state.value.map((ue) =>
        ue.assetId === action.payload.assetId ? action.payload : ue
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUtilizationExecsAsync.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(loadUtilizationExecsAsync.fulfilled, (state, action) => {
        state.value = action.payload!;
        state.pending = false;
      })
      .addCase(loadUtilizationExecsAsync.rejected, (state, action) => {
        state.value = [];
        state.pending = false;
      });
  },
});

export const { utilExecChanged } = utilExecsSlice.actions;

export const selectUtilizationExecs = createSelector(
  (state: RootState) => state.main.utilExecs.value,
  (utilizationExecs) => utilizationExecs
);

export const selectSelectedAssetUtilizationExec = createSelector(
  selectSelectedAsset,
  selectUtilizationExecs,
  (selectedAsset, utilizationExecs) =>
    utilizationExecs.find((ue) => ue.assetId === selectedAsset?.id)
);

export const selectUtilizationExecsPending = createSelector(
  (state: RootState) => state.main.utilExecs.pending,
  (availableUtilRawsPending) => availableUtilRawsPending
);

export default utilExecsSlice.reducer;
