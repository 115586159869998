import React, { FunctionComponent } from "react";
import { FallbackProps } from "react-error-boundary";
import { RouteComponentProps, withRouter } from "react-router";
import { useAppDispatch } from "../../../store/hooks";
import { logOutAsync } from "../../../store/user/authSlice";
import AppError from "./AppError";
import ComponentError from "./ComponentError";
import ViewError from "./ViewError";

export enum ErrorLevel {
  App,
  View,
  Component,
}

interface ErrorFallbackProps extends FallbackProps {
  level: ErrorLevel;
}

const ErrorFallback: FunctionComponent<
  ErrorFallbackProps & RouteComponentProps
> = ({ level, error, resetErrorBoundary, history }) => {
  const dispatch = useAppDispatch();

  const handleClear = async () => {
    // clear local storage
    global?.localStorage?.clear();
    //  clear cache
    //FIX for reference error on some browsers
    try {
      caches?.keys()?.then((names) => {
        names?.forEach((name) => {
          caches?.delete(name);
        });
      });
    } catch (Exception) {}

    await dispatch(logOutAsync());
    history.push("/login");
    resetErrorBoundary();
  };
  return (
    <>
      {level === ErrorLevel.App && (
        <AppError
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          onClear={handleClear}
        />
      )}
      {level === ErrorLevel.View && (
        <ViewError
          error={error}
          resetErrorBoundary={resetErrorBoundary}
          onClear={handleClear}
        />
      )}
      {level === ErrorLevel.Component && (
        <ComponentError error={error} resetErrorBoundary={resetErrorBoundary} />
      )}
    </>
  );
};

export default withRouter(ErrorFallback);
