import * as React from "react";
import { TFunction } from "react-i18next";

export const FormatTaskState = (
  stateName: string,
  color: string,
  t: TFunction
) => {
  return (
    <span>
      <div style={{ display: "flex", alignItems: "center" }}>
        {color && (
          <div
            style={{
              backgroundColor: color,
              marginRight: "0.2rem",
              width: "0.3rem",
              height: "1.5rem",
            }}
          ></div>
        )}
        {t(stateName)}
      </div>
    </span>
  );
};
