import { makeStyles } from "tss-react/mui";
import {
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Theme,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { useState } from "react";
import GridName from "../../../helpers/enums/gridName";
import { draggedItemUpdated } from "../../../store/common/gridsSlice";
import { useAppDispatch } from "../../../store/hooks";
import { deleteCustomComponentAsync } from "../../../store/common/customGridComponentsSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  listBackground: {
    width: "100%",
    height: "100%",
    objectFit: "scale-down",
  },
  gridTile: {
    height: "15rem",
  },
  tileRoot: {
    height: "2rem",
  },
  tileTitleWrap: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  tileTitle: {
    fontSize: "0.7rem",
  },
  tooltip: {
    maxWidth: "unset",
    padding: "0.1rem",
  },
  deleteIcon: {
    position: "absolute",
    zIndex: 10,
    top: 5,
    right: 5,
  },
  newLabel: {
    position: "absolute",
    zIndex: 10,
    top: 5,
    left: 5,
    color: "green",
    fontWeight: "bold",
  },
}));

export interface SingleEditorTileProps {
  gridName: GridName;
  tileKey: string;
  title: string;
  width: number;
  height: number;
  background: string;
  deletable: boolean;
}

const SingleEditorTile: React.FC<SingleEditorTileProps> = ({
  gridName,
  tileKey,
  title,
  width,
  height,
  background,
  deletable,
}) => {
  const { classes } = useStyles();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  return (
    <ImageListItem
      className={classes.gridTile}
      component="div"
      draggable={true}
      unselectable="on"
      onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData("text/plain", "");
        setIsDisabled(true);
        dispatch(
          draggedItemUpdated({
            i: tileKey,
            w: width,
            h: height,
          })
        );
      }}
      onDragEnd={(event: React.DragEvent<HTMLDivElement>) => {
        setIsDisabled(false);
      }}
    >
      {tileKey.toLowerCase() === "editable_component" && (
        <Typography className={classes.newLabel} variant="h6">
          NEW
        </Typography>
      )}
      {deletable && (
        <IconButton
          className={classes.deleteIcon}
          onClick={() => {
            dispatch(
              deleteCustomComponentAsync({ gridName: gridName, key: tileKey })
            );
          }}
          disabled={isDisabled}
          size="large"
        >
          <DeleteIcon color="error" />
        </IconButton>
      )}
      <img className={classes.listBackground} src={background} alt={tileKey} />
      <ImageListItemBar
        classes={{
          root: classes.tileRoot,
          titleWrap: classes.tileTitleWrap,
          title: classes.tileTitle,
        }}
        title={title}
      />
    </ImageListItem>
  );
};

export default SingleEditorTile;
