import { Paper } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  chartHeader: {
    fontSize: "1.8rem",
    textAlign: "left",
  },
  chart: {
    minWidth: 0,
    minHeight: 0,
  },
}));

interface ProductionEfficiencyChartProps {
  chartStyle: string;
}

const ProductionEfficiencyChartComponent: React.FC<
  ProductionEfficiencyChartProps
> = ({ chartStyle }) => {
  // const runnedTask = useAppSelector(selectRunnedTask);
  const { classes } = useStyles();
  const { t } = useTranslation();

  // const data = useMemo(
  //   () => ({
  //     labels: [t("Estimated production"), t("Real production")],
  //     datasets: [
  //       {
  //         data: [
  //           estProductionRate(runnedTask!),
  //           realProductionRate(runnedTask!, runnedTask?.duration ?? 0),
  //         ],
  //         backgroundColor: [chartGoodColor, chartBadColor],
  //         hoverBackgroundColor: [chartGoodColor, chartBadColor],
  //       },
  //     ],
  //   }),
  //   [runnedTask, t]
  // );

  // const translateProdUom = () => {
  //   switch (runnedTask?.prodUom) {
  //     case 0: {
  //       return t("Hours per batch");
  //     }
  //     case 1: {
  //       return t("Minutes per batch");
  //     }
  //     case 2: {
  //       return t("Seconds per batch");
  //     }
  //     case 3: {
  //       return t("Batches per hour");
  //     }
  //     case 4: {
  //       return t("Batches per minute");
  //     }
  //     case 5: {
  //   return t("Batches per seconds");
  //   }
  //   }
  // };

  return (
    <Paper className={chartStyle} elevation={2} square>
      <div className={classes.chartHeader}>
        {t("Production efficiency")}
        {/* [{translateProdUom()}] */}
      </div>
      <div className={classes.chart}>
        {/* <Bar
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            indexAxis: "y",
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: (tooltipItems: Array<TooltipItem<"bar">>) => {
                    return tooltipItems[0]?.label;
                  },
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: translateProdUom(),
                },
                stacked: true,
              },
            },
          }}
        /> */}
      </div>
    </Paper>
  );
};

export default ProductionEfficiencyChartComponent;
