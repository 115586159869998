import { CheckmarkIcon, CrossIcon } from "@improdis/core";
import { styled } from "@mui/material";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";
import ProductionReason from "../../../helpers/enums/productionReasonEnum";
import TableName from "../../../helpers/enums/tableName";
import { sortDateTime } from "../../../helpers/tableSorts";
import { FormatDate } from "../../../helpers/tables/date/FormatDate";
import { filterDates } from "../../../helpers/tables/date/filterDates";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  ProductionEvent,
  productionEventSelected,
  productionEventsSelectionCleared,
  selectProductionEvents,
  selectProductionEventsPending,
  selectSelectedProductionEventLogId,
} from "../../../store/main/production-events/prodEventsSlice";
import BooleanColumnFilter from "../../common/table-filters/BooleanColumnFilter";
import TableComponent from "../../common/table/TableComponent";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  maxHeight: "100%",
  width: "100%",
  maxWidth: "100%",
  padding: "0.5rem",
  boxSizing: "border-box",
}));

const SuccessCheckmarkIcon = styled(CheckmarkIcon)(({ theme }) => ({
  fill: theme.palette.success.main,
}));
const ErrorCrossIcon = styled(CrossIcon)(({ theme }) => ({
  fill: theme.palette.error.main,
}));

interface ProductionHistoryTableProps {}

const ProductionHistoryTableComponent: FunctionComponent<
  ProductionHistoryTableProps
> = () => {
  const dispatch = useAppDispatch();
  const selectedProductionEventLogId = useAppSelector(
    selectSelectedProductionEventLogId
  );
  const productionEvents = useAppSelector(selectProductionEvents);
  const productionEventsPending = useAppSelector(selectProductionEventsPending);
  const { t } = useTranslation();

  const columns: Array<Column<ProductionEvent>> = useMemo(
    () => [
      {
        Header: t("logId").toString(),
        id: "logId",
        accessor: "logId",
      },
      {
        Header: t("workOrderName").toString(),
        id: "workOrderName",
        accessor: "workOrderName",
      },
      {
        Header: t("taskId").toString(),
        id: "taskId",
        accessor: "taskId",
      },
      {
        Header: t("taskName").toString(),
        id: "taskName",
        accessor: "taskName",
      },
      {
        Header: t("assetId").toString(),
        id: "assetId",
        accessor: "assetId",
      },
      {
        Header: t("productionReasonId").toString(),
        id: "productionReasonId",
        accessor: "productionReasonId",
      },
      {
        Header: t("productionReason").toString(),
        id: "productionReasonName",
        accessor: "productionReasonName",
      },
      {
        Header: t("isGood").toString(),
        id: "isGood",
        Filter: BooleanColumnFilter,
        filter: "boolean",
        accessor: (row) => row.productionReasonName === ProductionReason.Good,
        Cell: (cell) =>
          cell.value ? <SuccessCheckmarkIcon /> : <ErrorCrossIcon />,
      },
      {
        Header: t("quantityProduced").toString(),
        id: "quantityProduced",
        accessor: "quantityProduced",
        // Filter: NumberColumnFilter,
        // filter: "number",
      },
      {
        Header: t("startedAt").toString(),
        id: "startedAt",
        accessor: "startedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
        sortType: sortDateTime,
      },
      {
        Header: t("finishedAt").toString(),
        id: "finishedAt",
        accessor: "finishedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
        sortType: sortDateTime,
      },
      {
        Header: t("comment").toString(),
        id: "comment",
        accessor: "comment",
      },
      {
        Header: t("sourceLogId").toString(),
        id: "sourceLogId",
        accessor: "sourceLogId",
      },
      {
        Header: t("itemName").toString(),
        id: "itemName",
        accessor: "itemName",
      },
      {
        Header: t("uomName").toString(),
        id: "uomName",
        accessor: "uomName",
      },
      {
        Header: t("createdById"),
        id: "createdById",
        accessor: "createdById",
      },
      {
        Header: t("createdByName"),
        id: "createdByName",
        accessor: (o) => o.createdByFullName || o.createdByUserName,
      },
    ],
    [t]
  );

  const handleRowClick = (row: Row<ProductionEvent>) => {
    if (!row.isSelected) {
      dispatch(productionEventSelected(row.original.logId));
    } else {
      dispatch(productionEventsSelectionCleared());
    }
  };

  const handleDataRefresh = (
    selectedFlatRows: Array<Row<ProductionEvent>>,
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => {
    if (!selectedFlatRows.some((r) => r.id === selectedProductionEventLogId)) {
      toggleAllRowsSelected(false);
      dispatch(productionEventsSelectionCleared());
    }
  };

  const customGetRowId = (
    row: ProductionEvent,
    relativeIndex: number,
    parent: Row<ProductionEvent> | undefined
  ) => (parent ? [parent.id, row.logId].join(".") : row.logId);

  return (
    <Root>
      <TableComponent
        name={TableName.PRODUCTION_HISTORY}
        data={productionEvents}
        columns={columns}
        isLoading={productionEventsPending}
        onClick={handleRowClick}
        editable
        onDataRefresh={handleDataRefresh}
        getRowId={customGetRowId}
      />
    </Root>
  );
};

export default ProductionHistoryTableComponent;
