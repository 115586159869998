import { Theme } from "@mui/material/styles";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";
import "simplebar-react/dist/simplebar.min.css";
import { makeStyles } from "tss-react/mui";
import TableName from "../../../helpers/enums/tableName";
import { FormatDate } from "../../../helpers/tables/date/FormatDate";
import { filterDates } from "../../../helpers/tables/date/filterDates";
import { sortDateTime } from "../../../helpers/tables/date/sortDates";
import { filterDurations } from "../../../helpers/tables/duration/filterDurations";
import { FormatUtilRaw } from "../../../helpers/tables/util-raw/FormatUtilRaw";
import { filterUtilRaws } from "../../../helpers/tables/util-raw/filterUtilRaws";
import { FormatUtilState } from "../../../helpers/tables/util-state/FormatUtilState";
import { filterUtilStates } from "../../../helpers/tables/util-state/filterUtilStates";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  UtilEvent,
  selectSelectedUtilEventLogId,
  selectUtilEvents,
  selectUtilEventsPending,
  utilEventSelected,
  utilEventsSelectionCleared,
} from "../../../store/main/utilization-events/utilEventsSlice";
import { selectSelectedAssetId } from "../../../store/user/assetSelectionSlice";
import Duration from "../../common/Duration";
import TableComponent from "../../common/table/TableComponent";
import DialogUtilEventHistoryComponent from "./DialogUtilEventHistoryComponent";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    width: "calc(100% - 13rem)",
    maxWidth: "calc(100% - 13rem)",
    paddingRight: "1rem",
  },

  goodIcon: {
    fill: theme.palette.success.main,
  },

  badIcon: {
    fill: theme.palette.error.main,
  },
}));

interface UtilHistoryTableProps {}

const UtilHistoryTableComponent: React.FC<UtilHistoryTableProps> = () => {
  const dispatch = useAppDispatch();
  const selectedUtilEventLogId = useAppSelector(selectSelectedUtilEventLogId);
  const utilEvents = useAppSelector(selectUtilEvents);
  const utilEventsPending = useAppSelector(selectUtilEventsPending);
  const selectedAssetId = useAppSelector(selectSelectedAssetId);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const columns: Array<Column<UtilEvent>> = useMemo(
    () => [
      {
        Header: t("logId").toString(),
        id: "logId",
        accessor: "logId",
      },
      {
        Header: t("assetName").toString(),
        id: "assetName",
        accessor: "assetName",
      },
      {
        Header: t("startedAt"),
        id: "startedAt",
        accessor: "startedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        sortType: sortDateTime,
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
      },
      {
        Header: t("finishedAt"),
        id: "finishedAt",
        accessor: "finishedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        sortType: sortDateTime,
        filter: (rows, id, filterValue) =>
          filterDates(rows, id, filterValue, t),
      },
      {
        Header: t("duration"),
        id: "duration",
        accessor: "duration",
        // Cell: (cell) => FormatDuration(cell.value, t),
        Cell: (cell) => (
          <Duration
            startDate={cell.row.original.startedAt}
            endDate={cell.row.original.finishedAt}
          />
        ),
        filter: (rows, id, filterValue) =>
          filterDurations(rows, id, filterValue, t),
      },
      {
        Header: t("utilRawName"),
        id: "utilRawName",
        accessor: "utilRawName",
        Cell: (cell) =>
          FormatUtilRaw(cell.value!, cell.row.original.utilStateColor, t),
        filter: (rows, id, filterValue) =>
          filterUtilRaws(rows, id, filterValue, t),
      },
      {
        Header: t("utilStateName"),
        id: "utilStateName",
        accessor: "utilStateName",
        Cell: (cell) => FormatUtilState(cell.value!, t),
        filter: (rows, id, filterValue) =>
          filterUtilStates(rows, id, filterValue, t),
      },
      {
        Header: t("comment"),
        id: "comment",
        accessor: "comment",
      },
      {
        Header: t("createdById"),
        id: "createdById",
        accessor: "createdById",
      },
      {
        Header: t("createdByName"),
        id: "createdByName",
        accessor: "createdByUserName",
      },
    ],
    [t]
  );

  useEffect(() => {
    return () => {
      dispatch(utilEventsSelectionCleared());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  const handleRowClick = (row: Row<UtilEvent>) => {
    if (!row.isSelected) {
      dispatch(utilEventSelected(row.original.logId));
    } else {
      dispatch(utilEventsSelectionCleared());
    }
  };

  const handleDataRefresh = (
    selectedFlatRows: Array<Row<UtilEvent>>,
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => {
    if (!selectedFlatRows.some((r) => r.id === selectedUtilEventLogId)) {
      toggleAllRowsSelected(false);
      dispatch(utilEventsSelectionCleared());
    }
  };

  const customGetRowId = (
    row: UtilEvent,
    relativeIndex: number,
    parent: Row<UtilEvent> | undefined
  ) => (parent ? [parent.id, row.logId].join(".") : row.logId);

  return (
    <div className={classes.root}>
      <TableComponent
        name={TableName.UTIL_HISTORY}
        data={utilEvents}
        columns={columns}
        isLoading={utilEventsPending}
        onClick={handleRowClick}
        editable
        onDataRefresh={handleDataRefresh}
        getRowId={customGetRowId}
      />
      <DialogUtilEventHistoryComponent />
    </div>
  );
};

export default UtilHistoryTableComponent;
