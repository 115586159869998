import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={20.051} height={13.683} viewBox="0 0 20.051 13.683" {...props}>
      <g data-name="Icon / Utilization">
        <g data-name="TopSpeed / Line / M">
          <path
            data-name="Path 1689"
            d="M8.125 10.377l6.2-8.479a.7.7 0 01.9-.19.605.605 0 01.3.76v.1l-3.9 9.8a1.988 1.988 0 01-2.6 1.17c-.1-.04-.2-.07-.3-.12a2.11 2.11 0 01-.8-2.81l.1-.12.1-.11 6.2-8.479zm3.8-2.62l-2.6 3.51-.1.061a.728.728 0 00.2.78l.1.029a.534.534 0 00.7-.239v-.08zm4.7-5.39a9.884 9.884 0 012 2.18 10.079 10.079 0 011.4 2.391.831.831 0 01-.5.979.653.653 0 01-.9-.43c-.1-.25-.3-.53-.4-.811l-2.1 1.181a.728.728 0 01-1-.271.737.737 0 01.2-.97l.1-.061 2-1.159-.2-.32c-.4-.42-.7-.811-1.1-1.16l.4-.96c0-.1.1-.19.1-.29v-.3zm-2.6-1.561a.972.972 0 00-.4.33l-.1.141-.6.729a9.343 9.343 0 00-2.1-.47v2.68a.807.807 0 01-.8.75.667.667 0 01-.7-.649v-2.79a9.014 9.014 0 00-4.6 1.63 7.535 7.535 0 00-2.2 2.2l2.1 1.21a.735.735 0 01-.6 1.34l-.1-.04-2.1-1.2a3.45 3.45 0 01-.4.82.653.653 0 01-.9.43.825.825 0 01-.5-.97 11.346 11.346 0 013.8-5.021 10.681 10.681 0 0110.2-1.119z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
