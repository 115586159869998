import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

export const initialState: boolean = false;

export const addUtilEventSlice = createSlice({
  name: "addUtilEventDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      return true;
    },
    closed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const {
  opened: addUtilEventDialogOpened,
  closed: addUtilEventDialogClosed,
} = addUtilEventSlice.actions;

export const selectAddUtilEventDialogOpen = createSelector(
  (state: RootState) => state.dialogs.openAddUtilEventDialog,
  (openAddUtilEventDialog) => openAddUtilEventDialog
);

export default addUtilEventSlice.reducer;
