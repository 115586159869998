import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../..";

export const initialState: boolean = false;

export const runningTaskStateChangedSlice = createSlice({
  name: "runningTaskStateChangedDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      return true;
    },
    closed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const {
  opened: runningTaskStateChangedDialogOpened,
  closed: runningTaskStateChangedDialogClosed,
} = runningTaskStateChangedSlice.actions;

export const selectRunningTaskStateChangedDialogOpen = createSelector(
  (state: RootState) => state.dialogs.openRunningTaskStateChangedDialog,
  (openRunningTaskStateChangedDialog) => openRunningTaskStateChangedDialog
);

export default runningTaskStateChangedSlice.reducer;
