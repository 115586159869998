import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import {
  runningTaskStateChangedDialogClosed,
  selectRunningTaskStateChangedDialogOpen,
} from "../../../store/common/dialogs/running-task-changed-state/runningTaskStateChangedSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectIsPendingLogOut } from "../../../store/user/authSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  dialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },

  header: {
    width: "30rem",
  },

  content: {},
}));

interface DialogJobStateChangedProps {}

const DialogJobStateChangedComponent: React.FC<
  DialogJobStateChangedProps
> = () => {
  const isPendingLogout = useAppSelector(selectIsPendingLogOut);
  const open = useAppSelector(selectRunningTaskStateChangedDialogOpen);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleClose = () => dispatch(runningTaskStateChangedDialogClosed());

  return (
    <Dialog
      open={open && !isPendingLogout}
      onClose={handleClose}
      className={classes.dialog}
      maxWidth={false}
    >
      <DialogTitle className={classes.header}>
        {t("Someone changed status of your job")}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          {t("You will be taken to the order selection panel")}
        </DialogContentText>
        <DialogActions>
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleClose()}
          >
            {t("Submit")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DialogJobStateChangedComponent;
