import Paper from "@mui/material/Paper";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../store/hooks";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: "1rem",
    paddingTop: "1.5rem",
    position: "relative",
    boxSizing: "border-box",
  },

  goodProductionHeader: {
    textAlign: "left",
    fontSize: "1.5rem",
  },

  actualGoodProductionContent: {
    display: "flex",
    textAlign: "left",
    padding: "1rem",
    fontSize: "2.5rem",
  },
}));

export interface GoodProductionReadOnlyComponentProps {}

const GoodProductionReadOnlyComponent: React.FC<
  GoodProductionReadOnlyComponentProps
> = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const runnedTask = useAppSelector(selectRunnedTask);

  return (
    <Paper className={classes.paper} elevation={2} square>
      <div className={classes.goodProductionHeader}>
        {t("Good production")}:
      </div>
      <div className={classes.actualGoodProductionContent}>
        <div>{runnedTask?.quantityProduced ?? 0}</div>
        <div style={{ paddingLeft: "1rem" }}>
          {runnedTask?.abbreviation || runnedTask?.uomName}
        </div>
      </div>
    </Paper>
  );
};

export default GoodProductionReadOnlyComponent;
