import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  NestedSystemAttributeGroupDTO,
  SystemAttributeGroupDTO,
  SystemAttributeGroupsClient,
} from "../httpService";

const http = new SystemAttributeGroupsClient(
  env.REACT_APP_MES_API_URL,
  instance
);

export const getNestedSystemAttributeGroups = async (
  throwError: boolean = true
): Promise<Array<NestedSystemAttributeGroupDTO> | undefined> => {
  try {
    const result = await http.getNestedSystemAttributeGroups();
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getSystemAttributeGroupsById = async (
  id: string,
  throwError: boolean = true
): Promise<SystemAttributeGroupDTO | undefined> => {
  try {
    const result = await http.getSystemAttributeGroupsById(id);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getSystemAttributesByGroupId = async (
  groupId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    await http.getSystemAttributeGroupsById(groupId);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
