import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "..";

export const initialState: boolean = false;

export const refreshTokenAsync = (): AppThunk => async (dispatch, getState) => {
  // const userInfo = getState().user.auth.details;
  // const mesConnection = getState().user.connectionToMes;
  // const status = await imFactoryRefreshSession(
  //   ClientType.Operator,
  //   sessionId ?? -1,
  //   userInfo.username || "",
  //   selectedEnt.entId,
  //   false
  // );
  // if (status.result) {
  //   //SUCCESS
  //   if (status.result.newSessionId !== status.result.oldSessionId) {
  //     snackbarHelper.info(
  //       i18n.t("ExpSessnMsg", {
  //         newSessionId: status.result.newSessionId,
  //       })
  //     );
  //     dispatch(sessionSwitched(status.result.newSessionId));
  //   }
  //   !mesConnection && dispatch(connectedToMes());
  // } else {
  //   //FAILURE
  //   mesConnection && dispatch(disconnectedFromMes());
  //   if (!status.networkError) {
  //     dispatch(logOutAsync());
  //   }
  // }
};

export const mesConnectionSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    connectedToMes: (state, action: PayloadAction<void>) => {
      return true;
    },
    disconnectedFromMes: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const { connectedToMes, disconnectedFromMes } =
  mesConnectionSlice.actions;

export default mesConnectionSlice.reducer;
