import { env } from "../../env";
import GetAvailableUtilRawsActionType from "../../helpers/enums/getAvailableUtilRawsActionType";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  GetNestedUtilRawsQueryGroupDto,
  UtilRawDto,
  UtilizationRawsClient,
} from "../httpService";

const http = new UtilizationRawsClient(env.REACT_APP_MES_API_URL, instance);

export const getAvailableNestedUtilRawsByAsset = async (
  assetId: string,
  utilRawId: string | undefined,
  actionType: GetAvailableUtilRawsActionType,
  throwError: boolean = true
): Promise<Array<GetNestedUtilRawsQueryGroupDto> | undefined> => {
  try {
    const result = await http.getAvailableNestedUtilRawsByAsset(
      assetId,
      utilRawId,
      actionType as any
    );
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getUtilRawById = async (
  utilRawId: string,
  throwError: boolean = true
): Promise<UtilRawDto | undefined> => {
  try {
    const result = await http.getUtilRawById(utilRawId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
