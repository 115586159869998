import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import { UserDto, UsersClient } from "../httpService";

const http = new UsersClient(env.REACT_APP_MES_API_URL, instance);

export const getUserById = async (
  utilRawId: string,
  throwError: boolean = true
): Promise<UserDto | undefined> => {
  try {
    const result = await http.getUserById(utilRawId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
