import { Paper } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { TooltipItem } from "chart.js";
import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { remainingTimeForWork } from "../../../../../helpers/runnedJobHelper";
import { FormatDurationString } from "../../../../../helpers/tables/duration/FormatDuration";
import { useAppSelector } from "../../../../../store/hooks";
import { chartGoodColor, chartBadColor } from "../../../../../themes/appTheme";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  chartHeader: {
    fontSize: "1.8rem",
    textAlign: "left",
  },
  chart: {
    minWidth: 0,
    minHeight: 0,
  },
}));
interface UseOfTimeChartProps {
  chartStyle: string;
}

const UseOfTimeChartComponent: React.FC<UseOfTimeChartProps> = ({
  chartStyle,
}) => {
  const runnedTask = useAppSelector(selectRunnedTask);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const data = useMemo(
    () => ({
      labels: [
        `${t("Used time")}: ${FormatDurationString(
          runnedTask?.duration ?? 0,
          t
        )}`,
        `${t("Remaining time")}: ${FormatDurationString(
          remainingTimeForWork(runnedTask, runnedTask?.duration ?? 0) > 0
            ? remainingTimeForWork(runnedTask, runnedTask?.duration ?? 0)
            : 0,
          t
        )}`,
      ],
      datasets: [
        {
          data: [
            runnedTask?.duration ?? 0,
            remainingTimeForWork(runnedTask, runnedTask?.duration ?? 0) > 0
              ? remainingTimeForWork(runnedTask, runnedTask?.duration ?? 0)
              : 0,
          ],
          backgroundColor: [chartGoodColor, chartBadColor],
          hoverBackgroundColor: [chartGoodColor, chartBadColor],
        },
      ],
    }),
    [runnedTask, t]
  );

  return (
    <Paper className={chartStyle} elevation={2} square>
      <div className={classes.chartHeader}>
        {t("Use of time")} [{t("h")}]
      </div>
      <div className={classes.chart}>
        <Doughnut
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  title: () => "",
                  label: (tooltipItem: TooltipItem<"doughnut">) =>
                    tooltipItem.label,
                },
              },
            },
          }}
        ></Doughnut>
      </div>
    </Paper>
  );
};

export default UseOfTimeChartComponent;
