import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  getNestedAssetNodeId,
  selectNestedAssets,
} from "../definitions/assetsSlice";
import {
  StepOfProduction,
  runningTaskCleared,
  selectRunnedTask,
  stepOfProductionSelected,
} from "../main/job-execution/jobExecutionSlice";
import { logOnAsset } from "../../services/main/assetLogonsService";

export interface SelectedAssetState {
  selectedAssetId: string | null;
  selectedAssetSourceStack: Array<string>;
  pendingAssetLogon: boolean;
}

export const initialState: SelectedAssetState = {
  selectedAssetId: null,
  selectedAssetSourceStack: [],
  pendingAssetLogon: false,
};

export interface LogOnEntParams {
  assetId: string;
  sourceStack: Array<string>;
}

export const logOnAssetAsync = createAsyncThunk(
  "assetNavigation/logOnAssetAsync",
  async (params: LogOnEntParams, { getState, dispatch }) => {
    const runnedTask = selectRunnedTask(getState() as RootState);
    await logOnAsset(params.assetId, true);
    // await imfactoryLogOnEnt(
    //   params.sessionId || userInfo.sessionId!,
    //   params.username || userInfo.username!,
    //   params.entId,
    //   curSelectedEnt.value.entId
    // );
    runnedTask !== undefined &&
      dispatch(stepOfProductionSelected(StepOfProduction.FirstStep));
    dispatch(runningTaskCleared());
    return params;
  }
);

export const assetSelectionSlice = createSlice({
  name: "assetNavigation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logOnAssetAsync.pending, (state, action) => {
        state.pendingAssetLogon = true;
      })
      .addCase(logOnAssetAsync.fulfilled, (state, action) => {
        state.selectedAssetId = action.meta.arg.assetId;
        state.selectedAssetSourceStack = action.meta.arg.sourceStack;
        state.pendingAssetLogon = false;
      })
      .addCase(logOnAssetAsync.rejected, (state, action) => {
        state.pendingAssetLogon = false;
      });
  },
});

// export const {} = entSelectionSlice.actions;

export const selectSelectedAssetId = createSelector(
  (state: RootState) => state.user.selectedAsset.selectedAssetId,
  (selectedAssetId) => selectedAssetId
);

export const selectSelectedAssetSourceStack = createSelector(
  (state: RootState) => state.user.selectedAsset.selectedAssetSourceStack,
  (selectedAssetSourceStack) => selectedAssetSourceStack
);

export const selectSelectedAssetNodeId = createSelector(
  selectSelectedAssetSourceStack,
  selectSelectedAssetId,
  (selectedAssetSourceStack, selectedAssetId) =>
    selectedAssetId
      ? getNestedAssetNodeId(selectedAssetSourceStack, selectedAssetId)
      : undefined
);

export const selectSelectedAsset = createSelector(
  selectNestedAssets,
  selectSelectedAssetId,
  selectSelectedAssetSourceStack,
  (nestedAssets, selectedAssetId, selectedAssetSourceStack) => {
    if (selectedAssetSourceStack.length > 0) {
      let currentAssets = nestedAssets;
      selectedAssetSourceStack.forEach((sId) => {
        const foundAsset = currentAssets.find((a) => a.id === sId);
        if (!foundAsset) return null;
        currentAssets = foundAsset.childrenAssets ?? [];
      });
      return currentAssets.find((a) => a.id === selectedAssetId);
    } else if (selectedAssetId) {
      return nestedAssets.find((a) => a.id === selectedAssetId);
    } else {
      return null;
    }
  }
);

export default assetSelectionSlice.reducer;
