import badProductionBackground from "./badProduction.png";
import badProductionReadonlyBackground from "./badProductionReadonly.png";
import entityParametersBackground from "./entityParameters.png";
import goodProductionBackground from "./goodProduction.png";
import goodProductionReadonlyBackground from "./goodProductionReadonly.png";
import jobInformationBackground from "./jobInformation.png";
import jobProgressBackground from "./jobProgress.png";

const productionTiles = {
  badProductionBackground,
  badProductionReadonlyBackground,
  entityParametersBackground,
  goodProductionBackground,
  goodProductionReadonlyBackground,
  jobInformationBackground,
  jobProgressBackground,
};

export default productionTiles;
