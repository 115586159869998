import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../..";
import GridName from "../../../helpers/enums/gridName";
import { OverviewGridItemKey } from "../../../helpers/grids/overview-grid/defaultGridSettings";
import { ProductionGridItemKey } from "../../../helpers/grids/production-grid/defaultGridSettings";
import {
  customComponentKeyChanged,
  customComponentValuesChanged,
  selectEditDialogCustomComponents,
} from "../customGridComponentsSlice";
import { componentKeyUpdated, layoutsRefreshed } from "../gridsSlice";

const keyMap = new Map<GridName, string[]>([
  [GridName.OVERVIEW, Object.values(OverviewGridItemKey)],
  [GridName.PRODUCTION, Object.values(ProductionGridItemKey)],
]);

interface EditableComponentDialogState {
  open: boolean;
  gridName: GridName | null;
  componentKey: {
    original: string | null;
    modified: string | null;
  };
  code: string;
  imFactoryAttrName: string;
  title: string;
  isNew: boolean;
}

export const initialState: EditableComponentDialogState = {
  open: false,
  gridName: null,
  componentKey: {
    original: null,
    modified: null,
  },
  code: "",
  imFactoryAttrName: "",
  title: "",
  isNew: false,
};

export const submitComponentChangesAndCloseDialogAsync = createAsyncThunk(
  "editableComponentDialog/submitChangesAndCloseDialogAsync",
  async (_, { getState, dispatch }) => {
    const dialog = selectEditableComponentDialog(getState() as RootState);
    dispatch(
      customComponentValuesChanged({
        gridName: dialog.gridName!,
        key: dialog.componentKey.original!,
        values: {
          title: dialog.title,
          code: dialog.code,
          componentAttrName: dialog.imFactoryAttrName,
          componentAttrId: null,
        },
      })
    );
    if (
      dialog.isNew &&
      dialog.componentKey.original !== dialog.componentKey.modified
    ) {
      dispatch(
        componentKeyUpdated({
          gridName: dialog.gridName!,
          oldKey: dialog.componentKey.original!,
          newKey: dialog.componentKey.modified!,
        })
      );
      dispatch(
        customComponentKeyChanged({
          gridName: dialog.gridName!,
          oldKey: dialog.componentKey.original!,
          newKey: dialog.componentKey.modified!,
        })
      );
    }
    dispatch(editableComponentDialogClosed());
    dispatch(layoutsRefreshed(true));
  }
);

export const editableComponentSlice = createSlice({
  name: "editableComponentDialog",
  initialState,
  reducers: {
    opened: (
      state,
      action: PayloadAction<{
        gridName: GridName;
        componentKey: string;
        code: string;
        title: string;
        imFactoryAttrName?: string;
        isNew?: boolean;
      }>
    ) => {
      state.gridName = action.payload.gridName;
      state.componentKey = {
        original: action.payload.componentKey,
        modified: action.payload.componentKey,
      };
      state.code = action.payload.code;
      state.title = action.payload.title;
      state.imFactoryAttrName =
        action.payload.imFactoryAttrName || "editable_component";
      state.isNew = action.payload.isNew ?? false;
      state.open = true;
    },
    componentTitleChanged: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    componentKeyChanged: (state, action: PayloadAction<string>) => {
      state.componentKey.modified = action.payload;
    },
    componentCodeChanged: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    componentAttrChanged: (state, action: PayloadAction<string>) => {
      state.imFactoryAttrName = action.payload;
    },
    closed: (state, action: PayloadAction<void>) => {
      state.open = false;
    },
  },
});

export const {
  opened: editableComponentDialogOpened,
  componentTitleChanged: editableComponentDialogTitleChanged,
  componentKeyChanged: editableComponentDialogComponentKeyChanged,
  componentCodeChanged: editableComponentDialogComponentCodeChanged,
  componentAttrChanged: editableComponentDialogComponentAtrrChanged,
  closed: editableComponentDialogClosed,
} = editableComponentSlice.actions;

export const selectEditableComponentDialogOpen = createSelector(
  (state: RootState) => state.dialogs.editableComponentDialog.open,
  (editableComponentDialogOpen) => editableComponentDialogOpen
);

export const selectEditableComponentDialog = createSelector(
  (state: RootState) => state.dialogs.editableComponentDialog,
  (editableComponentDialog) => editableComponentDialog
);

export const selectEditComponentDialogKeyError = createSelector(
  (state: RootState) => state.dialogs.editableComponentDialog.gridName,
  (state: RootState) =>
    state.dialogs.editableComponentDialog.componentKey.modified,
  selectEditDialogCustomComponents,
  (gridName, newKey, customComponents) =>
    gridName !== null &&
    newKey !== null &&
    (newKey === "" ||
      //check if has any whitespaces
      /\s/.test(newKey) ||
      customComponents.some(
        (c) =>
          !c.isNew &&
          c.key.toLowerCase() !== "editable_component" &&
          c.key.toLowerCase() === newKey.toLowerCase()
      ) ||
      keyMap
        .get(gridName)
        ?.some(
          (key) =>
            key.toLowerCase() !== "editable_component" &&
            key.toLowerCase() === newKey.toLowerCase()
        ))
);

export const selectEditComponentDialogAttrNameError = createSelector(
  (state: RootState) => state.dialogs.editableComponentDialog.imFactoryAttrName,
  selectEditDialogCustomComponents,
  (newAttrName, customComponents) =>
    newAttrName === "" ||
    /\s/.test(newAttrName) ||
    customComponents.some(
      (c) =>
        !c.isNew &&
        c.componentAttrName.toLowerCase() !== "editable_component" &&
        c.componentAttrName.toLowerCase() === newAttrName.toLowerCase()
    )
);

export default editableComponentSlice.reducer;
