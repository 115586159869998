import Paper from "@mui/material/Paper";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DueDateIcon from "../../../../../icons/DueDateIcon";
import DurationIcon from "../../../../../icons/DurationIcon";
import ProductionIcon from "../../../../../icons/ProductionIcon";
import { useAppSelector } from "../../../../../store/hooks";
import { Box } from "@mui/material";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "auto",
    position: "relative",
    boxSizing: "border-box",
  },

  table: {
    width: "100%",
    fontSize: "2.5rem",
  },

  cell: {
    fontSize: "1.2rem",
    maxWidth: "8rem",
  },

  header: {
    display: "flex",
    alignItems: "center",
  },

  bar: {
    width: "0.3rem",
    height: "1.6rem",
  },

  icon: {
    marginRight: "0.3rem",
    marginLeft: "0.1rem",
  },
}));

interface TargetProductionProps {}

const TargetProductionComponent: React.FC<TargetProductionProps> = () => {
  const runnedTask = useAppSelector(selectRunnedTask);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [runtime, setRuntime] = useState<number>(0);
  const [actualDate, setActualDate] = useState<number>(0);
  const [initialRuntime, setInitialRuntime] = useState<number>(0);

  useEffect(() => {
    if (runnedTask?.duration && runnedTask?.duration !== initialRuntime) {
      setInitialRuntime(runnedTask?.duration);
      setRuntime(runnedTask?.duration);
    }
    setActualDate(new Date().getTime());
  }, [runnedTask?.duration, initialRuntime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRuntime(runtime + 1);
      setActualDate(actualDate + 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [runtime, actualDate]);

  const FormatDuration = (diff: number): string => {
    if (diff >= 0) {
      const hours = Math.floor(diff / 3600);
      const minutes = Math.floor((diff - hours * 3600) / 60);
      const seconds = Math.floor(diff - hours * 3600 - minutes * 60);
      return `${hours} ${t("h")} ${minutes} ${t("m")} ${seconds} ${t("s")}`;
    } else {
      const hours = Math.ceil(diff / 3600);
      const minutes = Math.ceil((diff - hours * 3600) / 60);
      const seconds = Math.ceil(diff - hours * 3600 - minutes * 60);
      return `${hours} ${t("h")} ${minutes} ${t("m")} ${seconds} ${t("s")}`;
    }
    // const days = Math.floor(diff / 86400); // 24 * 3600 [s]
  };

  const timeForWork = useMemo(() => {
    if (
      runnedTask &&
      runnedTask.scheduledFinishAt !== null &&
      runnedTask.scheduledStartAt !== null
    ) {
      const ticks =
        runnedTask.scheduledFinishAt.getTime() -
        runnedTask.scheduledStartAt.getTime();
      return (ticks / 1000).toFixed(1);
    }
  }, [runnedTask]);

  return (
    <TableContainer
      component={Paper}
      elevation={2}
      className={classes.root}
      square
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}></TableCell>
            <TableCell align="center" className={classes.cell}>
              <div>{t("Target")}</div>
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              <div>{t("Left")}</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={1}>
            <TableCell component="th" scope="row" className={classes.cell}>
              <div className={classes.header}>
                <Box
                  className={classes.bar}
                  sx={{ backgroundColor: "success.color" }}
                />
                <ProductionIcon className={classes.icon}></ProductionIcon>
                <div>{t("Production")}</div>
              </div>
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              {runnedTask?.quantityRequired ?? 0}{" "}
              {runnedTask?.abbreviation || runnedTask?.uomName}
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              {runnedTask?.quantityRequired !== undefined &&
                runnedTask.quantityProduced !== undefined &&
                runnedTask?.quantityRequired -
                  runnedTask?.quantityProduced}{" "}
              {runnedTask?.abbreviation || runnedTask?.uomName}
            </TableCell>
          </TableRow>
          <TableRow key={2}>
            <TableCell component="th" scope="row" className={classes.cell}>
              <div className={classes.header}>
                <Box
                  className={classes.bar}
                  sx={{ backgroundColor: "warning.main" }}
                />
                <DueDateIcon className={classes.icon}></DueDateIcon>
                <div>{t("Due date")}</div>
              </div>
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              {moment(runnedTask?.scheduledFinishAt).format(
                t("DateWithTimeWithoutSecondsFormat")
              )}
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              {runnedTask &&
                runnedTask.scheduledFinishAt !== null &&
                FormatDuration(
                  (runnedTask.scheduledFinishAt.getTime() - actualDate) / 1000
                )}
            </TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell component="th" scope="row" className={classes.cell}>
              <div className={classes.header}>
                <Box
                  className={classes.bar}
                  sx={{ backgroundColor: "error.main" }}
                />
                <DurationIcon className={classes.icon}></DurationIcon>
                <div>{t("Duration")}</div>
              </div>
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              {FormatDuration(Number(timeForWork))}
            </TableCell>
            <TableCell align="center" className={classes.cell}>
              {FormatDuration(Number(timeForWork) - runtime)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TargetProductionComponent;
