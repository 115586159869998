import { JobHistoryIcon } from "@improdis/core";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Paper, { PaperProps } from "@mui/material/Paper";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { makeStyles } from "tss-react/mui";
import TableName from "../../../../helpers/enums/tableName";
import EntitiesIcon from "../../../../icons/EntitiesIcon";
import WorkQueueFlatIcon from "../../../../icons/WorkQueueFlatIcon";
import WorkQueueTreeIcon from "../../../../icons/WorkQueueTreeIcon";
import { getFromLocalStorage } from "../../../../services/localStorageService";
import { RootState } from "../../../../store";
import {
  cancelNewTableLayouts,
  endEditingTables,
  localStorageKey,
  saveNewTableLayout,
  saveNewTableLayoutGlobal,
  tableColumnsUpdated,
} from "../../../../store/common/tablesSlice";
import { useAppDispatch } from "../../../../store/hooks";
import appTheme from "../../../../themes/appTheme";
import ConfirmCancelNewTableConfigurationsDialogComponent from "./ConfirmCancelNewTableConfigurationsDialogComponent";
import ConfirmSaveGlobalTableDialogComponent from "./ConfirmSaveGlobalTableDialogComponent";

const DraggablePaper = styled(Paper)(({ theme }) => ({
  "&.MuiDialog-paper": {
    position: "absolute",
    zIndex: 1,
    right: 0,
    bottom: 160,
    margin: 0,
    minWidth: "25rem",
  },
}));

const StyledOpenWithIcon = styled(OpenWithIcon)(({ theme }) => ({
  position: "absolute",
  right: "1rem",
  width: "2rem",
  height: "2rem",
}));

interface DraggablePaperComponentProps extends PaperProps {}

const DraggablePaperComponent: FunctionComponent<
  DraggablePaperComponentProps
> = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds="#container"
      positionOffset={{ x: 0, y: 100 }}
    >
      <DraggablePaper {...props} elevation={2} />
    </Draggable>
  );
};

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  justifyContent: "center",
  cursor: "-webkit-grab" /* fallback if grab cursor is unsupported */,
  "&:active": {
    cursor: "-webkit-grabbing",
  },
}));

const TablesIcons = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  width: "6rem",
  left: "10px",
  top: "10px",
  justifyContent: "space-around",
  fill: appTheme.palette.text.secondary,
}));

const useStyles = makeStyles()((theme: Theme) => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a: Array<string>, b: Array<string>) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: Array<string>, b: Array<string>) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: Array<string>, b: Array<string>) {
  return [...a, ...not(b, a)];
}

export interface TableColumnsEditDialogProps {}

const mapState = (state: RootState) => {
  return {
    props: state.dialogs.editTableDialog,
    tableColumns: state.tables.configurations,
    areTablesEditable: state.tables.areTablesEditable,
  };
};

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & TableColumnsEditDialogProps;

const TableColumnsEditDialogComponent: React.FC<Props> = ({
  props,
  areTablesEditable,
  tableColumns,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation("tableHeader");
  const { t: t2 } = useTranslation("common");
  const [checked, setChecked] = useState<Array<string>>([]);
  const [left, setLeft] = useState<Array<string>>([]);
  const [right, setRight] = useState<Array<string>>([]);
  const [saveGlobal, setSaveGlobal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [openConfirmSaveGlobal, setOpenConfirmSaveGlobal] =
    useState<boolean>(false);
  const [openCancelNewTableConf, setOpenCancelNewTableConf] =
    useState<boolean>(false);
  const [isProductionHistoryConfChanged, setIsProductionHistoryConfChanged] =
    useState<boolean>(false);
  const [isWorkQueueFlatConfChanged, setIsWorkQueueFlatConfChanged] =
    useState<boolean>(false);
  const [isWorkQueueTreeConfChanged, setIsWorkQueueTreeConfChanged] =
    useState<boolean>(false);
  const [isUtilHistoryConfChanged, setIsUtilHistoryConfChanged] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const mayEditGlobal = useMemo(() => true, []);

  const hasTableConfigurationChanged = useCallback(
    (tableName: TableName) => {
      return getFromLocalStorage(localStorageKey.get(tableName) || "") !==
        undefined
        ? JSON.stringify(
            getFromLocalStorage(localStorageKey.get(tableName) || "")
          ) !== JSON.stringify(tableColumns[tableName].columns)
        : JSON.stringify(props.allColumns) !==
            JSON.stringify(tableColumns[tableName].columns);
    },
    [props.allColumns, tableColumns] //FIXME how to detect array order changes?
  );

  useEffect(() => {
    if (props.name === TableName.WORK_QUEUE) {
      setIsWorkQueueFlatConfChanged(
        hasTableConfigurationChanged(TableName.WORK_QUEUE)
      );
    }
    if (props.name === TableName.WORK_QUEUE_TREE) {
      setIsWorkQueueTreeConfChanged(
        hasTableConfigurationChanged(TableName.WORK_QUEUE_TREE)
      );
    }
    if (props.name === TableName.PRODUCTION_HISTORY) {
      setIsProductionHistoryConfChanged(
        hasTableConfigurationChanged(TableName.PRODUCTION_HISTORY)
      );
    }
    if (props.name === TableName.UTIL_HISTORY) {
      setIsUtilHistoryConfChanged(
        hasTableConfigurationChanged(TableName.UTIL_HISTORY)
      );
    }
  }, [props.name, isSubmitting, hasTableConfigurationChanged]);

  const customList = (title: React.ReactNode, items: Array<string>) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(
              items.filter(
                (i) =>
                  !(
                    props.name === TableName.WORK_QUEUE_TREE &&
                    (i === "expander" ||
                      i === "moId" ||
                      i === "woId" ||
                      i === "operId")
                  )
              )
            )}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            // inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${t2(
          "selected"
        )}`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
              disabled={
                props.name === TableName.WORK_QUEUE_TREE &&
                (value === "expander" ||
                  value === "moId" ||
                  value === "woId" ||
                  value === "operId")
              }
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${t(value)}  (${value})`} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  useEffect(() => {
    setChecked([]);
    setLeft(
      props.allColumns?.filter(
        (c) =>
          props.name &&
          tableColumns[props.name] &&
          !tableColumns[props.name]?.columns.some((tc) => tc.columnId === c)
      ) || []
    );
    props.name &&
      setRight(
        tableColumns[props.name]?.columns.map((tc) => tc.columnId) ||
          props.allColumns ||
          []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name, props.allColumns]);

  useEffect(() => {
    setLeft(
      props.allColumns?.filter(
        (c) =>
          props.name &&
          tableColumns[props.name] &&
          !tableColumns[props.name]?.columns.some((tc) => tc.columnId === c)
      ) || []
    );
    props.name &&
      setRight(
        tableColumns[props.name]?.columns.map((tc) => tc.columnId) ||
          props.allColumns ||
          []
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns]);

  useEffect(() => {
    props.name &&
      JSON.stringify(
        tableColumns[props.name].columns.map((tc) => tc.columnId)
      ) !== JSON.stringify(right) &&
      dispatch(tableColumnsUpdated({ name: props.name, columns: right }));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [right]);

  useEffect(() => {
    if (props.open) {
      setIsWorkQueueTreeConfChanged(false);
      setIsWorkQueueFlatConfChanged(false);
      setIsProductionHistoryConfChanged(false);
      setIsUtilHistoryConfChanged(false);
    }
  }, [props.open]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: Array<string>) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: Array<string>) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleSaveNewTableLayout = () => {
    if (props.name && saveGlobal) {
      setOpenConfirmSaveGlobal(true);
    } else {
      setIsSubmitting(true);
      props.name && dispatch(saveNewTableLayout(props.name));
      setTimeout(() => setIsSubmitting(false), 500); //FIXME remove submitting and replace with better detecting changes hasTableConfigurationChanged()
    }
  };

  const handleCancelNewTableLayout = () => {
    if (
      isWorkQueueTreeConfChanged ||
      isWorkQueueFlatConfChanged ||
      isProductionHistoryConfChanged ||
      isUtilHistoryConfChanged
    ) {
      setOpenCancelNewTableConf(true);
    } else {
      dispatch(endEditingTables());
    }
  };

  return (
    <>
      <Dialog
        open={props.open && areTablesEditable}
        PaperComponent={DraggablePaperComponent}
        hideBackdrop
        disableEnforceFocus
        sx={{ display: "contents" }}
      >
        <StyledDialogTitle>
          <TablesIcons>
            {/* TODO check mui theme color */}
            <WorkQueueTreeIcon
              style={{
                fill: isWorkQueueTreeConfChanged
                  ? appTheme.palette.success.main
                  : appTheme.palette.primary.contrastText,
              }}
            />
            <WorkQueueFlatIcon
              style={{
                fill: isWorkQueueFlatConfChanged
                  ? appTheme.palette.success.main
                  : appTheme.palette.primary.contrastText,
              }}
            />
            <JobHistoryIcon
              style={{
                fill: isProductionHistoryConfChanged
                  ? appTheme.palette.success.main
                  : appTheme.palette.primary.contrastText,
              }}
            />
            <EntitiesIcon
              style={{
                fill: isUtilHistoryConfChanged
                  ? appTheme.palette.success.main
                  : appTheme.palette.primary.contrastText,
              }}
            />
          </TablesIcons>
          <Box width="100%" textAlign="center" id="draggable-dialog-title">
            {props.name ? t(props.name) : t("No table")}
          </Box>
          <StyledOpenWithIcon />
        </StyledDialogTitle>
        {props.name && (
          <DialogContent>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>{customList(t2("Available columns"), left)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList(t2("Chosen columns"), right)}</Grid>
            </Grid>
            <Box alignSelf="flex-start">
              {mayEditGlobal && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saveGlobal}
                      onChange={(e) => setSaveGlobal(e.target.checked)}
                    />
                  }
                  label={t("Set as default for all entities")}
                />
              )}
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            disabled={isSubmitting}
            onClick={() => handleSaveNewTableLayout()}
          >
            {t2("Save")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleCancelNewTableLayout()}
          >
            {t2("Close")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmSaveGlobalTableDialogComponent
        open={openConfirmSaveGlobal}
        handleConfirm={() => {
          props.name && dispatch(saveNewTableLayoutGlobal(props.name));
          setOpenConfirmSaveGlobal(false);
        }}
        handleClose={() => setOpenConfirmSaveGlobal(false)}
        setSubmitting={setIsSubmitting}
      />
      <ConfirmCancelNewTableConfigurationsDialogComponent
        open={openCancelNewTableConf}
        handleConfirm={() => {
          dispatch(cancelNewTableLayouts());
          setOpenCancelNewTableConf(false);
        }}
        handleClose={() => setOpenCancelNewTableConf(false)}
      />
    </>
  );
};

export default connector(TableColumnsEditDialogComponent);
