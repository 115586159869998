import * as React from "react";

function LogoWithSignature(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={197.184}
      height={261.4656}
      viewBox="0 0 164.32 217.888"
      {...props}
    >
      <defs>
        <style>{`.im_operator__a{fill:#00259a}.im_operator__b{fill:#00c1de}`}</style>
      </defs>
      <path
        className="im_operator__a"
        d="M83.656 147.339h-2.061l-50.323-20.875-1.485-1.485L8.956 74.677v-2.06l20.837-50.308 1.485-1.485L81.595 0h2.061l50.323 20.831 1.485 1.485 20.831 50.3v2.06l-20.839 50.309-1.485 1.485zm-49.247-25.445l48.262 19.981 48.26-19.981 19.986-48.247-20.03-48.2L82.625 5.421 34.409 25.445l-20.03 48.2z"
      />
      <path
        className="im_operator__a"
        d="M88.227 62.673h39.075v5.241H88.227zM49.151 79.427h27.917v5.241H49.151z"
      />
      <path
        className="im_operator__b"
        d="M88.227 67.914h39.075v5.241H88.227zM49.151 84.668h27.917v5.241H49.151z"
      />
      <path
        className="im_operator__a"
        d="M110.542 101.423H37.989l-2.64-2.64v-50.22l2.64-2.6h89.308l2.6 2.6v33.509h-5.248V51.204H40.592V96.18h69.951zM54.08 204.032v-6.464c0-4.96-3.072-8.288-8.448-8.288s-8.448 3.328-8.448 8.288v6.464c0 4.96 3.072 8.288 8.448 8.288s8.448-3.328 8.448-8.288zm-4.288 0a3.862 3.862 0 01-4.16 4.032 3.862 3.862 0 01-4.16-4.032v-6.464a3.862 3.862 0 014.16-4.032 3.862 3.862 0 014.16 4.032zm12.32 6.4a4.9 4.9 0 003.872 1.888c3.36 0 6.624-2.3 6.624-7.168v-2.944c0-4.864-3.264-7.168-6.624-7.168a4.9 4.9 0 00-3.872 1.888v-1.44h-4.224v22.4h4.224zm6.272-5.44a2.966 2.966 0 01-3.136 3.2 2.966 2.966 0 01-3.136-3.2v-2.624a2.966 2.966 0 013.136-3.2 2.966 2.966 0 013.136 3.2zm22.24.192v-2.56c0-4.576-2.624-7.584-7.36-7.584s-7.36 3.008-7.36 7.584v2.112c0 4.576 2.624 7.584 7.36 7.584 4.032 0 6.528-2.176 7.2-5.632h-4.288a2.881 2.881 0 01-2.912 1.824 2.935 2.935 0 01-3.136-3.232v-.1zm-7.36-6.336a2.838 2.838 0 013.1 2.816v.032h-6.24v-.032a2.9 2.9 0 013.14-2.816zm15.36 3.68a2.966 2.966 0 013.136-3.2h1.984V195.2h-1.28a4.088 4.088 0 00-3.84 2.08v-1.792H94.4V212h4.224zm17.7-1.248c-2.5.192-5.792.448-7.424 1.216a4.79 4.79 0 00-3.136 4.928c0 3.04 2.24 4.9 6.016 4.9 2.528 0 3.84-.96 4.608-2.208V212h4.224v-9.376c0-4.576-2.624-7.584-7.36-7.584-4.032 0-6.5 2.176-7.168 5.632h4.256a2.881 2.881 0 012.912-1.824 2.856 2.856 0 013.072 2.4zm-5.44 4.256c.8-.416 3.1-.608 5.5-.768v.544c0 2.08-1.728 3.3-3.9 3.3-1.888 0-2.5-.864-2.5-1.664a1.331 1.331 0 01.896-1.412zm20.028 2.656c-1.024 0-1.216-.544-1.216-1.312v-7.58h4.064v-3.808h-4.064v-3.744h-4.224v3.744h-2.336v3.808h2.336v8.48c0 2.652 1.28 4.22 4.224 4.22h4.064v-3.808zm5.536-3.456c0 4.576 2.624 7.584 7.364 7.584s7.36-3.008 7.36-7.584v-2.112c0-4.576-2.624-7.584-7.36-7.584s-7.36 3.008-7.36 7.584zm10.5.544a2.935 2.935 0 01-3.136 3.232 2.935 2.935 0 01-3.14-3.232v-3.2a2.935 2.935 0 013.14-3.232 2.935 2.935 0 013.132 3.232zm12.252-2.752a2.966 2.966 0 013.136-3.2h1.984V195.2h-1.28a4.088 4.088 0 00-3.84 2.08v-1.792h-4.224V212h4.224z"
      />
      <path
        className="im_operator__b"
        d="M4.288 189.6H0V212h4.288zm19.008 12.768a2.961 2.961 0 013.04-3.2 2.961 2.961 0 013.04 3.2V212H33.6v-9.792c0-4.832-3.072-7.168-7.264-7.168a6.268 6.268 0 00-5.152 2.176 5.309 5.309 0 00-4.448-2.176 4.732 4.732 0 00-3.744 1.824v-1.376H8.768V212h4.224v-9.632a2.961 2.961 0 013.04-3.2 2.961 2.961 0 013.04 3.2V212h4.224z"
      />
    </svg>
  );
}

export default LogoWithSignature;
