import { combineReducers } from "@reduxjs/toolkit";
import addUtilEventDialogReducer from "./addUtilEventSlice";
import defaultAssetDialogReducer from "./defaultAssetSlice";
import editGridsDialogReducer from "./editGridsSlice";
import editTableDialogReducer from "./editTableSlice";
import editableComponentDialogReducer from "./editableComponentSlice";
import entNavigationDialogReducer from "./entNavigationSlice";
import runningTaskStateChangedDialogReducer from "./running-task-changed-state/runningTaskStateChangedSlice";
import newShiftDialogReducer from "./new-shift/newShiftSlice";
import splitUtilEventDialogReducer from "./splitUtilEventSlice";
import updateUtilEventDialogReducer from "./updateUtilEventSlice";
import utilEventHistoryDialogReducer from "./utilEventHistorySlice";
import productionEventsDialogsReducer from "./production-events";

export default combineReducers({
  openAddUtilEventDialog: addUtilEventDialogReducer,
  openUpdateUtilEventDialog: updateUtilEventDialogReducer,
  openSplitUtilEventDialog: splitUtilEventDialogReducer,
  utilEventHistoryDialog: utilEventHistoryDialogReducer,
  openDefaultAssetDialog: defaultAssetDialogReducer,
  openEntNavigationDialog: entNavigationDialogReducer,
  editTableDialog: editTableDialogReducer,
  editGridsDialog: editGridsDialogReducer,
  openRunningTaskStateChangedDialog: runningTaskStateChangedDialogReducer,
  editableComponentDialog: editableComponentDialogReducer,
  openNewShiftDialog: newShiftDialogReducer,
  productionEventsDialogs: productionEventsDialogsReducer,
});
