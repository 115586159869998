import {
  CircularProgress,
  Fade,
  lighten,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import {
  Column,
  Row,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import TableName from "../../../helpers/enums/tableName";
import {
  filterBoolean,
  filterDateTime,
  filterDuration,
  filterNumber,
} from "../../../helpers/tableFilters";
import {
  editTableDialogUpdated,
  selectOpenEditTablesDialog,
} from "../../../store/common/dialogs/editTableSlice";
import {
  selectAreTablesEditable,
  selectFilterColumnsByTableName,
  tableColumnsUpdated,
} from "../../../store/common/tablesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import appTheme from "../../../themes/appTheme";
import DefaultColumnFilterComponent from "./DefaultColumnFilterComponent";

const TableRoot = styled(Paper)(({ theme }) => ({
  display: "flex",
  position: "relative",
  boxSizing: "border-box",
  flexDirection: "column",
  justifyContent: "space-between",
  flexGrow: 1,
  maxHeight: "100%",
  height: "100%",
  width: "100%",
  overflow: "auto",
}));

const StyledTableContainer = styled(SimpleBar)(({ theme }) => ({
  maxHeight: "calc(100% - 2.5rem)",
  height: "calc(100% - 2.5rem)",
  overflow: "auto",
}));

const Pagination = styled("div")(({ theme }) => ({
  textAlign: "center",
  padding: "2rem",
}));

const PaginationButton = styled(Button)(({ theme }) => ({}));

const Loading = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  margin: "auto",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
}));

interface TableProps<T extends object> {
  name: TableName;
  data: Array<T>;
  columns: Array<Column<T>>;
  initialState?: T;
  isLoading?: boolean;
  delay?: number;
  onClick?: (row: Row<T>) => void;
  subRowsProperty?: string;
  editable?: boolean;
  onDataRefresh?: (
    selectedRows: Array<T>,
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => void;
  getRowId?: (row: T, relativeIndex: number, parent: Row<T> | undefined) => any;
}

const TableComponent: React.FC<TableProps<any>> = ({
  name,
  data,
  columns,
  initialState,
  isLoading,
  delay = 1000,
  onClick,
  subRowsProperty = "subRows",
  editable = false,
  onDataRefresh,
  getRowId,
}) => {
  const [delayedIsLoading, setDelayedIsLoading] = useState<
    boolean | undefined
  >();
  const [currentColOrder, setCurrentColOrder] = useState<Array<string>>([]);
  const dispatch = useAppDispatch();
  const openDialog = useAppSelector(selectOpenEditTablesDialog);
  const areTablesEditable = useAppSelector(selectAreTablesEditable);
  const filteredColumns = useAppSelector((state) =>
    selectFilterColumnsByTableName(state, name, columns)
  );

  useEffect(() => {
    //FIXME what about dialog table?
    if (editable && openDialog) {
      dispatch(
        editTableDialogUpdated({
          name: name,
          allColumns: columns.map((o) => o.id || ""),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog, name, columns]);

  useEffect(() => {
    return () => {
      if (editable && openDialog) {
        dispatch(
          editTableDialogUpdated({
            name: undefined,
            allColumns: undefined,
          })
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading === true) {
      const timer = setTimeout(() => {
        setDelayedIsLoading(isLoading);
      }, delay);
      return () => clearTimeout(timer);
    } else if (isLoading === false) {
      setDelayedIsLoading(isLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilterComponent,
    }),
    []
  );

  const filterTypes = useMemo(
    () => ({
      // text: filterText,
      boolean: filterBoolean,
      duration: filterDuration,
      number: filterNumber,
      date: filterDateTime("DateFormat"),
      dateTimeWithSeconds: filterDateTime("DateWithTimeWithSecondsFormat"),
      dateTimeWithoutSeconds: filterDateTime(
        "DateWithTimeWithoutSecondsFormat"
      ),
      timeWithSeconds: filterDateTime("TimeWithSecondsFormat"),
      timeWithoutSeconds: filterDateTime("TimeWithoutSecondsFormat"),
    }),
    []
  );

  const { t } = useTranslation();

  const getSubRows = useCallback(
    (row: any) => row[subRowsProperty] || [],
    [subRowsProperty]
  );

  const editedColumns = useMemo(
    () => (editable ? filteredColumns : columns),
    [editable, filteredColumns, columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    selectedFlatRows,
    headerGroups,
    prepareRow,
    allColumns,
    setColumnOrder,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleAllRowsSelected,
    toggleRowExpanded,
    state: { pageIndex, pageSize, columnOrder },
  } = useTable(
    {
      columns: editedColumns,
      data,
      filterTypes: filterTypes,
      initialState: initialState,
      defaultColumn,
      autoResetPage: false,
      autoResetSelectedRows: false,
      autoResetFilters: false,
      getRowId: getRowId,
      getSubRows: getSubRows,
    },
    useFilters,
    useGlobalFilter,
    useColumnOrder,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    onDataRefresh && onDataRefresh(selectedFlatRows, toggleAllRowsSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (pageIndex > pageCount - 1) {
      gotoPage(0);
    }
  }, [pageIndex, pageCount, gotoPage]);

  const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
    ...draggableStyle,
    userSelect: "none",
    background: isDragging
      ? lighten(appTheme.palette.primary.main ?? "", 0.4)
      : "inherit",
    ...(!isDragging && { transform: "translate(0,0)" }),
    ...(isDropAnimating && { transitionDuration: "0.5s" }),
  });

  const handleRowOnClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: Row<object>
  ) => {
    if (!row.canExpand) {
      if (onClick) {
        if (row.isSelected) {
          toggleAllRowsSelected(false);
        } else {
          toggleAllRowsSelected(false);
          row.toggleRowSelected();
        }
        onClick(row);
      }
    } else {
      toggleRowExpanded([row.id]);
    }
  };

  return (
    <TableRoot
      elevation={2}
      style={{
        border: editable && areTablesEditable ? "solid 2px red" : "",
      }}
    >
      <Fade
        in={isLoading}
        style={{
          transitionDelay: isLoading ? delay.toString() + "ms" : "0ms",
        }}
        unmountOnExit
      >
        <Loading size="5rem" />
      </Fade>
      <StyledTableContainer autoHide={false}>
        <Table
          style={{
            opacity: delayedIsLoading ? 0.25 : 1,
            pointerEvents: delayedIsLoading ? "none" : "initial",
          }}
          {...getTableProps()}
          stickyHeader
        >
          <TableHead>
            {headerGroups.map((headerGroup, index) =>
              editable ? (
                <DragDropContext
                  key={index}
                  onDragStart={() => {
                    const newOrder = allColumns.map((o) => o.id);
                    setCurrentColOrder(newOrder);
                  }}
                  onDragUpdate={(dragUpdateObj, b) => {
                    const colOrder = [...currentColOrder];
                    const sIndex = dragUpdateObj.source.index;
                    const dIndex =
                      dragUpdateObj.destination &&
                      dragUpdateObj.destination.index;

                    if (
                      typeof sIndex === "number" &&
                      typeof dIndex === "number"
                    ) {
                      colOrder.splice(sIndex, 1);
                      colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                      setColumnOrder(colOrder);
                    }
                  }}
                  onDragEnd={() =>
                    dispatch(
                      tableColumnsUpdated({ name: name, columns: columnOrder })
                    )
                  }
                >
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(droppableProvided, snapshot) => (
                      <TableRow
                        {...headerGroup.getHeaderGroupProps()}
                        ref={droppableProvided.innerRef}
                      >
                        {headerGroup.headers.map((column, index) => (
                          <Draggable
                            key={column.id}
                            draggableId={column.id}
                            index={index}
                            isDragDisabled={!areTablesEditable}
                          >
                            {(provided, snapshot) => {
                              return (
                                <TableCell
                                  {...column.getHeaderProps()}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={{
                                    ...getItemStyle(
                                      snapshot,
                                      provided.draggableProps.style
                                    ),
                                  }}
                                >
                                  {column.canSort ? (
                                    <TableSortLabel
                                      {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                      )}
                                      active={column.isSorted}
                                      direction={
                                        column.isSortedDesc ? "desc" : "asc"
                                      }
                                    >
                                      {column.render("Header")}
                                    </TableSortLabel>
                                  ) : (
                                    column.render("Header")
                                  )}
                                  <div>
                                    {column.canFilter
                                      ? column.render("Filter")
                                      : ""}
                                  </div>
                                </TableCell>
                              );
                            }}
                          </Draggable>
                        ))}
                      </TableRow>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell {...column.getHeaderProps()}>
                      {column.canSort ? (
                        <TableSortLabel
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        >
                          {column.render("Header")}
                        </TableSortLabel>
                      ) : (
                        column.render("Header")
                      )}
                      <div>
                        {column.canFilter ? column.render("Filter") : ""}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              )
            )}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  selected={row.isSelected && !row.canExpand}
                  onClick={(
                    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
                  ) => handleRowOnClick(e, row)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <Pagination>
        <PaginationButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          disableElevation
        >
          {"<<"}
        </PaginationButton>{" "}
        <PaginationButton
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </PaginationButton>{" "}
        <PaginationButton
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </PaginationButton>{" "}
        <PaginationButton
          variant="contained"
          color="primary"
          size="small"
          disableElevation
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </PaginationButton>{" "}
        <span>
          {t("Page")}{" "}
          <strong>
            {pageIndex + 1} {t("of")} {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | {t("Go to page")}:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {t("Show")} {pageSize}
            </option>
          ))}
        </select>
      </Pagination>
    </TableRoot>
  );
};

export default TableComponent;
