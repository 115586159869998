import * as React from "react";

function SvgComponent(props: any) {
  return (
    <svg viewBox="0 0 64 64" {...props}>
      <path fill="#F9F9F9" d="M2 14h60v36H2z" />
      <path
        d="M62 51H2a1 1 0 01-1-1V14a1 1 0 011-1h60a1 1 0 011 1v36a1 1 0 01-1 1zM3 49h58V15H3v34z"
        fill="#414042"
      />
      <path fill="#D34A4B" d="M2 32h60v18H2z" />
      <path
        d="M62 51H2a1 1 0 01-1-1V32a1 1 0 011-1h60a1 1 0 011 1v18a1 1 0 01-1 1zM3 49h58V33H3v16z"
        fill="#414042"
      />
    </svg>
  );
}

export default SvgComponent;
