import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      data-name="Icon / Entity"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      {...props}
    >
      <g data-name="SendLogging / Line / M">
        <path
          data-name="Path 1610"
          d="M15.7 0A2.258 2.258 0 0118 2.25v13.5A2.258 2.258 0 0115.7 18H2.2A2.237 2.237 0 010 15.75V2.25A2.237 2.237 0 012.2 0zm0 1.5H2.2a.72.72 0 00-.7.75v13.5a.714.714 0 00.7.75h13.5a.738.738 0 00.8-.75V2.25a.744.744 0 00-.8-.75zm-10.4 7l1.5-3.55a.723.723 0 011.3-.08l.1.1 2.4 5.95 1-2.01a.689.689 0 01.5-.4l.2-.01h1.9a.744.744 0 01.8.75.767.767 0 01-.6.74l-.2.01h-1.5l-1.5 3.08a.73.73 0 01-1.3.04l-.1-.1-2.3-5.83-1.1 2.36a.725.725 0 01-.5.44l-.2.01H3.8a.744.744 0 01-.8-.75.767.767 0 01.6-.74l.2-.01h1.5l1.5-3.55z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
