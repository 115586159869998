import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";
import GridLayout from "react-grid-layout";
import { AppThunk, RootState } from "..";
import GridName from "../../helpers/enums/gridName";
import {
  defaultLayouts as overviewDefaulLayouts,
  defaultItems as overviewDefaultItems,
} from "../../helpers/grids/overview-grid/defaultGridSettings";
import {
  defaultLayouts as productionDefaulLayouts,
  defaultItems as productionDefaultItems,
} from "../../helpers/grids/production-grid/defaultGridSettings";
import { LocalStorageKey } from "../../helpers/localStorageKeys";
import SnackbarUtils from "../../helpers/snackbarHelper";
import i18n from "../../i18n";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../services/localStorageService";
import { changeSystemAttributeDetails } from "../../services/main/systemAttributesService";
import {
  configurationAttributeName,
  loadNestedAttributeGroupsAsync,
  selectLayoutAttribute,
} from "../definitions/attributeGroupsSlice";
import { newCustomComponentsDeleted } from "./customGridComponentsSlice";

export const localStorageKey = new Map<
  GridName,
  { items: LocalStorageKey; layouts: LocalStorageKey }
>([
  [
    GridName.OVERVIEW,
    {
      items: LocalStorageKey.OVERVIEW_ITEMS,
      layouts: LocalStorageKey.OVERVIEW_LAYOUTS,
    },
  ],
  [
    GridName.PRODUCTION,
    {
      items: LocalStorageKey.PRODUCTION_ITEMS,
      layouts: LocalStorageKey.PRODUCTION_LAYOUTS,
    },
  ],
]);

export const gridEditPaths = new Map<GridName, Array<string>>([
  [GridName.OVERVIEW, ["/main/overview"]],
  [GridName.PRODUCTION, ["/main/production"]],
]);

export const defaultItems = new Map<GridName | string, Array<string>>([
  [GridName.OVERVIEW, overviewDefaultItems],
  [GridName.PRODUCTION, productionDefaultItems],
]);

export const defaultLayouts = new Map<GridName | string, GridLayout.Layouts>([
  [GridName.OVERVIEW, overviewDefaulLayouts],
  [GridName.PRODUCTION, productionDefaulLayouts],
]);

export interface DroppingItem {
  i: string;
  w: number;
  h: number;
}

interface SingleGridConfiguration {
  items: Array<string>;
  layouts: GridLayout.Layouts;
}

export interface GridsState {
  cmdRefreshLayouts: boolean;
  lastDraggedItem: DroppingItem | undefined;
  areGridsEditable: boolean;
  breakpoint: string;
  configurations: Record<string, SingleGridConfiguration>;
}

export const initialState: GridsState = {
  cmdRefreshLayouts: false,
  lastDraggedItem: undefined,
  areGridsEditable: false,
  breakpoint: "lg",
  configurations: {},
};

export const saveNewLayout =
  (gridName: GridName): AppThunk =>
  async (dispatch, getState) => {
    if (localStorageKey.has(gridName)) {
      saveToLocalStorage(
        localStorageKey.get(gridName)!.items,
        getState().grids.configurations[gridName].items
      );
      saveToLocalStorage(
        localStorageKey.get(gridName)!.layouts,
        getState().grids.configurations[gridName].layouts
      );
    }
  };

export const saveGloballyNewLayout =
  (gridName: GridName): AppThunk =>
  async (dispatch, getState) => {
    if (
      localStorageKey.has(gridName) &&
      configurationAttributeName.has(gridName)
    ) {
      saveToLocalStorage(
        localStorageKey.get(gridName)!.items,
        getState().grids.configurations[gridName].items
      );
      saveToLocalStorage(
        localStorageKey.get(gridName)!.layouts,
        getState().grids.configurations[gridName].layouts
      );
      const confToSend = {
        layouts: getState().grids.configurations[gridName].layouts,
        items: getState().grids.configurations[gridName].items,
      };
      const attributeId = selectLayoutAttribute(getState(), gridName)?.id;
      if (attributeId) {
        await changeSystemAttributeDetails(
          attributeId,
          configurationAttributeName.get(gridName)!,
          t(configurationAttributeName.get(gridName)!),
          JSON.stringify(confToSend),
          4
        );
        await dispatch(loadNestedAttributeGroupsAsync());
        SnackbarUtils.success(i18n.t("SaveLayoutConfMesSuccess"));
      }
    } else {
      SnackbarUtils.error(i18n.t("MissingGridConf"));
    }
  };

export const cancelNewLayout =
  (gridName: GridName): AppThunk =>
  async (dispatch, getState) => {
    const attributeValue = JSON.parse(
      selectLayoutAttribute(getState(), gridName)?.value as any
    );

    if (localStorageKey.has(gridName)) {
      const localStorageLayouts = getFromLocalStorage(
        localStorageKey.get(gridName)!.layouts
      );
      dispatch(
        layoutsUpdated({
          gridName,
          layouts:
            localStorageLayouts ||
            attributeValue?.layouts ||
            defaultLayouts.get(gridName),
        })
      );
      const localStorageItems = getFromLocalStorage(
        localStorageKey.get(gridName)!.items
      );
      dispatch(
        itemsUpdated({
          gridName,
          items:
            localStorageItems ||
            attributeValue?.items ||
            defaultItems.get(gridName),
        })
      );
      dispatch(newCustomComponentsDeleted());
    }
    dispatch(layoutsRefreshed(true));
  };

export const resetLayoutsConfiguration =
  (): AppThunk => async (dispatch, getState) => {
    await dispatch(loadNestedAttributeGroupsAsync());
    for (var gridName in GridName) {
      const attributeValue = selectLayoutAttribute(
        getState(),
        gridName as GridName
      )?.value;
      if (attributeValue) {
        const storageKey = localStorageKey.get(gridName as GridName);
        storageKey && deleteFromLocalStorage(storageKey.items);
        storageKey && deleteFromLocalStorage(storageKey.layouts);
        dispatch(
          layoutsUpdated({
            gridName: gridName as GridName,
            layouts:
              JSON.parse(attributeValue)?.layouts ||
              defaultLayouts.get(gridName as GridName),
          })
        );
        dispatch(
          itemsUpdated({
            gridName: gridName as GridName,
            items:
              JSON.parse(attributeValue)?.items ||
              defaultItems.get(gridName as GridName),
          })
        );
      } else {
        defaultLayouts.has(gridName as GridName) &&
          dispatch(
            layoutsUpdated({
              gridName: gridName as GridName,
              layouts: defaultLayouts.get(gridName as GridName)!,
            })
          );
        defaultItems.has(gridName as GridName) &&
          dispatch(
            itemsUpdated({
              gridName: gridName as GridName,
              items: defaultItems.get(gridName as GridName)!,
            })
          );
      }
    }
    dispatch(layoutsRefreshed(true));
  };

export const resetLayout =
  (gridName: GridName): AppThunk =>
  async (dispatch, getState) => {
    const attributeValue = selectLayoutAttribute(getState(), gridName)?.value;

    if (attributeValue !== null && attributeValue !== undefined) {
      const confObject = JSON.parse(attributeValue);
      dispatch(
        layoutsUpdated({
          gridName,
          layouts: confObject.layouts || defaultLayouts[gridName],
        })
      );
      dispatch(
        itemsUpdated({
          gridName,
          items: confObject.items || defaultItems[gridName],
        })
      );
    } else {
      dispatch(layoutsUpdated({ gridName, layouts: defaultLayouts[gridName] }));
      dispatch(itemsUpdated({ gridName, items: defaultItems[gridName] }));
    }

    dispatch(layoutsRefreshed(true));
    deleteFromLocalStorage(LocalStorageKey.OVERVIEW_ITEMS);
    deleteFromLocalStorage(LocalStorageKey.OVERVIEW_LAYOUTS);
  };

export const loadDefaultLayoutConfiguration =
  (gridName: GridName): AppThunk =>
  async (dispatch, getState) => {
    const localLayoutConfiguration = getFromLocalStorage(
      localStorageKey.get(gridName)!.layouts
    );
    const localItemsConfiguration = getFromLocalStorage(
      localStorageKey.get(gridName)!.items
    );
    if (
      localLayoutConfiguration !== undefined &&
      localItemsConfiguration !== undefined
    ) {
      dispatch(
        defaultConfigurationLoaded({
          gridName,
          items: localItemsConfiguration,
          layouts: localLayoutConfiguration,
        })
      );
    } else {
      const layoutAttribute = selectLayoutAttribute(
        getState(),
        gridName as GridName
      );
      if (layoutAttribute?.value) {
        const attributeValue = JSON.parse(
          (layoutAttribute?.value as any) ?? "{}"
        );

        dispatch(
          defaultConfigurationLoaded({
            gridName,
            items: attributeValue.items,
            layouts: attributeValue.layouts,
          })
        );
      } else {
        dispatch(
          defaultConfigurationLoaded({
            gridName,
            items: defaultItems.get(gridName as GridName)!,
            layouts: defaultLayouts.get(gridName as GridName)!,
          })
        );
      }
    }
  };

export const gridsSlice = createSlice({
  name: "grids",
  initialState,
  reducers: {
    layoutsRefreshed: (state, action: PayloadAction<boolean>) => {
      state.cmdRefreshLayouts = action.payload;
    },
    layoutsUpdated: (
      state,
      action: PayloadAction<{ gridName: GridName; layouts: GridLayout.Layouts }>
    ) => {
      state.configurations[action.payload.gridName].layouts =
        action.payload.layouts;
    },
    itemsUpdated: (
      state,
      action: PayloadAction<{ gridName: GridName; items: Array<string> }>
    ) => {
      state.configurations[action.payload.gridName].items =
        action.payload.items;
    },
    itemAdded: (
      state,
      action: PayloadAction<{
        gridName: GridName;
        itemName: string;
      }>
    ) => {
      state.configurations[action.payload.gridName].items.push(
        action.payload.itemName
      );
    },
    componentKeyUpdated: (
      state,
      action: PayloadAction<{
        gridName: GridName;
        oldKey: string;
        newKey: string;
      }>
    ) => {
      const grid = state.configurations[action.payload.gridName];
      let layouts = grid.layouts;
      layouts["lg"] = layouts["lg"].map((i) =>
        i.i !== action.payload.oldKey ? i : { ...i, i: action.payload.newKey }
      );
      layouts["md"] = layouts["md"].map((i) =>
        i.i !== action.payload.oldKey ? i : { ...i, i: action.payload.newKey }
      );
      layouts["sm"] = layouts["sm"].map((i) =>
        i.i !== action.payload.oldKey ? i : { ...i, i: action.payload.newKey }
      );
      layouts["xs"] = layouts["xs"].map((i) =>
        i.i !== action.payload.oldKey ? i : { ...i, i: action.payload.newKey }
      );
      state.configurations[action.payload.gridName].layouts = layouts;
      state.configurations[action.payload.gridName].items = grid.items.map(
        (i) => (i !== action.payload.oldKey ? i : action.payload.newKey)
      );
    },
    editableItemAdded: (
      state,
      action: PayloadAction<{
        gridName: GridName;
        key: string;
      }>
    ) => {
      state.configurations[action.payload.gridName].items.push(
        action.payload.key
      );
    },
    itemRemoved: (
      state,
      action: PayloadAction<{ gridName: GridName; itemName: string }>
    ) => {
      const index = state.configurations[
        action.payload.gridName
      ].items.findIndex((i) => i === action.payload.itemName);
      state.configurations[action.payload.gridName].items.splice(index, 1);
    },
    draggedItemUpdated: (state, action: PayloadAction<DroppingItem>) => {
      state.lastDraggedItem = action.payload;
    },
    draggedItemCleared: (state, action: PayloadAction<void>) => {
      state.lastDraggedItem = undefined;
    },
    gridEdititingStarted: (state, action: PayloadAction<void>) => {
      state.areGridsEditable = true;
    },
    gridEdititingFinished: (state, action: PayloadAction<void>) => {
      state.areGridsEditable = false;
    },
    defaultConfigurationLoaded: (
      state,
      action: PayloadAction<{
        gridName: GridName | string;
        items: Array<string>;
        layouts: GridLayout.Layouts;
      }>
    ) => {
      state.configurations[action.payload.gridName] = {
        items: action.payload.items,
        layouts: action.payload.layouts,
      };
    },
    breakpointChanged: (state, action: PayloadAction<string>) => {
      state.breakpoint = action.payload;
    },
  },
});

export const {
  layoutsRefreshed,
  layoutsUpdated,
  itemsUpdated,
  itemAdded,
  editableItemAdded,
  componentKeyUpdated,
  itemRemoved,
  draggedItemUpdated,
  draggedItemCleared,
  gridEdititingStarted,
  gridEdititingFinished,
  defaultConfigurationLoaded,
  breakpointChanged,
} = gridsSlice.actions;

export const selectAreGridsEditable = createSelector(
  (state: RootState) => state.grids.areGridsEditable,
  (areGridsEditable) => areGridsEditable
);

export const selectBreakpoint = createSelector(
  (state: RootState) => state.grids.breakpoint,
  (breakpoint) => breakpoint
);

export const selectRefreshGrid = createSelector(
  (state: RootState) => state.grids.cmdRefreshLayouts,
  (cmdRefreshLayouts) => cmdRefreshLayouts
);

export const selectGridItems = (gridName: GridName | null) =>
  createSelector(
    (state: RootState) => state.grids.configurations,
    (configurations) => (gridName ? configurations[gridName]?.items : [])
  );

export const selectGridLayouts = (gridName: GridName | null) =>
  createSelector(
    (state: RootState) => state.grids.configurations,
    (configurations) =>
      gridName ? configurations[gridName]?.layouts : ({} as GridLayout.Layouts)
  );

export default gridsSlice.reducer;
