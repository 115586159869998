import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { getTaskStateById } from "../../../services/main/taskStatesService";
import {
  taskCompletedSocketEvent,
  taskPausedSocketEvent,
  taskRegisteredBadProductionSocketEvent,
  taskRegisteredGoodProductionSocketEvent,
  taskStartedSocketEvent,
} from "../../sockets/manufacturing-execution/tasksSlice";
import {
  taskCancelledSocketEvent,
  taskReleasedSocketEvent,
} from "../../sockets/production-planning/tasksSlice";
import { logOnAssetAsync } from "../../user/assetSelectionSlice";
import {
  loadWorkQueueAsync,
  quantityBadOnTaskChanged,
  quantityGoodOnTaskChanged,
  taskCancelled,
  taskStateChanged,
} from "./workQueueSlice";

const workQueueListener = createListenerMiddleware();

workQueueListener.startListening({
  actionCreator: taskRegisteredGoodProductionSocketEvent,
  effect: async (action, listenerApi) => {
    const { taskId, newTaskQuantity } = action.payload;
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(
      quantityGoodOnTaskChanged({ taskId, quantity: newTaskQuantity })
    );
  },
});

workQueueListener.startListening({
  actionCreator: taskRegisteredBadProductionSocketEvent,
  effect: async (action, listenerApi) => {
    const { taskId, newTaskQuantity } = action.payload;
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(
      quantityBadOnTaskChanged({ taskId, quantity: newTaskQuantity })
    );
  },
});

workQueueListener.startListening({
  matcher: isAnyOf(taskStartedSocketEvent, taskPausedSocketEvent),
  effect: async (action, listenerApi) => {
    const { taskId, taskStateId } = action.payload;
    const taskState = await getTaskStateById(taskStateId);
    listenerApi.cancelActiveListeners();
    taskState &&
      listenerApi.dispatch(
        taskStateChanged({
          taskId,
          taskStateName: taskState.name!,
          taskStateColor: taskState.color!,
        })
      );
  },
});

workQueueListener.startListening({
  matcher: isAnyOf(taskCompletedSocketEvent, logOnAssetAsync.fulfilled),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(loadWorkQueueAsync());
  },
});

workQueueListener.startListening({
  matcher: isAnyOf(taskReleasedSocketEvent),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId } = action.payload;
    for (let i = 0; i < 5; i++) {
      const task = listenerApi.fork(async (forkApi) => {
        await forkApi.delay(2000);
        listenerApi.dispatch(loadWorkQueueAsync());
        const workQueue = (listenerApi.getState() as RootState).main.workQueue
          .workQueue.value;
        if (workQueue.find((wq) => wq.taskId === taskId)) {
          i = 5;
        }
      });
      await task.result;
    }
  },
});

workQueueListener.startListening({
  matcher: isAnyOf(taskCancelledSocketEvent),
  effect: async (action, listenerApi) => {
    const { taskId } = action.payload;
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(taskCancelled({ taskId: taskId }));
  },
});

export default workQueueListener;
