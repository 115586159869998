import { Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { TooltipItem } from "chart.js";
import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { chartGoodColor, chartBadColor } from "../../../../../themes/appTheme";
import { useAppSelector } from "../../../../../store/hooks";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  chartHeader: {
    fontSize: "1.8rem",
    textAlign: "left",
  },
  chart: {
    minWidth: 0,
    minHeight: 0,
  },
}));

interface SummarizeProductionChartProps {
  chartStyle: string;
}

const SummarizeProductionChartComponent: React.FC<
  SummarizeProductionChartProps
> = ({ chartStyle }) => {
  const runnedTask = useAppSelector(selectRunnedTask);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const data = useMemo(
    () => ({
      labels: [
        `${t("Good production")}: ${runnedTask?.quantityProduced}`,
        `${t("Bad production")}: ${runnedTask?.quantityRejected}`,
      ],
      datasets: [
        {
          data: [runnedTask?.quantityProduced, runnedTask?.quantityRejected],
          backgroundColor: [chartGoodColor, chartBadColor],
          hoverBackgroundColor: [chartGoodColor, chartBadColor],
        },
      ],
    }),
    [runnedTask, t]
  );

  return (
    <Paper className={chartStyle} elevation={2} square>
      <div className={classes.chartHeader}>
        {t("Production summary")} [
        {t(runnedTask?.abbreviation || runnedTask?.uomName || "")}]
      </div>
      <div className={classes.chart}>
        <Pie
          data={data}
          options={{
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  title: () => "",
                  label: (tooltipItem: TooltipItem<"pie">) => tooltipItem.label,
                },
              },
            },
          }}
        ></Pie>
      </div>
    </Paper>
  );
};

export default SummarizeProductionChartComponent;
