import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import assetSelectionReducer from "./assetSelectionSlice";
import defaultAssetReducer from "./defaultAssetSlice";
import mesConnectionReducer from "./mesConnectionSlice";

const userReducer = combineReducers({
  auth: authReducer,
  connectionToMes: mesConnectionReducer,
  selectedAsset: assetSelectionReducer,
  defaultAsset: defaultAssetReducer,
});

export default userReducer;

export type UserState = ReturnType<typeof userReducer>;
