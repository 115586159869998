import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useAppSelector } from "../../../store/hooks";
import { StepOfProduction } from "../../../store/main/job-execution/jobExecutionSlice";
import FinalStepContainerComponent from "./3-summary-step/FinalStepContainerComponent";
import FirstStepContainerComponent from "./1-select-job-step/FirstStepContainerComponent";
import ProductionNavigationComponent from "./ProductionNavigationComponent";
import SecondStepContainerComponent from "./2-production-step/SecondStepContainerComponent";
import { selectStepOfProduction } from "../../../store/main/job-execution/jobExecutionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.5rem",
    boxSizing: "border-box",
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
  },

  content: {
    paddingTop: "0.5rem",
    width: "100%",
    maxWidth: "100%",
    maxHeight: "calc(100% - 3.5rem)",
    height: "calc(100% - 3.5rem)",
    boxSizing: "border-box",
  },
}));

interface ProductionProps {}

const ProductionComponent: React.FC<ProductionProps> = () => {
  const { classes } = useStyles();
  const stepOfProduction = useAppSelector(selectStepOfProduction);

  return (
    <>
      <div className={classes.root}>
        <ProductionNavigationComponent></ProductionNavigationComponent>
        <div className={classes.content}>
          {stepOfProduction === StepOfProduction.FirstStep && (
            <FirstStepContainerComponent></FirstStepContainerComponent>
          )}
          {stepOfProduction === StepOfProduction.SecondStep && (
            <SecondStepContainerComponent></SecondStepContainerComponent>
          )}
          {stepOfProduction === StepOfProduction.FinalStep && (
            <FinalStepContainerComponent></FinalStepContainerComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductionComponent;
