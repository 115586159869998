import { TFunction } from "i18next";
import React from "react";

export const FormatDurationFromStringToSeconds = (
  value: string,
  t: TFunction
) => {
  if (value !== undefined && value !== "") {
    const values = value.split(/h|m|s/);
    if (values.length >= 3) {
      const hours = Number(values[0] !== "_" ? values[0] : 0);
      const minutes = Number(values[1] !== "_" ? values[1] : 0);
      const seconds = Number(values[2] !== "_" ? values[2] : 0);
      return hours * 3600 + minutes * 60 + seconds;
    }
    return 0;
  }
  return 0;
};

export const FormatDuration = (value: number, t: TFunction) => {
  const hours = Math.floor(Number(value) / 3600);
  const minutes = Math.floor((Number(value) - hours * 3600) / 60);
  const seconds = Math.floor(Number(value - hours * 3600 - minutes * 60));
  return (
    <span>
      {hours}
      {t("h")} {minutes}
      {t("m")} {seconds}
      {t("s")}
    </span>
  );
};

export const FormatDurationString = (value: number, t: TFunction) => {
  const hours = Math.floor(Number(value) / 3600);
  const minutes = Math.floor((Number(value) - hours * 3600) / 60);
  const seconds = Math.floor(Number(value - hours * 3600 - minutes * 60));
  return `${hours}${t("h")} ${minutes}${t("m")} ${seconds}${t("s")}`;
};
