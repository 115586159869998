import * as React from "react";

function SvgComponent(props: any) {
  return (
    <svg
      id="prefix__Icon_Entities_Utilization"
      data-name="Icon / Entities / Utilization"
      width={20.188}
      height={20}
      viewBox="0 0 20.188 20"
      {...props}
    >
      <g id="prefix__Group_45" data-name="Group 45">
        <path
          id="prefix__Path_1610"
          data-name="Path 1610"
          d="M12899.7 1604a2.258 2.258 0 012.3 2.25v13.5a2.258 2.258 0 01-2.3 2.25h-13.5a2.237 2.237 0 01-2.2-2.25v-13.5a2.237 2.237 0 012.2-2.25zm0 1.5h-13.5a.72.72 0 00-.7.75v13.5a.714.714 0 00.7.75h13.5a.738.738 0 00.8-.75v-13.5a.744.744 0 00-.8-.75zm-10.4 7l1.5-3.55a.723.723 0 011.3-.08l.1.1 2.4 5.95 1-2.01a.689.689 0 01.5-.4l.2-.01h1.9a.744.744 0 01.8.75.767.767 0 01-.6.74l-.2.01h-1.5l-1.5 3.08a.73.73 0 01-1.3.04l-.1-.1-2.3-5.83-1.1 2.36a.725.725 0 01-.5.44l-.2.01h-1.9a.744.744 0 01-.8-.75.767.767 0 01.6-.74l.2-.01h1.5l1.5-3.55z"
          transform="translate(-12884 -1604)"
          fill="none"
        />
        <g id="prefix__Group_46" data-name="Group 46">
          <path
            id="prefix__Path_1803"
            data-name="Path 1803"
            className="prefix__cls-2"
            d="M12889.3 1612.5l1.5-3.55a.723.723 0 011.3-.08l.1.1 2.4 5.95 1-2.01a.689.689 0 01.5-.4l.2-.01h1.9a.744.744 0 01.8.75.767.767 0 01-.6.74l-.2.01h-1.5l-1.5 3.08a.73.73 0 01-1.3.04l-.1-.1-2.3-5.83-1.1 2.36a.725.725 0 01-.5.44l-.2.01h-1.9a.744.744 0 01-.8-.75.767.767 0 01.6-.74l.2-.01h1.5l1.5-3.55z"
            transform="translate(-12884 -1604)"
          />
        </g>
      </g>
      <g
        id="prefix__Group_44"
        data-name="Group 44"
        transform="translate(-1587.738 -1607)"
      >
        <g
          id="prefix__SelectAllOff_Line_M"
          data-name="SelectAllOff / Line / M"
          transform="translate(1587.926 1607.001)"
        >
          <path
            id="prefix__Path_1599"
            data-name="Path 1599"
            className="prefix__cls-2"
            d="M12901.5 1350.63a2.2 2.2 0 011.5 2.12v10a4.236 4.236 0 01-4.2 4.25h-10a2.332 2.332 0 01-2.2-1.5h12.2a2.719 2.719 0 002.7-2.75v-12.12zm-3.3-3.63a2.258 2.258 0 012.3 2.25v13a2.258 2.258 0 01-2.3 2.25h-13a2.237 2.237 0 01-2.2-2.25v-13a2.237 2.237 0 012.2-2.25zm0 1.5h-13a.72.72 0 00-.7.75v13a.72.72 0 00.7.75h13a.744.744 0 00.8-.75v-13a.744.744 0 00-.8-.75z"
            transform="translate(-12883 -1347)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
