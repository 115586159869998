import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import {
  Box,
  BoxProps,
  Paper,
  Typography,
  TypographyProps,
} from "@mui/material";
import { styled } from "@mui/styles";
import React, { FunctionComponent, useMemo } from "react";
import { FallbackProps } from "react-error-boundary";
import useMeasure from "react-use-measure";
import DeskIcon from "../../../icons/error-fallback/DeskIcon";
import ErrorIcon from "../../../icons/error-fallback/ErrorIcon";
import MarkIcon from "../../../icons/error-fallback/MarkIcon";

const Root = styled(Paper)(({ theme }) => ({
  height: "100%",
  position: "relative",
  color: theme.palette.primary.main,
}));

interface ContentProps extends BoxProps {
  dense?: boolean;
}

const Content = styled<ContentProps & React.ElementType<any>>(Box)(
  ({ theme, dense }) => ({
    position: "absolute",
    height: "100%",
    maxHeight: "100%",
    width: dense ? "100%" : "50%",
    overflow: "hidden",
    display: "grid",
    grid: "min-content min-content auto / 1fr",
    justifyItems: "center",
    zIndex: 100,
    padding: "5%",
    boxSizing: "border-box",
    margin: "auto",
  })
);

const SmallErrorIcon = styled(WarningTwoToneIcon)(({ theme }) => ({
  position: "absolute",
  left: "3%",
  top: "3%",
}));

const BigErrorIcon = styled(ErrorIcon)(({ theme }) => ({
  height: "3rem",
  marginBottom: "1rem",
}));

const MarkBackgroundIcon = styled(MarkIcon)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  height: "100%",
  width: "50%",
  padding: "1rem",
  boxSizing: "border-box",
  zIndex: 10,
}));

const DeskBackgroundIcon = styled(DeskIcon)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  height: "100%",
  width: "50%",
  padding: "1rem",
  boxSizing: "border-box",
  zIndex: 10,
}));

const Error = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: "-webkit-box",
  maxWidth: "100%",
  wordBreak: "break-word",
  overflow: "auto",
}));

interface ComponentErrorProps extends FallbackProps {}

const ComponentError: FunctionComponent<ComponentErrorProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const [ref, bounds] = useMeasure();

  const dense = useMemo(() => bounds.width < 500, [bounds]);
  return (
    <Root ref={ref} elevation={2} square>
      {dense && <SmallErrorIcon color="error" />}
      {dense ? <MarkBackgroundIcon /> : <DeskBackgroundIcon />}
      <Content dense={dense}>
        <Box display="flex">
          {!dense && <BigErrorIcon color="error" fontSize="large" />}
          <Typography variant="h3" component="h1">
            Error
          </Typography>
        </Box>
        <Typography variant="h6" component="h2">
          Ooooops!
        </Typography>
        <Box overflow="auto">
          <Error variant="body2" gutterBottom>
            {error.message}
          </Error>
          <Error variant="body2">{error.stack}</Error>
        </Box>
      </Content>
    </Root>
  );
};

export default ComponentError;
