import { LiveEditor, LiveParser, LiveProvider } from "@improdis/core";
import "@improdis/core/dist/core.cjs.development.css";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Theme,
} from "@mui/material";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import staticScope from "../../../../helpers/grids/staticScope";
import {
  editableComponentDialogComponentAtrrChanged,
  editableComponentDialogComponentCodeChanged,
  editableComponentDialogComponentKeyChanged,
  editableComponentDialogTitleChanged,
  selectEditComponentDialogAttrNameError,
  selectEditComponentDialogKeyError,
  selectEditableComponentDialog,
  submitComponentChangesAndCloseDialogAsync,
} from "../../../../store/common/dialogs/editableComponentSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
// import { selectEnt } from "../../../../store/sockets/entsSlice";
// import { selectUserDetails } from "../../../../store/user/authSlice";
// import { selectRunnedJob } from "../../../../store/views/productionSlice";
import { selectRunnedTask } from "../../../../store/main/job-execution/jobExecutionSlice";
import { selectSelectedAsset } from "../../../../store/user/assetSelectionSlice";
import { selectUserDetails } from "../../../../store/user/authSlice";
import ErrorFallback, { ErrorLevel } from "../../error-fallbacks/ErrorFallback";
// import { selectEntityJobExecs } from "../../../../store/sockets/jobExecsSlice";
// import { selectUtilExec } from "../../../../store/sockets/utilExecsSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  list: {
    display: "flex",
  },
  listItem: {
    minWidth: "20rem",
    alignItems: "flex-start",
    minHeight: "2.3rem",
  },
  listItemIcon: {
    maxWidth: "6rem",
    width: "50%",
    "@media (min-width:1100px)": {
      paddingLeft: "2.2rem",
    },
  },
  listItemAction: {
    minWidth: "50%",
    width: "100%",
  },
}));

interface UniversalComponentEditDialogProps {}

const EditableComponentEditDialogComponent: FunctionComponent<
  UniversalComponentEditDialogProps
> = () => {
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(selectEditableComponentDialog);
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const userDetails = useAppSelector(selectUserDetails);
  const runnedTask = useAppSelector(selectRunnedTask);
  // const entityJobExecs = useAppSelector(selectEntityJobExecs);
  // const utilizationExecs = useAppSelector(selectUtilizationExecs);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const keyError = useAppSelector(selectEditComponentDialogKeyError);
  const attrNameError = useAppSelector(selectEditComponentDialogAttrNameError);

  const handleTitleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(editableComponentDialogTitleChanged(event.target.value));
    },
    [dispatch]
  );

  const handleComponentKeyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(editableComponentDialogComponentKeyChanged(event.target.value));
    },
    [dispatch]
  );

  const handleComponentAttrChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(editableComponentDialogComponentAtrrChanged(event.target.value));
    },
    [dispatch]
  );

  const handleComponentCodeChange = useCallback(
    (code: string) => {
      dispatch(editableComponentDialogComponentCodeChanged(code));
    },
    [dispatch]
  );

  const handleDialogClose = () => {
    dispatch(submitComponentChangesAndCloseDialogAsync());
  };

  const handleOpenDocumentation = () => {
    var win = window.open();
    win &&
      win.document.write(
        '<iframe width="100%" height="100%" src="../custom_components_instruction.pdf" frameborder="0" allowfullscreen></iframe>'
      );
  };

  const dynamicScope = useMemo(
    () => ({
      asset: selectedAsset,
      // utilExec: utilExec,
      // jobExecs: entityJobExecs,
      user: userDetails,
      runningTask: runnedTask,
    }),
    [selectedAsset, userDetails, runnedTask]
  );

  return (
    <Dialog open={dialog.open} keepMounted={false} fullScreen>
      <DialogTitle>{t("Component edit")}</DialogTitle>
      <List className={classes.list} disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            {t("TITLE")}
          </ListItemIcon>
          <div className={classes.listItemAction}>
            <TextField
              fullWidth
              value={dialog.title}
              onChange={handleTitleChange}
              variant="standard"
            />
          </div>
        </ListItem>
      </List>
      <List className={classes.list} disablePadding>
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            {t("KEY")}
          </ListItemIcon>
          <div className={classes.listItemAction}>
            <TextField
              fullWidth
              error={dialog.isNew && keyError}
              helperText="EDITABLE_COMPONENT => {xxxxx}_COMPONENT"
              value={dialog.componentKey.modified}
              onChange={handleComponentKeyChange}
              disabled={!dialog.isNew}
              variant="standard"
            />
          </div>
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listItemIcon}>
            {t("ATTR NAME")}
          </ListItemIcon>
          <div className={classes.listItemAction}>
            <TextField
              fullWidth
              error={dialog.isNew && attrNameError}
              helperText="editable_component => {xxxxx}_component"
              value={dialog.imFactoryAttrName}
              onChange={handleComponentAttrChange}
              disabled={!dialog.isNew}
              variant="standard"
            />
          </div>
        </ListItem>
      </List>
      <DialogContent>
        <LiveProvider
          staticScope={staticScope}
          dynamicScope={dynamicScope}
          code={dialog.code}
          onChange={handleComponentCodeChange}
          ErrorFallbackComponent={(props) => (
            <ErrorFallback {...props} level={ErrorLevel.Component} />
          )}
        >
          <div className="wrapper">
            <Box
              id="code-editor"
              overflow="auto"
              padding="0.5rem"
              boxSizing="border-box"
            >
              <LiveEditor />
            </Box>
            <Box
              display="grid"
              overflow="auto"
              padding="0.5rem"
              boxSizing="border-box"
            >
              <LiveParser />
            </Box>
          </div>
        </LiveProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleOpenDocumentation}>
          {t("Documentation")}
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={handleDialogClose}
          disabled={dialog.isNew && (keyError || attrNameError)}
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditableComponentEditDialogComponent;
