import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../..";

export const initialState: boolean = false;

export const newShiftSlice = createSlice({
  name: "newShiftDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      return true;
    },
    closed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const { opened: newShiftDialogOpened, closed: newShiftDialogClosed } =
  newShiftSlice.actions;

export const selectNewShiftDialogOpen = createSelector(
  (state: RootState) => state.dialogs.openNewShiftDialog,
  (openNewShiftDialog) => openNewShiftDialog
);

export default newShiftSlice.reducer;
