import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import { TaskStateDto, TaskStatesClient } from "../httpService";

const http = new TaskStatesClient(env.REACT_APP_MES_API_URL, instance);

export const getAllTaskStates = async (
  throwError: boolean = true
): Promise<Array<TaskStateDto> | undefined> => {
  try {
    const result = await http.getAllTaskStates();
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getTaskStateById = async (
  taskStateId: string,
  throwError: boolean = true
): Promise<TaskStateDto | undefined> => {
  try {
    const result = await http.getTaskStateById(taskStateId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
