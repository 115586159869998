import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { getUtilizationEventByLogId } from "../../../services/main/utilizationEventsService";
import {
  assetStateChangedSocketEvent,
  assetStateCorrectedSocketEvent,
} from "../../sockets/utilization/assetsSlice";
import {
  UtilExec,
  selectSelectedAssetUtilizationExec,
  utilExecChanged,
} from "./utilExecsSlice";

const utilExecsListener = createListenerMiddleware();

utilExecsListener.startListening({
  actionCreator: assetStateChangedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { assetId, utilLogId } = action.payload;
    const utilEvent = await getUtilizationEventByLogId(utilLogId);
    utilEvent &&
      listenerApi.dispatch(
        utilExecChanged({
          assetId: assetId,
          comment: utilEvent.comment,
          createdByUserId: utilEvent.createdById,
          createdByUserFullName: utilEvent.createdByFullName,
          createdByUserName: utilEvent.createdByUserName,
          currentUtilRawStartedAt: utilEvent.startedAt,
          utilRawName: utilEvent.utilRawName,
          utilStateName: utilEvent.utilStateName,
          utilStateColor: utilEvent.utilStateColor,
        } as UtilExec)
      );
  },
});

utilExecsListener.startListening({
  actionCreator: assetStateCorrectedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { assetId, newUtilLogId } = action.payload;
    const utilExec = selectSelectedAssetUtilizationExec(
      listenerApi.getState() as RootState
    );
    const newUtilEvent = await getUtilizationEventByLogId(newUtilLogId);

    newUtilEvent &&
      utilExec &&
      newUtilEvent.finishedAt == null &&
      listenerApi.dispatch(
        utilExecChanged({
          assetId: assetId,
          comment: newUtilEvent.comment,
          createdByUserId: newUtilEvent.createdById,
          createdByUserFullName: newUtilEvent.createdByFullName,
          createdByUserName: newUtilEvent.createdByUserName,
          currentUtilRawStartedAt: newUtilEvent.startedAt,
          utilRawName: newUtilEvent.utilRawName,
          utilStateName: newUtilEvent.utilStateName,
          utilStateColor: newUtilEvent.utilStateColor,
        } as UtilExec)
      );
  },
});

export default utilExecsListener;
