import { EntityIcon } from "@improdis/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import TableName from "../../../helpers/enums/tableName";
import { FormatDate } from "../../../helpers/tables/date/FormatDate";
import { FormatDuration } from "../../../helpers/tables/duration/FormatDuration";
import { FormatUtilRaw } from "../../../helpers/tables/util-raw/FormatUtilRaw";
import { filterUtilRaws } from "../../../helpers/tables/util-raw/filterUtilRaws";
import { FormatUtilState } from "../../../helpers/tables/util-state/FormatUtilState";
import { filterUtilStates } from "../../../helpers/tables/util-state/filterUtilStates";
import {
  NestedUtilEventHistory,
  loadUtilizationEventHistoryAsync,
  selectUtilEventHistory,
  selectUtilEventHistoryDialogOpen,
  selectUtilEventHistoryPending,
  utilEventHistoryDialogClosed,
} from "../../../store/common/dialogs/utilEventHistorySlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import TableComponent from "../../common/table/TableComponent";

const AssetIcon = styled(EntityIcon)(({ theme }) => ({}));

interface DialogUtilEventHistoryProps {}

const DialogUtilEventHistoryComponent: FunctionComponent<
  DialogUtilEventHistoryProps
> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectUtilEventHistoryDialogOpen);
  const utilHistory = useAppSelector(selectUtilEventHistory);
  const pending = useAppSelector(selectUtilEventHistoryPending);

  useEffect(() => {
    if (open) {
      dispatch(loadUtilizationEventHistoryAsync());
    }
  }, [dispatch, open]);

  const columnsTree: Array<Column<NestedUtilEventHistory>> = useMemo(
    () => [
      {
        id: "expander", // Make sure it has an ID
        disableSortBy: true,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              style={{
                marginLeft: `${row.depth * 2}rem`,
                cursor: "auto",
              }}
            >
              {row.isExpanded ? <ExpandLessIcon /> : <ChevronRightIcon />}
            </span>
          ) : null,
      },
      {
        Header: t("logId").toString(),
        id: "logId",
        accessor: "logId",
      },
      // {
      //   Header: t("sourceLogId").toString(),
      //   id: "sourceLogId",
      //   accessor: "sourceLogId",
      //   disableSortBy: true,
      // },
      {
        Header: t("assetName").toString(),
        id: "assetName",
        accessor: "assetName",
        disableSortBy: true,
      },
      {
        Header: t("startedAt").toString(),
        id: "startedAt",
        accessor: "startedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        disableSortBy: true,
      },
      {
        Header: t("finishedAt").toString(),
        id: "finishedAt",
        accessor: "finishedAt",
        Cell: (cell) => FormatDate(cell.value, t),
        disableSortBy: true,
      },
      {
        Header: t("duration").toString(),
        id: "duration",
        accessor: "duration",
        Cell: (cell) => FormatDuration(cell.value, t),
        disableSortBy: true,
      },
      {
        Header: t("utilRawName"),
        id: "utilRawName",
        accessor: "utilRawName",
        Cell: (cell) =>
          FormatUtilRaw(cell.value!, cell.row.original.utilStateColor, t),
        filter: (rows, id, filterValue) =>
          filterUtilRaws(rows, id, filterValue, t),
        disableSortBy: true,
      },
      {
        Header: t("utilStateName"),
        id: "utilStateName",
        accessor: "utilStateName",
        Cell: (cell) => FormatUtilState(cell.value!, t),
        filter: (rows, id, filterValue) =>
          filterUtilStates(rows, id, filterValue, t),
        disableSortBy: true,
      },
      {
        Header: t("comment").toString(),
        id: "comment",
        accessor: "comment",
        disableSortBy: true,
      },
      {
        Header: t("createdByName"),
        id: "createdByName",
        accessor: "createdByUserName",
        disableSortBy: true,
      },
      {
        Header: t("createdAt").toString(),
        id: "createdAt",
        accessor: "createdAt",
        Cell: (cell) => FormatDate(cell.value, t),
        disableSortBy: true,
      },
    ],
    [t]
  );

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(utilEventHistoryDialogClosed())}
      fullScreen
    >
      <DialogTitle>
        <AssetIcon />
        <div>{t("Utilization")}</div>
      </DialogTitle>
      <DialogContent>
        <TableComponent
          name={TableName.UTIL_EVENT_HISTORY}
          data={utilHistory}
          columns={columnsTree}
          isLoading={pending}
          subRowsProperty="childrenUtilEvents"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => dispatch(utilEventHistoryDialogClosed())}
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUtilEventHistoryComponent;
