import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Packet } from "mqtt";
import { Middleware } from "redux";
import { REHYDRATE } from "redux-persist";
import { PersistConfigKey, RootState } from "..";
import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { getAssetIdFromTopic, getAssetTopic } from "../../helpers/mqttUtils";
import { mqttService } from "../../services/mqtt/mqttInstance";
import {
  connectedToMqtt,
  disconnectedFromMqtt,
  mqttTopicValueChanged,
  reconnectingMqtt,
  selectMqttDefinitionsByAssetId,
  selectSelectedAssetMqttDefinition,
} from "../common/mqttSlice";
import {
  logOnAssetAsync,
  selectSelectedAssetId,
} from "../user/assetSelectionSlice";
import { logInAsync, logOutAsync } from "../user/authSlice";

const initHandlers = (
  dispatch: Dispatch<AnyAction>,
  getState: () => RootState
) => {
  const handleConnect = () => dispatch(connectedToMqtt());
  const handleDisconnect = () => dispatch(disconnectedFromMqtt());
  const handleError = (err: Error) => handleExceptionMessage(err);
  const handleReconnect = () => dispatch(reconnectingMqtt());
  const handleMessage = (topic: string, payload: Buffer, packet: Packet) => {
    const assetId = getAssetIdFromTopic(topic);
    const assetTopic = getAssetTopic(topic);
    const extTopic = selectMqttDefinitionsByAssetId(getState(), assetId);

    if (extTopic?.topics?.some((et) => et.value === assetTopic)) {
      const extTopicName = extTopic.topics.find(
        (et) => et.value === assetTopic
      )?.name;
      if (extTopic !== undefined && extTopicName !== undefined) {
        const value = new TextDecoder("utf-8").decode(payload);
        value !== undefined &&
          dispatch(
            mqttTopicValueChanged({
              assetId: assetId,
              propName: extTopicName,
              value: value,
            })
          );
      }
    } else {
      //TODO REMOVE BECAUSE OF CUSTOM COMPONENTS MQTT POSSIBLE TOPICS
      // handleExceptionMessage(
      //   new Error("Unknown topic with message: " + payload.toString())
      // );
    }
  };
  return {
    handleConnect,
    handleDisconnect,
    handleError,
    handleReconnect,
    handleMessage,
  };
};

const mqttMiddleware: Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === logInAsync.fulfilled.type && env.REACT_APP_MQTT_URL) {
      const {
        handleConnect,
        handleDisconnect,
        handleError,
        handleReconnect,
        handleMessage,
      } = initHandlers(dispatch, getState);

      mqttService.connect(
        handleConnect,
        handleDisconnect,
        handleReconnect,
        handleError,
        handleMessage
      );
    } else if (
      action.type === REHYDRATE &&
      action.key === PersistConfigKey.User &&
      env.REACT_APP_MQTT_URL
    ) {
      const token = action.payload?.auth?.details?.token;
      if (token !== null && token !== undefined) {
        const {
          handleConnect,
          handleDisconnect,
          handleError,
          handleReconnect,
          handleMessage,
        } = initHandlers(dispatch, getState);

        mqttService.connect(
          handleConnect,
          handleDisconnect,
          handleReconnect,
          handleError,
          handleMessage
        );
      }
    } else if (action.type === logOnAssetAsync.fulfilled.type) {
      const oldAssetId = selectSelectedAssetId(getState() as RootState);
      const { assetId: newAssetId } = action.payload;
      if (oldAssetId !== undefined) {
        const oldAssetDefinition = selectSelectedAssetMqttDefinition(
          getState() as RootState
        );
        oldAssetDefinition?.topics.forEach((t) => {
          t.errors === undefined &&
            mqttService.unsubscribe(
              env.REACT_APP_MQTT_PREFIX +
                "/" +
                oldAssetDefinition.assetId +
                t.value
            );
        });
      }
      if (newAssetId !== undefined) {
        const newAssetDefinition = selectMqttDefinitionsByAssetId(
          getState() as RootState,
          newAssetId
        );
        newAssetDefinition?.topics.forEach((t) => {
          t.errors === undefined &&
            mqttService.subscribe(
              env.REACT_APP_MQTT_PREFIX +
                "/" +
                newAssetDefinition.assetId +
                t.value
            );
        });
      }
    } else if (
      (action.type === logOutAsync.fulfilled.type ||
        action.type === logOutAsync.rejected.type) &&
      env.REACT_APP_MQTT_URL
    ) {
      mqttService.disconnect();
      dispatch(disconnectedFromMqtt());
    }
    return next(action);
  };

export default mqttMiddleware;
