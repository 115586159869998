import { TFunction } from "i18next";
import moment from "moment";
import * as React from "react";

export const FormatDate = (
  value: Date | string | undefined | null,
  t: TFunction
) => {
  return value ? (
    <span>
      {moment(value).format(t("DateWithTimeWithSecondsFormat").toString())}
    </span>
  ) : (
    <span>-----</span>
  );
};

export const FormatDateString = (
  value: Date | string | undefined | null,
  t: TFunction
) => {
  return value
    ? moment(value).format(t("DateWithTimeWithSecondsFormat").toString())
    : "-----";
};
