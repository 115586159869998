import { Drawer, styled } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { makeStyles } from "tss-react/mui";
import EntityIcon from "../../../icons/EntityIcon";
import { entNavigationDialogClosed } from "../../../store/common/dialogs/entNavigationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  logOnAssetAsync,
  selectSelectedAssetNodeId,
} from "../../../store/user/assetSelectionSlice";
import TreeViewComponent from "./inner-components/TreeViewComponent";

const Container = styled(SimpleBar)(({ theme }) => ({
  maxHeight: "100%",
  minWidth: "17rem",
  maxWidth: "40rem",
  overflow: "auto",
  "& .simplebar-content": {
    width: "fit-content",
    overflow: "auto",
  },
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "1.5rem",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  fontSize: "1.5rem",
}));

const Icon = styled(EntityIcon)(({ theme }) => ({
  height: 20,
  width: 20,
  marginRight: "1rem",
}));

const useStyles = makeStyles()((theme: Theme) => ({
  backDrop: {
    marginTop: "3.125rem",
  },
}));

interface NavigationEntitiesPanelProps {}

const NavigationEntitiesPanelComponent: React.FC<
  NavigationEntitiesPanelProps
> = () => {
  const dispatch = useAppDispatch();
  const selectedAssetNodeId = useAppSelector(selectSelectedAssetNodeId);
  const openEntNavigation = useAppSelector(
    (state) => state.dialogs.openEntNavigationDialog
  );
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(entNavigationDialogClosed());
  };

  const handleSelectAsset = async (nodeId: string) => {
    let sourceStack = nodeId.split("/");
    const assetId = sourceStack[sourceStack.length - 1];
    sourceStack.pop();
    if (nodeId !== selectedAssetNodeId) {
      dispatch(entNavigationDialogClosed());
      await dispatch(
        logOnAssetAsync({
          assetId: assetId,
          sourceStack: sourceStack,
        })
      );
    }
  };

  return (
    <Drawer
      open={openEntNavigation}
      BackdropProps={{ invisible: false, className: classes.backDrop }}
      onClose={handleClose}
    >
      <Container>
        <Header>
          <Icon />
          <div>{t("Entities")}</div>
        </Header>
        <TreeViewComponent
          handleClick={handleSelectAsset}
          selectedAssetNodeId={selectedAssetNodeId}
        ></TreeViewComponent>
      </Container>
    </Drawer>
  );
};
export default NavigationEntitiesPanelComponent;
