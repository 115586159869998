import { env } from "../env";
import "@mui/lab/themeAugmentation";
import { createAppTheme } from "@improdis/core";

const appTheme = createAppTheme(env.REACT_APP_THEME_TYPE);

export const chartBackgroundColor = appTheme.palette.chart?.background;
export const chartGoodColor = appTheme.palette.chart?.success;
export const chartBadColor = appTheme.palette.chart?.error;
export const chartWarningColor = appTheme.palette.chart?.warning;
export const chartAlphaValue = appTheme.palette.chart?.alpha;

export default appTheme;
