import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "..";
import { GetNestedAvailableUtilRawsQueryGroupDto } from "../../services/httpService";
import { getAvailableNestedUtilRawsByAsset } from "../../services/main/utilRawsService";
import { selectSelectedAssetId } from "../user/assetSelectionSlice";
import GetAvailableUtilRawsActionType from "../../helpers/enums/getAvailableUtilRawsActionType";

export const getNestedUtilRawNodeId = (
  sourceStack: Array<string> | null,
  id: string | null
) =>
  (sourceStack && sourceStack.length > 0 ? sourceStack?.join("/") + "/" : "") +
  id;

export interface NestedAvailableUtilRaw
  extends GetNestedAvailableUtilRawsQueryGroupDto {}

export type AvailableUtilRawsState = AsyncValue<Array<NestedAvailableUtilRaw>>;

export const initialState: AvailableUtilRawsState = {
  value: [],
  pending: false,
};

interface LoadAvailableUtilRawsParams {
  utilRawId: string | undefined;
  actionType: GetAvailableUtilRawsActionType;
}

export const loadAvailableUtilRawsAsync = createAsyncThunk(
  "availableUtilRaws/loadAvailableUtilRawsAsync",
  async (params: LoadAvailableUtilRawsParams, { getState, dispatch }) => {
    const assetId = selectSelectedAssetId(getState() as RootState);
    if (assetId) {
      const result = await getAvailableNestedUtilRawsByAsset(
        assetId,
        params.utilRawId,
        params.actionType
      );
      return result;
    } else {
      return [];
    }
  }
);

export const availableUtilRawsSlice = createSlice({
  name: "availableUtilRaws",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAvailableUtilRawsAsync.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(loadAvailableUtilRawsAsync.fulfilled, (state, action) => {
        state.value = action.payload!;
        state.pending = false;
      })
      .addCase(loadAvailableUtilRawsAsync.rejected, (state, action) => {
        state.value = [];
        state.pending = false;
      });
  },
});

// export const {} = availableUtilRawsSlice.actions;

export const selectAvailableUtilRaws = createSelector(
  (state: RootState) => state.main.availableUtilRaws.value,
  (availableUtilRaws) => availableUtilRaws
);

export const selectAvailableUtilRawsPending = createSelector(
  (state: RootState) => state.main.availableUtilRaws.pending,
  (availableUtilRawsPending) => availableUtilRawsPending
);

export const selectAvailableUtilRawsNodeIds = createSelector(
  selectAvailableUtilRaws,
  (availableUtilRaws) => {
    let ids: Array<string> = [];
    const lookup = (node: NestedAvailableUtilRaw) => {
      ids = [...ids, node.id];
      node.childrenGroups?.forEach((node) => lookup(node));
    };
    availableUtilRaws.forEach((node) => lookup(node));
    return ids;
  }
);

export default availableUtilRawsSlice.reducer;
