import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { makeStyles } from "tss-react/mui";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  StepOfProduction,
  VariantOfSummarizeProduction,
  pauseTaskAsync,
  selectSummiarizeProductionVariant,
  stepOfProductionSelected,
} from "../../../../store/main/job-execution/jobExecutionSlice";
import DialogConfirmEndWoComponent from "./DialogConfirmEndWoComponent";
import DeadlineSummaryComponent from "./charts/DeadlineSummaryComponent";
import ProductionEfficiencyChartComponent from "./charts/ProductionEfficiencyChartComponent";
import SummarizeProductionChartComponent from "./charts/SummarizeProductionChartComponent";
import SummarizeProductionPercentChartComponent from "./charts/SummarizeProductionPercentChartComponent";
import UseOfTimeChartComponent from "./charts/UseOfTimeChartComponent";
import WorkOrderProgressChartComponent from "./charts/WorkOrderProgressChartComponent";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    "& .simplebar-content": {
      display: "flex",
      height: "100%",
    },
  },

  chartContent: {
    display: "grid",
    boxSizing: "border-box",
    margin: "0.3rem 0.4rem 0.4rem 0rem",
    padding: "0.2rem",
    height: "calc(100% - 0.8rem)",
    width: "calc(100% - 12rem)",
    overflow: "auto",
    [theme.breakpoints.up("xl")]: {
      gridTemplateColumns: "repeat(16, 1fr)",
      gridTemplateRows: "1fr 1fr",
      gridTemplateAreas: `'summaryPieces summaryPieces summaryPieces summaryPieces efficiency efficiency efficiency efficiency efficiency useOfTime useOfTime useOfTime useOfTime deadline deadline deadline'
          'summaryPercent summaryPercent summaryPercent summaryPercent summaryPercent summaryPercent progress progress progress progress progress progress progress progress progress progress'`,
    },
    [theme.breakpoints.down("xl")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
      gridTemplateRows: "300px 350px 300px",
      gridTemplateAreas: `'summaryPieces efficiency useOfTime'
          'summaryPercent summaryPercent deadline'
          'progress progress progress'`,
    },
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridTemplateRows:
        "minmax(300px, 1fr) minmax(350px, 1fr) minmax(350px, 1fr)",
      gridTemplateAreas: `'summaryPieces summaryPieces efficiency efficiency'
          'useOfTime summaryPercent summaryPercent summaryPercent '
          'deadline progress progress progress'`,
    },
    [theme.breakpoints.down("md")]: {
      grid: "repeat(6, minmax(350px, auto)) / 1fr",
      gridTemplateAreas: `'summaryPieces'
        'efficiency'
        'useOfTime'
        'summaryPercent'
        'deadline'
        'progress'`,
    },
    gridGap: "0.5rem",
  },

  summaryPiecesChartStyle: {
    display: "grid",
    grid: "min-content / 1fr",
    boxSizing: "border-box",
    padding: "0.5rem",
    gridArea: "summaryPieces",
    overflow: "auto",
    height: "100%",
    width: "100%",
  },

  efficiencyChartStyle: {
    display: "grid",
    grid: "min-content / 1fr",
    boxSizing: "border-box",
    padding: "0.5rem",
    gridArea: "efficiency",
    maxHeight: "100%",
    maxWidth: "100%",
  },

  useOfTimeChartStyle: {
    display: "grid",
    grid: "min-content / 1fr",
    boxSizing: "border-box",
    padding: "0.5rem",
    gridArea: "useOfTime",
    height: "100%",
    width: "100%",
  },

  deadlineChartStyle: {
    display: "grid",
    grid: "min-content / 1fr",
    boxSizing: "border-box",
    padding: "0.5rem",
    gridArea: "deadline",
    height: "100%",
    width: "100%",
    textAlign: "center",
  },

  summaryPercentChartStyle: {
    display: "grid",
    grid: "min-content / 1fr",
    boxSizing: "border-box",
    padding: "0.5rem",
    gridArea: "summaryPercent",
    height: "100%",
    width: "100%",
  },

  progressChartStyle: {
    display: "grid",
    grid: "min-content / 1fr",
    boxSizing: "border-box",
    padding: "0.5rem",
    gridArea: "progress",
    height: "100%",
    minHeight: "300px",
    width: "100%",
  },

  manageWoContainer: {
    minWidth: "12rem",
    margin: "0.5rem 0.1rem",
  },

  manageWoHeader: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    fontSize: "1.8rem",
    wordBreak: "break-word",
  },

  manageWoContent: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    gap: "1rem",
  },

  woIcon: {
    minWidth: 30,
    minHeight: 30,
    paddingLeft: "0.5rem",
    marginRight: "0.25rem",
  },

  jobStateBar: {
    minWidth: "0.3rem",
    height: "2rem",
    backgroundColor: "white",
  },
}));

export interface FinalStepContainerProps {}

const FinalStepContainerComponent: React.FC<FinalStepContainerProps> = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const summarizeProductionVariant = useAppSelector(
    selectSummiarizeProductionVariant
  );
  const [submittingWorkOrder, setSubmittingWorkOrder] =
    useState<boolean>(false);
  const [openConfirmEndWoDialog, setOpenConfirmEndWoDialog] =
    useState<boolean>(false);

  const handleCloseConfirmEndWoDialog = () => {
    setOpenConfirmEndWoDialog(false);
  };

  const handleSuspendOperation = async () => {
    setSubmittingWorkOrder(true);
    await dispatch(pauseTaskAsync());
    setSubmittingWorkOrder(false);
  };

  const handleReturnToRegisterProduction = () => {
    dispatch(stepOfProductionSelected(StepOfProduction.SecondStep));
  };

  // useEffect(() => {
  //   selectedEnt !== undefined &&
  //     runnedJob !== undefined &&
  //     previousJobExec !== undefined &&
  //     runnedJobExec === undefined &&
  //     !submittingWorkOrder &&
  //     setOpenjobStateChangedDialog(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedEnt, runnedJob, runnedJobExec, submittingWorkOrder]);

  return (
    <SimpleBar className={classes.root}>
      <div className={classes.chartContent}>
        <SummarizeProductionChartComponent
          chartStyle={classes.summaryPiecesChartStyle}
        />
        <ProductionEfficiencyChartComponent
          chartStyle={classes.efficiencyChartStyle}
        />
        <UseOfTimeChartComponent chartStyle={classes.useOfTimeChartStyle} />
        <DeadlineSummaryComponent chartStyle={classes.deadlineChartStyle} />
        <SummarizeProductionPercentChartComponent
          chartStyle={classes.summaryPercentChartStyle}
        />
        <WorkOrderProgressChartComponent
          chartStyle={classes.progressChartStyle}
        />
      </div>
      <Paper className={classes.manageWoContainer} elevation={2} square>
        <div className={classes.manageWoContent}>
          {summarizeProductionVariant ===
            VariantOfSummarizeProduction.Complete && (
            <Button
              variant="contained"
              color="complete"
              onClick={() => {
                setOpenConfirmEndWoDialog(true);
              }}
            >
              {t("Submit")}
            </Button>
          )}

          {summarizeProductionVariant ===
            VariantOfSummarizeProduction.Pause && (
            <Button
              variant="contained"
              color="suspend"
              disabled={submittingWorkOrder}
              onClick={() => {
                handleSuspendOperation();
              }}
            >
              {t("Submit")}
            </Button>
          )}
          <Button
            variant="contained"
            color="warning"
            disabled={submittingWorkOrder}
            onClick={() => handleReturnToRegisterProduction()}
          >
            {t("Return")}
          </Button>
        </div>
      </Paper>
      <DialogConfirmEndWoComponent
        isSubmitting={submittingWorkOrder}
        onSubmitChange={setSubmittingWorkOrder}
        open={openConfirmEndWoDialog}
        handleClose={handleCloseConfirmEndWoDialog}
      ></DialogConfirmEndWoComponent>
    </SimpleBar>
  );
};

export default FinalStepContainerComponent;
