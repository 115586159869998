import oeeChartBackground from "./oeeChart.png";
import productionSummaryChartBackground from "./productionSummaryChart.png";
import utilDowntimesChartBackground from "./utilDowntimesChart.png";
import utilSummaryChartBackground from "./utilSummaryChart.png";
import utilTimelineChartBackground from "./utilTimelineChart.png";

const overviewTiles = {
  oeeChartBackground,
  productionSummaryChartBackground,
  utilDowntimesChartBackground,
  utilSummaryChartBackground,
  utilTimelineChartBackground,
};

export default overviewTiles;
