import { LoadingButton } from "@mui/lab";
import { styled, TextField } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import * as Yup from "yup";
import LoginIcon from "../../../icons/LoginIcon";
import { AppThunkDispatch } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { logInAsync } from "../../../store/user/authSlice";

const Form = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  flexGrow: 1,
  padding: "1rem 4rem",
  boxSizing: "border-box",
}));

const Inputs = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  width: "100%",
  gap: "3rem",
  padding: "2rem 0rem",
  flexGrow: 1,
  boxSizing: "border-box",
}));

const SubmitButton = styled(LoadingButton)(({ theme }) => ({
  padding: "2px 16px",
}));

const StyledLoginIcon = styled(LoginIcon)(({ theme }) => ({
  width: "2rem",
  height: "2rem",
  fill: theme.palette.primary.contrastText,
}));

interface LoginFormProps extends RouteComponentProps {
  pinLogInMode: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ pinLogInMode, history }) => {
  const { t } = useTranslation();
  const thunkDispatch = useAppDispatch() as AppThunkDispatch;
  const pendingLogIn = useAppSelector((state) => state.user.auth.pendingLogIn);

  const { isValid, handleSubmit, errors, handleChange } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: pinLogInMode ? Yup.string() : Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values, action) => {
      await thunkDispatch(
        logInAsync({
          username: values.username,
          password: values.password,
        })
      );
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Inputs>
        {!pinLogInMode && (
          <TextField
            id="username"
            label={t("Username")}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            type="text"
            helperText={errors.username !== undefined && t(errors.username)}
            error={errors.username !== undefined}
          />
        )}
        <TextField
          sx={{ alignSelf: "end" }}
          id="password"
          label={pinLogInMode ? "PIN" : t("Password")}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          type="password"
          helperText={errors.password !== undefined && t(errors.password)}
          error={errors.password !== undefined}
        />
      </Inputs>
      <SubmitButton
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        loading={pendingLogIn}
        loadingPosition="start"
        disabled={pendingLogIn || !isValid}
        endIcon={<StyledLoginIcon />}
      >
        {t("Log in")}
      </SubmitButton>
    </Form>
  );
};

export default withRouter(LoginForm);
