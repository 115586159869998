import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";
import {
  addGoodProductionAsync,
  reduceGoodProductionAsync,
  selectAddProductionPending,
} from "../../../../../store/main/production-events/prodEventsSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: "1rem",
    paddingTop: "1.5rem",
    position: "relative",
    boxSizing: "border-box",
  },

  goodProductionHeader: {
    textAlign: "left",
    fontSize: "1.5rem",
  },

  actualGoodProductionContent: {
    display: "flex",
    textAlign: "left",
    padding: "2rem",
    fontSize: "2.5rem",
  },

  arrowBtnGood: {
    height: "1.5rem",
    maxWidth: "0.5rem",
    padding: 0,
    margin: "0.15rem",
    minWidth: "1.5rem",
  },

  textField: {
    minWidth: "4rem",
  },
}));

export interface GoodProductionComponentProps {}

const GoodProductionComponent: React.FC<GoodProductionComponentProps> = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const runnedTask = useAppSelector(selectRunnedTask);
  const pendingAddProduction = useAppSelector(selectAddProductionPending);
  const [multipleCounter, setMultipleCounter] = useState<string>("5");

  const handleAddProduction = async (value: number) => {
    if (value >= 0)
      dispatch(
        addGoodProductionAsync({
          taskId: runnedTask?.taskId!,
          quantityProduced: value,
          comment: null,
        })
      );
    else
      dispatch(
        reduceGoodProductionAsync({
          taskId: runnedTask?.taskId!,
          quantity: Math.abs(value),
          comment: null,
        })
      );
  };

  const handleChangeMultpleCounter = (value: string) => {
    setMultipleCounter(value);
  };

  const isGoodProdNumerical = () => {
    var numericalMultipleCounter = Number(multipleCounter);
    return !isNaN(numericalMultipleCounter);
  };

  const isValidAmountOfGoodProd = () => {
    var numericalMultipleCounter = Number(multipleCounter);
    return (
      multipleCounter.trim() !== "" &&
      !isNaN(numericalMultipleCounter) &&
      numericalMultipleCounter !== 0
    );
  };

  return (
    <Paper className={classes.paper} elevation={2} square>
      <div className={classes.goodProductionHeader}>
        {t("Good production")}:
      </div>
      <div className={classes.actualGoodProductionContent}>
        <div>{runnedTask?.quantityProduced ?? 0}</div>
        <div style={{ paddingLeft: "1rem" }}>
          {runnedTask?.abbreviation || runnedTask?.uomName}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="success"
          style={{ marginRight: "0.3rem" }}
          variant="contained"
          size="small"
          onClick={async () => await handleAddProduction(1)}
          disabled={pendingAddProduction}
        >
          <div style={{ fontSize: "1.3rem" }}>+1</div>
        </Button>
        <TextField
          value={multipleCounter}
          onChange={(event) => {
            handleChangeMultpleCounter(event.target.value);
          }}
          variant="filled"
          label={t("Add more")}
          className={classes.textField}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "0.25rem",
          }}
        >
          <Button
            variant="contained"
            color="success"
            className={classes.arrowBtnGood}
            onClick={() =>
              handleChangeMultpleCounter(
                (Number(multipleCounter) + 1).toString()
              )
            }
            disabled={!isGoodProdNumerical()}
          >
            <ArrowDropUpIcon />
          </Button>
          <Button
            variant="contained"
            color="success"
            className={classes.arrowBtnGood}
            onClick={() =>
              handleChangeMultpleCounter(
                (Number(multipleCounter) - 1).toString()
              )
            }
            disabled={!isGoodProdNumerical()}
          >
            <ArrowDropDownIcon />
          </Button>
        </div>
        <Button
          variant="contained"
          color="success"
          style={{ marginLeft: "0.3rem", marginRight: "0.3rem" }}
          onClick={() => handleAddProduction(Number(multipleCounter))}
          disabled={pendingAddProduction || !isValidAmountOfGoodProd()}
        >
          <DoneIcon></DoneIcon>
        </Button>
      </div>
    </Paper>
  );
};

export default GoodProductionComponent;
