import { createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { getUtilizationEventByLogId } from "../../../services/main/utilizationEventsService";
import { assetStateChangedSocketEvent } from "../../sockets/utilization/assetsSlice";
import {
  utilizationEventCorrectedSocketEvent,
  utilizationEventFinishedSocketEvent,
  utilizationEventSplittedSocketEvent,
} from "../../sockets/utilization/utilizationEventsSlice";
import {
  logOnAssetAsync,
  selectSelectedAssetId,
} from "../../user/assetSelectionSlice";
import {
  UtilEvent,
  currentUtilEventDurationUpdated,
  loadUtilEventsForCurrentShiftAsync,
  selectUtilEventByLogId,
  utilEventCreated,
  utilEventSplitted,
  utilEventUpdated,
} from "./utilEventsSlice";

const utilEventsListener = createListenerMiddleware();

const updateIntervalInSeconds = 30000;

utilEventsListener.startListening({
  actionCreator: loadUtilEventsForCurrentShiftAsync.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    while (true) {
      const task = listenerApi.fork(async (forkApi) => {
        await forkApi.delay(updateIntervalInSeconds);
        listenerApi.dispatch(
          currentUtilEventDurationUpdated(updateIntervalInSeconds / 1000)
        );
      });
      await task.result;
    }
  },
});

utilEventsListener.startListening({
  actionCreator: logOnAssetAsync.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(loadUtilEventsForCurrentShiftAsync());
  },
});

utilEventsListener.startListening({
  actionCreator: assetStateChangedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { utilLogId, assetId } = action.payload;
    const selectedAssetId = selectSelectedAssetId(
      listenerApi.getState() as RootState
    );
    if (selectedAssetId === assetId) {
      const createdUtilEvent = await getUtilizationEventByLogId(utilLogId);
      if (createdUtilEvent)
        listenerApi.dispatch(utilEventCreated(createdUtilEvent));
    }
  },
});

utilEventsListener.startListening({
  actionCreator: utilizationEventFinishedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { logId } = action.payload;
    const newUtilEvent = await getUtilizationEventByLogId(logId);
    if (newUtilEvent)
      //FIXME started at should be narrowed down to current shift
      listenerApi.dispatch(
        utilEventUpdated({
          oldEventLogId: logId,
          newEvent: {
            logId: newUtilEvent.logId,
            assetName: newUtilEvent.assetName,
            comment: newUtilEvent.comment,
            duration: newUtilEvent.duration,
            oeeDefinition: newUtilEvent.oeeDefinition,
            utilRawId: newUtilEvent.utilRawId,
            utilRawName: newUtilEvent.utilRawName,
            utilStateId: newUtilEvent.utilStateId,
            utilStateName: newUtilEvent.utilStateName,
            utilStateColor: newUtilEvent.utilStateColor,
            // startedAt: newUtilEvent.startedAt,
            finishedAt: newUtilEvent.finishedAt,
            createdById: newUtilEvent.createdById,
            createdByUserName: newUtilEvent.createdByUserName,
            createdByFullName: newUtilEvent.createdByFullName,
          } as UtilEvent,
        })
      );
  },
});

utilEventsListener.startListening({
  actionCreator: utilizationEventCorrectedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { utilLogId, newUtilLogId } = action.payload;
    const newUtilEvent = await getUtilizationEventByLogId(newUtilLogId);
    if (newUtilEvent)
      listenerApi.dispatch(
        utilEventUpdated({
          oldEventLogId: utilLogId,
          newEvent: {
            logId: newUtilEvent.logId,
            assetName: newUtilEvent.assetName,
            comment: newUtilEvent.comment,
            duration: newUtilEvent.duration,
            oeeDefinition: newUtilEvent.oeeDefinition,
            utilRawId: newUtilEvent.utilRawId,
            utilRawName: newUtilEvent.utilRawName,
            utilStateId: newUtilEvent.utilStateId,
            utilStateName: newUtilEvent.utilStateName,
            utilStateColor: newUtilEvent.utilStateColor,
            // startedAt: newUtilEvent.startedAt,
            finishedAt: newUtilEvent.finishedAt,
            createdById: newUtilEvent.createdById,
            createdByUserName: newUtilEvent.createdByUserName,
            createdByFullName: newUtilEvent.createdByFullName,
          } as UtilEvent,
        })
      );
  },
});

utilEventsListener.startListening({
  actionCreator: utilizationEventSplittedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { logId, olderUtilLogId, newerUtilLogId } = action.payload;
    const utilEvent = selectUtilEventByLogId(
      listenerApi.getState() as RootState,
      logId
    );
    const olderUtilEvent = await getUtilizationEventByLogId(olderUtilLogId);
    const newerUtilEvent = await getUtilizationEventByLogId(newerUtilLogId);
    if (utilEvent && olderUtilEvent && newerUtilEvent)
      listenerApi.dispatch(
        utilEventSplitted({
          logId: logId,
          olderEvent: {
            logId: olderUtilEvent.logId,
            assetName: olderUtilEvent.assetName,
            comment: olderUtilEvent.comment,
            duration: olderUtilEvent.duration,
            oeeDefinition: olderUtilEvent.oeeDefinition,
            utilRawId: olderUtilEvent.utilRawId,
            utilRawName: olderUtilEvent.utilRawName,
            utilStateId: olderUtilEvent.utilStateId,
            utilStateName: olderUtilEvent.utilStateName,
            utilStateColor: olderUtilEvent.utilStateColor,
            startedAt: utilEvent.startedAt,
            finishedAt: olderUtilEvent.finishedAt,
            createdById: olderUtilEvent.createdById,
            createdByUserName: olderUtilEvent.createdByUserName,
            createdByFullName: olderUtilEvent.createdByFullName,
          } as UtilEvent,
          newerEvent: {
            logId: newerUtilEvent.logId,
            assetName: newerUtilEvent.assetName,
            comment: newerUtilEvent.comment,
            duration: newerUtilEvent.duration,
            oeeDefinition: newerUtilEvent.oeeDefinition,
            utilRawId: newerUtilEvent.utilRawId,
            utilRawName: newerUtilEvent.utilRawName,
            utilStateId: newerUtilEvent.utilStateId,
            utilStateName: newerUtilEvent.utilStateName,
            utilStateColor: newerUtilEvent.utilStateColor,
            startedAt: newerUtilEvent.startedAt,
            finishedAt: newerUtilEvent.finishedAt,
            createdById: newerUtilEvent.createdById,
            createdByUserName: newerUtilEvent.createdByUserName,
            createdByFullName: newerUtilEvent.createdByFullName,
          } as UtilEvent,
        })
      );
  },
});

export default utilEventsListener;
