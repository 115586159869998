import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { makeStyles } from "tss-react/mui";
import { env } from "../../env";
import { RootState } from "../../store";
import {
  entNavigationDialogClosed,
  entNavigationDialogOpened,
} from "../../store/common/dialogs/entNavigationSlice";
import {
  breakpointChanged,
  selectBreakpoint,
} from "../../store/common/gridsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectRunnedTask } from "../../store/main/job-execution/jobExecutionSlice";
import { selectSelectedAsset } from "../../store/user/assetSelectionSlice";
import { logOutAsync, selectIsAuthorized } from "../../store/user/authSlice";
import AppBarUserContentComponent from "./AppBarUserContentComponent";
import DefaultAssetDialogComponent from "./DefaultAssetDialogComponent";

const useStyles = makeStyles()((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    height: "3.125rem",
    maxHeight: "3.5rem",
  },

  appBarContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },

  navigationContent: {
    display: "flex",
  },

  infoContent: {
    display: "flex",
    alignItems: "center",
  },

  menuIcon: {
    width: "2.25rem",
    height: "2.25rem",
    fill: "#FFFFFF",
  },

  menuButton: {
    margin: "0.3375rem 0.50125rem 0.3375rem 1.025rem",
    padding: "0.1rem",
  },

  titleText: {
    fontSize: "1rem",
    textAlign: "left",
  },

  userNameContent: {
    display: "flex",
    alignItems: "center",
    marginRight: "2.5rem",
  },

  userNameIcon: {
    height: 25,
    width: 25,
    fill: "#ffffff",
    paddingRight: "0.5rem",
  },

  languageContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "space-between",
  },

  languageText: {
    paddingLeft: "0.5rem",
  },

  languageIcon: {
    height: 20,
    width: 20,
    paddingLeft: "0.8rem",
  },

  entContent: {
    display: "flex",
    alignItems: "center",
    paddingRight: "3rem",
  },

  entIcon: {
    minHeight: 24,
    minWidth: 24,
    paddingRight: "0.25rem",
  },

  mesCommunicationIcon: {
    height: 25,
    width: 25,
    paddingRight: "0.5rem",
  },

  userButton: {
    borderRadius: "1rem",
  },

  defaultEntContent: {
    display: "flex",
    alignItems: "center",
  },

  defaultEntText: {
    wordWrap: "break-word",
  },
}));

const mapState = (state: RootState) => {
  return {
    openEntNavigation: state.dialogs.openEntNavigationDialog,
    openDefaultAssetDialog: state.dialogs.openDefaultAssetDialog,
    statusMes: state.user.connectionToMes,
    areTablesEditable: state.tables.areTablesEditable,
    areGridsEditable: state.grids.areGridsEditable,
  };
};

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & RouteComponentProps;

const AppBarComponent: React.FC<Props> = ({
  openEntNavigation,
  openDefaultAssetDialog,
  areTablesEditable,
  areGridsEditable,
}) => {
  const screenfull = require("screenfull");
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const breakpoint = useAppSelector(selectBreakpoint);
  const runnedTask = useAppSelector(selectRunnedTask);
  const currentBreakpointId = [
    useMediaQuery(theme.breakpoints.only("xs")),
    useMediaQuery(theme.breakpoints.only("sm")),
    useMediaQuery(theme.breakpoints.only("md")),
    useMediaQuery(theme.breakpoints.only("lg")),
    useMediaQuery(theme.breakpoints.only("xl")),
  ].findIndex((item) => item === true);

  useEffect(() => {
    switch (currentBreakpointId) {
      case 0:
        dispatch(breakpointChanged("xs"));
        break;
      case 1:
        dispatch(breakpointChanged("sm"));
        break;
      case 2:
        dispatch(breakpointChanged("md"));
        break;
      case 3:
        dispatch(breakpointChanged("lg"));
        break;
      case 4:
        dispatch(breakpointChanged("lg"));
        break;
    }
  }, [dispatch, currentBreakpointId]);

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        setIsFullScreen(screenfull.isFullscreen);
      });
    }
  }, [screenfull]);

  const handleLogout = async () => {
    await dispatch(logOutAsync());
    setOpenMenu(false);
  };

  const handleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openMenu);
  React.useEffect(() => {
    prevOpen.current = openMenu;
  }, [openMenu]);

  return (
    <>
      <AppBar position="relative" className={classes.appBar} elevation={2}>
        <div className={classes.appBarContent}>
          <div className={classes.navigationContent}>
            <IconButton
              onClick={() =>
                openEntNavigation
                  ? dispatch(entNavigationDialogClosed())
                  : dispatch(entNavigationDialogOpened())
              }
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon className={classes.menuIcon}></MenuIcon>
            </IconButton>
            <div className={classes.titleText}>
              <div style={{ fontWeight: 700, fontSize: "1.125rem" }}>
                {process.env.REACT_APP_WEBSITE_NAME}
              </div>
              <div style={{ fontWeight: 400 }}>
                {env.REACT_APP_THEME_TYPE === "AVEVA" ? "AVEVA" : "ImProdis"}
              </div>
            </div>
            <div
              style={{
                width: 1,
                backgroundColor: "#FFFFFF",
                margin: "0.5rem 1.5rem 0.5rem 1.5rem",
              }}
            ></div>

            <Breadcrumbs
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ffffff",
                fontSize: "0.875rem",
                fontWeight: "normal",
              }}
            >
              {matchesDesktop && selectedAsset && (
                <div>{selectedAsset.path}</div>
              )}
              {!matchesDesktop && matchesMobile && selectedAsset && (
                <div>{selectedAsset.name}</div>
              )}

              {matchesDesktop && runnedTask && (
                <div>
                  {runnedTask?.workOrderName} {">"} {runnedTask?.taskName}
                </div>
              )}
              {!matchesDesktop &&
                matchesMobile &&
                selectedAsset &&
                runnedTask && <div>{runnedTask?.taskName}</div>}
            </Breadcrumbs>
          </div>

          <div className={classes.infoContent}>
            {areTablesEditable && (
              <Typography
                variant="subtitle2"
                color="error"
                style={{ paddingRight: "1rem", fontWeight: "bolder" }}
              >
                {t("Table editing mode")}
              </Typography>
            )}
            {areGridsEditable && (
              <Typography
                variant="subtitle2"
                color="error"
                style={{ paddingRight: "1rem", fontWeight: "bolder" }}
              >
                {t("Layout editing mode") + ": " + breakpoint}
              </Typography>
            )}

            {isAuthorized && (
              <AppBarUserContentComponent
                onLogout={handleLogout}
                openNavigation={openEntNavigation}
              ></AppBarUserContentComponent>
            )}
            {screenfull.isEnabled && (
              <IconButton
                onClick={() => handleFullScreen()}
                className={classes.menuButton}
                size="large"
              >
                {isFullScreen ? (
                  <FullscreenExitIcon
                    className={classes.menuIcon}
                  ></FullscreenExitIcon>
                ) : (
                  <FullscreenIcon className={classes.menuIcon}></FullscreenIcon>
                )}
              </IconButton>
            )}
          </div>
        </div>
      </AppBar>
      <DefaultAssetDialogComponent
        open={openDefaultAssetDialog}
      ></DefaultAssetDialogComponent>
    </>
  );
};

export default withRouter(connector(AppBarComponent));
