import { Button, Paper, styled, Theme } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import UtilizationIcon from "../../../icons/UtilizationIcon";
import { addUtilEventDialogOpened } from "../../../store/common/dialogs/addUtilEventSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectSelectedAssetUtilizationExec } from "../../../store/main/util-execs/utilExecsSlice";
import { selectSelectedAssetId } from "../../../store/user/assetSelectionSlice";

interface ReasonBarProps {
  barColor: string;
}

const ReasonBar = styled("div")<ReasonBarProps>(({ theme, barColor }) => ({
  width: "0.4rem",
  height: "1.5rem",
  marginRight: "0.3rem",
  backgroundColor: barColor,
}));

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "1.3rem",
    height: "2.5rem",
    minWidth: "fit-content",
    maxWidth: "25rem",
    paddingLeft: "1rem",
    // paddingRight: "1rem",
    marginRight: "1rem",
    wordBreak: "break-word",
  },
  utilStateWrapper: {
    display: "flex",
    alignItems: "center",
  },
  utilDesc: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "12rem",
  },
  utilizationIcon: {
    minWidth: 25,
    minHeight: 25,
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
  },
  utilReasonBar: {
    minWidth: "0.3rem",
    height: "1.5rem",
    backgroundColor: "white",
  },
  addButton: {
    // marginTop: "1rem",
    marginLeft: "1rem",
    marginRight: "0.5rem",
    minWidth: "7rem",
  },
}));

export interface PortableAddNewUtilProps {}

const PortableAddNewUtilComponent: React.FC<PortableAddNewUtilProps> = () => {
  const dispatch = useAppDispatch();
  const selectedAssetId = useAppSelector(selectSelectedAssetId);
  const utilExec = useAppSelector(selectSelectedAssetUtilizationExec);
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.header} elevation={1}>
      <div className={classes.utilStateWrapper}>
        {utilExec?.utilStateColor && (
          <ReasonBar barColor={utilExec?.utilStateColor ?? "transparent"} />
        )}
        <UtilizationIcon className={classes.utilizationIcon}></UtilizationIcon>
        <div className={classes.utilDesc}>
          {utilExec?.utilRawName ? t(utilExec?.utilRawName) : t("Unknown")}
        </div>
      </div>
      <Button
        variant="contained"
        color="success"
        size="small"
        className={classes.addButton}
        onClick={() => dispatch(addUtilEventDialogOpened())}
        disabled={!selectedAssetId}
      >
        {t("Add new")}
      </Button>
    </Paper>
  );
};

export default PortableAddNewUtilComponent;
