import { Packet } from "mqtt";
import { useEffect, useMemo, useState } from "react";
import { env } from "../../env";
import { mqttService } from "../../services/mqtt/mqttInstance";

const useMqttValue = <T extends string>(
  topic: string,
  retain: boolean = true,
  mqttPrefix: boolean = true
) => {
  const [value, setValue] = useState<T>();

  const mqttTopic = useMemo(
    () => (mqttPrefix ? env.REACT_APP_MQTT_PREFIX + "/" + topic : topic),
    [topic, mqttPrefix]
  );

  useEffect(() => {
    mqttService.subscribe(mqttTopic);
    mqttService.client &&
      mqttService.client.on(
        "message",
        (topic: string, payload: Buffer, packet: Packet) => {
          if (topic === mqttTopic) {
            const rawValue = new TextDecoder("utf-8").decode(payload);
            setValue(rawValue as T);
          }
        }
      );
    return () => {
      mqttService.unsubscribe(mqttTopic);
    };
  }, [mqttTopic]);

  const handleSetValue = (value: T) => {
    mqttService.publish(mqttTopic, value, retain);
  };

  return [value, handleSetValue];
};

export default useMqttValue;
