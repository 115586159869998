import { NestedSystemAttributeDTO } from "../../services/httpService";
import { RootState } from "../../store";
import { configurationAttributeName } from "../../store/common/tablesSlice";
import AppAttributeGroups from "../enums/appAttributeGroups";
import MainAttributeGroups from "../enums/mainAttributeGroups";
import SectionAttributeGroups from "../enums/sectionAttributeGroups";
import TableName from "../enums/tableName";

export function getTableConfigurationAttribute(
  tableName: TableName,
  state: RootState
): NestedSystemAttributeDTO | undefined {
  return state.definitions.attributeGroups
    .find((g) => g.name === MainAttributeGroups.USER_INTERFACE)
    ?.systemAttributeGroups?.find(
      (uig) => uig.name === AppAttributeGroups.ImOperator
    )
    ?.systemAttributeGroups?.find(
      (og) =>
        og.name ===
        AppAttributeGroups.ImOperator + SectionAttributeGroups.Tables
    )
    ?.systemAttributes?.find(
      (a) => a.name === configurationAttributeName.get(tableName)
    );
}
