import { Box, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Theme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { makeStyles } from "tss-react/mui";
import EntityIcon from "../../../icons/EntityIcon";
import {
  addUtilEventDialogClosed,
  selectAddUtilEventDialogOpen,
} from "../../../store/common/dialogs/addUtilEventSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  loadAvailableUtilRawsAsync,
  selectAvailableUtilRaws,
  selectAvailableUtilRawsNodeIds,
  selectAvailableUtilRawsPending,
} from "../../../store/main/availableUtilRawsSlice";
import {
  addUtilEventAsync,
  selectUtilEventsPendingAdd,
} from "../../../store/main/utilization-events/utilEventsSlice";
import UtilRawsTreeViewComponent from "./UtilRawsTreeViewComponent";
import { selectSelectedAssetUtilizationExec } from "../../../store/main/util-execs/utilExecsSlice";
import GetAvailableUtilRawsActionType from "../../../helpers/enums/getAvailableUtilRawsActionType";

const useStyles = makeStyles()((theme: Theme) => ({
  commentArea: {
    [theme.breakpoints.down("md")]: {
      width: "15rem",
    },
    width: "25rem",
    maxWidth: "25rem",
    marginLeft: "0.5rem",
    padding: 0,
  },

  reasonContent: {
    [theme.breakpoints.down("md")]: {
      width: "15rem",
    },
    width: "25rem",
    height: "24rem",
    overflowX: "auto",
    overflowY: "auto",
    marginBottom: "1rem",
    border: "1px solid #c4c4c4",
    borderRadius: theme.shape.borderRadius,
  },

  reasonHeader: {
    padding: "0.4rem",
  },

  entIcon: {
    width: 30,
    height: 30,
    marginRight: "0.5rem",
  },
}));

interface DialogAddNewUtilComponentProps {}

const DialogAddNewUtilComponent: React.FC<
  DialogAddNewUtilComponentProps
> = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectAddUtilEventDialogOpen);
  const utilExec = useAppSelector(selectSelectedAssetUtilizationExec);
  const availableUtilRaws = useAppSelector(selectAvailableUtilRaws);
  const initialExpanded = useAppSelector(selectAvailableUtilRawsNodeIds);
  const availableUtilRawsPending = useAppSelector(
    selectAvailableUtilRawsPending
  );
  const pendingAdd = useAppSelector(selectUtilEventsPendingAdd);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const [selectedUtilRawId, setSelectedUtilRawId] = useState<string>();

  const handleAddSelectedUtilEvent = () => {
    if (selectedUtilRawId) {
      dispatch(addUtilEventAsync({ newUtilRawId: selectedUtilRawId, comment }));
      dispatch(addUtilEventDialogClosed());
    }
  };

  useEffect(() => {
    if (open) {
      setSelectedUtilRawId(undefined);
      setComment("");
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      dispatch(
        loadAvailableUtilRawsAsync({
          utilRawId: utilExec?.utilRawId ?? undefined,
          actionType: GetAvailableUtilRawsActionType.Add,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, open]);

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(addUtilEventDialogClosed())}
      maxWidth={false}
    >
      <DialogTitle>
        <EntityIcon className={classes.entIcon} />
        <div>{t("Utilization")}</div>
      </DialogTitle>
      <DialogContent>
        <Box display="flex">
          <Box className={classes.reasonContent} component={SimpleBar}>
            <UtilRawsTreeViewComponent
              nestedUtilRaws={availableUtilRaws}
              initialExpanded={initialExpanded}
              selectedUtilRawId={selectedUtilRawId}
              onUtilRawSelect={(utilRawId) => setSelectedUtilRawId(utilRawId)}
              isLoading={availableUtilRawsPending}
            />
          </Box>
          <TextField
            placeholder={t("Comment")}
            className={classes.commentArea}
            multiline
            minRows={16}
            maxRows={16}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleAddSelectedUtilEvent()}
          disabled={
            pendingAdd ||
            availableUtilRawsPending ||
            selectedUtilRawId === undefined
          }
        >
          {t("Submit")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => dispatch(addUtilEventDialogClosed())}
          disabled={pendingAdd}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAddNewUtilComponent;
