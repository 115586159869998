import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      data-name="Icon / Job"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      {...props}
    >
      <g data-name="CheckBoxChecked / Line / M">
        <path
          data-name="Path 1230"
          d="M15.25 0A2.748 2.748 0 0118 2.75v12.5A2.748 2.748 0 0115.25 18H2.75A2.748 2.748 0 010 15.25V2.75A2.748 2.748 0 012.75 0zm0 1.5H2.75A1.251 1.251 0 001.5 2.75v12.5a1.251 1.251 0 001.25 1.25h12.5a1.251 1.251 0 001.25-1.25V2.75a1.251 1.251 0 00-1.25-1.25zM7 11.44l6.47-6.47a.75.75 0 011.13.98l-.07.08-7 7a.748.748 0 01-.98.07l-.08-.07-3-3a.75.75 0 01.98-1.13l.08.07L7 11.44l6.47-6.47z"
          fill="#212121"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
