import { makeStyles } from "tss-react/mui";
import { Theme, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React, { useEffect, useState } from "react";
import { AlarmOptions } from "../../../store/common/mqttSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: "red",
  },
  typography: {
    display: "flex",
    alignItems: "center",
    "&.MuiTypography-subtitle1": {
      marginRight: "0.5rem",
    },
  },
}));
interface AlarmProps {
  value: number;
  options: AlarmOptions | undefined;
}

const AlarmComponent: React.FC<AlarmProps> = ({ value, options }) => {
  const { classes } = useStyles();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        options?.operator !== undefined &&
        options?.alarmValue !== undefined
      ) {
        options.operator === "ge" &&
          value >= options.alarmValue &&
          setVisible((visible) => !visible);
        options.operator === "le" &&
          value <= options.alarmValue &&
          setVisible((visible) => !visible);
        options.operator === "g" &&
          value > options.alarmValue &&
          setVisible((visible) => !visible);
        options.operator === "l" &&
          value < options.alarmValue &&
          setVisible((visible) => !visible);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [value, options?.alarmValue, options?.operator]);

  return (
    <Typography
      variant="h6"
      gutterBottom
      className={classes.typography}
      style={{
        color: visible ? "red" : "",
        backgroundColor: visible ? "" : "",
      }}
    >
      {visible && (
        <ErrorOutlineIcon className={classes.icon}></ErrorOutlineIcon>
      )}
      {value}
    </Typography>
  );
};

export default AlarmComponent;
