import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import { Paper } from "@mui/material";
import { Theme } from "@mui/material/styles";
import moment from "moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { useAppSelector } from "../../../../../store/hooks";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  chartHeader: {
    fontSize: "1.8rem",
    textAlign: "center",
  },
  chart: {
    flexGrow: 1,
    padding: "0.2rem",
    fontWeight: 500,
  },
  chartText: {
    display: "flex",
    justifyContent: "space-around",
    fontSize: "1.3rem",
    fontWeight: 500,
    padding: "0.2rem",
  },
}));

interface DeadlineSummaryProps {
  chartStyle: string;
}

const DeadlineSummaryComponent: React.FC<DeadlineSummaryProps> = ({
  chartStyle,
}) => {
  const runnedTask = useAppSelector(selectRunnedTask);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const FormatDuration = useCallback(() => {
    if (runnedTask?.scheduledFinishAt) {
      const diff =
        Math.abs(
          runnedTask.scheduledFinishAt.getTime() - new Date().getTime()
        ) / 1000;
      const days = Math.floor(diff / 86400);
      const hours = Math.floor((diff - days * 86400) / 3600);
      const minutes = Math.floor((diff - days * 86400 - hours * 3600) / 60);
      const seconds = Math.floor(
        diff - days * 86400 - hours * 3600 - minutes * 60
      );
      return (
        <span>
          {days}
          {t("d")} {hours}
          {t("h")} {minutes}
          {t("m")} {seconds}
          {t("s")}
        </span>
      );
    }
  }, [runnedTask, t]);

  return (
    <Paper className={chartStyle} elevation={2} square>
      <div className={classes.chartHeader}>{t("Deadline")}</div>
      <div className={classes.chart}>
        <div className={classes.chart}>
          <div style={{ fontWeight: 700 }}>{t("Scheduled start")}</div>
          <div>
            {moment(runnedTask?.scheduledStartAt).format(
              t("DateWithTimeWithoutSecondsFormat")
            )}
          </div>
        </div>
        <div className={classes.chart}>
          <div style={{ fontWeight: 700 }}>{t("Actual start")}</div>
          <div>
            {moment(runnedTask?.executionStartedAt).format(
              t("DateWithTimeWithoutSecondsFormat")
            )}
          </div>
        </div>
        <div className={classes.chart}>
          <div style={{ fontWeight: 700 }}> {t("Scheduled finish")}</div>
          <div>
            {moment(runnedTask?.scheduledFinishAt).format(
              t("DateWithTimeWithoutSecondsFormat")
            )}
          </div>
        </div>
        {/* <div className={classes.chart}>
            <div style={{ fontWeight: 700 }}> {t("Actual Finish")}</div>
            <div>
              {moment(runnedJob?.actFinishTimeUtc).format(
                t("DateWithTimeWithoutSecondsFormat")
              )}
            </div>
          </div> */}
        <h3 style={{ marginBottom: 0, marginTop: "0.2rem" }}>
          {runnedTask?.scheduledFinishAt &&
          runnedTask.scheduledFinishAt >= new Date() ? (
            <div>{t("Remained")}:</div>
          ) : (
            <div>{t("Exceeded")}:</div>
          )}
        </h3>
      </div>

      <div className={classes.chart}>
        <h3 style={{ marginBottom: 0, marginTop: "0.2rem" }}>
          {FormatDuration()}
        </h3>
      </div>
      <div className={classes.chartText}>
        {runnedTask?.scheduledFinishAt &&
        runnedTask.scheduledFinishAt >= new Date() ? (
          <AlarmOnIcon
            color="success"
            style={{ width: 40, height: 40 }}
          ></AlarmOnIcon>
        ) : (
          <AlarmOffIcon
            color="error"
            style={{ width: 40, height: 40 }}
          ></AlarmOffIcon>
        )}
      </div>
    </Paper>
  );
};

export default DeadlineSummaryComponent;
