// import { RunnedTask } from "../store/views/productionSlice";

import { RunnedTask } from "../store/main/job-execution/jobExecutionSlice";

export const realProductionRate = (
  runnedJob: RunnedTask | undefined,
  runtimeOfRunnedJob: number
) => {
  return 0;
  // if (!runnedJob) return 0;
  // const allProd = runnedJob.quantityProduced + runnedJob.quantityRejected;
  // if (allProd === 0) return 0;
  // switch (runnedJob?.prodUom) {
  //   case 0: {
  //     return runtimeOfRunnedJob / 3600 / allProd;
  //   }
  //   case 1: {
  //     return runtimeOfRunnedJob / 60 / allProd;
  //   }
  //   case 2: {
  //     return runtimeOfRunnedJob / allProd;
  //   }
  //   case 3: {
  //     return allProd / (runtimeOfRunnedJob / 3600);
  //   }
  //   case 4: {
  //     return allProd / (runtimeOfRunnedJob / 60);
  //   }
  //   case 5: {
  //     return allProd / runtimeOfRunnedJob;
  //   }
  //   default: {
  // return 0;
  //   }
  // }
};

export const estProductionRate = (runnedJob: RunnedTask | undefined) => {
  return 0;
  // if (!runnedJob) return 0;
  // if (runnedJob?.prodUom >= 0 && runnedJob?.prodUom < 3) {
  //   return runnedJob?.estProdRate / runnedJob?.batchSize;
  // } else {
  //   return runnedJob?.estProdRate * runnedJob?.batchSize;
  // }
};

export const remainingTimeForWork = (
  runnedJob: RunnedTask | undefined,
  runtimeOfRunnedJob: number
) => {
  if (
    runnedJob &&
    runnedJob.scheduledFinishAt !== null &&
    runnedJob.scheduledStartAt !== null
  ) {
    const availableTime =
      (runnedJob.scheduledFinishAt.getTime() -
        runnedJob.scheduledStartAt.getTime()) /
      1000;
    return availableTime - runtimeOfRunnedJob;
  } else {
    return 0;
  }
};
