import { Row } from "react-table";

export const sortDateTime = (
  rowA: Row<any>,
  rowB: Row<any>,
  id: string,
  desc: boolean | undefined
) => {
  const value1 = rowA.original[id]
    ? (rowA.original[id] as Date)
    : new Date(1900, 1, 1, 0, 0, 0);
  const value2 = rowB.original[id]
    ? (rowB.original[id] as Date)
    : new Date(1900, 1, 1, 0, 0, 0);
  if (value1?.getTime() > value2?.getTime()) return -1;
  if (value2?.getTime() > value1?.getTime()) return 1;
  return 0;
};
