export enum LocalStorageKey {
  DEFAULT_ASSET_ID = "default-asset-id",
  DEFAULT_ASSET_SOURCESTACK = "default-asset-sourcestack",
  PRODUCTION_VIEW_TYPE = "production-view-type",
  PRODUCTION_LAYOUTS = "production-layouts",
  PRODUCTION_ITEMS = "production-items",
  OVERVIEW_LAYOUTS = "overview-layouts",
  OVERVIEW_ITEMS = "overview-items",
  UTIL_HISTORY_TABLE_CONFIGURATION = "util-history-table-configuration",
  WORK_QUEUE_TABLE_CONFIGURATION = "work-queue-table-configuration",
  WORK_QUEUE_TREE_TABLE_CONFIGURATION = "work-queue-tree-table-configuration",
  PRODUCTION_HISTORY_TABLE_CONFIGURATION = "production-history-table-configuration",
}
