import { Box, Divider, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Theme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { makeStyles } from "tss-react/mui";
import GetAvailableUtilRawsActionType from "../../../helpers/enums/getAvailableUtilRawsActionType";
import { FormatDateString } from "../../../helpers/tables/date/FormatDate";
import { FormatUtilRaw } from "../../../helpers/tables/util-raw/FormatUtilRaw";
import EntityIcon from "../../../icons/EntityIcon";
import {
  selectUpdateUtilEventDialogOpen,
  updateUtilEventDialogClosed,
} from "../../../store/common/dialogs/updateUtilEventSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  loadAvailableUtilRawsAsync,
  selectAvailableUtilRaws,
  selectAvailableUtilRawsNodeIds,
  selectAvailableUtilRawsPending,
} from "../../../store/main/availableUtilRawsSlice";
import {
  correctUtilEventAsync,
  selectSelectedUtilEvent,
  selectUtilEventsPendingCorrect,
} from "../../../store/main/utilization-events/utilEventsSlice";
import Duration from "../../common/Duration";
import UtilRawsTreeViewComponent from "./UtilRawsTreeViewComponent";

const useStyles = makeStyles()((theme: Theme) => ({
  commentArea: {
    [theme.breakpoints.down("md")]: {
      width: "15rem",
    },
    width: "25rem",
    maxWidth: "25rem",
    padding: 0,
    marginLeft: "0.5rem",
  },

  reasonContent: {
    [theme.breakpoints.down("md")]: {
      width: "15rem",
    },
    height: "24rem",
    overflowX: "auto",
    overflowY: "auto",
    marginBottom: "0rem",
    width: "25rem",
    border: "1px solid #c4c4c4",
    borderRadius: theme.shape.borderRadius,
  },

  entIcon: {
    width: 30,
    height: 30,
    marginRight: "0.5rem",
  },

  utilLogWrapper: {
    fontSize: "large",
    marginLeft: "1rem",
    marginRight: "1rem",
    display: "flex",
    padding: "0.5rem",
    width: "calc(100% - 2rem)",
    justifyContent: "space-around",
    alignItems: "center",
    boxSizing: "border-box",
  },
}));

interface DialogUpdateUtilComponentProps {}

const DialogUpdateUtilComponent: React.FC<
  DialogUpdateUtilComponentProps
> = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectUpdateUtilEventDialogOpen);
  const selectedUtilEvent = useAppSelector(selectSelectedUtilEvent);
  const availableUtilRaws = useAppSelector(selectAvailableUtilRaws);
  const initialExpanded = useAppSelector(selectAvailableUtilRawsNodeIds);
  const availableUtilRawsPending = useAppSelector(
    selectAvailableUtilRawsPending
  );
  const pendingCorrect = useAppSelector(selectUtilEventsPendingCorrect);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [comment, setComment] = useState<string>("");
  const [selectedUtilRawId, setSelectedUtilRawId] = useState<string>();

  const handleCorrectSelectedUtilEvent = async () => {
    if (selectedUtilRawId) {
      dispatch(
        correctUtilEventAsync({ newUtilRawId: selectedUtilRawId, comment })
      );
      dispatch(updateUtilEventDialogClosed());
    }
  };

  useEffect(() => {
    setSelectedUtilRawId(undefined);
    setComment(selectedUtilEvent?.comment ?? "");
  }, [selectedUtilEvent?.logId, selectedUtilEvent?.comment]);

  useEffect(() => {
    if (open) {
      dispatch(
        loadAvailableUtilRawsAsync({
          utilRawId: selectedUtilEvent?.utilRawId,
          actionType: GetAvailableUtilRawsActionType.Correct,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, open]);

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(updateUtilEventDialogClosed())}
      maxWidth={false}
    >
      <DialogTitle>
        <EntityIcon className={classes.entIcon}></EntityIcon>
        <div>{t("Update of the reason")}</div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.utilLogWrapper}>
          <div style={{ fontSize: "smaller" }}>{t("Edited reason")}: </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "25rem",
              whiteSpace: "nowrap",
            }}
          >
            {FormatUtilRaw(
              selectedUtilEvent?.utilRawName!,
              selectedUtilEvent?.utilStateColor ?? null,
              t
            )}
          </div>
          <Divider flexItem orientation="vertical" />
          <div>
            {FormatDateString(selectedUtilEvent?.startedAt, t)} -{" "}
            {FormatDateString(selectedUtilEvent?.finishedAt, t)}
          </div>
          <Divider flexItem orientation="vertical" />
          <div>
            {selectedUtilEvent && (
              <Duration
                startDate={selectedUtilEvent.startedAt}
                endDate={selectedUtilEvent.finishedAt}
                typographyVariant="inherit"
              />
            )}
          </div>
        </div>
        <Box display="flex">
          <Box className={classes.reasonContent} component={SimpleBar}>
            <UtilRawsTreeViewComponent
              nestedUtilRaws={availableUtilRaws}
              initialExpanded={initialExpanded}
              selectedUtilRawId={selectedUtilRawId}
              onUtilRawSelect={(utilRawId) => setSelectedUtilRawId(utilRawId)}
              isLoading={availableUtilRawsPending}
            />
          </Box>
          <TextField
            placeholder={t("Comment")}
            className={classes.commentArea}
            multiline
            minRows={16}
            maxRows={16}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleCorrectSelectedUtilEvent()}
          disabled={
            pendingCorrect ||
            availableUtilRawsPending ||
            selectedUtilRawId === undefined
          }
        >
          {t("Submit")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => dispatch(updateUtilEventDialogClosed())}
          disabled={pendingCorrect}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUpdateUtilComponent;
