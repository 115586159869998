import {
  Action,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { getPersistConfig } from "redux-deep-persist";
import {
  persistCombineReducers,
  PersistConfig,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { deleteFromLocalStorage } from "../services/localStorageService";
import dialogsReducer from "./common/dialogs/index";
import gridsReducer from "./common/gridsSlice";
import mqttReducer from "./common/mqttSlice";
import tablesReducer from "./common/tablesSlice";
import definitionsReducer from "./definitions/index";
import authMiddleware from "./middlewares/authMiddleware";
import mqttMiddleware from "./middlewares/mqttMiddleware";
import signalRMiddleware from "./middlewares/signalRMiddleware";
import socketsReducer from "./sockets/index";
import { logOutAsync } from "./user/authSlice";
import userReducer, { UserState } from "./user/index";
import mainReducer from "./main";
import listenersMiddleware from "./middlewares/listenersMiddleware";
import customGridComponentsReducer from "./common/customGridComponentsSlice";

export enum PersistConfigKey {
  Root = "root",
  User = "user",
}

const userPersistConfig: PersistConfig<UserState> = getPersistConfig({
  key: PersistConfigKey.User,
  storage: storage,
  blacklist: ["auth.pendingLogIn", "auth.pendingLogOut"],
  rootReducer: userReducer,
});

const appReducer = persistCombineReducers(
  {
    key: PersistConfigKey.Root,
    storage: storage,
    whitelist: [],
  },
  {
    definitions: definitionsReducer,
    sockets: socketsReducer,
    user: persistReducer(userPersistConfig, userReducer),
    dialogs: dialogsReducer,
    mqtt: mqttReducer,
    main: mainReducer,
    grids: gridsReducer,
    customGridComponents: customGridComponentsReducer,
    tables: tablesReducer,
  }
);

const rootReducer = (state: any, action: any) => {
  if (
    action.type === logOutAsync.fulfilled.type ||
    action.type === logOutAsync.rejected.type
  ) {
    Object.keys(PersistConfigKey).map((key) =>
      deleteFromLocalStorage(`persist:${PersistConfigKey[key]}`)
    );
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, //FIXME shouldn't be like that :(
    })
      .prepend(listenersMiddleware)
      // prepend and concat calls can be chained
      // .concat(logger)
      .concat(authMiddleware)
      .concat(signalRMiddleware)
      .concat(mqttMiddleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunkDispatch = ThunkDispatch<
  RootState,
  unknown,
  Action<string>
>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AsyncValue<R> = {
  value: R;
  pending: boolean;
};
