import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
} from "@microsoft/signalr";
import { AnyAction, Dispatch } from "redux";
import { env } from "../../../../env";
import {
  assetCreatedSocketEvent,
  assetStateChangedSocketEvent,
  assetStateCorrectedSocketEvent,
  assetsConnectionStatusChanged,
} from "../../../../store/sockets/utilization/assetsSlice";
import { selectIsAuthorized } from "../../../../store/user/authSlice";
import convertStringDates from "../../dateParser";
import RetryPolicy from "../../retryPolicy";

export interface AssetCreatedSocketDto {
  assetId: string;
}

export interface AssetStateChangedSocketDto {
  assetId: string;
  startedAt: Date;
  finishedAt: Date | null;
  utilLogId: string;
}

export interface AssetStateCorrectedSocketDto {
  assetId: string;
  oldUtilLogId: string;
  newUtilLogId: string;
}

interface IAssetsAllService {
  init(): void;
  connect(dispatch: Dispatch<AnyAction>, getState: any): void;
  disconnect(): void;
  subscribe(dispatch: Dispatch<AnyAction>, getState: any): void;
  unsubscribe(): void;
  joinGroup(dispatch: Dispatch<AnyAction>): void;
}

const assetsHubUrl = `${env.REACT_APP_MES_API_URL}/utilization/assets`;

const retryPolicy = new RetryPolicy();

const protocol = new JsonHubProtocol();

const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options = {
  transport,
};

export class AssetsAllService implements IAssetsAllService {
  public connectionAssetsHub: HubConnection | undefined;

  constructor() {
    this.init();
  }

  init() {
    this.connectionAssetsHub = new HubConnectionBuilder()
      .withUrl(assetsHubUrl, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect(retryPolicy)
      .build();
  }

  async start(dispatch: Dispatch<AnyAction>) {
    try {
      await this.connectionAssetsHub?.start().then(async (a) => {
        dispatch(assetsConnectionStatusChanged(HubConnectionState.Connected));
        this.joinGroup(dispatch);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async connect(dispatch: Dispatch<AnyAction>, getState: any) {
    if (this.connectionAssetsHub?.state !== HubConnectionState.Disconnected) {
      this.unsubscribe();
      this.init();
      this.subscribe(dispatch, getState());
    }

    await this.start(dispatch);

    this.connectionAssetsHub?.onreconnecting((error) => {
      console.error(error);
      dispatch(assetsConnectionStatusChanged(HubConnectionState.Reconnecting));
    });

    this.connectionAssetsHub?.onreconnected((connectionId) => {
      this.joinGroup(dispatch);
      dispatch(assetsConnectionStatusChanged(HubConnectionState.Connected));
    });

    this.connectionAssetsHub?.onclose(async (error) => {
      error && console.error(error);
      const isAuthorized = selectIsAuthorized(getState());
      if (isAuthorized) {
        //STILL LOGGED IN
        await this.start(dispatch);
      } else {
        dispatch(
          assetsConnectionStatusChanged(HubConnectionState.Disconnected)
        );
      }
    });
  }

  disconnect() {
    this.unsubscribe();
    this.connectionAssetsHub?.stop();
  }

  subscribe(dispatch: Dispatch<AnyAction>, getState: any) {
    this.connectionAssetsHub?.on("asset-created", (message) => {
      if (message !== null) {
        dispatch(assetCreatedSocketEvent(message));
      }
    });
    this.connectionAssetsHub?.on("asset-state-changed", (message) => {
      if (message !== null) {
        convertStringDates(message, ["startedAt", "finishedAt"]);
        dispatch(assetStateChangedSocketEvent(message));
      }
    });
    this.connectionAssetsHub?.on("asset-state-corrected", (message) => {
      if (message !== null) {
        dispatch(assetStateCorrectedSocketEvent(message));
      }
    });
  }

  async joinGroup(dispatch: Dispatch<AnyAction>): Promise<void> {
    if (this.connectionAssetsHub?.state === HubConnectionState.Connected) {
      this.connectionAssetsHub?.invoke("JoinAllGroup");
    }
  }

  unsubscribe = () => {
    this.connectionAssetsHub?.off("asset-created");
    this.connectionAssetsHub?.off("asset-state-changed");
  };
}
