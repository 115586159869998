import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import React from "react";
import { env } from "../../env";
import AppBarLoginLanguageComponent from "./AppBarLoginLanguageComponent";

const useStyles = makeStyles()((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "2rem",
    fontWeight: 400,
    height: "3.125rem",
  },

  appBarContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0.1rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },

  titleText: {
    fontSize: "1rem",
    textAlign: "left",
  },

  primaryLabel: {
    fontSize: "1.125rem",
    fontWeight: "bold",
  },

  secondaryLabel: {
    fontSize: "1rem",
  },
}));

interface Props {}

const AppBarLoginComponent: React.FC<Props> = () => {
  const { classes } = useStyles();

  return (
    <div>
      <AppBar position="relative" elevation={1} className={classes.appBar}>
        <div className={classes.appBarContent}>
          <Box display="flex">
            <div className={classes.titleText}>
              <div className={classes.primaryLabel}>ImOperator</div>
              <div className={classes.secondaryLabel}>
                {env.REACT_APP_THEME_TYPE === "AVEVA" ? "AVEVA" : "ImProdis"}
              </div>
            </div>
            <Box
              sx={{
                width: 1,
                backgroundColor: "primary.contrastText",
                margin: "0.5rem 1rem",
              }}
            />
          </Box>
          <Box>
            <AppBarLoginLanguageComponent />
          </Box>
        </div>
      </AppBar>
    </div>
  );
};

export default AppBarLoginComponent;
