import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { CircularProgress, Fade, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NestedAvailableUtilRaw } from "../../../store/main/availableUtilRawsSlice";

const TreeLabel = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 0),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "inherit",
  fontSize: "1rem",
  flexGrow: 1,
}));

interface ReasonBarProps {
  barColor: string;
}

const ReasonBar = styled("div")<ReasonBarProps>(({ theme, barColor }) => ({
  width: "0.4rem",
  height: "1.5rem",
  marginRight: "0.3rem",
  backgroundColor: barColor,
}));

const Loading = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  margin: "auto",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
}));

type ITreeItem = NestedAvailableUtilRaw;

interface UtilRawsTreeViewProps {
  nestedUtilRaws: Array<ITreeItem>;
  initialExpanded: Array<string>;
  onUtilRawSelect: (utilRawId: string | undefined) => void;
  selectedUtilRawId: string | undefined;
  isLoading?: boolean;
}

const UtilRawsTreeViewComponent: React.FC<UtilRawsTreeViewProps> = ({
  nestedUtilRaws,
  initialExpanded,
  onUtilRawSelect,
  selectedUtilRawId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    setExpanded(initialExpanded);
  }, [initialExpanded]);

  const handleUtilRawSelect = (utilRawId: string | undefined) =>
    onUtilRawSelect(utilRawId);

  const renderTree = (nodes: Array<ITreeItem>) =>
    nodes.map((node) => {
      return (
        <TreeItem
          key={node.id}
          nodeId={node.id}
          label={
            <TreeLabel>
              <StyledTypography variant="body2">
                {t(node.name!)}
              </StyledTypography>
            </TreeLabel>
          }
        >
          {Array.isArray(node.utilRaws) && node.utilRaws.length > 0
            ? node.utilRaws.map((utilRaw) => (
                <TreeItem
                  key={utilRaw.id}
                  nodeId={utilRaw.id}
                  // onLabelClick={(event) =>
                  //   Array.isArray(o.children) ? null : event.preventDefault()
                  // }
                  label={
                    <TreeLabel>
                      <ReasonBar
                        barColor={utilRaw.utilStateColor ?? "transparent"}
                      />
                      <StyledTypography variant="body2">
                        {t(utilRaw.name!)}
                      </StyledTypography>
                    </TreeLabel>
                  }
                ></TreeItem>
              ))
            : null}
          {Array.isArray(node.childrenGroups) && node.childrenGroups.length > 0
            ? node.childrenGroups.map((node) => renderTree([node]))
            : null}
        </TreeItem>
      );
    });

  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodes: string[]) => {
    setExpanded(nodes);
  };

  const handleNodeSelect = async (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    // if (utilReasons.some((r) => r.reasCd === Number(value))) {
    handleUtilRawSelect(value);
    // } else {
    // event.preventDefault();
    // }
  };

  return (
    <>
      <Fade in={isLoading} unmountOnExit>
        <Loading size="5rem" />
      </Fade>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selectedUtilRawId ?? ""}
        onNodeToggle={handleNodeToggle}
        onNodeSelect={handleNodeSelect}
        // isLoading={isLoading}
      >
        {renderTree(nestedUtilRaws)}
      </TreeView>
    </>
  );
};
export default UtilRawsTreeViewComponent;
