import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

export const initialState: boolean = false;

export const updateUtilEventSlice = createSlice({
  name: "updateUtilEventDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      return true;
    },
    closed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const {
  opened: updateUtilEventDialogOpened,
  closed: updateUtilEventDialogClosed,
} = updateUtilEventSlice.actions;

export const selectUpdateUtilEventDialogOpen = createSelector(
  (state: RootState) => state.dialogs.openUpdateUtilEventDialog,
  (openUpdateUtilEventDialog) => openUpdateUtilEventDialog
);

export default updateUtilEventSlice.reducer;
