import { EditIcon, SaveIcon } from "@improdis/core";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  IconButton,
  Theme,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import _ from "lodash";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import GridLayout, { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import GridName from "../../../helpers/enums/gridName";
import {
  customComponentAdded,
  newCustomComponentsDeleted,
  saveCustomComponentAsync,
} from "../../../store/common/customGridComponentsSlice";
import { editableComponentDialogOpened } from "../../../store/common/dialogs/editableComponentSlice";
import {
  breakpointChanged,
  draggedItemCleared,
  editableItemAdded,
  itemAdded,
  itemRemoved,
  layoutsRefreshed,
  layoutsUpdated,
  selectAreGridsEditable,
  selectBreakpoint,
  selectGridItems,
  selectGridLayouts,
  selectRefreshGrid,
} from "../../../store/common/gridsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ErrorFallback, { ErrorLevel } from "../error-fallbacks/ErrorFallback";

const HiddenButton = styled(IconButton)(({ theme }) => ({
  transition: "opacity 1s ",
  opacity: 0.2,
  "&:hover": {
    opacity: "1",
  },
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderColor: theme.palette.error.main,
    borderWidth: "2px",
  },
}));

const useStyles = makeStyles()((theme: Theme) => ({
  gridLayout: {
    minHeight: "100% !important",
    overflow: "hidden",
  },
  removeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 300,
    transform: "rotate(45deg)",
  },
  editButtons: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 200,
    transform: "translate(-50%, -50%)",
  },
}));

const ResponsiveGridLayout = WidthProvider(Responsive);

export interface GridElement {
  key: string;
  element: React.ReactElement;
  attrName?: string;
  title?: string;
  code?: string;
  isNew?: boolean;
  isEditable?: boolean;
  id: string;
}

interface GridWrapperComponentProps {
  gridName: GridName;
  childrenList: Array<GridElement>;
  defaultNewUniversalComponent?: string;
}

const GridWrapperComponent: FunctionComponent<GridWrapperComponentProps> = ({
  gridName,
  childrenList,
  defaultNewUniversalComponent,
}) => {
  const dispatch = useAppDispatch();
  const droppingItem = useAppSelector((state) => state.grids.lastDraggedItem);
  const areGridsEditable = useAppSelector(selectAreGridsEditable);
  const refreshGrid = useAppSelector(selectRefreshGrid);
  const breakpoint = useAppSelector(selectBreakpoint);
  const items = useAppSelector(selectGridItems(gridName));
  const gridLayouts = useAppSelector(selectGridLayouts(gridName));
  const { classes } = useStyles();
  const { t } = useTranslation();
  useEffect(() => {
    if (refreshGrid) {
      dispatch(layoutsRefreshed(false));
    }
  }, [dispatch, refreshGrid]);

  const handleRemoveItem = useCallback(
    (key: string, isNew?: boolean) => {
      isNew && dispatch(newCustomComponentsDeleted());
      dispatch(itemRemoved({ gridName, itemName: key }));
      let newAllLayouts = _.cloneDeep(gridLayouts);
      newAllLayouts[breakpoint] = newAllLayouts[breakpoint].filter((l) =>
        items.filter((i) => i !== key).some((i) => i === l.i)
      );
      dispatch(
        layoutsUpdated({
          gridName,
          layouts: newAllLayouts,
        })
      );
    },
    [dispatch, gridName, breakpoint, gridLayouts, items]
  );

  const handleLayoutChange = useCallback(
    (currentLayout: GridLayout.Layout[], allLayouts: GridLayout.Layouts) => {
      if (!droppingItem) {
        let newAllLayouts = _.cloneDeep(allLayouts);
        newAllLayouts[breakpoint] = currentLayout.map(
          (l) =>
            ({
              i: l.i,
              x: l.x,
              y: l.y,
              w: l.w,
              h: l.h,
              minW: 2,
              minH: 4,
            } as GridLayout.Layout)
        );
        dispatch(
          layoutsUpdated({
            gridName,
            layouts: _.cloneDeep(allLayouts),
          })
        );
      }
    },
    [dispatch, gridName, breakpoint, droppingItem]
  );

  const handleBreakpointChange = (newBreakpoint: string, newCols: number) => {
    dispatch(breakpointChanged(newBreakpoint));
  };

  const breakpoints = useMemo(
    () => ({ lg: 1280, md: 960, sm: 600, xs: 0 }),
    []
  );

  const getGridItem = useCallback(
    (
      key: string,
      component: React.ReactNode,
      attrName?: string,
      code?: string,
      title?: string,
      isEditable?: boolean,
      isNew?: boolean
    ) => {
      return (
        <Box
          style={{
            transition: !areGridsEditable ? "none" : undefined,
          }}
          key={key}
        >
          {areGridsEditable && (
            <IconButton
              color="error"
              className={classes.removeButton}
              onClick={() => handleRemoveItem(key, isNew)}
              size="small"
            >
              <AddIcon />
            </IconButton>
          )}
          {areGridsEditable && isEditable && (
            <div className={classes.editButtons}>
              <HiddenButton
                color="success"
                onClick={() =>
                  dispatch(
                    editableComponentDialogOpened({
                      gridName: gridName,
                      componentKey: key,
                      code: code || defaultNewUniversalComponent || "",
                      title: title || "",
                      imFactoryAttrName: attrName,
                      isNew: isNew,
                    })
                  )
                }
                size="medium"
              >
                <EditIcon fontSize="large" />
              </HiddenButton>
              <StyledTooltip
                title={
                  key.toLowerCase() === "editable_component" ||
                  attrName?.toLowerCase() === "editable_component"
                    ? t(
                        "Can not save component - key and attribute name not changed."
                      )
                    : null
                }
              >
                <span>
                  <HiddenButton
                    color="success"
                    onClick={() =>
                      attrName &&
                      dispatch(saveCustomComponentAsync({ attrName, isNew }))
                    }
                    size="medium"
                    disabled={
                      key.toLowerCase() === "editable_component" ||
                      attrName?.toLowerCase() === "editable_component"
                    }
                  >
                    <SaveIcon fontSize="large" />
                  </HiddenButton>
                </span>
              </StyledTooltip>
            </div>
          )}
          <ErrorBoundary
            FallbackComponent={(props) => (
              <ErrorFallback {...props} level={ErrorLevel.Component} />
            )}
          >
            {component}
          </ErrorBoundary>
        </Box>
      );
    },
    [
      t,
      dispatch,
      classes.removeButton,
      classes.editButtons,
      gridName,
      defaultNewUniversalComponent,
      handleRemoveItem,
      areGridsEditable,
    ]
  );

  const handleDrop = useCallback(
    (layout: GridLayout.Layout[], item: GridLayout.Layout, e: Event) => {
      e.preventDefault(); //FIX FOR FIREFOX
      let newAllLayouts = _.cloneDeep(gridLayouts);
      newAllLayouts[breakpoint] = layout.map(
        (l) =>
          ({
            i: l.i,
            x: l.x,
            y: l.y,
            w: l.w,
            h: l.h,
            minW: 2,
            minH: 4,
          } as GridLayout.Layout)
      );
      dispatch(layoutsUpdated({ gridName, layouts: newAllLayouts }));
      if (item.i.toLowerCase() !== "editable_component") {
        dispatch(itemAdded({ gridName, itemName: item.i }));
      } else {
        dispatch(
          customComponentAdded({
            componentAttrId: null,
            componentAttrName: "editable_component",
            gridName,
            code: defaultNewUniversalComponent || "",
            key: item.i,
            title: "EditableComponentTitle",
            isNew: true,
          })
        );
        dispatch(
          editableItemAdded({
            gridName,
            key: item.i,
          })
        );
      }
      dispatch(draggedItemCleared());
    },
    [dispatch, defaultNewUniversalComponent, gridLayouts, gridName, breakpoint]
  );

  const children = useMemo(() => {
    const elementsList = childrenList.filter((c) =>
      items.some((i) => i === c.key)
    );
    return elementsList.map((c) =>
      getGridItem(
        c.key,
        c.element,
        c.attrName,
        c.code,
        c.title,
        c.isEditable,
        c.isNew
      )
    );
  }, [getGridItem, items, childrenList]);

  return (
    <>
      {!refreshGrid && (
        <ResponsiveGridLayout
          className={classes.gridLayout}
          layouts={gridLayouts}
          margin={[8, 8]}
          containerPadding={[1.6, 8]}
          breakpoints={breakpoints}
          onLayoutChange={handleLayoutChange}
          onBreakpointChange={handleBreakpointChange}
          onDrop={handleDrop}
          droppingItem={droppingItem}
          cols={{ lg: 16, md: 12, sm: 8, xs: 6 }}
          rowHeight={30}
          isDraggable={areGridsEditable}
          isResizable={areGridsEditable}
          isDroppable={areGridsEditable}
          measureBeforeMount
          useCSSTransforms
        >
          {children}
        </ResponsiveGridLayout>
      )}
    </>
  );
};

export default GridWrapperComponent;
