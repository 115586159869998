import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { env } from "../../../env";
import TopicTypes from "../../../helpers/enums/topicTypes";
import {
  AlarmOptions,
  GaugeOptions,
  MqttTopicValue,
  ProgressOptions,
  TopicDefinition,
} from "../../../store/common/mqttSlice";
import AlarmComponent from "./AlarmComponent";
import GaugeComponent from "./GaugeComponent";
import ProgressBarComponent from "./ProgressBarComponent";

const useStyles = makeStyles()((theme: Theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "3rem",
  },
  typography: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    "&.MuiTypography-subtitle1": {
      marginRight: "0.5rem",
    },
  },
  reasonBar: {
    width: "0.3rem",
    height: "1.5rem",
    margin: "0.0rem 0.3rem 0.0rem 0rem",
  },
  icon: {
    marginRight: "0.3rem",
    width: 20,
    height: 20,
  },
}));

interface ParameterProps {
  topicDefinition: TopicDefinition | undefined;
  topic: MqttTopicValue | undefined;
  entityParameters: Array<MqttTopicValue> | undefined;
}

const ParameterComponent: React.FC<ParameterProps> = ({
  topicDefinition,
  topic,
  entityParameters,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {topicDefinition?.type !== TopicTypes.HIDDEN && (
        <div className={classes.row}>
          <Box display="flex" alignItems="center">
            {topicDefinition?.icon && (
              <img
                className={classes.icon}
                src={`${env.REACT_APP_ICONS_URL}/${topicDefinition?.icon}`}
                alt=""
              />
            )}
            <Typography variant="subtitle1">
              {t(topicDefinition?.name || "")}:
            </Typography>
          </Box>
          {topicDefinition?.errors !== undefined ? (
            <Tooltip
              title={t("Validation error") + ": " + topicDefinition.errors}
              arrow
            >
              <WarningIcon color="error" fontSize="large"></WarningIcon>
            </Tooltip>
          ) : (
            <>
              {topic?.value !== undefined ? (
                <>
                  {topicDefinition?.type === TopicTypes.TEXT && (
                    <Typography
                      variant="h6"
                      gutterBottom
                      align="right"
                      className={classes.typography}
                    >
                      {topic?.value}
                    </Typography>
                  )}
                  {topicDefinition?.type === TopicTypes.GAUGE &&
                    topicDefinition?.options &&
                    (topicDefinition?.options as GaugeOptions).max !==
                      undefined && (
                      <GaugeComponent
                        value={Number(topic?.value)}
                        options={topicDefinition.options as GaugeOptions}
                        entityParameters={entityParameters}
                      ></GaugeComponent>
                    )}
                  {topicDefinition?.type === TopicTypes.PROGRESS &&
                    topicDefinition?.options &&
                    (topicDefinition?.options as ProgressOptions).max !==
                      undefined && (
                      <ProgressBarComponent
                        value={Number(topic?.value)}
                        options={topicDefinition.options as ProgressOptions}
                        entityParameters={entityParameters}
                      ></ProgressBarComponent>
                    )}
                  {topicDefinition?.type === TopicTypes.ALARM &&
                    topicDefinition?.options &&
                    (topicDefinition?.options as AlarmOptions).alarmValue !==
                      undefined && (
                      <AlarmComponent
                        value={Number(topic?.value)}
                        options={topicDefinition.options as AlarmOptions}
                      ></AlarmComponent>
                    )}
                </>
              ) : (
                <Tooltip title={t("NoDataMQTT").toString()} arrow>
                  <ContactSupportIcon color="warning" fontSize="large" />
                </Tooltip>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ParameterComponent;
