import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  logIn,
  logOut,
  refreshToken,
} from "../../services/main/authenticationService";
import { ConnectionType } from "../../helpers/enums/connectionType";

interface AuthState {
  details: UserInfo;
  pendingLogIn: boolean;
  pendingLogOut: boolean;
  pendingTokenRefresh: boolean;
}

const detailsInitialState: UserInfo = {
  userId: null,
  userName: null,
  userDesc: null,
  firstName: null,
  lastName: null,
  token: null,
};

export const initialState: AuthState = {
  details: detailsInitialState,
  pendingLogIn: false,
  pendingLogOut: false,
  pendingTokenRefresh: false,
};

export interface UserInfo {
  userId: string | null;
  userName: string | null;
  userDesc: string | null;
  firstName: string | null;
  lastName: string | null;
  token: string | null;
}

export interface LogInAsyncParameters {
  username: string;
  password: string;
}

export const logInAsync = createAsyncThunk(
  "user/logInAsync",
  async (parameters: LogInAsyncParameters, { getState, dispatch }) => {
    const response = await logIn(
      ConnectionType.Operator,
      parameters.username,
      parameters.password
    );
    return response;
  }
);

export const refreshTokenAsync = createAsyncThunk(
  "user/refreshTokenAsync",
  async (_, { getState, dispatch }) => {
    const response = await refreshToken();
    return response;
  }
);

export const logOutAsync = createAsyncThunk(
  "user/logOutAsync",
  async (_, { getState, dispatch }) => {
    await logOut();
  }
);

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userDetailsLoaded: (
      state,
      action: PayloadAction<{
        userName: string;
        userDesc: string | null;
        firstName: string | null;
        lastName: string | null;
      }>
    ) => {
      state.details.userName = action.payload.userName;
      state.details.userDesc = action.payload.userDesc;
      state.details.firstName = action.payload.firstName;
      state.details.lastName = action.payload.lastName;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logInAsync.pending, (state, action) => {
        state.pendingLogIn = true;
      })
      .addCase(logInAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.details.userId = action.payload.userId!;
          state.details.token = action.payload.token!;
        }
        state.pendingLogIn = false;
      })
      .addCase(logInAsync.rejected, (state, action) => {
        state.pendingLogIn = false;
        state.details = detailsInitialState;
      })
      .addCase(refreshTokenAsync.pending, (state, action) => {
        state.pendingTokenRefresh = true;
      })
      .addCase(refreshTokenAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.details.token = action.payload.token;
        }
        state.pendingTokenRefresh = false;
      })
      .addCase(refreshTokenAsync.rejected, (state, action) => {
        state.pendingTokenRefresh = false;
        state.details = detailsInitialState;
      })
      .addCase(logOutAsync.pending, (state, action) => {
        state.pendingLogOut = true;
      })
      .addCase(logOutAsync.fulfilled, (state, action) => {
        state.pendingLogOut = false;
        state.details = detailsInitialState;
      })
      .addCase(logOutAsync.rejected, (state, action) => {
        state.pendingLogOut = false;
        state.details = detailsInitialState;
      });
  },
});

export const { userDetailsLoaded } = authSlice.actions;

export const selectIsAuthorized = createSelector(
  (state: RootState) => state.user.auth.details.token,
  (token) => token !== null && token !== undefined
);

export const selectUserToken = createSelector(
  (state: RootState) => state.user.auth.details.token,
  (token) => token
);

export const selectUserDetails = createSelector(
  (state: RootState) => state.user.auth.details,
  (details) => details
);

export const selectUserName = createSelector(
  (state: RootState) => state.user.auth.details,
  (details) =>
    (details?.firstName &&
      details?.lastName &&
      details.firstName.concat(" ", details.lastName)) ||
    details?.userName ||
    details.userId
);

export const selectIsPendingLogIn = createSelector(
  (state: RootState) => state.user.auth.pendingLogIn,
  (pendingLogIn) => pendingLogIn
);

export const selectIsPendingLogOut = createSelector(
  (state: RootState) => state.user.auth.pendingLogOut,
  (pendingLogOut) => pendingLogOut
);

export default authSlice.reducer;
