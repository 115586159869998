import { makeStyles } from "tss-react/mui";
import { Theme, Typography } from "@mui/material";
import { ArcElement, Chart as ChartJS } from "chart.js";
import React, { ReactElement, useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { GaugeOptions, MqttTopicValue } from "../../../store/common/mqttSlice";

ChartJS.register(ArcElement);

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    position: "relative",
    width: "65px",
    height: "40px",
  },
  gaugeDesc: {
    position: "absolute",
    left: "50%",
    top: "65%",
    transform: "translate(-50%, -50%)",
  },
}));

interface GaugeProps {
  value: number;
  options: GaugeOptions | undefined;
  entityParameters: Array<MqttTopicValue> | undefined;
}

const GaugeComponent: React.FC<GaugeProps> = ({
  value,
  options,
  entityParameters,
}): ReactElement => {
  const { classes } = useStyles();

  const prepareMaxValueMqtt = () => {
    return isNaN(Number(options?.max))
      ? Number(
          entityParameters?.find((e) => e.name === options?.max)?.value || 0
        )
      : Number(options?.max);
  };

  const max = prepareMaxValueMqtt();

  const prepareBackgroundColor = (value: number, max: number) => {
    const backgroundColors: Array<string> = [];
    if (options !== undefined) {
      const availableLevels = options.levels
        .filter((o) => o.value * max < value)
        .map((o) => o.value);
      const maxLevel = Math.max(...availableLevels);
      const maxColor = options.levels.find((o) => o.value === maxLevel)?.color;
      maxColor && backgroundColors.push(maxColor);
    }
    backgroundColors.push("#E5E5E5");
    return backgroundColors;
  };

  const progressDesc = useMemo(
    () =>
      `${
        !isNaN(Math.round((value / max) * 100)) &&
        isFinite(Math.round((value / max) * 100))
          ? Math.round((value / max) * 100) > 100
            ? 100
            : Math.round((value / max) * 100)
          : 0
      }%`,
    [value, max]
  );

  const data = {
    datasets: [
      {
        data: value < max ? [value, max - value] : [100, 0],
        backgroundColor: prepareBackgroundColor(value, max),
      },
    ],
  };

  return (
    <div className={classes.wrapper}>
      <Doughnut
        data={data}
        options={{
          maintainAspectRatio: false,
          cutout: "75%",
          circumference: 180,
          rotation: 270,
          animation: {
            duration: 0,
          },
          hover: {
            mode: undefined,
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          events: [],
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        }}
      />
      <Typography className={classes.gaugeDesc} variant="subtitle2">
        {progressDesc}
      </Typography>
    </div>
  );
};
export default GaugeComponent;
