import { LiveParser, LiveProvider } from "@improdis/core";
import { Theme } from "@mui/material/";
import React, { FunctionComponent, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import staticScope from "../../../helpers/grids/staticScope";
import { selectAreGridsEditable } from "../../../store/common/gridsSlice";
import { useAppSelector } from "../../../store/hooks";
import { selectRunnedTask } from "../../../store/main/job-execution/jobExecutionSlice";
import { selectSelectedAsset } from "../../../store/user/assetSelectionSlice";
import { selectUserDetails } from "../../../store/user/authSlice";
import ErrorFallback, { ErrorLevel } from "../error-fallbacks/ErrorFallback";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "relative",
    height: "100%",
    maxHeight: "100%",
    width: "100%",
    overflow: "auto",
  },
  triangleEditable: {
    position: "absolute",
    width: 0,
    height: 0,
    borderTop: "15px solid #ff3426",
    borderRight: "15px solid transparent",
    zIndex: 210,
  },
  triangleNew: {
    position: "absolute",
    width: 0,
    height: 0,
    borderTop: "20px solid #41BD64",
    borderRight: "20px solid transparent",
    zIndex: 205,
  },
}));

interface EditableGridComponentProps {
  code: string;
  isNew?: boolean;
}

const EditableGridComponent: FunctionComponent<EditableGridComponentProps> = ({
  code,
  isNew,
}) => {
  const { classes } = useStyles();
  const selectedAsset = useAppSelector(selectSelectedAsset);
  const userDetails = useAppSelector(selectUserDetails);
  const runnedTask = useAppSelector(selectRunnedTask);
  const areGridsEditable = useAppSelector(selectAreGridsEditable);

  const dynamicScope = useMemo(
    () => ({
      asset: selectedAsset,
      user: userDetails,
      runningTask: runnedTask,
    }),
    [selectedAsset, userDetails, runnedTask]
  );

  return (
    <>
      {areGridsEditable && (
        <>
          {isNew && <div className={classes.triangleNew} />}
          <div className={classes.triangleEditable} />
        </>
      )}

      <LiveProvider
        staticScope={staticScope}
        dynamicScope={dynamicScope}
        code={code}
        ErrorFallbackComponent={(props) => (
          <ErrorFallback {...props} level={ErrorLevel.Component} />
        )}
      >
        <LiveParser />
      </LiveProvider>
    </>
  );
};

export default EditableGridComponent;
