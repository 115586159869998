import WarningIcon from "@mui/icons-material/Warning";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import {
  selectMqttErrors,
  selectSelectedAssetMqttDefinition,
  selectSelectedAssetParameters,
} from "../../../../../store/common/mqttSlice";
import { useAppSelector } from "../../../../../store/hooks";
import ParameterComponent from "../../../../common/parameter-parts/ParameterComponent";
import _ from "lodash";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: "1rem",
    paddingTop: "1.5rem",
    position: "relative",
    [theme.breakpoints.down("xl")]: {
      padding: "0.2rem",
      paddingTop: "0.5rem",
    },
    boxSizing: "border-box",
  },
  content: {
    padding: "0.5rem",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    width: "100%",
    minHeight: "2rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  typography: {
    display: "flex",
    alignItems: "center",
  },
  reasonBar: {
    width: "0.3rem",
    height: "1.5rem",
    margin: "0.0rem 0.3rem 0.0rem 0rem",
  },

  divider: {
    backgroundColor: theme.palette.text.disabled,
  },
  icon: {
    marginRight: "0.3rem",
  },
}));

interface AssetParametersProps {}

const AssetParametersComponent: React.FC<AssetParametersProps> = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const errorsRoot = useAppSelector(selectMqttErrors);

  const parameters = useAppSelector(selectSelectedAssetParameters);

  const mqttDefinition = useAppSelector(selectSelectedAssetMqttDefinition);

  return (
    <Paper className={classes.root} elevation={2}>
      {errorsRoot && errorsRoot.length > 0 && (
        <Box position="absolute" top={5} right={5}>
          <Tooltip title={t("Validation error") + ": " + errorsRoot} arrow>
            <WarningIcon color="error" fontSize="small"></WarningIcon>
          </Tooltip>
        </Box>
      )}
      {mqttDefinition &&
        mqttDefinition.errors &&
        mqttDefinition.errors.length > 0 && (
          <Box position="absolute" top={5} right={5}>
            <Tooltip
              title={t("Validation error") + ": " + mqttDefinition?.errors}
              arrow
            >
              <WarningIcon color="error" fontSize="small"></WarningIcon>
            </Tooltip>
          </Box>
        )}
      <Typography className={classes.header} variant="h5" align="left">
        {t("AssetParametersTitle")}
      </Typography>
      <div className={classes.content}>
        {mqttDefinition?.topics && mqttDefinition?.topics?.length > 0 ? (
          _.orderBy(
            mqttDefinition.topics,
            (o) => (Number.isNaN(Number(o.priority)) ? -1 : Number(o.priority)),
            "desc"
          ).map((def) => (
            <ParameterComponent
              key={def.name}
              topicDefinition={def}
              entityParameters={parameters}
              topic={parameters?.find((e) => e.name === def.name)}
            />
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" align="center">
            {t("No predefined parameters")}
          </Typography>
        )}
      </div>
    </Paper>
  );
};

export default AssetParametersComponent;
