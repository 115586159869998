import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
} from "@microsoft/signalr";
import { AnyAction, Dispatch } from "redux";
import { env } from "../../../../env";
import {
  assetsConnectionStatusChanged,
  shiftChangedSocketEvent,
} from "../../../../store/sockets/utilization/assetsSlice";
import { selectIsAuthorized } from "../../../../store/user/authSlice";
import convertStringDates from "../../dateParser";
import RetryPolicy from "../../retryPolicy";

export interface ShiftChangedSocketDto {
  assetId: string;
  shiftStartTime: Date;
  shiftEndTime: Date | null;
}

interface IAssetsAssetGroupService {
  init(): void;
  connect(dispatch: Dispatch<AnyAction>, getState: any): void;
  disconnect(): void;
  subscribe(dispatch: Dispatch<AnyAction>, getState: any): void;
  unsubscribe(): void;
  joinAssetGroup(assetId: string): void;
  leaveAssetGroup(assetId: string): void;
}

const assetsHubUrl = `${env.REACT_APP_MES_API_URL}/utilization/assets`;

const retryPolicy = new RetryPolicy();

const protocol = new JsonHubProtocol();

const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

const options = {
  transport,
};

export class AssetsAssetGroupService implements IAssetsAssetGroupService {
  public connectionAssetsHub: HubConnection | undefined;
  private curAssetId: string | undefined;

  constructor() {
    this.init();
  }

  init() {
    this.connectionAssetsHub = new HubConnectionBuilder()
      .withUrl(assetsHubUrl, options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect(retryPolicy)
      .build();
  }

  async start(dispatch: Dispatch<AnyAction>) {
    try {
      await this.connectionAssetsHub?.start().then(async (a) => {
        dispatch(assetsConnectionStatusChanged(HubConnectionState.Connected));
      });
    } catch (error) {
      console.error(error);
    }
  }

  async connect(dispatch: Dispatch<AnyAction>, getState: any) {
    if (this.connectionAssetsHub?.state !== HubConnectionState.Disconnected) {
      this.unsubscribe();
      this.init();
      this.subscribe(dispatch, getState());
    }

    await this.start(dispatch);

    this.connectionAssetsHub?.onreconnecting((error) => {
      console.error(error);
      dispatch(assetsConnectionStatusChanged(HubConnectionState.Reconnecting));
    });

    this.connectionAssetsHub?.onreconnected((connectionId) => {
      this.curAssetId !== undefined && this.joinAssetGroup(this.curAssetId);
      dispatch(assetsConnectionStatusChanged(HubConnectionState.Connected));
    });

    this.connectionAssetsHub?.onclose(async (error) => {
      error && console.error(error);
      const isAuthorized = selectIsAuthorized(getState());
      if (isAuthorized) {
        //STILL LOGGED IN
        await this.start(dispatch);
      } else {
        dispatch(
          assetsConnectionStatusChanged(HubConnectionState.Disconnected)
        );
      }
    });
  }

  disconnect() {
    this.unsubscribe();
    this.connectionAssetsHub?.stop();
  }

  subscribe(dispatch: Dispatch<AnyAction>, getState: any) {
    this.connectionAssetsHub?.on("shift-changed", (message) => {
      if (message !== null) {
        convertStringDates(message, ["shiftStartTime, shiftEndTime"]);
        dispatch(shiftChangedSocketEvent(message));
      }
    });
  }

  async joinAssetGroup(assetId: string): Promise<void> {
    this.curAssetId = assetId;
    if (this.connectionAssetsHub?.state === HubConnectionState.Connected) {
      this.connectionAssetsHub?.invoke("JoinAssetGroup", assetId);
    }
  }

  leaveAssetGroup = (assetId: string) => {
    if (this.connectionAssetsHub?.state === HubConnectionState.Connected) {
      this.connectionAssetsHub?.invoke("LeaveAssetGroup", assetId);
    }
  };

  unsubscribe = () => {
    this.connectionAssetsHub?.off("shift-changed");
  };
}
