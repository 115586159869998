import { createListenerMiddleware } from "@reduxjs/toolkit";
import { shiftChangedSocketEvent } from "../../../sockets/utilization/assetsSlice";
import { newShiftDialogOpened } from "./newShiftSlice";

const newShiftListener = createListenerMiddleware();

newShiftListener.startListening({
  actionCreator: shiftChangedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(newShiftDialogOpened());
  },
});

export default newShiftListener;
