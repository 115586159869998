import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  RegisterBadProductionRequest,
  RegisterGoodProductionRequest,
  RunnedTaskDto,
  RunTaskRequest,
  TasksClient,
  UnregisterBadProductionRequest,
  UnregisterGoodProductionRequest,
  WorkQueueDto,
} from "../httpService";

const http = new TasksClient(env.REACT_APP_MES_API_URL, instance);

export const startTask = async (
  taskId: string,
  assetId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new RunTaskRequest({
      assetId,
    });
    await http.runTask(taskId, request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const pauseTask = async (
  taskId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    await http.pauseTask(taskId);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const completeTask = async (
  taskId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    await http.completeTask(taskId);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const resumeTask = async (
  taskId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    await http.resumeTask(taskId);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getWorkQueue = async (
  assetId: string,
  throwError: boolean = true
): Promise<Array<WorkQueueDto> | undefined> => {
  try {
    var result = await http.getWorkQueueByAssetId(assetId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const getRunnedTaskById = async (
  taskId: string,
  throwError: boolean = true
): Promise<RunnedTaskDto | undefined> => {
  try {
    var result = await http.getRunnedTaskById(taskId);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const addGoodProduction = async (
  taskId: string,
  quantityProduced: number,
  comment: string | null = null,
  tryAggregate: boolean | null = null,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new RegisterGoodProductionRequest({
      quantityProduced,
      comment,
      tryAggregate,
    });
    await http.addGoodProduction(taskId, request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const reduceGoodProduction = async (
  taskId: string,
  quantity: number,
  comment: string | null = null,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new UnregisterGoodProductionRequest({
      quantity,
      comment,
    });
    await http.reduceGoodProduction(taskId, request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const addBadProduction = async (
  taskId: string,
  quantityProduced: number,
  comment: string | null = null,
  tryAggregate: boolean | null = null,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new RegisterBadProductionRequest({
      quantityProduced,
      comment,
      tryAggregate,
    });
    await http.addBadProduction(taskId, request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const reduceBadProduction = async (
  taskId: string,
  quantity: number,
  comment: string | null = null,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new UnregisterBadProductionRequest({
      quantity,
      comment,
    });
    await http.reduceBadProduction(taskId, request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
