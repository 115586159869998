import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Slider,
  styled,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { makeStyles } from "tss-react/mui";
import { FormatDateString } from "../../../helpers/tables/date/FormatDate";
import { FormatDurationString } from "../../../helpers/tables/duration/FormatDuration";
import { FormatUtilRaw } from "../../../helpers/tables/util-raw/FormatUtilRaw";
import UtilizationIcon from "../../../icons/UtilizationIcon";
import {
  selectSplitUtilEventDialogOpen,
  splitUtilEventDialogClosed,
} from "../../../store/common/dialogs/splitUtilEventSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  loadAvailableUtilRawsAsync,
  NestedAvailableUtilRaw,
  selectAvailableUtilRaws,
  selectAvailableUtilRawsNodeIds,
  selectAvailableUtilRawsPending,
} from "../../../store/main/availableUtilRawsSlice";
import {
  selectSelectedUtilEvent,
  selectUtilEventsPendingSplit,
  splitUtilEventAsync,
} from "../../../store/main/utilization-events/utilEventsSlice";
import UtilRawsTreeViewComponent from "./UtilRawsTreeViewComponent";
import { GetNestedAvailableUtilRawsQueryUtilDto } from "../../../services/httpService";
import GetAvailableUtilRawsActionType from "../../../helpers/enums/getAvailableUtilRawsActionType";
import useDuration from "../../../helpers/hooks/useDuration";

const TimeSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-thumb": {
    height: 28,
    width: 10,
    borderRadius: 0,
    border: "solid 1px black",
    backgroundColor: "#fff",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
    },
  },
  "& .MuiSlider-valueLabel": {
    background: "transparent",
    "& *": {
      color: "#000",
    },
  },
  "& .MuiSlider-track": {
    height: 2,
  },
  "& .MuiSlider-mark": {
    backgroundColor: "transparent",
  },
  '& .MuiSlider-mark[data-index="0"] ': {
    height: "0.8rem",
    width: 3,
    backgroundColor: "#bfbfbf",
  },
  '& .MuiSlider-mark[data-index="1"] ': {
    height: "0.8rem",
    width: 2,
    backgroundColor: "#bfbfbf",
  },
  '& .MuiSlider-mark[data-index="2"]': {
    display: "inline-block",
    borderLeft: "0.7rem solid",
    borderColor: "#bfbfbf",
    borderTop: "0.7rem solid white",
    borderBottom: "0.7rem solid white",
    width: 0,
    height: 0,
  },
}));

const useStyles = makeStyles()((theme: Theme) => ({
  utilIcon: {
    width: 30,
    height: 30,
    marginRight: "0.5rem",
  },

  utilLogWrapper: {
    fontSize: "large",
    marginLeft: "1rem",
    marginRight: "1rem",
    display: "flex",
    padding: "0.5rem",
    width: "calc(100% - 2rem)",
    justifyContent: "space-around",
    alignItems: "center",
    boxSizing: "border-box",
  },

  reasonWrap: {
    display: "grid",
    grid: "1fr / 1fr 1fr",
  },

  reasonContent: {
    height: "18.2rem",
    position: "relative",
    overflow: "auto",
    marginBottom: "0rem",
    width: "25rem",
    border: "1px solid #c4c4c4",
    borderRadius: theme.shape.borderRadius,
  },

  commentArea: {
    width: "25rem",
    maxWidth: "25rem",
    padding: 0,
    marginLeft: "0.5rem",
  },

  sideButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "space-around",
  },
}));

interface DialogSplitUtilProps {}

const DialogSplitUtilComponent: FunctionComponent<
  DialogSplitUtilProps
> = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectSplitUtilEventDialogOpen);
  const selectedUtilEvent = useAppSelector(selectSelectedUtilEvent);
  const availableUtilRaws = useAppSelector(selectAvailableUtilRaws);
  const initialExpanded = useAppSelector(selectAvailableUtilRawsNodeIds);
  const availableUtilRawsPending = useAppSelector(
    selectAvailableUtilRawsPending
  );
  const pendingSplit = useAppSelector(selectUtilEventsPendingSplit);
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [selectedUtilRawId, setSelectedUtilRawId] = useState<string>();
  // const [selectedUtilRawSourceStack, setSelectedUtilRawSourceStack] =
  //   useState<Array<string>>();
  const [comment, setComment] = useState<string>("");
  const [ratio, setRatio] = useState<number>(50);
  const [side, setSide] = useState<"normal" | "inverted">("inverted");
  const startDate = selectedUtilEvent?.startedAt.valueOf() || 0;
  const [timeAfterWindowOpening, setTimeAfterWindowOpening] = useState<number>(
    Math.round(Date.now() - startDate) / 1000
  );
  const [addTimeAfterWindowOpening, setAddTimeAfterWindowOpening] =
    useState<boolean>(true);

  //FIXME get from store
  const isReasonActive = false;

  //TODO change to use source stack
  const selectedUtilRaw = useMemo(() => {
    var foundUtilRaw: GetNestedAvailableUtilRawsQueryUtilDto | null;
    const lookup = (node: NestedAvailableUtilRaw) => {
      node.utilRaws?.forEach((ur) => {
        if (ur.id === selectedUtilRawId) {
          foundUtilRaw = ur;
          return;
        }
      });
      node.childrenGroups?.forEach((node) => lookup(node));
    };
    availableUtilRaws.forEach((node) => lookup(node));
    return foundUtilRaw! as GetNestedAvailableUtilRawsQueryUtilDto;
  }, [availableUtilRaws, selectedUtilRawId]);

  const duration = useDuration(
    selectedUtilEvent?.startedAt,
    selectedUtilEvent?.finishedAt ?? undefined
  );

  const countDuration = useCallback(
    () => duration,
    //   {
    //   return (
    //     (Number(selectedUtilEvent?.duration) || 0) +
    //     (isReasonActive && addTimeAfterWindowOpening ? timeAfterWindowOpening : 0)
    //   );
    // }
    [
      duration,
      // selectedUtilEvent,
      // isReasonActive,
      // addTimeAfterWindowOpening,
      // timeAfterWindowOpening,
    ]
  );

  const leftDuration = useMemo(
    () => (ratio / 100) * countDuration(),
    [ratio, countDuration]
  );

  const rightDuration = useMemo(
    () => ((100 - ratio) / 100) * countDuration(),
    [ratio, countDuration]
  );

  useEffect(() => {
    if (open) {
      dispatch(
        loadAvailableUtilRawsAsync({
          utilRawId: selectedUtilEvent?.utilRawId,
          actionType: GetAvailableUtilRawsActionType.Split,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, open]);

  useEffect(() => {
    setSelectedUtilRawId(undefined);
    setComment(selectedUtilEvent?.comment ?? "");
  }, [selectedUtilEvent]);

  useEffect(() => {
    if (isReasonActive) {
      const timerId = setInterval(() => {
        setTimeAfterWindowOpening(Math.round(Date.now() - startDate) / 1000);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [startDate, isReasonActive]);

  const handleSplitUtilEvent = async () => {
    const splitTime = moment(selectedUtilEvent?.startedAt)
      .add(leftDuration, "seconds")
      .toDate();
    if (selectedUtilRawId) {
      if (side === "normal") {
        dispatch(
          splitUtilEventAsync({
            olderRange: {
              utilRawId: selectedUtilRawId,
              comment: comment,
            },
            splitTime: splitTime,
            newerRange: null,
          })
        );
      } else {
        dispatch(
          splitUtilEventAsync({
            olderRange: null,
            splitTime: splitTime,
            newerRange: {
              utilRawId: selectedUtilRawId,
              comment: comment,
            },
          })
        );
      }
    }
    //TODO move snackbar to slice
    // SnackbarUtils.success(t("Util was splitted successfully"));
    dispatch(splitUtilEventDialogClosed());
  };

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(splitUtilEventDialogClosed())}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle>
        <UtilizationIcon className={classes.utilIcon} />
        <Typography variant="h5">{t("Split of reason")}</Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.utilLogWrapper}>
          <div>
            <div style={{ fontSize: "smaller" }}>{t("Edited reason")}: </div>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "25rem",
                whiteSpace: "nowrap",
                paddingTop: "0.5rem",
              }}
            >
              {FormatUtilRaw(
                selectedUtilEvent?.utilRawName!,
                selectedUtilEvent?.utilStateColor ?? null,
                t
              )}
            </div>
          </div>
          <Divider flexItem orientation="vertical" />
          <div>
            {selectedUtilEvent?.startedAt &&
              FormatDateString(selectedUtilEvent?.startedAt, t)}
          </div>
          <Divider flexItem orientation="vertical" />
          <div style={{ width: "15%" }}>
            {FormatDurationString(countDuration(), t)}
          </div>
          {isReasonActive && (
            <>
              <Divider flexItem orientation="vertical" />
              <div style={{ width: "25%" }}>
                <div style={{ fontSize: "smaller" }}>{t("Reason active")}</div>
                <Checkbox
                  checked={addTimeAfterWindowOpening}
                  onChange={() => {
                    setAddTimeAfterWindowOpening(!addTimeAfterWindowOpening);
                  }}
                />
                <span>{t("Add")} </span>
                {FormatDurationString(timeAfterWindowOpening, t)}
              </div>
            </>
          )}
        </div>
        <TimeSlider
          style={{
            boxSizing: "border-box",
            marginTop: "3rem",
            marginBottom: "1rem",
            width: "80%",
          }}
          valueLabelDisplay="on"
          getAriaValueText={(value: number) => `${value}%`}
          valueLabelFormat={(value: number) => {
            return (
              <div style={{ display: "flex", gap: "1rem" }}>
                <span>{FormatDurationString(leftDuration, t)}</span>
                <span>|</span>
                <span>{FormatDurationString(rightDuration, t)}</span>
              </div>
            );
          }}
          min={0}
          max={100}
          track={side}
          marks={[
            {
              value: 0,
              label: (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <span>
                    {FormatDateString(selectedUtilEvent?.startedAt, t)}
                  </span>
                </Box>
              ),
            },
            {
              value: 50,
              label: (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                />
              ),
            },
            {
              value: 100,
              label: (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <span>
                    {FormatDateString(
                      moment(selectedUtilEvent?.startedAt)
                        .add(countDuration(), "seconds")
                        .toString() || "",
                      t
                    )}
                  </span>
                </Box>
              ),
            },
          ]}
          defaultValue={50}
          onChange={(event: any, newValue: number | number[]) =>
            setRatio(newValue as number)
          }
        />
        <div className={classes.sideButtons}>
          <IconButton onClick={() => setSide("normal")} size="large">
            <ArrowUpwardIcon color={side === "normal" ? "primary" : "action"} />
          </IconButton>
          <IconButton onClick={() => setSide("inverted")} size="large">
            <ArrowUpwardIcon
              color={side === "inverted" ? "primary" : "action"}
            />
          </IconButton>
        </div>
        <div className={classes.utilLogWrapper}>
          <div style={{ fontSize: "smaller" }}>{t("New reason")}: </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "25rem",
              whiteSpace: "nowrap",
            }}
          >
            {FormatUtilRaw(
              selectedUtilRaw?.name ?? "",
              selectedUtilRaw?.utilStateColor ?? "transparent",
              t
            )}
          </div>
          <Divider flexItem orientation="vertical" />
          <div>
            {side === "normal"
              ? FormatDateString(selectedUtilEvent?.startedAt, t)
              : FormatDateString(
                  moment(selectedUtilEvent?.startedAt)
                    .add(leftDuration, "seconds")
                    .toString() || "",
                  t
                )}
          </div>
          <Divider flexItem orientation="vertical" />
          <div style={{ width: "15%" }}>
            {side === "normal"
              ? FormatDurationString(leftDuration, t)
              : FormatDurationString(rightDuration, t)}
          </div>
        </div>
        <div className={classes.reasonWrap}>
          <Paper
            className={classes.reasonContent}
            elevation={0}
            component={SimpleBar}
            square
          >
            <UtilRawsTreeViewComponent
              nestedUtilRaws={availableUtilRaws}
              initialExpanded={initialExpanded}
              selectedUtilRawId={selectedUtilRawId}
              onUtilRawSelect={(utilRawId) => setSelectedUtilRawId(utilRawId)}
              isLoading={availableUtilRawsPending}
            />
          </Paper>
          <TextField
            placeholder={t("Comment")}
            className={classes.commentArea}
            multiline
            minRows={12}
            maxRows={12}
            onChange={(e) => setComment(e.target.value)}
            value={comment}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleSplitUtilEvent()}
          disabled={
            pendingSplit ||
            availableUtilRawsPending ||
            selectedUtilRawId === undefined
          }
          disableElevation
        >
          {t("Submit")}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => dispatch(splitUtilEventDialogClosed())}
          disabled={pendingSplit}
          disableElevation
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSplitUtilComponent;
