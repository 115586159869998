import { changeLanguage } from "@improdis/core";
import { Theme } from "@mui/material/styles";
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart as ChartJS,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  SubTitle,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import LoginComponent from "./components/common/LoginComponent";
import LoginLayout from "./components/layouts/LoginLayout";
import MainLayout from "./components/layouts/MainLayout";
import OverviewComponent from "./components/main/overview/OverviewComponent";
import ProductionHistoryComponent from "./components/main/production-history/ProductionHistoryComponent";
import ProductionComponent from "./components/main/production/ProductionComponent";
import UtilComponent from "./components/main/utilization/UtilComponent";
import ProtectedRoute, { ProtectedRouteProps } from "./helpers/ProtectedRoute";
import { SnackbarUtilsConfigurator } from "./helpers/snackbarHelper";
import { logOffTaskFetch } from "./services/main/taskLogonsFetchService";
import { useAppSelector } from "./store/hooks";
import { selectRunnedTaskId } from "./store/main/job-execution/jobExecutionSlice";
import { selectIsAuthorized, selectUserToken } from "./store/user/authSlice";

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh ",
    maxWidth: "100vw",
    width: "100vw",
  },

  suspend: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  snackBar: {
    display: "flex",
    alignItems: "center",
  },

  actionContent: {
    top: 0,
  },
}));

interface AppProps {}

const App: React.FC<AppProps & RouteComponentProps> = ({ history }) => {
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const userRehydrated = useAppSelector(
    (state) => state.user?._persist?.rehydrated
  );
  const token = useAppSelector(selectUserToken);
  const runnedTaskId = useAppSelector(selectRunnedTaskId);
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  useEffect(() => {
    const handleTabClose = async (event) => {
      token && runnedTaskId && (await logOffTaskFetch(token, runnedTaskId));
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  });

  const protectedProps: ProtectedRouteProps = {
    isAuthenticated: isAuthorized,
    isAllowed: isAuthorized,
    authenticationPath: "/login",
    restrictedPath: "/login",
  };

  useEffect(() => {
    if (history.location.pathname === "/login" && isAuthorized) {
      history.push("/");
    }
    if (
      (userRehydrated || userRehydrated === undefined) &&
      history.location.pathname !== "/login" &&
      !isAuthorized
    ) {
      history.push("/");
    }
  }, [userRehydrated, history.location.pathname, isAuthorized, history]);

  useEffect(() => changeLanguage(i18n.language), [i18n.language]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      classes={{
        root: classes.snackBar,
        containerRoot: classes.snackBar,
      }}
      autoHideDuration={5000}
    >
      <SnackbarUtilsConfigurator />

      <div className={classes.root} id="app">
        <Switch>
          <Route path={["/login"]}>
            <LoginLayout>
              <Route exact path={"/login"} component={LoginComponent} />
            </LoginLayout>
          </Route>
          <Route
            path={[
              "/main/overview",
              "/main/production",
              "/main/production-history",
              "/main/util",
            ]}
          >
            <MainLayout>
              <ProtectedRoute
                {...protectedProps}
                exact
                path={"/main/overview"}
                component={OverviewComponent}
              />
              <ProtectedRoute
                {...protectedProps}
                exact
                path={"/main/production"}
                component={ProductionComponent}
              />
              <ProtectedRoute
                {...protectedProps}
                exact
                path={"/main/production-history"}
                component={ProductionHistoryComponent}
              />
              <ProtectedRoute
                {...protectedProps}
                exact
                path={"/main/util"}
                component={UtilComponent}
              />
            </MainLayout>
          </Route>
          {isAuthorized ? (
            <Redirect exact from="/" to="/main/overview" />
          ) : (
            <Redirect exact from="/" to="/login" />
          )}

          <Redirect to="/" />
        </Switch>
      </div>
    </SnackbarProvider>
  );
};

export default withRouter(App);
