import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import React from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { makeStyles } from "tss-react/mui";
import { useAppSelector } from "../../../store/hooks";
import { selectSelectedAssetId } from "../../../store/user/assetSelectionSlice";
import OverviewGridWrapper from "./OverviewGridWrapper";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    // maxHeight: "calc(100% - 0.5rem)",
    height: "calc(100% - 0.5rem)",
    width: "100%",
    marginTop: "0.5rem",
    boxSizing: "border-box",
    "& .simplebar-content": {
      height: "100%",
    },
  },
}));

interface OverviewProps {}

const OverviewComponent: React.FC<OverviewProps> = () => {
  const selectedAssetId = useAppSelector(selectSelectedAssetId);
  const { classes } = useStyles();

  return (
    <Box className={classes.root} component={SimpleBar}>
      {selectedAssetId !== undefined && <OverviewGridWrapper />}
    </Box>
  );
};

export default OverviewComponent;
