import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { LocalStorageKey } from "../../helpers/localStorageKeys";
import SnackbarUtils from "../../helpers/snackbarHelper";
import i18n from "../../i18n";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "../../services/localStorageService";
import { defaultAssetDialogClosed } from "../../store/common/dialogs/defaultAssetSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  logOnAssetAsync,
  selectSelectedAssetId,
} from "../../store/user/assetSelectionSlice";
import {
  defaultAssetCleared,
  defaultAssetSelected,
  defaultAssetSkipped,
  selectDefaultAssetId,
  selectDefaultAssetNodeId,
  selectDefaultAssetSourceStack,
} from "../../store/user/defaultAssetSlice";
import TreeViewComponent from "../main/navigation/inner-components/TreeViewComponent";

interface DefaultAssetDialogProps {
  open: boolean;
}

const DefaultAssetDialogComponent: React.FC<DefaultAssetDialogProps> = ({
  open,
}) => {
  const dispatch = useAppDispatch();
  const selectedAssetId = useAppSelector(selectSelectedAssetId);
  const defaultAssetNodeId = useAppSelector(selectDefaultAssetNodeId);
  const defaultAssetId = useAppSelector(selectDefaultAssetId);
  const defaultAssetSourceStack = useAppSelector(selectDefaultAssetSourceStack);
  const { t } = useTranslation();

  const handleSelectDefaultAsset = async (nodeId: string) => {
    let sourceStack = nodeId.split("/");
    const assetId = sourceStack[sourceStack.length - 1];
    sourceStack.pop();
    dispatch(
      defaultAssetSelected({
        defaultAssetId: assetId,
        defaultAssetSourceStack: sourceStack,
      })
    );
  };

  const handleSubmitDefaultAssetId = async () => {
    if (defaultAssetId) {
      saveToLocalStorage(
        LocalStorageKey.DEFAULT_ASSET_ID,
        String(defaultAssetId)
      );
      saveToLocalStorage(
        LocalStorageKey.DEFAULT_ASSET_SOURCESTACK,
        String(defaultAssetSourceStack)
      );
      dispatch(
        defaultAssetSelected({
          defaultAssetId: defaultAssetId,
          defaultAssetSourceStack: defaultAssetSourceStack,
        })
      );
      SnackbarUtils.success(i18n.t("Default entity has been set"));
      if (defaultAssetId !== selectedAssetId && defaultAssetSourceStack) {
        await dispatch(
          logOnAssetAsync({
            assetId: defaultAssetId,
            sourceStack: defaultAssetSourceStack,
          })
        );
      }
    }
    handleCloseDefaultAssetDialog();
  };

  const handleSkipDefaultAssetId = () => {
    saveToLocalStorage(LocalStorageKey.DEFAULT_ASSET_ID, String(-1));
    saveToLocalStorage(LocalStorageKey.DEFAULT_ASSET_SOURCESTACK, String(-1));
    dispatch(defaultAssetSkipped());
    SnackbarUtils.warning(i18n.t("Default entity has been skiped"));
    handleCloseDefaultAssetDialog();
  };

  const handleCancelSelectionDefaultAssetId = () => {
    dispatch(
      defaultAssetSelected({
        defaultAssetId: getFromLocalStorage(LocalStorageKey.DEFAULT_ASSET_ID),
        defaultAssetSourceStack: getFromLocalStorage(
          LocalStorageKey.DEFAULT_ASSET_SOURCESTACK
        ).split(","),
      })
    );
    handleCloseDefaultAssetDialog();
  };

  const handleCloseDefaultAssetDialog = () => {
    dispatch(defaultAssetDialogClosed());
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(defaultAssetCleared());
        handleCloseDefaultAssetDialog();
      }}
      maxWidth="lg"
      aria-labelledby="default-entity"
      aria-describedby="choose-default-entity"
    >
      <DialogTitle id="default-entity-title">
        {t("Selection of the default position")}
      </DialogTitle>
      <DialogContent sx={{ width: "35rem" }}>
        <Box
          width="100%"
          maxHeight="30rem"
          overflow="auto"
          component={SimpleBar}
        >
          <TreeViewComponent
            handleClick={handleSelectDefaultAsset}
            selectedAssetNodeId={defaultAssetNodeId}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmitDefaultAssetId}
          color="success"
          variant="contained"
          disabled={!defaultAssetId}
        >
          {t("Submit")}
        </Button>
        <Button
          onClick={handleSkipDefaultAssetId}
          color="warning"
          variant="contained"
        >
          {t("Skip")}
        </Button>
        <Button
          onClick={handleCancelSelectionDefaultAssetId}
          color="error"
          variant="contained"
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DefaultAssetDialogComponent;
