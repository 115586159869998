import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: boolean = false;

export const defaultAssetSlice = createSlice({
  name: "defaultAssetDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      return true;
    },
    closed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const {
  opened: defaultAssetDialogOpened,
  closed: defaultAssetDialogClosed,
} = defaultAssetSlice.actions;

export default defaultAssetSlice.reducer;
