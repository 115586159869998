import dayjs from "dayjs";
import { useEffect, useState } from "react";

const useDuration = (startDate?: Date, endDate?: Date) => {
  const [duration, setDuration] = useState(
    startDate ? dayjs(endDate ?? new Date()).diff(startDate, "second") : 0
  );

  //FIXME WRONG seconds offset

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    if (!endDate) {
      setDuration(dayjs(endDate ?? new Date()).diff(startDate, "second"));
      interval = setInterval(
        () => setDuration((duration) => duration + 1),
        1000
      );
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [startDate, endDate]);

  return duration;
};

export default useDuration;
