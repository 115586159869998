import { Paper, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { TooltipItem } from "chart.js";
import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { chartGoodColor, chartBadColor } from "../../../../../themes/appTheme";
import { useAppSelector } from "../../../../../store/hooks";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  chartHeader: {
    fontSize: "1.8rem",
    textAlign: "left",
  },
  chart: {
    minWidth: 0,
    minHeight: 0,
  },
}));

interface SummarizeProductionPercentChartProps {
  chartStyle: string;
}

const SummarizeProductionPercentChartComponent: React.FC<
  SummarizeProductionPercentChartProps
> = ({ chartStyle }) => {
  const runnedTask = useAppSelector(selectRunnedTask);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const goodProdPercent = useMemo(
    () =>
      (runnedTask?.quantityProduced! /
        (runnedTask?.quantityProduced! + runnedTask?.quantityRejected!)) *
        100 || 0,
    [runnedTask]
  );

  const badProdPercent = useMemo(
    () =>
      (runnedTask?.quantityRejected! /
        (runnedTask?.quantityProduced! + runnedTask?.quantityRejected!)) *
        100 || 0,
    [runnedTask]
  );

  const data = useMemo(
    () => ({
      labels: [""],
      datasets: [
        {
          label: `${t("Good production")}: ${goodProdPercent.toFixed(2)}%`,
          data: [goodProdPercent],
          backgroundColor: [chartGoodColor],
          hoverBackgroundColor: [chartGoodColor],
        },
        {
          label: `${t("Bad production")}: ${badProdPercent.toFixed(2)}%`,
          data: [badProdPercent],
          backgroundColor: [chartBadColor],
          hoverBackgroundColor: [chartBadColor],
        },
      ],
    }),
    [goodProdPercent, badProdPercent, t]
  );

  return (
    <Paper className={chartStyle} elevation={2} square>
      <div className={classes.chartHeader}>{t("Production summary")} [%]</div>
      <div className={classes.chart}>
        <Bar
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            indexAxis: "y",
            plugins: {
              tooltip: {
                mode: "point",
                callbacks: {
                  title: (tooltipItems: Array<TooltipItem<"bar">>) => {
                    return tooltipItems[0]?.label;
                  },
                  label: (tooltipItem: TooltipItem<"bar">) =>
                    tooltipItem.dataset.label ?? "",
                },
              },
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                display: false,
                stacked: true,
              },
            },
          }}
        />
      </div>
    </Paper>
  );
};

export default SummarizeProductionPercentChartComponent;
