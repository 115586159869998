import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "..";
import GridName from "../../helpers/enums/gridName";
import SnackbarUtils from "../../helpers/snackbarHelper";
import i18n from "../../i18n";
import {
  changeSystemAttributeDetails,
  createSystemAttribute,
  deleteSystemAttribute,
  getSystemAttributesByGroupId,
} from "../../services/main/systemAttributesService";
import { selectEditGridsDialogGridName } from "./dialogs/editGridsSlice";
import { selectComponentsAttributeGroup } from "../definitions/attributeGroupsSlice";

interface CustomGridComponent {
  componentAttrId: string | null;
  componentAttrName: string;
  gridName: string;
  key: string;
  title: string;
  code: string;
  isNew?: boolean;
}

type CustomGridComponentsState = Array<CustomGridComponent>;

const initialState: CustomGridComponentsState = [];

export const saveCustomComponentAsync = createAsyncThunk(
  "customGridComponents/saveCustomComponentAsync",
  async (
    { attrName, isNew }: { attrName: string; isNew?: boolean },
    { getState, dispatch }
  ) => {
    const component = (getState() as RootState).customGridComponents.find(
      (c) => c.componentAttrName === attrName
    );
    let componentId = component?.componentAttrId;
    if (component !== undefined) {
      if (isNew) {
        const groupId = selectComponentsAttributeGroup(
          getState() as RootState
        )?.id;
        componentId = (
          await createSystemAttribute(
            groupId!,
            component.componentAttrName,
            "Component created from ImOperator app",
            JSON.stringify({
              gridName: component.gridName,
              key: component.key,
              title: component.title,
              code: component.code, //.replace(/  |\r\n|\n|\r/gm, ""),
            }),
            4
          )
        )?.id;
      } else {
        await changeSystemAttributeDetails(
          component.componentAttrId!,
          component.componentAttrName,
          "Component changed from ImOperator app",
          JSON.stringify({
            gridName: component.gridName,
            key: component.key,
            title: component.title,
            code: component.code, //.replace(/  |\r\n|\n|\r/gm, ""),
          }),
          4
        );
      }
      return {
        gridName: component.gridName,
        key: component.key,
        id: componentId ?? null,
      };
    }
  }
);

export const deleteCustomComponentAsync = createAsyncThunk(
  "customGridComponents/deleteCustomComponentAsync",
  async (
    { gridName, key }: { gridName: GridName; key: string },
    { getState, dispatch }
  ) => {
    const component = (getState() as RootState).customGridComponents.find(
      (c) => c.key === key && c.gridName === gridName
    );
    if (component !== undefined && component.componentAttrId !== null) {
      await deleteSystemAttribute(component.componentAttrId);
      return { gridName: gridName, key: component.key };
    }
  }
);

export const loadCustomGridComponentsAsync = createAsyncThunk(
  "customGridComponents/loadCustomGridComponentsAsync",
  async (_, { getState, dispatch }) => {
    const groupId = selectComponentsAttributeGroup(getState() as RootState)?.id;
    if (groupId) {
      const response = await getSystemAttributesByGroupId(groupId);
      return response;
    }
  }
);

const customGridComponentsSlice = createSlice({
  name: "customGridComponents",
  initialState,
  reducers: {
    customComponentAdded: (
      state,
      action: PayloadAction<CustomGridComponent>
    ) => {
      state.push(action.payload);
    },
    newCustomComponentsDeleted: (state, action: PayloadAction<void>) => {
      return state.filter((c) => c.isNew !== true);
    },
    customComponentValuesChanged: (
      state,
      action: PayloadAction<{
        gridName: GridName;
        key: string;
        values: Omit<CustomGridComponent, "gridName" | "key" | "isNew">;
      }>
    ) => {
      const index = state.findIndex(
        (c) =>
          c.key === action.payload.key && c.gridName === action.payload.gridName
      );
      if (index !== -1) {
        state[index].componentAttrName =
          action.payload.values.componentAttrName;
        state[index].title = action.payload.values.title;
        state[index].code = action.payload.values.code;
      }
    },
    customComponentAttrChanged: (
      state,
      action: PayloadAction<{
        gridName: GridName;
        key: string;
        attrName: string;
      }>
    ) => {
      const index = state.findIndex(
        (c) =>
          c.key === action.payload.key && c.gridName === action.payload.gridName
      );
      if (index !== -1) {
        state[index].componentAttrName = action.payload.attrName;
      }
    },
    customComponentKeyChanged: (
      state,
      action: PayloadAction<{
        gridName: GridName;
        oldKey: string;
        newKey: string;
      }>
    ) => {
      const index = state.findIndex(
        (c) =>
          c.key === action.payload.oldKey &&
          c.gridName === action.payload.gridName
      );
      if (index !== -1) {
        state[index].key = action.payload.newKey;
      }
    },
    customComponentCodeChanged: (
      state,
      action: PayloadAction<{ gridName: GridName; key: string; code: string }>
    ) => {
      const index = state.findIndex(
        (c) =>
          c.key === action.payload.key && c.gridName === action.payload.gridName
      );
      if (index !== -1) {
        state[index].code = action.payload.code;
      }
    },
    customComponentTitleChanged: (
      state,
      action: PayloadAction<{ gridName: GridName; key: string; title: string }>
    ) => {
      const index = state.findIndex(
        (c) =>
          c.key === action.payload.key && c.gridName === action.payload.gridName
      );
      if (index !== -1) {
        state[index].title = action.payload.title;
      }
    },
    componentsLoaded: (
      state,
      action: PayloadAction<Array<CustomGridComponent>>
    ) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCustomGridComponentsAsync.fulfilled, (state, action) => {
        return action
          .payload!.filter((a) => a.value !== null)
          .map(
            (a) =>
              ({
                ...JSON.parse(a.value!),
                componentAttrName: a.name,
                componentAttrId: a.id,
              } as CustomGridComponent)
          );
      })
      .addCase(loadCustomGridComponentsAsync.rejected, (state, action) => {
        return [];
      })
      .addCase(saveCustomComponentAsync.fulfilled, (state, action) => {
        if (action.payload) {
          action.meta.arg.isNew
            ? SnackbarUtils.success(i18n.t("CreateCustomComponentSuccess"))
            : SnackbarUtils.success(i18n.t("SaveCustomComponentSuccess"));
          const index = state.findIndex(
            (c) =>
              c.key === action.payload!.key &&
              c.gridName === action.payload!.gridName
          );
          if (index !== -1) {
            state[index].isNew = false;
            state[index].componentAttrId = action.payload.id;
          }
        }
      })
      .addCase(saveCustomComponentAsync.rejected, (state, action) => {})
      .addCase(deleteCustomComponentAsync.fulfilled, (state, action) => {
        if (action.payload) {
          SnackbarUtils.success(i18n.t("DeleteCustomComponentSuccess"));
          return state.filter(
            (c) =>
              !(
                c.key === action.payload!.key &&
                c.gridName === action.payload!.gridName
              )
          );
        }
      })
      .addCase(deleteCustomComponentAsync.rejected, (state, action) => {
        //SnackbarUtils.error(i18n.t("DeleteCustomComponentFailure"));
      });
  },
});

export const {
  customComponentAdded,
  customComponentValuesChanged,
  customComponentAttrChanged,
  customComponentKeyChanged,
  customComponentCodeChanged,
  customComponentTitleChanged,
  newCustomComponentsDeleted,
  componentsLoaded: customGridComponentsLoaded,
} = customGridComponentsSlice.actions;

export const selectOverviewCustomGridComponents = createSelector(
  (state: RootState) => state.customGridComponents,
  (customGridComponents) =>
    customGridComponents.filter((c) => c.gridName === GridName.OVERVIEW)
);

export const selectProductionCustomGridComponents = createSelector(
  (state: RootState) => state.customGridComponents,
  (customGridComponents) =>
    customGridComponents.filter((c) => c.gridName === GridName.PRODUCTION)
);

export const selectEditDialogCustomComponents = createSelector(
  (state: RootState) => state.customGridComponents,
  (state: RootState) => selectEditGridsDialogGridName(state),
  (customGridComponents, gridName) =>
    customGridComponents.filter((c) => c.gridName === gridName)
);

export default customGridComponentsSlice.reducer;
