import { Menu } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import EnglishIcon from "../../icons/EnglishIcon";
import PolishIcon from "../../icons/PolishIcon";

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    flexGrow: 1,
    fontSize: "1rem",
  },

  userNameContent: {
    display: "flex",
    alignItems: "center",
  },

  languageContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "space-between",
  },

  languageText: {
    paddingLeft: "0.5rem",
  },

  languageIcon: {
    height: 20,
    width: 20,
    paddingLeft: "0.8rem",
  },

  button: {
    borderRadius: "1rem",
    padding: "0.5rem",
  },
}));

export interface AppBarLoginLanguageProps {}

const AppBarLoginLanguageComponent: React.FC<AppBarLoginLanguageProps> = () => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { i18n } = useTranslation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (
    event: React.MouseEvent<EventTarget>,
    lng: string
  ) => {
    handleClose();
    i18n.changeLanguage(lng);
  };

  return (
    <div className={classes.userNameContent}>
      <IconButton
        classes={{ root: classes.button }}
        onClick={handleMenu}
        color="inherit"
        size="medium"
      >
        <Typography variant="h6" className={classes.title}>
          {i18n.language.toLocaleUpperCase()}
        </Typography>
      </IconButton>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList>
          <MenuItem onClick={(event) => handleChangeLanguage(event, "en")}>
            <div className={classes.languageContent}>
              <EnglishIcon className={classes.languageIcon} />
              <div className={classes.languageText}>EN</div>
            </div>
          </MenuItem>
          <MenuItem onClick={(event) => handleChangeLanguage(event, "pl")}>
            <div className={classes.languageContent}>
              <PolishIcon className={classes.languageIcon} />
              <div className={classes.languageText}>PL</div>
            </div>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};

export default AppBarLoginLanguageComponent;
