export enum TypeOfProduction {
  Good = 0,
  Bad = 1,
}

export enum TaskState {
  New = "New",
  Ready = "Ready",
  Running = "Running",
  Completed = "Completed",
  Paused = "Paused",
  Cancelled = "Cancelled",
  ByPassed = "ByPassed",
  Superseded = "Superseded",
}

export enum ProdUom {
  "hours/batch",
  "minutes/batch",
  "seconds/batch",
  "batches/hour",
  "batches/minute",
  "batches/second",
}
