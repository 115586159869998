import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  addBadProductionAsync,
  reduceBadProductionAsync,
  selectAddProductionPending,
} from "../../../../../store/main/production-events/prodEventsSlice";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: "1rem",
    paddingTop: "1.5rem",
    position: "relative",
    boxSizing: "border-box",
  },

  badProductionHeader: {
    textAlign: "left",
    fontSize: "1.5rem",
  },

  actualBadProductionContent: {
    display: "flex",
    textAlign: "left",
    padding: "2rem",
    fontSize: "2.5rem",
  },

  badProductionContent: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
  },

  arrowBtnBad: {
    height: "1.5rem",
    maxWidth: "0.5rem",
    padding: 0,
    margin: "0.15rem",
    minWidth: "1.5rem",
  },

  textField: {
    minWidth: "4rem",
  },
}));

export interface BadProductionComponentProps {}

const BadProductionComponent: React.FC<BadProductionComponentProps> = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const runnedTask = useAppSelector(selectRunnedTask);
  const pendingAddProduction = useAppSelector(selectAddProductionPending);
  const [multipleCounter, setMultipleCounter] = useState<string>("5");

  const handleProductionChange = async (value: number) => {
    if (value >= 0)
      dispatch(
        addBadProductionAsync({
          taskId: runnedTask?.taskId!,
          quantityProduced: value,
          comment: null,
        })
      );
    else
      dispatch(
        reduceBadProductionAsync({
          taskId: runnedTask?.taskId!,
          quantity: Math.abs(value),
          comment: null,
        })
      );
  };

  const handleChangeMultpleCounter = (value: string) => {
    setMultipleCounter(value);
  };

  const isBadProdNumerical = () => {
    var numericalMultipleCounter = Number(multipleCounter);
    return !isNaN(numericalMultipleCounter);
  };

  const isValidAmountOfBadProd = () => {
    var numericalMultipleCounter = Number(multipleCounter);
    return (
      multipleCounter.trim() !== "" &&
      !isNaN(numericalMultipleCounter) &&
      numericalMultipleCounter !== 0
    );
  };

  return (
    <Paper className={classes.paper} elevation={2} square>
      <div className={classes.badProductionHeader}>{t("Bad production")}:</div>
      <div className={classes.actualBadProductionContent}>
        <div>{runnedTask?.quantityRejected ?? 0}</div>
        <div style={{ paddingLeft: "1rem" }}>
          {runnedTask?.abbreviation || runnedTask?.uomName}
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="error"
          style={{ marginRight: "0.3rem" }}
          variant="contained"
          size="small"
          onClick={async () => await handleProductionChange(1)}
          disabled={pendingAddProduction}
        >
          <div style={{ fontSize: "1.3rem" }}>+1</div>
        </Button>
        <TextField
          value={multipleCounter}
          onChange={(event) => {
            handleChangeMultpleCounter(event.target.value);
          }}
          variant="filled"
          label={t("Add more")}
          className={classes.textField}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "0.25rem",
          }}
        >
          <Button
            variant="contained"
            color="error"
            className={classes.arrowBtnBad}
            onClick={() =>
              handleChangeMultpleCounter(
                (Number(multipleCounter) + 1).toString()
              )
            }
            disabled={!isBadProdNumerical()}
          >
            <ArrowDropUpIcon></ArrowDropUpIcon>
          </Button>
          <Button
            variant="contained"
            color="error"
            className={classes.arrowBtnBad}
            onClick={() =>
              handleChangeMultpleCounter(
                (Number(multipleCounter) - 1).toString()
              )
            }
            disabled={!isBadProdNumerical()}
          >
            <ArrowDropDownIcon></ArrowDropDownIcon>
          </Button>
        </div>
        <Button
          variant="contained"
          color="error"
          style={{ marginLeft: "0.3rem", marginRight: "0.3rem" }}
          disabled={pendingAddProduction || !isValidAmountOfBadProd()}
          onClick={() => handleProductionChange(Number(multipleCounter))}
        >
          <DoneIcon></DoneIcon>
        </Button>
      </div>
    </Paper>
  );
};

export default BadProductionComponent;
