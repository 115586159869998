import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../..";

export const initialState: boolean = false;

export const updateProductionEventSlice = createSlice({
  name: "updateProductionEventDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      return true;
    },
    closed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const {
  opened: updateProductionEventDialogOpened,
  closed: updateProductionEventDialogClosed,
} = updateProductionEventSlice.actions;

export const selectUpdateProductionEventDialogOpen = createSelector(
  (state: RootState) =>
    state.dialogs.productionEventsDialogs.openUpdateProductionEventDialog,
  (openUpdateProductionEventDialog) => openUpdateProductionEventDialog
);

export default updateProductionEventSlice.reducer;
