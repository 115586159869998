import GridLayout from "react-grid-layout";

export const defaultNewUniversalComponent = `
({ asset, user, runningTask }) => {
  //PLACE FOR OBJECT DESTRUCTURING
  const {
    useTranslation,
    useMqttValue,
    useSnackbar,
    useLocalStorage,
    useLocalStorageState,
  } = hooks;  
  const { success, warning, info, error } = snackbar;
  const { styled, Paper, Typography, Box, Divider, Button, TextField } =
    materialUI;

  //PLACE FOR HOOKs
  const [assetDesc, setAssetDesc] = React.useState();
  const { t, i18n } = useTranslation();
  const params = React.useMemo(
    () => ({ ent_id: asset ? asset.id.toString() : "" }),
    [asset]
  );

  //HTTP REQUESTS
  const http = axios.create({
    baseURL: mesApiUrl,
    timeout: 10000,
    headers: { Authorization: "Bearer " + user.token },
  });

  //PLACE FOR STYLED COMPONENTS
  const Root = React.useMemo(
    () =>
      styled(Paper)(({ theme }) => ({
        height: "100%",
        overflow: "auto",
        padding: "0.5rem",
        boxSizing: "border-box",
      })),
    []
  );

  const Title = React.useMemo(
    () =>
      styled("h1")(({ theme }) => ({
        fontSize: "1.8rem",
      })),
    []
  );

  //YOUR COMPONENT LOGIC

  //TODO...

  //RENDER
  return (
    <Root elevation={2} square>
      <Title>{t("EditableComponentTitle")}</Title>
      <div>
        <Typography variant="subtitle1">
          Witaj {user.userDesc}! Znajdujesz się na stanowisku {asset.name}.
        </Typography>
        <Typography variant="subtitle2">
          Oto przykładowe użycie edytowalnego komponentu.
        </Typography>
      </div>
    </Root>
  );
};
`;

export enum ProductionGridItemKey {
  EDITABLE_COMPONENT = "EDITABLE_COMPONENT",
  GOOD_PRODUCTION = "GOOD_PRODUCTION",
  GOOD_PRODUCTION_READONLY = "GOOD_PRODUCTION_READONLY",
  BAD_PRODUCTION = "BAD_PRODUCTION",
  BAD_PRODUCTION_READONLY = "BAD_PRODUCTION_READONLY",
  JOB_INFORMATION = "JOB_INFORMATION",
  JOB_PROGRESS = "JOB_PROGRESS",
  ENTITY_PARAMETERS = "ENTITY_PARAMETERS",
}

export const defaultLayouts = {
  lg: [
    { i: ProductionGridItemKey.GOOD_PRODUCTION, x: 0, y: 0, w: 4, h: 8 },
    { i: ProductionGridItemKey.BAD_PRODUCTION, x: 4, y: 0, w: 4, h: 8 },
    { i: ProductionGridItemKey.JOB_INFORMATION, x: 8, y: 0, w: 8, h: 8 },
    { i: ProductionGridItemKey.JOB_PROGRESS, x: 0, y: 8, w: 16, h: 8 },
  ] as Array<GridLayout.Layout>,
  md: [
    { i: ProductionGridItemKey.GOOD_PRODUCTION, x: 0, y: 0, w: 6, h: 7 },
    { i: ProductionGridItemKey.BAD_PRODUCTION, x: 6, y: 0, w: 6, h: 7 },
    { i: ProductionGridItemKey.JOB_INFORMATION, x: 0, y: 7, w: 12, h: 7 },
    { i: ProductionGridItemKey.JOB_PROGRESS, x: 0, y: 14, w: 12, h: 7 },
  ] as Array<GridLayout.Layout>,
  sm: [
    { i: ProductionGridItemKey.GOOD_PRODUCTION, x: 0, y: 0, w: 4, h: 7 },
    { i: ProductionGridItemKey.BAD_PRODUCTION, x: 4, y: 0, w: 4, h: 7 },
    { i: ProductionGridItemKey.JOB_INFORMATION, x: 0, y: 7, w: 8, h: 7 },
    { i: ProductionGridItemKey.JOB_PROGRESS, x: 0, y: 14, w: 8, h: 7 },
  ] as Array<GridLayout.Layout>,
  xs: [
    { i: ProductionGridItemKey.GOOD_PRODUCTION, x: 0, y: 0, w: 6, h: 7 },
    { i: ProductionGridItemKey.BAD_PRODUCTION, x: 0, y: 7, w: 6, h: 7 },
    { i: ProductionGridItemKey.JOB_INFORMATION, x: 0, y: 14, w: 6, h: 7 },
    { i: ProductionGridItemKey.JOB_PROGRESS, x: 0, y: 21, w: 6, h: 7 },
  ] as Array<GridLayout.Layout>,
} as GridLayout.Layouts;

export const defaultItems = [
  ProductionGridItemKey.GOOD_PRODUCTION,
  ProductionGridItemKey.BAD_PRODUCTION,
  ProductionGridItemKey.JOB_INFORMATION,
  ProductionGridItemKey.JOB_PROGRESS,
];
