import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  ProductionEventAggregatedSocketDto,
  ProductionEventCorrectedSocketDto,
  ProductionEventCreatedSocketDto,
  ProductionEventFinishedSocketDto,
} from "../../../services/sockets/hub-services/manufacturing-execution/productionEventsAssetGroupService";

export interface ProductionEventsState {
  connectionStatus: HubConnectionState;
}

export const initialState: ProductionEventsState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const productionEventsSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    productionEventsConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    productionEventFinishedSocketEvent: (
      state,
      action: PayloadAction<ProductionEventFinishedSocketDto>
    ) => {},
    productionEventCorrectedSocketEvent: (
      state,
      action: PayloadAction<ProductionEventCorrectedSocketDto>
    ) => {},
    productionEventCreatedSocketEvent: (
      state,
      action: PayloadAction<ProductionEventCreatedSocketDto>
    ) => {},
    productionEventAggregatedSocketEvent: (
      state,
      action: PayloadAction<ProductionEventAggregatedSocketDto>
    ) => {},
  },
});

export const {
  productionEventsConnectionStatusChanged,
  productionEventFinishedSocketEvent,
  productionEventCorrectedSocketEvent,
  productionEventCreatedSocketEvent,
  productionEventAggregatedSocketEvent,
} = productionEventsSlice.actions;

export const selectProductionEventsConnectionStatus = createSelector(
  (state: RootState) =>
    state.sockets.manufacturingExecution.productionEvents.connectionStatus,
  (connectionStatus) => connectionStatus
);

export default productionEventsSlice.reducer;
