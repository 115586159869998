import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="Icon / GenericEdit"
      xmlns="http://www.w3.org/2000/svg"
      width={20.079}
      height={20.08}
      viewBox="0 0 20.079 20.08"
      {...props}
    >
      <g data-name="Edit / Line / M">
        <path
          data-name="Path 1347"
          d="M19.029 1.048a3.573 3.573 0 010 5.06l-11.97 11.97a2.257 2.257 0 01-1 .579l-5.11 1.4a.748.748 0 01-.92-.921l1.39-5.116a2.2 2.2 0 01.58-1l11.97-11.968a3.577 3.577 0 015.06-.004zm-6.03 3.091l-9.94 9.939a.72.72 0 00-.19.333l-1.05 3.849 3.85-1.05a.759.759 0 00.33-.193l9.94-9.938zm2.03-2.031l-.97.97 2.94 2.94.97-.97a2.079 2.079 0 00-2.94-2.94z"
          fill="#212121"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
