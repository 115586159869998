import { makeStyles } from "tss-react/mui";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
} from "@mui/material";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
  },

  dialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
}));

export interface ConfirmSaveGlobalTableDialogProps {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
}

const ConfirmSaveGlobalTableDialogComponent: React.FC<
  ConfirmSaveGlobalTableDialogProps
> = ({ open, handleConfirm, handleClose, setSubmitting }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      maxWidth={false}
    >
      <DialogTitle>{t("Confirm save to all entities")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("ConfirmSendToMesContent")}</DialogContentText>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setSubmitting(true);
              handleConfirm();
              setTimeout(() => setSubmitting(false), 500);
            }}
          >
            {t("Submit")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose()}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmSaveGlobalTableDialogComponent;
