import { TextField } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeaderProps } from "react-table";

// const useStyles = makeStyles()((theme: Theme) => ({
//   filterTextField: {
//     paddingTop: "0.5rem",
//     fontSize: "0.9rem",
//   },

//   underline: {
//     "&:after": {
//       borderBottom: "2px solid #00CAE4",
//     },
//   },
// }));

const DefaultColumnFilterComponent: React.FC<HeaderProps<any>> = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const { t } = useTranslation();
  // const { classes } = useStyles();

  return (
    <TextField
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={t("FilterPlaceholder")}
      fullWidth
      variant="standard"

      // className={classes.filterTextField}
      // InputProps={{
      //   classes: {
      //     input: classes.filterTextField,
      //     underline: classes.underline,
      //   },
      // }}
    />
  );
};

export default DefaultColumnFilterComponent;
