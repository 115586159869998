import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { NestedSystemAttributeGroupDTO } from "../../services/httpService";
import { getNestedSystemAttributeGroups } from "../../services/main/systemAttributeGroupsService";
import AppAttributeGroups from "../../helpers/enums/appAttributeGroups";
import MainAttributeGroups from "../../helpers/enums/mainAttributeGroups";
import { RootState } from "..";
import SectionAttributeGroups from "../../helpers/enums/sectionAttributeGroups";
import GridName from "../../helpers/enums/gridName";
import ConfigurationAttributes from "../../helpers/enums/configurationAttributes";

const appAttrGroup = AppAttributeGroups.ImOperator;

export const configurationAttributeName = new Map<
  GridName,
  ConfigurationAttributes
>([
  [GridName.OVERVIEW, ConfigurationAttributes.OVERVIEW_LAYOUT_CONFIGURATION],
  [
    GridName.PRODUCTION,
    ConfigurationAttributes.PRODUCTION_LAYOUT_CONFIGURATION,
  ],
]);

export interface NestedSystemAttributeGroup
  extends NestedSystemAttributeGroupDTO {}

export const initialState: Array<NestedSystemAttributeGroup> = [];

export const loadNestedAttributeGroupsAsync = createAsyncThunk(
  "definitions/loadNestedAttributeGroupsAsync",
  async (_, { getState, dispatch }) => {
    const result = await getNestedSystemAttributeGroups();
    return result;
  }
);

export const attributeGroupsSlice = createSlice({
  name: "attributeGroups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadNestedAttributeGroupsAsync.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(loadNestedAttributeGroupsAsync.rejected, (state, action) => {
        return [];
      });
  },
});

export const selectComponentsAttributeGroup = createSelector(
  (state: RootState) => state.definitions.attributeGroups,
  (attributeGroups) =>
    attributeGroups
      .find((g) => g.name === MainAttributeGroups.USER_INTERFACE)
      ?.systemAttributeGroups?.find((uig) => uig.name === appAttrGroup)
      ?.systemAttributeGroups?.find(
        (og) => og.name === appAttrGroup + SectionAttributeGroups.Components
      )
);

export const selectLayoutsAttributeGroup = createSelector(
  (state: RootState) => state.definitions.attributeGroups,
  (attributeGroups) =>
    attributeGroups
      .find((g) => g.name === MainAttributeGroups.USER_INTERFACE)
      ?.systemAttributeGroups?.find((uig) => uig.name === appAttrGroup)
      ?.systemAttributeGroups?.find(
        (og) => og.name === appAttrGroup + SectionAttributeGroups.Layouts
      )
);

export const selectLayoutAttribute = createSelector(
  (state: RootState) => state.definitions.attributeGroups,
  (state: RootState, gridName: GridName) => gridName,
  (attributeGroups, gridName) =>
    attributeGroups
      .find((g) => g.name === MainAttributeGroups.USER_INTERFACE)
      ?.systemAttributeGroups?.find((uig) => uig.name === appAttrGroup)
      ?.systemAttributeGroups?.find(
        (og) => og.name === appAttrGroup + SectionAttributeGroups.Layouts
      )
      ?.systemAttributes?.find(
        (a) => a.name === configurationAttributeName.get(gridName)
      )
);

export default attributeGroupsSlice.reducer;
