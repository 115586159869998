import { TFunction } from "i18next";
import { FilterValue, Row } from "react-table";
import {
  FormatDurationFromStringToSeconds,
  FormatDurationString,
} from "./FormatDuration";
import { NumberComparisonOperator } from "../NumberComparisonOperator";

export function filterDurations<T extends object>(
  rows: Array<Row<T>>,
  id: Array<string>,
  filterValue: FilterValue,
  t: TFunction
) {
  return rows.filter((row) => {
    const rowValue = FormatDurationString(row.values[id[0]], t);
    return rowValue !== undefined
      ? String(rowValue)
          .toLowerCase()
          .includes(String(filterValue).toLowerCase())
      : true;
  });
}

export interface DurationFilter {
  operand: NumberComparisonOperator | "";
  maskValue: string | undefined;
}

export function filterDuration<T extends object>(
  rows: Array<Row<T>>,
  id: Array<string>,
  filterValue: DurationFilter,
  t: TFunction
) {
  const operator = filterValue.operand;
  const valueToCompare = FormatDurationFromStringToSeconds(
    filterValue.maskValue ?? "0",
    t
  );
  return rows.filter((row) => {
    const value = row.values[id[0]];
    if (valueToCompare === undefined || operator === "") return true;
    if (operator === NumberComparisonOperator.LessThan)
      return value < Number(valueToCompare);

    if (operator === NumberComparisonOperator.LessOrEqualThan)
      return value <= Number(valueToCompare);

    if (operator === NumberComparisonOperator.GreaterThan)
      return value > Number(valueToCompare);

    if (operator === NumberComparisonOperator.GreaterOrEqualThan)
      return value >= Number(valueToCompare);

    if (operator === NumberComparisonOperator.Equal)
      return value === Number(valueToCompare);
    return true;
  });
}
