import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectIsAnySocketDisconnected,
  selectIsAnySocketReconnecting,
} from "../../store/sockets";
import { logOutAsync } from "../../store/user/authSlice";

const ProgressWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "0.5rem",
}));

export interface DialogMesSocketProps {}

const DialogMesSocketComponent: React.FC<DialogMesSocketProps> = () => {
  const isAnySocketDisconnected = useAppSelector(selectIsAnySocketDisconnected);
  const isAnySocketReconnecting = useAppSelector(selectIsAnySocketReconnecting);
  const connectedToMes = useAppSelector((state) => state.user.connectionToMes);
  const pendingLogOut = useAppSelector(
    (state) => state.user.auth.pendingLogOut
  );
  const pendingLogIn = useAppSelector((state) => state.user.auth.pendingLogIn);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !pendingLogOut &&
      !pendingLogIn &&
      connectedToMes &&
      isAnySocketDisconnected
    ) {
      dispatch(logOutAsync());
    }
  }, [
    dispatch,
    connectedToMes,
    pendingLogIn,
    pendingLogOut,
    isAnySocketDisconnected,
  ]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isAnySocketReconnecting}
      onClose={() => {}}
    >
      <DialogTitle>{t("Connection was lost")}</DialogTitle>
      <DialogContent>
        <span>{t("Reconnecting")}</span>
        <ProgressWrapper>
          <CircularProgress size={54} />
        </ProgressWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          color="warning"
          variant="contained"
          onClick={() => dispatch(logOutAsync())}
        >
          {t("Logout")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogMesSocketComponent;
