import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  AssetLogonsClient,
  LogOffAssetRequest,
  LogOnAssetDto,
  LogOnAssetRequest,
} from "../httpService";

const http = new AssetLogonsClient(env.REACT_APP_MES_API_URL, instance);

export const logOnAsset = async (
  assetId: string,
  logOffOtherEnts: boolean = true,
  throwError: boolean = true
): Promise<LogOnAssetDto | undefined> => {
  try {
    const request = new LogOnAssetRequest({
      assetId,
      logOffOtherEnts,
    });
    const result = await http.logOnAsset(request);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const logOffAsset = async (
  assetId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new LogOffAssetRequest({ assetId });
    await http.logOffAsset(request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
