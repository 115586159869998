import * as React from "react";

function SvgComponent(props: any) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        d="M469.058 421.161H42.942c-19.155 0-34.684-15.528-34.684-34.684V125.523c0-19.155 15.528-34.684 34.684-34.684h426.115c19.155 0 34.684 15.528 34.684 34.684v260.954c0 19.155-15.528 34.684-34.683 34.684z"
        fill="#103b9b"
      />
      <path
        d="M467.957 90.839h-44.608L256 202.405 88.65 90.839H39.271c-17.128 0-31.014 13.886-31.014 31.014v22.581L175.607 256 8.259 367.566v18.911c0 19.155 15.528 34.684 34.684 34.684H88.65L256 309.594 423.35 421.16h46.809c18.548 0 33.583-15.035 33.583-33.583v-20.012L336.392 256l167.35-111.566v-17.81c-.001-19.764-16.022-35.785-35.785-35.785z"
        fill="#fff"
      />
      <path
        d="M20.817 412.787c6.169 5.219 14.142 8.373 22.856 8.373h18.179l220.945-147.296 208.385 138.923 12.547-26.569.001-.033-221.243-147.844 208.694-139.13c-6.169-5.219-14.142-8.373-22.856-8.373h-18.18L229.214 238.142 20.817 99.211c-7.679 6.496-12.559 16.199-12.559 27.045v.337l220.659 147.461-208.1 138.733z"
        fill="#ed1f34"
      />
      <g fill="#121b21">
        <path d="M229.214 246.401a8.244 8.244 0 01-4.58-1.387L16.237 106.082a8.258 8.258 0 019.161-13.741l203.816 135.877 216.352-144.25a8.257 8.257 0 119.161 13.742L233.795 245.013a8.249 8.249 0 01-4.581 1.388zM503.734 394.452a8.205 8.205 0 01-4.58-1.393L277.899 245.207a8.259 8.259 0 01.008-13.737L486.601 92.341a8.258 8.258 0 019.161 13.742L297.363 238.348 508.33 379.326a8.257 8.257 0 012.277 11.454 8.246 8.246 0 01-6.873 3.672zM61.86 429.419a8.259 8.259 0 01-4.588-15.131l220.943-147.296a8.256 8.256 0 019.161 0l208.386 138.923a8.259 8.259 0 01-9.161 13.742L282.797 283.79 66.434 428.031a8.222 8.222 0 01-4.574 1.388zM20.825 421.046a8.259 8.259 0 01-4.588-15.131l197.806-131.87L3.67 133.459a8.257 8.257 0 01-2.277-11.454 8.256 8.256 0 0111.453-2.278l220.659 147.461a8.259 8.259 0 01-.008 13.737L25.398 419.659a8.22 8.22 0 01-4.573 1.387z" />
      </g>
      <path
        fill="#fff"
        d="M503.741 211.406H300.593V90.839h-89.186v120.567H8.259v89.187h203.148v120.568h89.186V300.593h203.148z"
      />
      <path
        fill="#ed1f34"
        d="M503.741 232.051H279.948V90.839h-47.896v141.212H8.259v47.897h223.793v141.213h47.896V279.948h223.793z"
      />
      <path
        d="M468.323 82.581H43.677c-24.083 0-43.676 19.592-43.676 43.676v259.487c0 24.083 19.594 43.676 43.676 43.676h424.646c24.083 0 43.676-19.592 43.676-43.676V126.256c0-24.083-19.593-43.675-43.676-43.675zm27.16 43.675v97.536H288.206V99.097h180.117c14.976 0 27.16 12.183 27.16 27.159zM43.677 99.097h180.117v124.697H16.517v-97.536c0-14.978 12.183-27.161 27.16-27.161zm-27.16 286.645v-97.536h207.277v124.697H43.677c-14.977 0-27.16-12.185-27.16-27.161zm451.806 27.161H288.206V288.206h149.471c4.562 0 8.258-3.697 8.258-8.258s-3.696-8.258-8.258-8.258H279.948a8.257 8.257 0 00-8.258 8.258v132.955h-31.38V279.948a8.257 8.257 0 00-8.258-8.258H16.517v-31.381h215.535a8.257 8.257 0 008.258-8.258V99.097h31.381v132.955a8.257 8.257 0 008.258 8.258h215.535v31.381H470.71c-4.562 0-8.258 3.697-8.258 8.258s3.696 8.258 8.258 8.258h24.774v97.536c-.001 14.975-12.185 27.16-27.161 27.16z"
        fill="#121b21"
      />
    </svg>
  );
}

export default SvgComponent;
