import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  UtilizationEventCorrectedSocketDto,
  UtilizationEventFinishedSocketDto,
  UtilizationEventSplittedSocketDto,
} from "../../../services/sockets/hub-services/utilization/utilizationEventsAssetGroupService";

export interface UtilizationEventsState {
  connectionStatus: HubConnectionState;
}

export const initialState: UtilizationEventsState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const utilizationEventsSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    utilizationEventsConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    utilizationEventFinishedSocketEvent: (
      state,
      action: PayloadAction<UtilizationEventFinishedSocketDto>
    ) => {},
    utilizationEventCorrectedSocketEvent: (
      state,
      action: PayloadAction<UtilizationEventCorrectedSocketDto>
    ) => {},
    utilizationEventSplittedSocketEvent: (
      state,
      action: PayloadAction<UtilizationEventSplittedSocketDto>
    ) => {},
  },
});

export const {
  utilizationEventsConnectionStatusChanged,
  utilizationEventFinishedSocketEvent,
  utilizationEventCorrectedSocketEvent,
  utilizationEventSplittedSocketEvent,
} = utilizationEventsSlice.actions;

export const selectUtilizationEventsConnectionStatus = createSelector(
  (state: RootState) =>
    state.sockets.utilization.utilizationEvents.connectionStatus,
  (connectionStatus) => connectionStatus
);

export default utilizationEventsSlice.reducer;
