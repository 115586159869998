import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <path fill="none" d="M0 0h16v16H0z" />
      <path
        d="M11.889 14H4.111A1.16 1.16 0 013 12.8l.006-8.4a1.157 1.157 0 011.105-1.2h.555V2h1.112v1.2h4.444V2h1.111v1.2h.555A1.159 1.159 0 0113 4.4v8.4a1.159 1.159 0 01-1.111 1.2zM4.111 6.8v6h7.778v-6zm0-2.4v1.2h7.778V4.4zm6.667 7.2H8v-3h2.778v3z"
        fill="#000100"
      />
    </svg>
  );
}

export default SvgComponent;
