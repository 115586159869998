import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  selectNewShiftDialogOpen,
  newShiftDialogClosed,
} from "../../../store/common/dialogs/new-shift/newShiftSlice";
import { loadNestedAssetsAsync } from "../../../store/definitions/assetsSlice";
import { loadNestedAttributeGroupsAsync } from "../../../store/definitions/attributeGroupsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { loadProductionEventsForCurrentShiftAsync } from "../../../store/main/production-events/prodEventsSlice";
import { loadUtilEventsForCurrentShiftAsync } from "../../../store/main/utilization-events/utilEventsSlice";
import { loadUtilizationExecsAsync } from "../../../store/main/util-execs/utilExecsSlice";

export interface NewShiftDialogProps {}

const NewShiftDialogComponent: React.FC<NewShiftDialogProps> = () => {
  const { t } = useTranslation();
  const defCount = 5;
  const open = useAppSelector(selectNewShiftDialogOpen);
  const [progress, setProgress] = useState<number>(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      fetchDefinitions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fetchDefinitions = async () => {
    //FIXME should be immadietly but event new shift comes from production planning - not from modules where information is refreshed
    await new Promise((resolve) => setTimeout(resolve, 3000));
    await dispatch(loadNestedAssetsAsync());
    setProgress((progress) => progress + 100 / defCount);
    await dispatch(loadNestedAttributeGroupsAsync());
    setProgress((progress) => progress + 100 / defCount);
    await dispatch(loadUtilizationExecsAsync());
    setProgress((progress) => progress + 100 / defCount);
    await dispatch(loadUtilEventsForCurrentShiftAsync());
    setProgress((progress) => progress + 100 / defCount);
    await dispatch(loadProductionEventsForCurrentShiftAsync());
    setProgress((progress) => progress + 100 / defCount);
  };

  return (
    <Dialog open={open} onClose={() => {}} fullWidth maxWidth={"sm"}>
      <DialogTitle>{t("Shift change")}</DialogTitle>
      <DialogContent sx={{ alignItems: "flex-start" }}>
        <Typography variant="subtitle1" gutterBottom>
          {t("DefLoading")}
        </Typography>
        <LinearProgress
          sx={{ width: "100%" }}
          variant="determinate"
          value={progress}
        />
        {progress >= 100 && (
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: "success.main" }}
          >
            {t("DefLoadingSuccess")}
          </Typography>
        )}
        {/* {progress >= 100 && (
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ color: "error.main" }}
          >
            {t("DefLoadingError")}
          </Typography>
        )} */}
      </DialogContent>
      <DialogActions>
        {progress >= 100 && (
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              dispatch(newShiftDialogClosed());
              setProgress(0);
            }}
          >
            {t("Close")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NewShiftDialogComponent;
