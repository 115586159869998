import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "../..";
import { GetNestedUtilLogsByLogIdDto } from "../../../services/httpService";
import { getNestedUtilizationEventHistory } from "../../../services/main/utilizationEventsService";
import { selectSelectedUtilEventLogId } from "../../main/utilization-events/utilEventsSlice";

export interface NestedUtilEventHistory extends GetNestedUtilLogsByLogIdDto {}

interface UtilEventHistoryDialogState {
  open: boolean;
  history: AsyncValue<NestedUtilEventHistory | null>;
}

export const initialState: UtilEventHistoryDialogState = {
  open: false,
  history: {
    value: null,
    pending: false,
  },
};

export const loadUtilizationEventHistoryAsync = createAsyncThunk(
  "utilEventHistoryDialog/loadUtilizationEventHistoryAsync",
  async (_, { getState, dispatch }) => {
    const selectedLogId = selectSelectedUtilEventLogId(getState() as RootState);
    const result = await getNestedUtilizationEventHistory(selectedLogId!);
    return result;
  }
);

export const utilEventHistorySlice = createSlice({
  name: "utilEventHistoryDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      state.open = true;
    },
    closed: (state, action: PayloadAction<void>) => {
      state.open = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUtilizationEventHistoryAsync.pending, (state, action) => {
        state.history.pending = true;
      })
      .addCase(loadUtilizationEventHistoryAsync.fulfilled, (state, action) => {
        state.history.value = action.payload!;
        state.history.pending = false;
      })
      .addCase(loadUtilizationEventHistoryAsync.rejected, (state, action) => {
        state.history.value = null;
        state.history.pending = false;
      });
  },
});

export const {
  opened: utilEventHistoryDialogOpened,
  closed: utilEventHistoryDialogClosed,
} = utilEventHistorySlice.actions;

export const selectUtilEventHistoryDialogOpen = createSelector(
  (state: RootState) => state.dialogs.utilEventHistoryDialog.open,
  (open) => open
);

export const selectUtilEventHistory = createSelector(
  (state: RootState) => state.dialogs.utilEventHistoryDialog.history.value,
  (value) => (value ? [value] : [])
);

export const selectUtilEventHistoryPending = createSelector(
  (state: RootState) => state.dialogs.utilEventHistoryDialog.history.pending,
  (open) => open
);

export default utilEventHistorySlice.reducer;
