import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ConfirmCancelNewTableConfigurationsDialogProps {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

const ConfirmCancelNewTableConfigurationsDialogComponent: React.FC<
  ConfirmCancelNewTableConfigurationsDialogProps
> = ({ open, handleConfirm, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <DialogTitle>{t("Confirm canceling of new configuration")}</DialogTitle>
      <DialogContent>{t("ConfirmCancelNewTableConfiguration")}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleConfirm()}
        >
          {t("Submit")}
        </Button>
        <Button variant="contained" color="error" onClick={() => handleClose()}>
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCancelNewTableConfigurationsDialogComponent;
