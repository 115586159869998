import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

export const initialState: boolean = false;

export const splitUtilEventSlice = createSlice({
  name: "splitUtilDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      return true;
    },
    closed: (state, action: PayloadAction<void>) => {
      return false;
    },
  },
});

export const {
  opened: splitUtilEventDialogOpened,
  closed: splitUtilEventDialogClosed,
} = splitUtilEventSlice.actions;

export const selectSplitUtilEventDialogOpen = createSelector(
  (state: RootState) => state.dialogs.openSplitUtilEventDialog,
  (open) => open
);

export default splitUtilEventSlice.reducer;
