import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "../../..";
import { GetNestedProductionLogHistoryByLogIdDto } from "../../../../services/httpService";
import { getNestedProductionEventHistory } from "../../../../services/main/productionEventsService";
import { selectSelectedProductionEventLogId } from "../../../main/production-events/prodEventsSlice";

export interface NestedProductionEventHistory
  extends GetNestedProductionLogHistoryByLogIdDto {}

interface ProductionEventHistoryDialogState {
  open: boolean;
  history: AsyncValue<NestedProductionEventHistory | null>;
}

export const initialState: ProductionEventHistoryDialogState = {
  open: false,
  history: {
    value: null,
    pending: false,
  },
};

export const loadProductionEventHistoryAsync = createAsyncThunk(
  "productionEventHistoryDialog/loadProductionEventHistoryAsync",
  async (_, { getState, dispatch }) => {
    const selectedLogId = selectSelectedProductionEventLogId(
      getState() as RootState
    );
    const result = await getNestedProductionEventHistory(selectedLogId!);
    return result;
  }
);

export const productionEventHistorySlice = createSlice({
  name: "productionEventHistoryDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      state.open = true;
    },
    closed: (state, action: PayloadAction<void>) => {
      state.open = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProductionEventHistoryAsync.pending, (state, action) => {
        state.history.pending = true;
      })
      .addCase(loadProductionEventHistoryAsync.fulfilled, (state, action) => {
        state.history.value = action.payload!;
        state.history.pending = false;
      })
      .addCase(loadProductionEventHistoryAsync.rejected, (state, action) => {
        state.history.value = null;
        state.history.pending = false;
      });
  },
});

export const {
  opened: productionEventHistoryDialogOpened,
  closed: productionEventHistoryDialogClosed,
} = productionEventHistorySlice.actions;

export const selectProductionEventHistoryDialogOpen = createSelector(
  (state: RootState) =>
    state.dialogs.productionEventsDialogs.productionEventHistoryDialog.open,
  (open) => open
);

export const selectProductionEventHistory = createSelector(
  (state: RootState) =>
    state.dialogs.productionEventsDialogs.productionEventHistoryDialog.history
      .value,
  (value) => (value ? [value] : [])
);

export const selectProductionEventHistoryPending = createSelector(
  (state: RootState) =>
    state.dialogs.productionEventsDialogs.productionEventHistoryDialog.history
      .pending,
  (open) => open
);

export default productionEventHistorySlice.reducer;
