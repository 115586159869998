import { TFunction } from "i18next";
import * as React from "react";

export const FormatUtilState = (
  utilStateName: string,
  t: TFunction,
  barColor?: string
) => {
  return (
    <span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {barColor && (
          <div
            style={{
              height: "1.5rem",
              minWidth: "0.5rem",
              marginRight: "0.25rem",
              backgroundColor: barColor,
            }}
          />
        )}
        <div
          style={{
            textAlign: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {t(utilStateName)}
        </div>
      </div>
    </span>
  );
};

export const FormatUtilStateString = (utilStateName: string, t: TFunction) => {
  return t(utilStateName);
};
