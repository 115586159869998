import { Typography, TypographyOwnProps } from "@mui/material";
import dayjs from "dayjs";
import humanizeDuration from "humanize-duration";
import React from "react";
import { FunctionComponent, useEffect, useState } from "react";

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

interface DurationProps {
  startDate: Date;
  endDate: Date | null;
  typographyVariant?: TypographyOwnProps["variant"];
}

const Duration: FunctionComponent<DurationProps> = ({
  startDate,
  endDate,
  typographyVariant = "body2",
}) => {
  const [duration, setDuration] = useState(
    dayjs(endDate ?? new Date()).diff(startDate, "seconds")
  );

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    if (!endDate) {
      setDuration(dayjs(endDate ?? new Date()).diff(startDate, "seconds"));
      interval = setInterval(
        () => setDuration((duration) => duration + 1),
        1000
      );
    } else {
      setDuration(dayjs(endDate ?? new Date()).diff(startDate, "seconds"));
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [startDate, endDate]);

  return (
    <Typography variant={typographyVariant} fontWeight="inherit">
      {shortEnglishHumanizer(duration * 1000, {
        units: ["h", "m", "s"],
        delimiter: " ",
      })}
    </Typography>
  );
};

export default Duration;
