import moment from "moment";
import { FilterValue, Row } from "react-table";
import i18n from "../i18n";
import { formatDuration } from "./tableFormatters";

const isFilteringDisabled = <T extends object>(row: Row<T>): boolean => {
  return (row.original as any).isEditing ?? false;
};

//CUSTOM
const _filterPermanent = <T extends object>(
  row: Row<T>,
  id: Array<string>,
  filterValue: FilterValue
): boolean => {
  if (row.subRows.length > 0) {
    return row.subRows.some((row) => _filterPermanent(row, id, filterValue));
  } else {
    const value = Boolean(row.values[id[0]]);
    if (filterValue) {
      return value === undefined || !value;
    } else if (filterValue === false) {
      return value === undefined || value;
    } else {
      return true;
    }
  }
};

export const filterPermanent = <T extends object>(
  rows: Array<Row<T>>,
  id: Array<string>,
  filterValue: FilterValue
) => {
  return rows.filter((row) => {
    return _filterPermanent(row, id, filterValue);
  });
};

//GLOBAL

const _filterText = <T extends object>(
  row: Row<T>,
  id: Array<string>,
  filterValue: FilterValue
): boolean => {
  const value = row.values[id[0]] as string;
  if (value === undefined && row.subRows.length > 0) {
    return row.subRows.some((row) => _filterText(row, id, filterValue));
  } else {
    return (
      value === undefined ||
      value.toLowerCase().includes(String(filterValue).toLowerCase())
    );
  }
};

export const filterText = <T extends object>(
  rows: Array<Row<T>>,
  id: Array<string>,
  filterValue: FilterValue
) => {
  return rows.filter((row) => {
    return isFilteringDisabled(row) || _filterText(row, id, filterValue);
  });
};

const _filterBoolean = <T extends object>(
  row: Row<T>,
  id: Array<string>,
  filterValue: FilterValue
): boolean => {
  if (row.subRows.length > 0) {
    return row.subRows.some((row) => _filterBoolean(row, id, filterValue));
  } else {
    const value = Boolean(row.values[id[0]]);
    if (filterValue !== undefined) {
      return value === undefined || value === filterValue;
    } else {
      return true;
    }
  }
};

export const filterBoolean = <T extends object>(
  rows: Array<Row<T>>,
  id: Array<string>,
  filterValue: FilterValue
) => {
  return rows.filter((row) => {
    return isFilteringDisabled(row) || _filterBoolean(row, id, filterValue);
  });
};

const _filterNumber = <T extends object>(
  row: Row<T>,
  id: Array<string>,
  filterValue: FilterValue
): boolean => {
  if (row.subRows.length > 0) {
    return row.subRows.some((row) => _filterNumber(row, id, filterValue));
  } else {
    const value = row.values[id[0]] as Number;
    return value >= filterValue[0] && value <= filterValue[1];
  }
};

export function filterNumber<T extends object>(
  rows: Array<Row<T>>,
  id: Array<string>,
  filterValue: FilterValue
) {
  return rows.filter((row) => {
    return isFilteringDisabled(row) || _filterNumber(row, id, filterValue);
  });
}

const _filterDateTime = <T extends object>(
  row: Row<T>,
  id: Array<string>,
  filterValue: FilterValue,
  timeFormat: string
): boolean => {
  if (row.subRows.length > 0) {
    return row.subRows.some((row) =>
      _filterDateTime(row, id, filterValue, timeFormat)
    );
  } else {
    const value = row.values[id[0]] as Date;
    const stringValue = value
      ? moment(value).local().format(i18n.t(timeFormat).toString())
      : "-----";
    return value === undefined || stringValue.includes(filterValue);
  }
};

export const filterDateTime =
  <T extends object>(timeFormat: string) =>
  (rows: Array<Row<T>>, id: Array<string>, filterValue: FilterValue) => {
    return rows.filter((row) => {
      return (
        isFilteringDisabled(row) ||
        _filterDateTime(row, id, filterValue, timeFormat)
      );
    });
  };

const _filterDuration = <T extends object>(
  row: Row<T>,
  id: Array<string>,
  filterValue: FilterValue
): boolean => {
  if (row.subRows.length > 0) {
    return row.subRows.some((row) => _filterDuration(row, id, filterValue));
  } else {
    const rowValue = formatDuration(row.values[id[0]]);
    return rowValue !== undefined
      ? String(rowValue)
          .toLowerCase()
          .includes(String(filterValue).toLowerCase())
      : true;
  }
};

export function filterDuration<T extends object>(
  rows: Array<Row<T>>,
  id: Array<string>,
  filterValue: FilterValue
) {
  return rows.filter((row) => {
    return isFilteringDisabled(row) || _filterDuration(row, id, filterValue);
  });
}
