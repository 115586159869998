import { createListenerMiddleware } from "@reduxjs/toolkit";
import { getUserById } from "../../services/main/usersService";
import {
  logInAsync,
  logOutAsync,
  refreshTokenAsync,
  userDetailsLoaded,
} from "./authSlice";

const userListener = createListenerMiddleware();

userListener.startListening({
  actionCreator: logInAsync.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const userDetails = await getUserById(action.payload?.userId!);

    userDetails &&
      listenerApi.dispatch(
        userDetailsLoaded({
          userName: userDetails.userName!,
          userDesc: userDetails.description,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
        })
      );
  },
});

userListener.startListening({
  actionCreator: refreshTokenAsync.rejected,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(logOutAsync());
  },
});

export default userListener;
