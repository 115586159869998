import { Paper, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  ScatterDataPoint,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import "chartjs-adapter-moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { useAppSelector } from "../../../../../store/hooks";
import { selectRunnedTaskProductionEvents } from "../../../../../store/main/production-events/prodEventsSlice";
import moment from "moment";
import ProductionReason from "../../../../../helpers/enums/productionReasonEnum";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";
import { Line } from "react-chartjs-2";
import _ from "lodash";

ChartJS.register(
  Filler,
  TimeScale,
  TimeSeriesScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles()((theme: Theme) => ({
  chartHeader: {
    fontSize: "1.8rem",
    textAlign: "left",
  },
  chart: {
    minWidth: 0,
    minHeight: 0,
  },
}));

interface WorkOrderProgressChartProps {
  chartStyle: string;
}

const WorkOrderProgressChartComponent: React.FC<
  WorkOrderProgressChartProps
> = ({ chartStyle }) => {
  const runnedTask = useAppSelector(selectRunnedTask);
  const productionEvents = useAppSelector(selectRunnedTaskProductionEvents);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const data = useMemo(() => {
    const successColor = theme.palette.success.main;
    const errorColor = theme.palette.error.main;
    let labels: Array<any> = [];
    let pointRadiuses: Array<number> = [];
    let data: Array<any> = [];
    let backgroundColor: Array<string> = [];
    let borderColor: Array<string> = [];
    let accQty = 0;
    _.orderBy(productionEvents, "startedAt").forEach((o) => {
      labels.push(moment(o.startedAt));
      if ((o.quantityProduced ?? 0) > 50) {
        pointRadiuses.push(5);
      } else if ((o.quantityProduced ?? 0) > 10) {
        pointRadiuses.push(4);
      } else {
        pointRadiuses.push(3);
      }
      accQty += o.quantityProduced;
      data.push({ x: moment(o.startedAt), y: accQty });
      backgroundColor.push(
        o.productionReasonName === ProductionReason.Good
          ? successColor
          : errorColor
      );
      borderColor.push(
        o.productionReasonName === ProductionReason.Good
          ? successColor
          : errorColor
      );
    });

    return {
      labels: labels,
      datasets: [
        {
          label: `${t("Work order progress")} [${t(
            runnedTask?.abbreviation || runnedTask?.uomName || ""
          )}]`,
          data: data,
          pointRadius: pointRadiuses,
          fill: true,
          borderColor: theme.palette.text.secondary,
          pointBackgroundColor: backgroundColor,
          pointBorderColor: backgroundColor,
        },
      ],
    };
  }, [
    t,
    runnedTask,
    productionEvents,
    theme.palette.success.main,
    theme.palette.error.main,
    theme.palette.text.secondary,
  ]);

  return (
    <Paper className={chartStyle} elevation={2} square>
      <div className={classes.chartHeader}>
        {t("Work order progress")} [
        {t(runnedTask?.abbreviation || runnedTask?.uomName || "")}]
      </div>
      <div className={classes.chart}>
        {data && (
          <Line
            data={data}
            // redraw={true}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              interaction: {
                mode: "nearest",
                intersect: false,
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    title: (tooltipItems: Array<TooltipItem<"line">>) => {
                      return (tooltipItems[0]?.raw as any)?.x?.format(
                        t("DateWithTimeWithSecondsFormat")
                      );
                    },
                    label: (tooltipItem: TooltipItem<"line">) => {
                      const previousValue =
                        (
                          tooltipItem.dataset.data[
                            tooltipItem.dataIndex - 1
                          ] as ScatterDataPoint
                        )?.y ?? 0;
                      const currentValue =
                        (
                          tooltipItem.dataset.data[
                            tooltipItem.dataIndex
                          ] as ScatterDataPoint
                        )?.y ?? 0;
                      const diff = currentValue - previousValue;

                      return `${currentValue} (${
                        diff >= 0 ? "+" : diff < 0 ? "-" : ""
                      }${diff})`;
                    },
                  },
                },
              },
              scales: {
                x: {
                  type: "time",
                  bounds: "data",
                  title: {
                    display: true,
                    text: t("Time"),
                  },
                  ticks: {
                    source: "auto",
                  },
                  time: {
                    minUnit: "hour",
                    displayFormats: {
                      millisecond: t("TimeWithSecondsFormat"), //"HH:mm:ss.SSS",
                      second: t("TimeWithSecondsFormat"), //"HH:mm:ss",
                      hour: t("TimeWithoutSecondsFormat"), //"HH:mm",
                      minute: t("TimeWithoutSecondsFormat"), //"HH:mm",
                      day: t("DateFormat"), //"DD.MM",
                    },
                  },
                },
                y: {},
              },
            }}
          />
        )}
      </div>
    </Paper>
  );
};

export default WorkOrderProgressChartComponent;
