import { Stack, Typography } from "@mui/material";
import React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface AssetNotSelectedProps {}

const AssetNotSelected: FunctionComponent<AssetNotSelectedProps> = () => {
  const { t } = useTranslation();
  return (
    <Stack flexGrow={1} justifyContent="center" alignItems="center">
      <Typography variant="h5" variantMapping={{ h1: "h6" }}>
        {t("Asset not selected")}
      </Typography>
    </Stack>
  );
};

export default AssetNotSelected;
