import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      data-name="Icon / Operator"
      width={16}
      height={20}
      viewBox="0 0 16 20"
      {...props}
    >
      <g data-name="Person / Line / M">
        <path
          data-name="Path 1523"
          d="M13.8 12a2.237 2.237 0 012.2 2.25v.57a3.645 3.645 0 01-.9 2.44C13.5 19.1 11.1 20 8 20s-5.5-.9-7.1-2.74a3.638 3.638 0 01-.9-2.43v-.58A2.258 2.258 0 012.3 12zm0 1.5H2.3a.744.744 0 00-.8.75v.58a2.522 2.522 0 00.5 1.46q1.95 2.2 6 2.21t6-2.21a2.528 2.528 0 00.5-1.47v-.57a.72.72 0 00-.7-.75zM8 0a5 5 0 11-5 5 4.976 4.976 0 015-5zm0 1.5A3.5 3.5 0 1011.5 5 3.523 3.523 0 008 1.5z"
          // fill="#212121"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
