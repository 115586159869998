import { styled } from "@mui/material";
import * as React from "react";
import ManageProductionHistoryComponent from "./ManageProductionHistoryComponent";
import ProductionHistoryTableComponent from "./ProductionHistoryTableComponent";
import DialogUpdateProductionEventComponent from "./dialogs/DialogUpdateProductionEventComponent";
import DialogProductionEventHistoryComponent from "./dialogs/DialogProductionEventHistoryComponent";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "0.5rem",
  height: "calc(100% - 1rem)",
  width: "calc(100% - 1rem)",
  maxWidth: "calc(100% - 1rem)",
  maxHeight: "calc(100% - 1rem)",
  gap: "0.5rem",
}));

export interface ProductionHistoryProps {}

const ProductionHistoryComponent: React.FC<ProductionHistoryProps> = () => {
  return (
    <Root>
      <ProductionHistoryTableComponent />
      <ManageProductionHistoryComponent />

      <DialogUpdateProductionEventComponent />
      <DialogProductionEventHistoryComponent />
    </Root>
  );
};

export default ProductionHistoryComponent;
