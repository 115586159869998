import { styled, Theme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { useAppSelector } from "../../../../../store/hooks";
import { selectRunnedTask } from "../../../../../store/main/job-execution/jobExecutionSlice";

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  "&.MuiLinearProgress-root": {
    height: 25,
  },
}));

const useStyles = makeStyles()((theme: Theme) => ({
  deadlineConent: {
    display: "flex",
    justifyContent: "space-between",
  },

  paper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: "1rem",
    paddingTop: "1.5rem",
    position: "relative",
    boxSizing: "border-box",
  },

  h2: {
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    fontSize: "1.5rem",
    marginBottom: 0,
    width: "10rem",
    textAlign: "center",
  },

  h4: {
    marginBottom: "0.5rem",
  },
}));

interface ProgresOfWorkProps {}

const ProgresOfWorkComponent: React.FC<ProgresOfWorkProps> = () => {
  const { classes } = useStyles();
  const runnedTask = useAppSelector(selectRunnedTask);
  const { t } = useTranslation();
  const [actualDateTime, setActualDateTime] = useState(new Date());

  const productionProgress = useMemo(() => {
    return runnedTask
      ? ((runnedTask.quantityProduced || 0) / runnedTask.quantityRequired) * 100
      : 0;
  }, [runnedTask]);

  const scheduleProgress = useMemo(() => {
    if (
      runnedTask &&
      runnedTask.scheduledStartAt !== null &&
      runnedTask.scheduledFinishAt !== null
    ) {
      const ticks =
        runnedTask.scheduledFinishAt.getTime() -
        runnedTask.scheduledStartAt.getTime();
      return (ticks / 1000).toFixed(1);
    }
  }, [runnedTask]);

  const colorVariant: "success" | "error" | "warning" = useMemo(() => {
    const ratioQtyProd = runnedTask?.quantityRequired
      ? (runnedTask?.quantityProduced || 0) / runnedTask?.quantityRequired
      : 0;
    const ratioRuntime = (runnedTask?.duration ?? 0) / Number(scheduleProgress);
    const ratioProgress = ratioQtyProd / ratioRuntime;
    if (ratioProgress > 0 && ratioProgress < 0.8) {
      return "error";
    }
    if (ratioProgress > 0.8 && ratioProgress < 1) {
      return "warning";
    }
    if (ratioProgress >= 1) {
      return "success";
    }
    return "error";
  }, [runnedTask, scheduleProgress]);

  const progressDueDate = useMemo(() => {
    if (
      runnedTask &&
      runnedTask.scheduledFinishAt !== null &&
      runnedTask.scheduledStartAt !== null
    ) {
      const diffSchedule =
        runnedTask.scheduledFinishAt.getTime() -
        runnedTask.scheduledStartAt.getTime();
      const diffReal =
        actualDateTime.getTime() - runnedTask.scheduledStartAt.getTime();
      if (runnedTask.scheduledFinishAt.getTime() < actualDateTime.getTime()) {
        return 100;
      }
      return (diffReal / diffSchedule) * 100;
    }
  }, [actualDateTime, runnedTask]);

  const progressRuntime = useMemo(() => {
    if (scheduleProgress && runnedTask?.duration) {
      if (Number(scheduleProgress) > runnedTask?.duration) {
        return (runnedTask?.duration / Number(scheduleProgress)) * 100;
      } else {
        return 100;
      }
    } else {
      return 0;
    }
  }, [scheduleProgress, runnedTask?.duration]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActualDateTime(new Date());
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Paper elevation={2} className={classes.paper} square>
      <div style={{ display: "flex", width: "100%" }}>
        <div className={classes.h2}>
          <div style={{ display: "flex" }}>{t("Progress")}:</div>
        </div>
        <div style={{ width: "100%" }}>
          <div className={classes.deadlineConent}>
            <h4 className={classes.h4}>0%</h4>
            <h4 className={classes.h4}>
              {productionProgress && Number(productionProgress.toFixed(1))}%
            </h4>
            <h4 className={classes.h4}>100%</h4>
          </div>

          <StyledLinearProgress
            variant="determinate"
            color="success"
            value={
              productionProgress
                ? Math.round(productionProgress) >= 100
                  ? 100
                  : Number(productionProgress.toFixed(1))
                : 0
            }
          />
        </div>
      </div>

      <div style={{ display: "flex", width: "100%" }}>
        <div className={classes.h2}>{t("Due date")}:</div>
        <div style={{ width: "100%" }}>
          <div className={classes.deadlineConent}>
            <h4 className={classes.h4}>
              {moment(runnedTask?.scheduledStartAt).format(
                t("DateWithTimeWithoutSecondsFormat")
              )}
            </h4>
            <h4 className={classes.h4}>
              {moment(actualDateTime).format(
                t("DateWithTimeWithoutSecondsFormat")
              )}
            </h4>
            <h4 className={classes.h4}>
              {moment(runnedTask?.scheduledFinishAt).format(
                t("DateWithTimeWithoutSecondsFormat")
              )}
            </h4>
          </div>
          <StyledLinearProgress
            variant="determinate"
            color="success"
            value={progressDueDate}
          />
        </div>
      </div>

      <div style={{ display: "flex", width: "100%" }}>
        <div className={classes.h2}>{t("Time")}:</div>
        <div style={{ width: "100%" }}>
          <div className={classes.deadlineConent}>
            <h4 className={classes.h4}>0.0 {t("h")}</h4>
            <h4 className={classes.h4}>
              {((runnedTask?.duration ?? 0) / 3600).toFixed(1)} {t("h")}
            </h4>
            <h4 className={classes.h4}>
              {(Number(scheduleProgress) / 3600).toFixed(1)} {t("h")}
            </h4>
          </div>

          <StyledLinearProgress
            variant="determinate"
            color={colorVariant}
            value={progressRuntime}
          />
        </div>
      </div>
    </Paper>
  );
};

export default ProgresOfWorkComponent;
