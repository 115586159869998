import { Paper, styled } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectStepOfProduction } from "../../../store/main/job-execution/jobExecutionSlice";

const Root = styled(Paper)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "3.5rem",
}));

interface ProductionNavigationProps {}

const ProductionNavigationComponent: React.FC<
  ProductionNavigationProps
> = () => {
  const stepOfProduction = useAppSelector(selectStepOfProduction);
  const { t } = useTranslation();

  return (
    <Root>
      <Stepper
        sx={{ width: "100%" }}
        activeStep={stepOfProduction}
        elevation={2}
      >
        <Step key={"Select work order"}>
          <StepLabel>{t("Select work order")}</StepLabel>
        </Step>
        <Step key={"Register production"}>
          <StepLabel>{t("Register production")}</StepLabel>
        </Step>
        <Step key={"Production summary"}>
          <StepLabel>{t("Production summary")}</StepLabel>
        </Step>
      </Stepper>
    </Root>
  );
};
export default ProductionNavigationComponent;
