import { TFunction } from "i18next";
import * as React from "react";

export const FormatUtilRaw = (
  reasName: string,
  barColor: string | null,
  t: TFunction
) => {
  return (
    <span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "1.5rem",
            minWidth: "0.5rem",
            marginRight: "0.25rem",
            backgroundColor: barColor ?? "transparent",
          }}
        />
        <div
          style={{
            textAlign: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {t(reasName)}
        </div>
      </div>
    </span>
  );
};

export const FormatUtilRawString = (reasName: string, t: TFunction) => {
  return t(reasName);
};
