import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import TableChartIcon from "@mui/icons-material/TableChart";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { Box, Menu, useMediaQuery } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectedProps, connect } from "react-redux";
import { makeStyles } from "tss-react/mui";
import EditIcon from "../../icons/EditIcon";
import EnglishIcon from "../../icons/EnglishIcon";
import EntityIcon from "../../icons/EntityIcon";
import PolishIcon from "../../icons/PolishIcon";
import UserIcon from "../../icons/UserIcon";
import { RootState } from "../../store";
import { defaultAssetDialogOpened } from "../../store/common/dialogs/defaultAssetSlice";
import { editGridsDialogOpened } from "../../store/common/dialogs/editGridsSlice";
import { editTableDialogOpened } from "../../store/common/dialogs/editTableSlice";
import {
  gridEdititingStarted,
  resetLayoutsConfiguration,
  selectAreGridsEditable,
} from "../../store/common/gridsSlice";
import {
  editingTablesEnabled,
  resetTablesConfiguration,
} from "../../store/common/tablesSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import DialogResetLayoutsComponent from "./dialogs/grid/DialogResetLayoutsComponent";
import ResetTablesDialogComponent from "./dialogs/table/ResetTablesDialogComponent";
import { selectUserName } from "../../store/user/authSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    flexGrow: 1,
    fontSize: "1rem",
  },

  userNameContent: {
    display: "flex",
    alignItems: "center",
    marginRight: "0.5rem",
  },

  userNameIcon: {
    height: 25,
    width: 25,
    fill: "#ffffff",
    paddingRight: "0.5rem",
  },

  languageContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "space-between",
  },

  languageText: {
    paddingLeft: "0.5rem",
  },

  languageIcon: {
    height: 20,
    width: 20,
    paddingLeft: "0.8rem",
  },

  button: {
    borderRadius: "1rem",
    padding: "0.5rem",
  },

  buttonDisabled: {
    color: "inherit !important",
  },

  defaultEntContent: {
    display: "flex",
    alignItems: "center",
  },

  defaultEntText: {
    wordWrap: "break-word",
  },

  entIcon: {
    minHeight: 24,
    minWidth: 24,
    paddingRight: "0.25rem",
  },

  collapseItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  popperPaper: {
    zIndex: 100,
    minWidth: "20rem",
  },
}));

const mapState = (state: RootState) => {
  return {
    tables: state.tables,
  };
};

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

export interface AppBarUserContentProps {
  onLogout: () => void;
  openNavigation: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & AppBarUserContentProps;

const AppBarUserContent: React.FC<Props> = ({
  openNavigation,
  onLogout,
  tables,
}) => {
  const dispatch = useAppDispatch();
  const userName = useAppSelector(selectUserName);
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openLanguage, setOpenLanguage] = useState<boolean>(false);
  const [openEditSection, setOpenEditSection] = useState<boolean>(false);
  const [openResetSection, setOpenResetSection] = useState<boolean>(false);
  const [showResetLayoutsDialog, setShowResetLayoutsDialog] =
    useState<boolean>(false);
  const [showResetTablesDialog, setShowResetTablesDialog] =
    useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const areGridsEditable = useAppSelector(selectAreGridsEditable);
  const mayOverrideConfiguration = true;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (
    event: React.MouseEvent<EventTarget>,
    lng: string
  ) => {
    handleClose();
    i18n.changeLanguage(lng);
  };

  const handleClickLanguage = () => {
    setOpenLanguage(!openLanguage);
  };

  const handleLogout = async () => {
    setIsSubmitting(true);
    await onLogout();
  };

  const handleOpenDefaultEntityDialog = (event) => {
    handleClose();
    dispatch(defaultAssetDialogOpened());
  };

  const handleClickEditSection = () => {
    setOpenEditSection(!openEditSection);
  };

  const handleClickResetSection = () => {
    setOpenResetSection(!openResetSection);
  };

  const handleClickEditLayouts = (event: React.MouseEvent<EventTarget>) => {
    if (!areGridsEditable) {
      dispatch(gridEdititingStarted());
      dispatch(editGridsDialogOpened());
      handleClose();
    }
  };

  const handleResetLayouts = () => {
    dispatch(resetLayoutsConfiguration());
    setShowResetLayoutsDialog(false);
    handleClose();
  };

  const handleClickEditTables = (event: React.MouseEvent<EventTarget>) => {
    if (!tables.areTablesEditable) {
      dispatch(editingTablesEnabled());
      dispatch(
        editTableDialogOpened({ name: undefined, allColumns: undefined })
      );
      handleClose();
    }
  };

  const handleResetTables = () => {
    dispatch(resetTablesConfiguration());
    handleClose();
    setShowResetTablesDialog(false);
  };

  return (
    <div className={classes.userNameContent}>
      <IconButton
        classes={{ root: classes.button, disabled: classes.buttonDisabled }}
        onClick={handleMenu}
        color="inherit"
        size="medium"
        disabled={openNavigation && isSubmitting}
      >
        <UserIcon className={classes.userNameIcon} />
        {matches && (
          <Typography variant="h6" className={classes.title}>
            {userName}
          </Typography>
        )}
      </IconButton>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ className: classes.popperPaper }}
      >
        <MenuList>
          <MenuItem
            className={classes.collapseItem}
            onClick={handleClickLanguage}
          >
            <Box display="flex" alignItems="center">
              <LanguageIcon style={{ marginRight: "0.2rem" }}></LanguageIcon>
              {t("Language")}
            </Box>
            {openLanguage ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={openLanguage} timeout="auto" unmountOnExit>
            <MenuItem onClick={(event) => handleChangeLanguage(event, "en")}>
              <div className={classes.languageContent}>
                <EnglishIcon className={classes.languageIcon} />
                <div className={classes.languageText}>EN</div>
              </div>
            </MenuItem>
            <MenuItem onClick={(event) => handleChangeLanguage(event, "pl")}>
              <div className={classes.languageContent}>
                <PolishIcon className={classes.languageIcon} />
                <div className={classes.languageText}>PL</div>
              </div>
            </MenuItem>
          </Collapse>

          <MenuItem
            className={classes.collapseItem}
            onClick={handleClickResetSection}
          >
            <Box display="flex" alignItems="center">
              <RotateLeftIcon
                style={{ marginRight: "0.2rem" }}
              ></RotateLeftIcon>

              {t("Reset")}
            </Box>
            {openResetSection ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={openResetSection} timeout="auto" unmountOnExit>
            <MenuItem onClick={() => setShowResetLayoutsDialog(true)}>
              <ViewQuiltIcon
                style={{
                  marginRight: "0.2rem",
                  paddingLeft: "1.2rem",
                }}
              ></ViewQuiltIcon>
              {t("Restore default layouts")}
            </MenuItem>
            <MenuItem onClick={() => setShowResetTablesDialog(true)}>
              <TableChartIcon
                style={{
                  marginRight: "0.2rem",
                  paddingLeft: "1.2rem",
                }}
              ></TableChartIcon>
              {t("Restore default tables")}
            </MenuItem>
          </Collapse>

          {mayOverrideConfiguration && (
            <MenuItem
              className={classes.collapseItem}
              onClick={handleClickEditSection}
              disabled={!mayOverrideConfiguration}
            >
              <Box display="flex" alignItems="center">
                <EditIcon style={{ marginRight: "0.2rem" }}></EditIcon>
                {t("Edit")}
              </Box>
              {openEditSection ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
          )}

          {mayOverrideConfiguration && (
            <Collapse in={openEditSection} timeout="auto" unmountOnExit>
              <>
                <MenuItem
                  disabled={!mayOverrideConfiguration}
                  onClick={handleClickEditLayouts}
                >
                  <ViewQuiltIcon
                    style={{
                      marginRight: "0.2rem",
                      paddingLeft: "1.2rem",
                    }}
                  ></ViewQuiltIcon>
                  {t("Layout")}
                </MenuItem>

                <MenuItem
                  disabled={!mayOverrideConfiguration}
                  onClick={handleClickEditTables}
                >
                  <TableChartIcon
                    style={{
                      marginRight: "0.2rem",
                      paddingLeft: "1.2rem",
                    }}
                  ></TableChartIcon>
                  {t("Table")}
                </MenuItem>
              </>
            </Collapse>
          )}
          <MenuItem onClick={handleOpenDefaultEntityDialog}>
            <div className={classes.defaultEntContent}>
              <EntityIcon className={classes.entIcon}></EntityIcon>
              <div className={classes.defaultEntText}>
                {t("Default entity")}
              </div>
            </div>
          </MenuItem>
          <MenuItem onClick={handleLogout} disabled={isSubmitting}>
            <ExitToAppIcon style={{ marginRight: "0.2rem" }}></ExitToAppIcon>
            {t("Logout")}
          </MenuItem>
        </MenuList>
      </Menu>
      <DialogResetLayoutsComponent
        open={showResetLayoutsDialog}
        onCancel={() => setShowResetLayoutsDialog(false)}
        onConfirm={() => handleResetLayouts()}
      />
      <ResetTablesDialogComponent
        open={showResetTablesDialog}
        onCancel={() => setShowResetTablesDialog(false)}
        onConfirm={() => handleResetTables()}
      />
    </div>
  );
};

export default connector(AppBarUserContent);
