import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../..";
import { RunnedTaskDto } from "../../../services/httpService";
import { logOffTask } from "../../../services/main/taskLogonsService";
import {
  completeTask,
  getRunnedTaskById,
  pauseTask,
  resumeTask,
  startTask,
} from "../../../services/main/tasksService";
import { selectSelectedAssetId } from "../../user/assetSelectionSlice";
import { selectSelectedTaskId } from "../work-queue/workQueueSlice";

export enum StepOfProduction {
  FirstStep = 0,
  SecondStep = 1,
  FinalStep = 3,
}

export enum VariantOfSummarizeProduction {
  Complete = 0,
  Pause = 1,
}

export interface RunnedTask extends RunnedTaskDto {}

export interface JobExecutionState {
  runnedTask: RunnedTask | undefined;
  stepOfProduction: StepOfProduction;
  summarizeVariant: VariantOfSummarizeProduction;
}

export const initialState: JobExecutionState = {
  runnedTask: undefined,
  stepOfProduction: StepOfProduction.FirstStep,
  summarizeVariant: VariantOfSummarizeProduction.Pause,
};

export const runTaskAsync = createAsyncThunk(
  "production/runTaskAsync",
  async (_, { getState, dispatch }) => {
    const selectedTaskId = selectSelectedTaskId(getState() as RootState);
    const selectedAssetId = selectSelectedAssetId(getState() as RootState);

    await startTask(selectedTaskId!, selectedAssetId!);
    const runnedTask = await getRunnedTaskById(selectedTaskId!);

    return runnedTask;
  }
);

export const resumeTaskAsync = createAsyncThunk(
  "production/resumeTaskAsync",
  async (_, { getState, dispatch }) => {
    const selectedTaskId = selectSelectedTaskId(getState() as RootState);
    await resumeTask(selectedTaskId!);
    const runnedTask = await getRunnedTaskById(selectedTaskId!);
    return runnedTask;
  }
);

export const pauseTaskAsync = createAsyncThunk(
  "production/pauseTaskAsync",
  async (_, { getState, dispatch }) => {
    const runnedTaskId = selectRunnedTaskId(getState() as RootState);
    if (runnedTaskId) {
      await pauseTask(runnedTaskId);
    }
  }
);

export const completeTaskAsync = createAsyncThunk(
  "production/completeTaskAsync",
  async (_, { getState, dispatch }) => {
    const runnedTaskId = selectRunnedTaskId(getState() as RootState);
    if (runnedTaskId) {
      await completeTask(runnedTaskId);
    }
  }
);

export const leaveTaskAsync = createAsyncThunk(
  "production/leaveTaskAsync",
  async (_, { getState, dispatch }) => {
    const runnedTaskId = selectRunnedTaskId(getState() as RootState);
    if (runnedTaskId) {
      await logOffTask(runnedTaskId);
    }
  }
);

export const jobExecutionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    variantOfSummarizeProductionSelected: (
      state,
      action: PayloadAction<VariantOfSummarizeProduction>
    ) => {
      state.summarizeVariant = action.payload;
    },
    stepOfProductionSelected: (
      state,
      action: PayloadAction<StepOfProduction>
    ) => {
      state.stepOfProduction = action.payload;
    },
    runningTaskCleared: (state, action: PayloadAction<void>) => {
      state.runnedTask = undefined;
      state.stepOfProduction = StepOfProduction.FirstStep;
    },
    quantityGoodChanged: (state, action: PayloadAction<number>) => {
      if (state.runnedTask)
        state.runnedTask = {
          ...state.runnedTask,
          quantityProduced: action.payload,
        };
    },
    quantityBadChanged: (state, action: PayloadAction<number>) => {
      if (state.runnedTask)
        state.runnedTask = {
          ...state.runnedTask,
          quantityRejected: action.payload,
        };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(runTaskAsync.fulfilled, (state, action) => {
        state.runnedTask = action.payload!;
        state.stepOfProduction = StepOfProduction.SecondStep;
      })
      .addCase(runTaskAsync.rejected, (state, action) => {
        state.runnedTask = undefined;
        state.stepOfProduction = StepOfProduction.FirstStep;
      })
      .addCase(resumeTaskAsync.fulfilled, (state, action) => {
        state.runnedTask = action.payload!;
        state.stepOfProduction = StepOfProduction.SecondStep;
      })
      .addCase(resumeTaskAsync.rejected, (state, action) => {
        state.runnedTask = undefined;
        state.stepOfProduction = StepOfProduction.FirstStep;
      })
      .addCase(pauseTaskAsync.fulfilled, (state, action) => {
        state.runnedTask = undefined;
        state.stepOfProduction = StepOfProduction.FirstStep;
      })
      .addCase(pauseTaskAsync.rejected, (state, action) => {
        state.runnedTask = undefined;
        state.stepOfProduction = StepOfProduction.FirstStep;
      })
      .addCase(completeTaskAsync.fulfilled, (state, action) => {
        state.runnedTask = undefined;
        state.stepOfProduction = StepOfProduction.FirstStep;
      })
      .addCase(completeTaskAsync.rejected, (state, action) => {
        state.runnedTask = undefined;
        state.stepOfProduction = StepOfProduction.FirstStep;
      })
      .addCase(leaveTaskAsync.fulfilled, (state, action) => {
        state.runnedTask = undefined;
        state.stepOfProduction = StepOfProduction.FirstStep;
      })
      .addCase(leaveTaskAsync.rejected, (state, action) => {
        state.runnedTask = undefined;
        state.stepOfProduction = StepOfProduction.FirstStep;
      });
  },
});

export const {
  variantOfSummarizeProductionSelected,
  stepOfProductionSelected,
  runningTaskCleared,
  quantityGoodChanged,
  quantityBadChanged,
} = jobExecutionSlice.actions;

export const selectRunnedTask = createSelector(
  (state: RootState) => state.main.jobExecution.runnedTask,
  (runnedTask) => runnedTask
);

export const selectRunnedTaskId = createSelector(
  selectRunnedTask,
  (runnedTask) => runnedTask?.taskId
);

export const selectStepOfProduction = createSelector(
  (state: RootState) => state.main.jobExecution.stepOfProduction,
  (stepOfProduction) => stepOfProduction
);

export const selectSummiarizeProductionVariant = createSelector(
  (state: RootState) => state.main.jobExecution.summarizeVariant,
  (summarizeVariant) => summarizeVariant
);

export default jobExecutionSlice.reducer;
