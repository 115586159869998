import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { AsyncValue, RootState } from "../..";
import { ProductionReasonDto } from "../../../services/httpService";
import { getProductionReasons } from "../../../services/main/productionReasonsService";

export interface ProductionReason extends ProductionReasonDto {}

export type ProductionReasonsState = AsyncValue<Array<ProductionReason>>;

export const initialState: ProductionReasonsState = {
  value: [],
  pending: false,
};

export const loadProductionReasonsAsync = createAsyncThunk(
  "productionReasons/loadProductionReasonsAsync",
  async (_, { getState, dispatch }) => {
    const result = await getProductionReasons();
    return result;
  }
);

export const productionReasonsSlice = createSlice({
  name: "productionReasons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadProductionReasonsAsync.pending, (state, action) => {
        state.pending = true;
      })
      .addCase(loadProductionReasonsAsync.fulfilled, (state, action) => {
        state.value = action.payload!;
        state.pending = false;
      })
      .addCase(loadProductionReasonsAsync.rejected, (state, action) => {
        state.value = [];
        state.pending = false;
      });
  },
});

// export const {} = productionReasonsSlice.actions;

export const selectProductionReasons = createSelector(
  (state: RootState) => state.main.productionReasons.value,
  (productionReasons) => productionReasons
);

export const selectProductionReasonsPending = createSelector(
  (state: RootState) => state.main.productionReasons.pending,
  (pending) => pending
);

export default productionReasonsSlice.reducer;
