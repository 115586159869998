import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { AppThunkDispatch } from "../../../../store";
import { useAppDispatch } from "../../../../store/hooks";
import { completeTaskAsync } from "../../../../store/main/job-execution/jobExecutionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  dialog: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },

  header: {
    width: "30rem",
  },

  content: {},
}));

interface DialogConfirmEndWoComponentProps {
  isSubmitting: boolean;
  onSubmitChange: (value: React.SetStateAction<boolean>) => void;
  open: boolean;
  handleClose: () => void;
}

const DialogConfirmEndWoComponent: React.FC<
  DialogConfirmEndWoComponentProps
> = ({ isSubmitting, onSubmitChange, open, handleClose }) => {
  const thunkDispatch = useAppDispatch() as AppThunkDispatch;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleEndOperation = async () => {
    onSubmitChange(true);
    await thunkDispatch(completeTaskAsync());
    onSubmitChange(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      maxWidth={false}
    >
      <DialogTitle className={classes.header}>
        {t("Confirm end of operation")}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          {t("Are you sure you want to complete the operation?")}
        </DialogContentText>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            disabled={isSubmitting}
            onClick={() => handleEndOperation()}
          >
            {t("Submit")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose()}
            disabled={isSubmitting}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DialogConfirmEndWoComponent;
