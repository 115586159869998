import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ResetTablesDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ResetTablesDialogComponent: React.FC<ResetTablesDialogProps> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={() => onCancel()} fullWidth maxWidth={"sm"}>
      <DialogTitle>{t("ResetTablesTitle")}</DialogTitle>
      <DialogContent sx={{ alignItems: "flex-start" }}>
        <List>
          <ListItem>
            <span>1. {t("FirstPointResetTables")}</span>
          </ListItem>
          <ListItem>
            <span>2. {t("SecondPointResetTables")}</span>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="success" variant="contained" onClick={() => onConfirm()}>
          {t("Submit")}
        </Button>
        <Button color="error" variant="contained" onClick={() => onCancel()}>
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetTablesDialogComponent;
