import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { makeStyles } from "tss-react/mui";
import { useAppDispatch } from "../../../../store/hooks";
import {
  leaveTaskAsync,
  StepOfProduction,
  stepOfProductionSelected,
  VariantOfSummarizeProduction,
  variantOfSummarizeProductionSelected,
} from "../../../../store/main/job-execution/jobExecutionSlice";
import ProductionGridWrapper from "./ProductionGridWrapper";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "grid",
    grid: "1fr / 1fr 12rem",
    gap: "0.5rem",
    height: "calc(100% - 0.5rem)",
  },

  container: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden auto",
    "& .simplebar-content": {
      height: "100%",
    },
  },

  sideActions: {
    maxHeight: "100%",
    overflow: "auto",
    "& .simplebar-content": {
      display: "grid",
      gridAutoRows: "min-content",
      rowGap: "0.5rem",
      maxHeight: "100%",
    },
  },

  progressOfWork: {
    textAlign: "center",
  },

  manageJobContainer: {
    paddingBottom: "1rem",
    marginRight: "0.1rem",
    maxWidth: "12rem",
  },

  manageJobHeader: {
    display: "flex",
    alignItems: "center",
    margin: "0rem 1rem",
    fontSize: "1.6rem",
    wordBreak: "break-word",
  },

  manageJobContent: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    gap: "1rem",
  },

  jobStateBar: {
    minWidth: "0.3rem",
    height: "2rem",
    backgroundColor: "white",
  },

  jobIcon: {
    minWidth: 30,
    minHeight: 30,
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
  },
}));

export interface SecondStepContainerProps {}

const SecondStepContainerComponent: React.FC<SecondStepContainerProps> = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [leavingTask, setLeavingTask] = useState<boolean>(false);

  const handleSuspendOperation = () => {
    dispatch(
      variantOfSummarizeProductionSelected(VariantOfSummarizeProduction.Pause)
    );
    dispatch(stepOfProductionSelected(StepOfProduction.FinalStep));
  };

  const handleCompleteOperation = () => {
    dispatch(
      variantOfSummarizeProductionSelected(
        VariantOfSummarizeProduction.Complete
      )
    );
    dispatch(stepOfProductionSelected(StepOfProduction.FinalStep));
  };

  const handleLeaveOperation = async () => {
    setLeavingTask(true);
    await dispatch(leaveTaskAsync());
    setLeavingTask(false);
    dispatch(stepOfProductionSelected(StepOfProduction.FirstStep));
  };

  const handleSwitchToWorkOrderList = () => {
    dispatch(stepOfProductionSelected(StepOfProduction.FirstStep));
  };

  return (
    <div className={classes.root}>
      <Box className={classes.container} component={SimpleBar}>
        <ProductionGridWrapper />
      </Box>
      <Box className={classes.sideActions} component={SimpleBar}>
        <Paper className={classes.manageJobContainer} elevation={2} square>
          <div className={classes.manageJobContent}>
            <Button
              variant="contained"
              color="complete"
              onClick={() => handleCompleteOperation()}
            >
              {t("Complete operation")}
            </Button>
            <Button
              variant="contained"
              color="suspend"
              onClick={() => {
                handleSuspendOperation();
              }}
            >
              {t("Suspend operation")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleLeaveOperation()}
              disabled={leavingTask}
            >
              {t("Leave operation")}
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => handleSwitchToWorkOrderList()}
            >
              {t("Work orders list")}
            </Button>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default SecondStepContainerComponent;
