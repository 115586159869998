import newShiftListener from "../common/dialogs/new-shift/newShiftListener";
import runningTaskStateChangedListener from "../common/dialogs/running-task-changed-state/runningTaskStateChangedListener";
import jobExecutionListener from "../main/job-execution/jobExecutionListener";
import prodEventsListener from "../main/production-events/prodEventsListener";
import productionReasonsListener from "../main/production-reasons/productionReasonsListener";
import utilExecsListener from "../main/util-execs/utilExecsListener";
import utilEventsListener from "../main/utilization-events/utilEventsListener";
import workQueueListener from "../main/work-queue/workQueueListener";
import userListener from "../user/userListener";

const listenersMiddleware = [
  newShiftListener.middleware,
  runningTaskStateChangedListener.middleware,
  workQueueListener.middleware,
  jobExecutionListener.middleware,
  utilExecsListener.middleware,
  userListener.middleware,
  prodEventsListener.middleware,
  utilEventsListener.middleware,
  productionReasonsListener.middleware,
];

export default listenersMiddleware;
