import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  taskCompletedSocketEvent,
  taskPausedSocketEvent,
  taskRegisteredBadProductionSocketEvent,
  taskRegisteredGoodProductionSocketEvent,
  taskUnregisteredBadProductionSocketEvent,
  taskUnregisteredGoodProductionSocketEvent,
} from "../../sockets/manufacturing-execution/tasksSlice";
import {
  quantityBadChanged,
  quantityGoodChanged,
  runningTaskCleared,
  selectRunnedTaskId,
} from "./jobExecutionSlice";

const jobExecutionListener = createListenerMiddleware();

jobExecutionListener.startListening({
  matcher: isAnyOf(taskPausedSocketEvent, taskCompletedSocketEvent),
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId } = action.payload;
    const runnedTaskId = selectRunnedTaskId(
      listenerApi.getState() as RootState
    );
    if (taskId === runnedTaskId) {
      listenerApi.dispatch(runningTaskCleared());
    }
  },
});

jobExecutionListener.startListening({
  actionCreator: taskRegisteredGoodProductionSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId, newTaskQuantity } = action.payload;
    const runnedTaskId = selectRunnedTaskId(
      listenerApi.getState() as RootState
    );
    if (taskId === runnedTaskId) {
      listenerApi.dispatch(quantityGoodChanged(newTaskQuantity));
    }
  },
});

jobExecutionListener.startListening({
  actionCreator: taskUnregisteredGoodProductionSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId, newTaskQuantity } = action.payload;
    const runnedTaskId = selectRunnedTaskId(
      listenerApi.getState() as RootState
    );
    if (taskId === runnedTaskId) {
      listenerApi.dispatch(quantityGoodChanged(newTaskQuantity));
    }
  },
});

jobExecutionListener.startListening({
  actionCreator: taskRegisteredBadProductionSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId, newTaskQuantity } = action.payload;

    const runnedTaskId = selectRunnedTaskId(
      listenerApi.getState() as RootState
    );
    if (taskId === runnedTaskId) {
      listenerApi.dispatch(quantityBadChanged(newTaskQuantity));
    }
  },
});

jobExecutionListener.startListening({
  actionCreator: taskUnregisteredBadProductionSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { taskId, newTaskQuantity } = action.payload;

    const runnedTaskId = selectRunnedTaskId(
      listenerApi.getState() as RootState
    );
    if (taskId === runnedTaskId) {
      listenerApi.dispatch(quantityBadChanged(newTaskQuantity));
    }
  },
});

export default jobExecutionListener;
