import { styled } from "@mui/material";
import React, { PropsWithChildren, FunctionComponent } from "react";
import AppBarLoginComponent from "../common/AppBarLoginComponent";
import FooterComponent from "../common/FooterComponent";

const Main = styled("main")(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

interface LoginLayoutProps {}

const LoginLayout: FunctionComponent<PropsWithChildren<LoginLayoutProps>> = ({
  children,
}) => {
  return (
    <React.Fragment>
      <AppBarLoginComponent />
      <Main>{children}</Main>
      <FooterComponent />
    </React.Fragment>
  );
};

export default LoginLayout;
