import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { splitUtilEventDialogOpened } from "../../../store/common/dialogs/splitUtilEventSlice";
import { updateUtilEventDialogOpened } from "../../../store/common/dialogs/updateUtilEventSlice";
import { utilEventHistoryDialogOpened } from "../../../store/common/dialogs/utilEventHistorySlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectSelectedUtilEvent } from "../../../store/main/utilization-events/utilEventsSlice";
import { selectSelectedAssetId } from "../../../store/user/assetSelectionSlice";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    minWidth: "12rem",
    maxWidth: "12rem",
    backgroundColor: "#ffffff",
    padding: "1rem",
    gap: "1rem",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

interface ManageUtilComponentProps {}

const ManageUtilComponent: React.FC<ManageUtilComponentProps> = () => {
  const selectedAssetId = useAppSelector(selectSelectedAssetId);
  const selectedUtilEvent = useAppSelector(selectSelectedUtilEvent);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper} elevation={2} square>
      <Button
        variant="contained"
        color="warning"
        onClick={() => {
          //TODO ADD pending dialog
          // if (selectedUtilHistory?.reasPending) {
          //   dispatch(pendingUtilDialogOpened(selectedUtilHistory?.logId));
          // } else {
          dispatch(updateUtilEventDialogOpened());
          // }
        }}
        disabled={!selectedAssetId || !selectedUtilEvent}
      >
        {t("Edit")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => dispatch(splitUtilEventDialogOpened())}
        disabled={
          !selectedAssetId ||
          !selectedUtilEvent ||
          !selectedUtilEvent.finishedAt
        }
      >
        {t("Split")}
      </Button>
      <Button
        variant="contained"
        color="info"
        onClick={() => dispatch(utilEventHistoryDialogOpened())}
        disabled={!selectedAssetId || !selectedUtilEvent}
      >
        {t("History")}
      </Button>
    </Paper>
  );
};

export default ManageUtilComponent;
