import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Icon / JobHistory"
      width={22.258}
      height={22}
      viewBox="0 0 22.258 22"
      {...props}
    >
      <g data-name="Group 51">
        <path
          data-name="Subtraction 1"
          d="M10.492 19.959L5.8 20a2.327 2.327 0 01-2.2-1.5h6.862c.17.528-.27.991.03 1.459zm-.267-2.459H2.2A2.228 2.228 0 010 15.25v-13A2.228 2.228 0 012.2 0h13a2.251 2.251 0 012.3 2.25v7.8a6.585 6.585 0 00-.852-.05c-.216 0-.434.011-.648.032V2.25a.743.743 0 00-.8-.75h-13a.718.718 0 00-.7.75v13a.718.718 0 00.7.75h7.967a6.747 6.747 0 00.057 1.5zm-3.074-4.25a.76.76 0 01-.451-.15l-.08-.07-3-3A.75.75 0 014.6 8.9l.08.07 2.47 2.47 6.47-6.47a.75.75 0 011.129.98l-.069.08-7 7a.742.742 0 01-.529.22zm12.767-2.793c-.47-.283-.892-.033-1.418-.189V3.631A2.217 2.217 0 0120 5.75z"
        />
        <path d="M11.462 11.339h10.127v1.582H11.462zM11.462 13.66h10.127v1.582H11.462zM11.462 15.899h10.127v1.582H11.462zM11.462 18.138h10.127v1.582H11.462z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
