import {
  OEEChartComponent,
  OEESimpleChartComponent,
  ProductionSummaryChartComponent,
  UtilDowntimesChartComponent,
  UtilSummaryChartComponent,
  UtilTimelineChartComponent,
} from "@improdis/core";
import { Box, CircularProgress, Paper, styled } from "@mui/material";
import _ from "lodash";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import GridName from "../../../helpers/enums/gridName";
import ProductionReason from "../../../helpers/enums/productionReasonEnum";
import {
  OverviewGridItemKey,
  defaultNewUniversalComponent,
} from "../../../helpers/grids/overview-grid/defaultGridSettings";
import defaultTileBackground from "../../../icons/tiles/defaultBackground.png";
import overviewTiles from "../../../icons/tiles/overview";
import {
  GetAvailabilityByAssetIdDto,
  GetPerformanceByAssetIdDto,
  GetQualityByAssetIdDto,
} from "../../../services/httpService";
import {
  getAvailabilityByAssetId,
  getPerformanceByAssetId,
  getQualityByAssetId,
} from "../../../services/main/oeeService";
import { selectOverviewCustomGridComponents } from "../../../store/common/customGridComponentsSlice";
import {
  editGridsDialogCleared,
  editGridsDialogUpdated,
  selectEditGridsDialogOpen,
} from "../../../store/common/dialogs/editGridsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectProductionEventsSummary } from "../../../store/main/production-events/prodEventsSlice";
import {
  selectUtilEventsDowntimes,
  selectUtilEventsOverTime,
  selectUtilEventsStateSummary,
} from "../../../store/main/utilization-events/utilEventsSlice";
import { selectSelectedAssetId } from "../../../store/user/assetSelectionSlice";
import ErrorFallback, {
  ErrorLevel,
} from "../../common/error-fallbacks/ErrorFallback";
import EditableGridComponent from "../../common/grid/EditableGridComponent";
import GridWrapperComponent, {
  GridElement,
} from "../../common/grid/GridWrapperComponent";

const BaseComponentPaper = styled(Paper)((props) => ({
  height: "100%",
  width: "100%",
}));

interface GridTile {
  key: OverviewGridItemKey;
  title: string;
  width: number;
  height: number;
  background: string;
  id: string;
}

interface OEEIndicator {
  oee: number | null;
  oeeTarget: number;
}

interface OverviewGridWrapperProps {}

const OverviewGridWrapper: FunctionComponent<OverviewGridWrapperProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const openDialog = useAppSelector(selectEditGridsDialogOpen);
  const customComponents = useAppSelector(selectOverviewCustomGridComponents);
  const selectedAssetId = useAppSelector(selectSelectedAssetId);
  const utilizationOverTime = useAppSelector(selectUtilEventsOverTime);
  const utilizationDowntimes = useAppSelector(selectUtilEventsDowntimes);
  const utilizationSummary = useAppSelector(selectUtilEventsStateSummary);
  const productionSummary = useAppSelector(selectProductionEventsSummary);
  const [performanceIndicator, setPerformanceIndicator] =
    useState<GetPerformanceByAssetIdDto>();
  const [qualityIndicator, setQualityIndicator] =
    useState<GetQualityByAssetIdDto>();
  const [availabilityIndicator, setAvailabilityIndicator] =
    useState<GetAvailabilityByAssetIdDto>();
  const [oeeIndicator, setOEEIndicator] = useState<OEEIndicator>();

  const oeeChart = useMemo(
    () => (
      <OEEChartComponent
        oee={{
          value: oeeIndicator?.oee,
          target: oeeIndicator?.oeeTarget,
        }}
        availability={{
          value: availabilityIndicator?.availability,
          target: availabilityIndicator?.targetAvailability,
        }}
        performance={{
          value: performanceIndicator?.performance,
          target: performanceIndicator?.targetPerformance,
        }}
        quality={{
          value: qualityIndicator?.quality,
          target: qualityIndicator?.targetQuality,
        }}
      />
    ),
    [
      availabilityIndicator,
      performanceIndicator,
      qualityIndicator,
      oeeIndicator,
    ]
  );

  const oeeSimpleChart = useMemo(
    () => (
      <OEESimpleChartComponent
        oee={{
          value: oeeIndicator?.oee,
          target: oeeIndicator?.oeeTarget,
        }}
        availability={{
          value: availabilityIndicator?.availability,
          target: availabilityIndicator?.targetAvailability,
        }}
        performance={{
          value: performanceIndicator?.performance,
          target: performanceIndicator?.targetPerformance,
        }}
        quality={{
          value: qualityIndicator?.quality,
          target: qualityIndicator?.targetQuality,
        }}
      />
    ),
    [
      availabilityIndicator,
      performanceIndicator,
      qualityIndicator,
      oeeIndicator,
    ]
  );

  const utilSummaryChart = useMemo(() => {
    var sumDuration = _.sumBy(utilizationSummary, "duration");
    return (
      <>
        {utilizationSummary ? (
          <UtilSummaryChartComponent
            data={utilizationSummary.map((s) => ({
              label: `${t(s.utilStateName || "Unknown")} ${(
                (s.duration / sumDuration) *
                100
              ).toFixed(2)}%`,
              color: s.utilStateColor!,
              duration: s.duration,
            }))}
          />
        ) : (
          <>
            {selectedAssetId && (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size="4rem" />
              </Box>
            )}
          </>
        )}
      </>
    );
  }, [t, selectedAssetId, utilizationSummary]);

  const productionSummaryChart = useMemo(
    () => (
      <>
        {productionSummary ? (
          <ProductionSummaryChartComponent
            data={productionSummary.map((p) => ({
              label: p.itemName || t("Unknown"),
              isGood: p.productionReasonName === ProductionReason.Good,
              quantity: p.quantityProduced,
            }))}
          />
        ) : (
          <>
            {selectedAssetId && (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size="4rem" />
              </Box>
            )}
          </>
        )}
      </>
    ),
    [t, productionSummary, selectedAssetId]
  );

  const utilDowntimesChart = useMemo(
    () => (
      <UtilDowntimesChartComponent
        data={utilizationDowntimes.map((d) => {
          return {
            label: t(d.utilRawName || "Unknown"),
            color: d.utilStateColor ?? "transparent",
            duration: d.duration / 60,
            count: d.count,
          };
        })}
      />
    ),
    [t, utilizationDowntimes]
  );

  const utilTimelineChart = useMemo(
    () => (
      <UtilTimelineChartComponent
        data={utilizationOverTime.map((s) => ({
          startDate: s.startedAt,
          endDate: s.finishedAt ?? new Date(),
          label: `${t(s.utilStateName ?? "Unknown")} - ${t(
            s.utilRawName ?? "Unknown"
          )}`,
          color: s.utilStateColor ?? "transparent",
          duration: s.duration,
        }))}
      />
    ),
    [t, utilizationOverTime]
  );

  const gridComponents = useMemo(
    () =>
      [
        {
          key: OverviewGridItemKey.OEE_CHART,
          element: oeeChart,
        },
        {
          key: OverviewGridItemKey.OEE_SIMPLE_CHART,
          element: oeeSimpleChart,
        },
        {
          key: OverviewGridItemKey.UTILIZATION_SUMMARY_CHART,
          element: utilSummaryChart,
        },
        {
          key: OverviewGridItemKey.PRODUCTION_SUMMARY_CHART,
          element: productionSummaryChart,
        },
        {
          key: OverviewGridItemKey.UTILIZATION_DOWNTIMES_CHART,
          element: utilDowntimesChart,
        },
        {
          key: OverviewGridItemKey.UTILIZATION_TIMELINE_CHART,
          element: utilTimelineChart,
        },
      ] as Array<GridElement>,
    [
      oeeChart,
      oeeSimpleChart,
      utilSummaryChart,
      productionSummaryChart,
      utilDowntimesChart,
      utilTimelineChart,
    ]
  );

  const gridTiles = useMemo(
    () =>
      [
        {
          key: OverviewGridItemKey.EDITABLE_COMPONENT,
          title: t("EditableComponentTitle").toString(),
          width: 4,
          height: 8,
          background: defaultTileBackground,
        },
        {
          key: OverviewGridItemKey.OEE_CHART,
          title: t("OeeChartTitle").toString(),
          width: 4,
          height: 8,
          background: overviewTiles.oeeChartBackground,
        },
        {
          key: OverviewGridItemKey.OEE_SIMPLE_CHART,
          title: t("OeeSimpleChartTitle").toString(),
          width: 4,
          height: 8,
          background: overviewTiles.oeeChartBackground,
        },
        {
          key: OverviewGridItemKey.UTILIZATION_SUMMARY_CHART,
          title: t("UtilizationSummaryChartTitle").toString(),
          width: 3,
          height: 5,
          background: overviewTiles.utilSummaryChartBackground,
        },
        {
          key: OverviewGridItemKey.PRODUCTION_SUMMARY_CHART,
          title: t("ProductionSummaryChartTitle").toString(),
          width: 4,
          height: 8,
          background: overviewTiles.productionSummaryChartBackground,
        },
        {
          key: OverviewGridItemKey.UTILIZATION_DOWNTIMES_CHART,
          title: t("UtilizationDowntimesChartTitle").toString(),
          width: 3,
          height: 5,
          background: overviewTiles.utilDowntimesChartBackground,
        },
        {
          key: OverviewGridItemKey.UTILIZATION_TIMELINE_CHART,
          title: t("UtilizationTimelineChartTitle").toString(),
          width: 8,
          height: 8,
          background: overviewTiles.utilTimelineChartBackground,
        },
      ] as Array<GridTile>,
    [t]
  );

  const allTiles = useMemo(
    () =>
      gridTiles.concat(
        customComponents
          .filter((c) => !c.isNew)
          .map(
            (c) =>
              ({
                key: c.key,
                title: t(c.title),
                width: 4,
                height: 8,
                background: defaultTileBackground,
                id: c.componentAttrId,
              } as GridTile)
          )
      ),
    [t, gridTiles, customComponents]
  );

  const allGridComponents = useMemo(
    () =>
      gridComponents
        .map((gc) => ({
          ...gc,
          element: (
            <BaseComponentPaper elevation={2} square>
              {gc.element}
            </BaseComponentPaper>
          ),
        }))
        .concat(
          customComponents.map(
            (c) =>
              ({
                key: c.key,
                element: (
                  <ErrorBoundary
                    FallbackComponent={(props) => (
                      <ErrorFallback {...props} level={ErrorLevel.Component} />
                    )}
                  >
                    <EditableGridComponent code={c.code} isNew={c.isNew} />
                  </ErrorBoundary>
                ),
                attrName: c.componentAttrName,
                title: c.title,
                code: c.code,
                isEditable: true,
                isNew: c.isNew,
                id: c.componentAttrId,
              } as GridElement)
          )
        ),
    [gridComponents, customComponents]
  );

  useEffect(() => {
    if (openDialog) {
      dispatch(
        editGridsDialogUpdated({
          gridName: GridName.OVERVIEW,
          allTiles: allTiles,
        })
      );
    }
  }, [dispatch, openDialog, allTiles]);

  useEffect(() => {
    return () => {
      openDialog && dispatch(editGridsDialogCleared());
    };
  }, [dispatch, openDialog]);

  useEffect(() => {
    const fetchKPIsData = async () => {
      if (selectedAssetId !== undefined && selectedAssetId !== null) {
        const performanceResult = await getPerformanceByAssetId(
          selectedAssetId,
          undefined,
          undefined
        );
        isMount &&
          performanceResult !== undefined &&
          setPerformanceIndicator(performanceResult);
        const qualityResult = await getQualityByAssetId(
          selectedAssetId,
          undefined,
          undefined
        );
        isMount &&
          qualityResult !== undefined &&
          setQualityIndicator(qualityResult);
        const availabilityResult = await getAvailabilityByAssetId(
          selectedAssetId,
          undefined,
          undefined
        );
        isMount &&
          availabilityResult !== undefined &&
          setAvailabilityIndicator(availabilityResult);
        if (
          availabilityResult !== undefined &&
          qualityResult !== undefined &&
          performanceResult !== undefined
        ) {
          let oee: number | null = null;
          if (
            availabilityResult.availability &&
            qualityResult.quality &&
            performanceResult.performance
          ) {
            oee =
              (availabilityResult.availability *
                performanceResult.performance *
                qualityResult.quality) /
              10000;
          }

          setOEEIndicator({
            oee: oee,
            oeeTarget:
              (availabilityResult.targetAvailability +
                performanceResult.targetPerformance +
                qualityResult.targetQuality) /
              3,
          });
        }
      }
    };
    let isMount = true;
    if (isMount) {
      fetchKPIsData();
    }
    const interval = setInterval(() => {
      if (isMount) {
        fetchKPIsData();
      }
    }, 60000);
    return () => {
      isMount = false;
      clearInterval(interval);
    };
  }, [selectedAssetId]);

  return (
    <GridWrapperComponent
      gridName={GridName.OVERVIEW}
      childrenList={allGridComponents}
      defaultNewUniversalComponent={defaultNewUniversalComponent}
    />
  );
};

export default OverviewGridWrapper;
