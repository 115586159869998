import { JobHistoryIcon } from "@improdis/core";
import Tabs from "@mui/material/Tabs";
import { Theme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { makeStyles } from "tss-react/mui";
import EntitiesIcon from "../../../icons/EntitiesIcon";
import JobsIcon from "../../../icons/JobsIcon";
import OverviewIcon from "../../../icons/OverviewIcon";
import PortableAddNewUtilComponent from "./PortableAddNewUtilComponent";
import ButtonTabComponent from "./styled/ButtonTabComponent";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },

  root: {
    minHeight: "2.5rem",
    height: "2.5rem",
    marginLeft: "0.5rem",
    marginTop: "1rem",
  },

  tabFlexContainer: {
    height: "100%",
  },

  tabIcon: {
    height: "1rem",
    width: "1rem",
    marginRight: "0.4rem",
    marginBottom: "unset !important",
  },

  indicator: {
    display: "none",
  },
}));

interface MenuPanelComponentProps extends RouteComponentProps {}

const MenuPanelComponent: React.FC<MenuPanelComponentProps> = ({ history }) => {
  const { classes } = useStyles();
  const [menuOption, setMenuOption] = useState<string | null>("/main/overview");
  const { t } = useTranslation();

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newMenuOption: string
  ) => {
    setMenuOption(newMenuOption);
    history.push(`${newMenuOption}`);
  };

  useEffect(() => {
    history.location.pathname !== "/login" &&
      setMenuOption(history.location.pathname);
  }, [history.location]);

  return (
    <div className={classes.wrapper}>
      <Tabs
        value={menuOption}
        onChange={handleChange}
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.tabFlexContainer,
        }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <ButtonTabComponent
          wrapped
          disableRipple
          icon={<OverviewIcon className={classes.tabIcon} />}
          value="/main/overview"
          label={t("Overview")}
        />

        <ButtonTabComponent
          wrapped
          disableRipple
          icon={<JobsIcon className={classes.tabIcon} />}
          value="/main/production"
          label={t("Production")}
        />

        <ButtonTabComponent
          wrapped
          disableRipple
          icon={<JobHistoryIcon className={classes.tabIcon} />}
          value="/main/production-history"
          label={t("Production history")}
        />

        <ButtonTabComponent
          wrapped
          disableRipple
          icon={<EntitiesIcon className={classes.tabIcon} />}
          value="/main/util"
          label={t("Utilization history")}
        />
      </Tabs>
      <PortableAddNewUtilComponent />
    </div>
  );
};

export default withRouter(MenuPanelComponent);
