import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { AsyncValue, RootState } from "../..";
import { ProductionLogDto } from "../../../services/httpService";
import {
  correctProductionEvent,
  getCurrentShiftProductionEventsByAssetId,
} from "../../../services/main/productionEventsService";
import {
  addBadProduction,
  addGoodProduction,
  reduceBadProduction,
  reduceGoodProduction,
} from "../../../services/main/tasksService";
import { selectSelectedAssetId } from "../../user/assetSelectionSlice";
import { selectRunnedTaskId } from "../job-execution/jobExecutionSlice";

export interface ProductionEvent extends ProductionLogDto {}
export type ProductionEventSummary = Pick<
  ProductionLogDto,
  "itemName" | "productionReasonName" | "quantityProduced"
>;

export interface ProductionEventsState {
  productionEvents: AsyncValue<Array<ProductionEvent>>;
  selectedProductionEventLogId: string | null; //logId
  pendingAddProduction: boolean;
  pendingCorrectProduction: boolean;
}

export const initialState: ProductionEventsState = {
  productionEvents: {
    value: [],
    pending: false,
  },
  selectedProductionEventLogId: null,
  pendingAddProduction: false,
  pendingCorrectProduction: false,
};

export const loadProductionEventsForCurrentShiftAsync = createAsyncThunk(
  "productionEvents/loadProductionEventsForCurrentShiftAsync",
  async (_, { getState, dispatch }) => {
    const assetId = selectSelectedAssetId(getState() as RootState);
    if (assetId) {
      const result = await getCurrentShiftProductionEventsByAssetId(assetId);
      return result;
    } else {
      return [];
    }
  }
);

interface AddGoodProductionParams {
  taskId: string;
  quantityProduced: number;
  comment: string | null;
}

export const addGoodProductionAsync = createAsyncThunk(
  "productionEvents/addGoodProductionAsync",
  async (params: AddGoodProductionParams, { getState, dispatch }) => {
    await addGoodProduction(
      params.taskId,
      params.quantityProduced,
      params.comment
    );
  }
);

interface ReduceGoodProductionParams {
  taskId: string;
  quantity: number;
  comment: string | null;
}

export const reduceGoodProductionAsync = createAsyncThunk(
  "productionEvents/reduceGoodProductionAsync",
  async (params: ReduceGoodProductionParams, { getState, dispatch }) => {
    await reduceGoodProduction(params.taskId, params.quantity, params.comment);
  }
);

interface AddBadProductionParams {
  taskId: string;
  quantityProduced: number;
  comment: string | null;
}

export const addBadProductionAsync = createAsyncThunk(
  "productionEvents/addBadProductionAsync",
  async (params: AddBadProductionParams, { getState, dispatch }) => {
    await addBadProduction(
      params.taskId,
      params.quantityProduced,
      params.comment
    );
  }
);

interface ReduceBadProductionParams {
  taskId: string;
  quantity: number;
  comment: string | null;
}

export const reduceBadProductionAsync = createAsyncThunk(
  "productionEvents/reduceBadProductionAsync",
  async (params: ReduceBadProductionParams, { getState, dispatch }) => {
    await reduceBadProduction(params.taskId, params.quantity, params.comment);
  }
);

interface CorretProductionEventParams {
  newProductionReasonId: string;
  newQuantity: number;
  comment: string | null;
}

export const correctProductionEventAsync = createAsyncThunk(
  "productionEvents/correctProductionEventAsync",
  async (params: CorretProductionEventParams, { getState, dispatch }) => {
    const { newProductionReasonId, newQuantity, comment } = params;
    const selectedProductionEventLogId = selectSelectedProductionEventLogId(
      getState() as RootState
    );
    if (selectedProductionEventLogId) {
      const result = await correctProductionEvent(
        selectedProductionEventLogId,
        newProductionReasonId,
        newQuantity,
        comment
      );
      return result;
    }
  }
);

export const productionEventsSlice = createSlice({
  name: "productionEvents",
  initialState,
  reducers: {
    productionEventSelected: (state, action: PayloadAction<string>) => {
      state.selectedProductionEventLogId = action.payload;
    },
    productionEventsSelectionCleared: (state, action: PayloadAction<void>) => {
      state.selectedProductionEventLogId = null;
    },
    productionEventCreated: (state, action: PayloadAction<ProductionEvent>) => {
      state.productionEvents.value = [
        action.payload,
        ...state.productionEvents.value,
      ];
    },
    productionEventUpdated: (
      state,
      action: PayloadAction<{
        oldEventLogId: string;
        newEvent: ProductionEvent;
      }>
    ) => {
      const { oldEventLogId, newEvent } = action.payload;
      state.productionEvents.value = state.productionEvents.value.map((pe) =>
        pe.logId === oldEventLogId ? newEvent : pe
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        loadProductionEventsForCurrentShiftAsync.pending,
        (state, action) => {
          state.productionEvents.pending = true;
        }
      )
      .addCase(
        loadProductionEventsForCurrentShiftAsync.fulfilled,
        (state, action) => {
          state.productionEvents.value = action.payload!;
          state.productionEvents.pending = false;
        }
      )
      .addCase(
        loadProductionEventsForCurrentShiftAsync.rejected,
        (state, action) => {
          state.productionEvents.value = [];
          state.productionEvents.pending = false;
        }
      )
      .addCase(correctProductionEventAsync.pending, (state, action) => {
        state.pendingCorrectProduction = true;
      })
      .addCase(correctProductionEventAsync.fulfilled, (state, action) => {
        state.pendingCorrectProduction = false;
      })
      .addCase(correctProductionEventAsync.rejected, (state, action) => {
        state.pendingCorrectProduction = false;
      });
  },
});

export const {
  productionEventSelected,
  productionEventsSelectionCleared,
  productionEventCreated,
  productionEventUpdated,
} = productionEventsSlice.actions;

export const selectProductionEvents = createSelector(
  (state: RootState) => state.main.productionEvents.productionEvents.value,
  (productionEvents) => productionEvents
);

export const selectRunnedTaskProductionEvents = createSelector(
  selectProductionEvents,
  selectRunnedTaskId,
  (productionEvents, runnedTaskId) =>
    productionEvents.filter((pe) => pe.taskId === runnedTaskId)
);

export const selectProductionEventsPending = createSelector(
  (state: RootState) => state.main.productionEvents.productionEvents.pending,
  (productionEventsPending) => productionEventsPending
);

export const selectAddProductionPending = createSelector(
  (state: RootState) => state.main.productionEvents.pendingAddProduction,
  (pendingAddProduction) => pendingAddProduction
);

export const selectSelectedProductionEventLogId = createSelector(
  (state: RootState) =>
    state.main.productionEvents.selectedProductionEventLogId,
  (selectedProductionEventLogId) => selectedProductionEventLogId
);

export const selectSelectedProductionEvent = createSelector(
  selectProductionEvents,
  selectSelectedProductionEventLogId,
  (productionEvents, selectedProductionEventLogId) =>
    productionEvents.find((pe) => pe.logId === selectedProductionEventLogId)
);

export const selectProductionEventsPendingCorrect = createSelector(
  (state: RootState) => state.main.productionEvents.pendingCorrectProduction,
  (pendingCorrect) => pendingCorrect
);

export const selectProductionEventsSummary = createSelector(
  selectProductionEvents,
  (productionEvents) => {
    const uniqs = _.uniqBy(productionEvents, (v) =>
      [v.itemName, v.productionReasonName].join()
    );
    return uniqs.map(
      (v) =>
        ({
          itemName: v.itemName,
          productionReasonName: v.productionReasonName,
          quantityProduced: _.sumBy(
            productionEvents.filter(
              (d) =>
                d.itemName === v.itemName &&
                d.productionReasonName === v.productionReasonName
            ),
            "quantityProduced"
          ),
        } as ProductionEventSummary)
    );
  }
);

export default productionEventsSlice.reducer;
