import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import React from "react";
import ManageUtilComponent from "./ManageUtilComponent";
import UtilHistoryTableComponent from "./UtilHistoryTableComponent";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    padding: "0.5rem",
    height: "calc(100% - 1rem)",
    width: "calc(100% - 1rem)",
    maxWidth: "calc(100% - 1rem)",
    maxHeight: "calc(100% - 1rem)",
    gap: "0.5rem",
  },
}));

const UtilComponent: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <UtilHistoryTableComponent></UtilHistoryTableComponent>
      <ManageUtilComponent />
    </div>
  );
};

export default UtilComponent;
