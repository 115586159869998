import { TFunction } from "i18next";
import { FilterValue, Row } from "react-table";
import { FormatUtilRawString } from "./FormatUtilRaw";

export function filterUtilRaws<T extends object>(
  rows: Array<Row<T>>,
  id: Array<string>,
  filterValue: FilterValue,
  t: TFunction
) {
  return rows.filter((row) => {
    const rowValue = FormatUtilRawString(row.values[id[0]], t);
    return rowValue !== undefined
      ? String(rowValue)
          .toLowerCase()
          .includes(String(filterValue).toLowerCase())
      : true;
  });
}
