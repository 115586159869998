import { Checkbox, styled } from "@mui/material";
import React, { useMemo } from "react";
import { HeaderProps } from "react-table";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  height: "1.95rem",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  borderRadius: "5px 5px 0px 0px",
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  margin: "auto",
  padding: "unset",
}));

const CheckboxTableFilterComponent: React.FC<HeaderProps<any>> = ({
  column: { filterValue, preFilteredRows, setFilter, id },
  rows,
}) => {
  const disabled = useMemo(
    () => rows.some((row) => row.original.isEditing),
    [rows]
  );

  const handleChange = (value: boolean) => {
    switch (filterValue) {
      case true:
        setFilter(false);
        break;
      case false:
        setFilter(undefined);
        break;
      default:
        setFilter(true);
        break;
    }
  };

  return (
    <Container>
      <StyledCheckbox
        color="primary"
        checked={filterValue || false}
        indeterminate={filterValue === false}
        onChange={(e) => handleChange(e.target.checked)}
        disabled={disabled}
      />
    </Container>
  );
};

export default CheckboxTableFilterComponent;
