import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { Typography, styled } from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import {
  NestedAsset,
  getNestedAssetNodeId,
  selectNestedAssets,
  selectNestedAssetsNodeIds,
} from "../../../../store/definitions/assetsSlice";
import { useAppSelector } from "../../../../store/hooks";
import { selectUtilizationExecs } from "../../../../store/main/util-execs/utilExecsSlice";

const TreeLabel = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 0),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "inherit",
  fontSize: "1rem",
  flexGrow: 1,
}));

interface ReasonBarProps {
  barColor: CSSProperties["backgroundColor"];
}

const ReasonBar = styled("div")<ReasonBarProps>(({ theme, barColor }) => ({
  width: "0.4rem",
  height: "1.5rem",
  marginRight: "0.3rem",
  backgroundColor: barColor,
}));

interface TreeViewProps {
  handleClick: (assetId: string) => void;
  selectedAssetNodeId: string | null | undefined;
}

const TreeViewComponent: React.FC<TreeViewProps> = ({
  handleClick,
  selectedAssetNodeId,
}) => {
  const nestedAssets = useAppSelector(selectNestedAssets);
  const nestedAssetsIds = useAppSelector(selectNestedAssetsNodeIds);
  const utilExecs = useAppSelector(selectUtilizationExecs);
  const [expanded, setExpanded] = useState<string[]>(nestedAssetsIds);
  const [canBeSelected, setCanBeSelected] = useState<boolean>(false);
  const [newSelectedAssetId, setNewSelectedAssetId] = useState<
    string | undefined
  >(undefined);

  const renderTree = (nodes: Array<NestedAsset>) =>
    nodes.map((node) => {
      const utilExec = utilExecs.find((ue) => ue.assetId === node.id);
      const id = getNestedAssetNodeId(node.sourceStack, node.id);
      return (
        <TreeItem
          key={id}
          nodeId={id}
          label={
            <TreeLabel>
              <ReasonBar barColor={utilExec?.utilStateColor ?? "transparent"} />
              <StyledTypography variant="body2">{node.name}</StyledTypography>
            </TreeLabel>
          }
        >
          {Array.isArray(node.childrenAssets)
            ? node.childrenAssets.map((ch) => renderTree([ch]))
            : null}
        </TreeItem>
      );
    });

  const checkIfNodeCanBeSelected = (
    nodes: Array<NestedAsset>,
    sourceStack: Array<string>,
    assetId: string
  ) => {
    const node =
      nodes.find((n) => n.id === sourceStack[0]) ??
      nodes.find((n) => n.id === assetId);

    if (node && node.id === assetId) {
      if (node.childrenAssets?.length === 0) {
        setCanBeSelected(true);
      } else {
        setNewSelectedAssetId(undefined);
        setCanBeSelected(false);
      }
    }

    if (node && Array.isArray(node.childrenAssets)) {
      sourceStack.shift();
      checkIfNodeCanBeSelected(node.childrenAssets, sourceStack, assetId);
    }
  };

  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodes: string[]) => {
    setExpanded(nodes);
  };

  const handleNodeSelect = async (
    event: React.ChangeEvent<{}>,
    value: string
  ) => {
    let sourceStack = value.split("/");
    const assetId = sourceStack[sourceStack.length - 1];
    sourceStack.pop();
    checkIfNodeCanBeSelected(nestedAssets, sourceStack, assetId);
    setNewSelectedAssetId(value);
  };

  useEffect(() => {
    if (canBeSelected && newSelectedAssetId) {
      handleClick(newSelectedAssetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSelectedAssetId, canBeSelected]);

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selectedAssetNodeId ?? undefined}
      onNodeToggle={handleNodeToggle}
      onNodeSelect={handleNodeSelect}
    >
      {renderTree(nestedAssets)}
    </TreeView>
  );
};

export default TreeViewComponent;
