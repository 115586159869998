import { createListenerMiddleware } from "@reduxjs/toolkit";
import { getProductionLogByLogId } from "../../../services/main/productionEventsService";
import {
  productionEventAggregatedSocketEvent,
  productionEventCorrectedSocketEvent,
  productionEventCreatedSocketEvent,
  productionEventFinishedSocketEvent,
} from "../../sockets/manufacturing-execution/productionEventsSlice";
import { logOnAssetAsync } from "../../user/assetSelectionSlice";
import {
  loadProductionEventsForCurrentShiftAsync,
  productionEventCreated,
  productionEventUpdated,
} from "./prodEventsSlice";

const prodEventsListener = createListenerMiddleware();

prodEventsListener.startListening({
  actionCreator: logOnAssetAsync.fulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    listenerApi.dispatch(loadProductionEventsForCurrentShiftAsync());
  },
});

prodEventsListener.startListening({
  actionCreator: productionEventCreatedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { logId } = action.payload;
    const createdProdEvent = await getProductionLogByLogId(logId);
    if (createdProdEvent)
      listenerApi.dispatch(productionEventCreated(createdProdEvent));
  },
});

prodEventsListener.startListening({
  actionCreator: productionEventAggregatedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { logId } = action.payload;
    const aggregatedProdEvent = await getProductionLogByLogId(logId);
    if (aggregatedProdEvent)
      listenerApi.dispatch(
        productionEventUpdated({
          oldEventLogId: logId,
          newEvent: aggregatedProdEvent,
        })
      );
  },
});

prodEventsListener.startListening({
  actionCreator: productionEventFinishedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { logId } = action.payload;
    const finishedProdEvent = await getProductionLogByLogId(logId);
    if (finishedProdEvent)
      listenerApi.dispatch(
        productionEventUpdated({
          oldEventLogId: logId,
          newEvent: finishedProdEvent,
        })
      );
  },
});

prodEventsListener.startListening({
  actionCreator: productionEventCorrectedSocketEvent,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { logId, newLogId } = action.payload;
    const newProdEvent = await getProductionLogByLogId(newLogId);
    if (newProdEvent)
      listenerApi.dispatch(
        productionEventUpdated({
          oldEventLogId: logId,
          newEvent: newProdEvent,
        })
      );
  },
});

export default prodEventsListener;
