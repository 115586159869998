import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectSelectedProductionEventLogId } from "../../../store/main/production-events/prodEventsSlice";
import { useTranslation } from "react-i18next";
import { Button, Paper, styled } from "@mui/material";
import React from "react";
import { selectSelectedAssetId } from "../../../store/user/assetSelectionSlice";
import { updateProductionEventDialogOpened } from "../../../store/common/dialogs/production-events/updateProductionEventSlice";
import { productionEventHistoryDialogOpened } from "../../../store/common/dialogs/production-events/productionEventHistorySlice";

const Root = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "12rem",
  maxWidth: "12rem",
  backgroundColor: "#ffffff",
  padding: "1rem",
  gap: "1rem",
  boxSizing: "border-box",
}));

interface ManageProductionHistoryProps {}

const ManageProductionHistoryComponent: FunctionComponent<
  ManageProductionHistoryProps
> = () => {
  const selectedAssetId = useAppSelector(selectSelectedAssetId);
  const selectedProductionEventLogId = useAppSelector(
    selectSelectedProductionEventLogId
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleOpenCorrectProductionEventDialog = () => {
    dispatch(updateProductionEventDialogOpened());
  };

  const handleOpenProductionEventHistoryDialog = () => {
    dispatch(productionEventHistoryDialogOpened());
  };

  return (
    <Root elevation={2} square>
      <Button
        variant="contained"
        color="warning"
        onClick={handleOpenCorrectProductionEventDialog}
        disabled={!selectedAssetId || !selectedProductionEventLogId}
      >
        {t("Edit")}
      </Button>
      {/* <Button
        variant="contained"
        color="primary"
        // onClick={() => dispatch(splitUtilEventDialogOpened())}
        disabled={!selectedAssetId || !selectedProductionEventLogId}
      >
        {t("Split")}
      </Button> */}
      <Button
        variant="contained"
        color="info"
        onClick={handleOpenProductionEventHistoryDialog}
        disabled={!selectedAssetId || !selectedProductionEventLogId}
      >
        {t("History")}
      </Button>
    </Root>
  );
};

export default ManageProductionHistoryComponent;
