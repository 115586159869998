import { HubConnectionState } from "@microsoft/signalr";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import {
  AssetCreatedSocketDto,
  AssetStateChangedSocketDto,
  AssetStateCorrectedSocketDto,
} from "../../../services/sockets/hub-services/utilization/assetsAllService";
import { ShiftChangedSocketDto } from "../../../services/sockets/hub-services/utilization/assetsAssetGroupService";

export interface AssetsState {
  connectionStatus: HubConnectionState;
}

export const initialState: AssetsState = {
  connectionStatus: HubConnectionState.Disconnected,
};

export const assetsSlice = createSlice({
  name: "sockets",
  initialState,
  reducers: {
    assetsConnectionStatusChanged: (
      state,
      action: PayloadAction<HubConnectionState>
    ) => {
      state.connectionStatus = action.payload;
    },
    assetCreatedSocketEvent: (
      state,
      action: PayloadAction<AssetCreatedSocketDto>
    ) => {},
    assetStateChangedSocketEvent: (
      state,
      action: PayloadAction<AssetStateChangedSocketDto>
    ) => {},
    assetStateCorrectedSocketEvent: (
      state,
      action: PayloadAction<AssetStateCorrectedSocketDto>
    ) => {},
    shiftChangedSocketEvent: (
      state,
      action: PayloadAction<ShiftChangedSocketDto>
    ) => {},
  },
});

export const {
  assetsConnectionStatusChanged,
  assetCreatedSocketEvent,
  assetStateChangedSocketEvent,
  assetStateCorrectedSocketEvent,
  shiftChangedSocketEvent,
} = assetsSlice.actions;

export const selectAssetsConnectionStatus = createSelector(
  (state: RootState) => state.sockets.utilization.assets.connectionStatus,
  (connectionStatus) => connectionStatus
);

export default assetsSlice.reducer;
