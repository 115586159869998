import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import GridName from "../../../helpers/enums/gridName";
import { OverviewGridItemKey } from "../../../helpers/grids/overview-grid/defaultGridSettings";
import { ProductionGridItemKey } from "../../../helpers/grids/production-grid/defaultGridSettings";

export interface GridElementTile {
  key: ProductionGridItemKey | OverviewGridItemKey;
  title: string;
  width: number;
  height: number;
  background: string;
}

interface EditGridsDialogState {
  open: boolean;
  name: GridName | null;
  allTiles: Array<GridElementTile>;
}

export const initialState: EditGridsDialogState = {
  open: false,
  name: null,
  allTiles: [],
};

export const editGridsSlice = createSlice({
  name: "editGridsDialog",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<void>) => {
      state.open = true;
      state.name = null;
      state.allTiles = [];
    },
    updated: (
      state,
      action: PayloadAction<{
        gridName: GridName;
        allTiles: Array<GridElementTile>;
      }>
    ) => {
      state.name = action.payload.gridName;
      state.allTiles = action.payload.allTiles;
    },
    cleared: (state, action: PayloadAction<void>) => {
      state.name = null;
      state.allTiles = [];
    },
    closed: (state, action: PayloadAction<void>) => {
      state.open = false;
      state.name = null;
    },
  },
});

export const {
  opened: editGridsDialogOpened,
  updated: editGridsDialogUpdated,
  cleared: editGridsDialogCleared,
  closed: editGridsDialogClosed,
} = editGridsSlice.actions;

export const selectEditGridsDialogOpen = createSelector(
  (state: RootState) => state.dialogs.editGridsDialog.open,
  (editGridsDialogOpen) => editGridsDialogOpen
);

export const selectEditGridsDialogGridName = createSelector(
  (state: RootState) => state.dialogs.editGridsDialog,
  (editGridsDialog) => editGridsDialog.name
);

export const selectEditGridsDialogTiles = createSelector(
  (state: RootState) => state.dialogs.editGridsDialog,
  (editGridsDialog) => editGridsDialog.allTiles
);

export default editGridsSlice.reducer;
