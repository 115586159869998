export const getAssetIdFromTopic = (topic: string) => {
  const parts = topic.split("/");
  return parts[1];
};

function getPosition(string: string, subString: string, index: number) {
  return string.split(subString, index).join(subString).length;
}

export const getAssetTopic = (topic: string) => {
  const index = getPosition(topic, "/", 2);
  const entityTopic = topic.substring(index);
  return entityTopic;
};

export const getTopicWithoutPrefix = (topic: string) => {
  const index = getPosition(topic, "/", 1);
  const topicWithoutPrefix = topic.substring(index);
  return topicWithoutPrefix;
};
