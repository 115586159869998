import { env } from "../../env";
import { handleExceptionMessage } from "../../helpers/handleExceptionMessage";
import { instance } from "../axiosInstance";
import {
  LogOnTaskRequest,
  LogOffTaskRequest,
  TaskLogonsClient,
} from "../httpService";

const http = new TaskLogonsClient(env.REACT_APP_MES_API_URL, instance);

export const logOnTask = async (
  taskId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new LogOnTaskRequest({
      taskId,
    });
    const result = await http.logOnTask(request);
    return result;
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};

export const logOffTask = async (
  taskId: string,
  throwError: boolean = true
): Promise<void> => {
  try {
    const request = new LogOffTaskRequest({ taskId });
    await http.logOffTask(request);
  } catch (ApiException) {
    handleExceptionMessage(ApiException);
    if (throwError) throw ApiException;
  }
};
